import * as React from 'react';
import './HeartLike.scss';
import serviceFactory from '../../services/serviceFactory';
import UserService from '../../services/user/user.service';
import { useRecoilState } from 'recoil';
import globalState from '../../state/globalState';
import { getPageFinder, undefinedHandler } from '../../utils/undefinedHandler';

interface HeartLikeProps {
	destinationId?: number;
	destinationDetailsId: number;
}

const HeartLike: React.FC<HeartLikeProps> = (props) => {
	const userService = serviceFactory.get<UserService>('UserService');
	const [user, setUser] = useRecoilState<Api.User.Res.Detail | undefined>(globalState.user);

	const [destinationSelection, setDestinationSelection] = React.useState<any>([]);

	const redHeart = '../../images/accountTravelPreferences/redHeart.svg';
	const whiteHeart = '../../images/accountTravelPreferences/whiteHeart.svg';

	const preferencesData = async () => {
		try {
			const userFavoriteDestinations = await userService.userFavoriteDestinations(user?.id);

			const FavoriteDestinations = userFavoriteDestinations.data.myFavoriteDestinations;
			if (
				typeof FavoriteDestinations === 'undefined' ||
				FavoriteDestinations === null ||
				FavoriteDestinations === ''
			) {
				setDestinationSelection([]);
			} else {
				setDestinationSelection(JSON.parse(FavoriteDestinations));
			}
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	};

	React.useEffect(() => {
		if (user) {
			preferencesData();
		}
	}, [user]);

	const showhH = React.useMemo(() => {
		try {
			const data = destinationSelection.includes(props.destinationDetailsId) ? redHeart : whiteHeart;
			return data;
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}, [destinationSelection]);

	return (
		<img
			className="heartIcon"
			onClick={async () => {
				if (!destinationSelection.includes(props.destinationDetailsId)) {
					setDestinationSelection((DestinationSelectionList: any) => [
						...DestinationSelectionList,
						props.destinationDetailsId
					]);

					try {
						await userService.saveMyFavoriteDestinations(
							JSON.stringify([...destinationSelection, props.destinationDetailsId]),
							user?.id
						);
					} catch (error: any) {
						const message = `Getting error :${error.message} on ${getPageFinder(
							window.location.pathname
						)} page.`;
						undefinedHandler(message);
					}
				} else {
					setDestinationSelection(
						destinationSelection.filter((item: any) => item !== props.destinationDetailsId)
					);

					try {
						await userService.saveMyFavoriteDestinations(
							JSON.stringify(
								destinationSelection.filter((item: any) => item !== props.destinationDetailsId)
							),
							user?.id
						);
					} catch (error: any) {
						const message = `Getting error :${error.message} on ${getPageFinder(
							window.location.pathname
						)} page.`;
						undefinedHandler(message);
					}
				}
			}}
			src={showhH}
			alt="Add Card"
		/>
	);
};

export default React.memo(HeartLike);
