import * as React from 'react';
import './TopSearchBarResponsive.scss';
import { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import Button from '@bit/redsky.framework.rs.button';
import Icon from '@bit/redsky.framework.rs.icon';
import { RsFormControl, RsFormGroup } from '@bit/redsky.framework.rs.form';
import Label from '@bit/redsky.framework.rs.label/dist/Label';
import { rsToastify } from '@bit/redsky.framework.rs.toastify';
import { Box } from '@bit/redsky.framework.rs.996';
import serviceFactory from '../../../services/serviceFactory';
import RegionService from '../../../services/region/region.service';
import { formatFilterDateForServer, ObjectUtils, WebUtils } from '../../../utils/utils';
import DateRangeSelector from '../../dateRangeSelector/DateRangeSelector';
import TitleLabel from '../titleLabel/TitleLabel';
import Paper from '../../paper/Paper';
import Counter from '../../counter/Counter';
import { useRecoilState } from 'recoil';
import globalState from '../../../state/globalState';
import LabelButton from '../../labelButton/LabelButton';
import useOnClickOutsideRef from '../../../customHooks/useOnClickOutsideRef';
import themes from '../../../themes/themes.scss';
import UserService from '../../../services/user/user.service';
import { getPageFinder, undefinedHandler } from '../../../utils/undefinedHandler';
import { useNavigate } from 'react-router-dom';

interface TopSearchBarResponsiveProps {}
interface UserSearchHistory {
	id: number;
	sessionId: number;
	sessionSearchHistory: any;
	userId: number;
}

const TopSearchBarResponsive: React.FC<TopSearchBarResponsiveProps> = (props) => {
	const regionService = serviceFactory.get<RegionService>('RegionService');
	const userService = serviceFactory.get<UserService>('UserService');
	const [reservationFilters, setReservationFilters] = useRecoilState<Misc.ReservationFilters>(
		globalState.reservationFilters
	);
	const [regionName, setRegionName] = useRecoilState<string>(globalState.regionName);
	const [startDateControl, setStartDateControl] = useState<moment.Moment | null>(
		moment(reservationFilters.startDate)
	);
	const [endDateControl, setEndDateControl] = useState<moment.Moment | null>(moment(reservationFilters.endDate));
	const [user, setUser] = useRecoilState<Api.User.Res.Detail | undefined>(globalState.user);
	const [unknownUserSearchHistoryData, setUnknownUserSearchHistoryData] = useState<any>();
	const [unknownUserSearchHistoryId, setUnknownUserSearchHistoryId] = useState<string>('');
	const [isFetchingResults, setIsFetchingResults] = useRecoilState<boolean>(globalState.isFetchingResults);
	const [searchHistoryShow, setSearchHistoryShow] = useRecoilState<number>(globalState.searchHistoryShow);
	const [userSearchHistory, setUserSearchHistory] = useState<UserSearchHistory[]>([]);

	const [focusedInput, setFocusedInput] = useState<'startDate' | 'endDate' | null>(null);
	const [regionList, setRegionList] = useState<Api.Region.Res.Get[]>([]);
	// const [selectedCategory, setSelectedCategory] = useState<number[]>([]);
	const [selectedCategory, setSelectedCategory] = useRecoilState<any | []>(globalState.regionNameListId);
	const navigate = useNavigate();
	const [searchRegionParameter, setSearchRegionParameter] = useState<string>('');
	const [regionNameList, setRegionNameList] = useRecoilState<any | []>(globalState.regionNameList);
	const [topSearchBarForm, setTopSearchBarForm] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl('regionIds', reservationFilters.regionIds || [], []),
			new RsFormControl('adultCount', reservationFilters.adultCount || 1, []),
			new RsFormControl('childCount', reservationFilters.childCount || 0, []),
			new RsFormControl('startDate', (reservationFilters.startDate as string) || '', []),
			new RsFormControl('endDate', (reservationFilters.endDate as string) || '', [])
		])
	);
	const labelInputRef = useRef<HTMLElement>(null);
	const [popupOpened, setPopupOpened] = useState<boolean>(false);
	const dropdownRef = useOnClickOutsideRef(() => {
		if (popupOpened) setPopupOpened(false);
	});
	const backUpArrow = '../../../images/backUpArrow.svg';

	const [isDisabled, setIsDisabled] = useState<boolean>(false);
	useEffect(() => {
		(async () => {
			try {
				let regions: Api.Region.Res.Get[] = await regionService.getAllRegions();
				setRegionList(regions);
				const newRegionName = regionList.find((item) => reservationFilters.regionIds?.includes(item.id));
				setRegionName(newRegionName ? newRegionName.name : regionName);
				let topSearchBarFormCopy = topSearchBarForm.clone();
				let regionIdControl = topSearchBarFormCopy.get('regionIds');
				let adultCountControl = topSearchBarFormCopy.get('adultCount');
				let childCountControl = topSearchBarFormCopy.get('childCount');
				let startDateControl = topSearchBarFormCopy.get('startDate');
				let endDateControl = topSearchBarFormCopy.get('endDate');
				regionIdControl.value = reservationFilters.regionIds || [];
				adultCountControl.value = reservationFilters.adultCount;
				childCountControl.value = reservationFilters.childCount;
				startDateControl.value = (reservationFilters.startDate as string) || '';
				endDateControl.value = (reservationFilters.endDate as string) || '';
				topSearchBarFormCopy.update(regionIdControl);
				topSearchBarFormCopy.update(adultCountControl);
				topSearchBarFormCopy.update(childCountControl);
				topSearchBarFormCopy.update(startDateControl);
				topSearchBarFormCopy.update(endDateControl);
				setTopSearchBarForm(topSearchBarFormCopy);
				setStartDateControl(moment(reservationFilters.startDate));
				setEndDateControl(moment(reservationFilters.endDate));
			} catch (e) {
				rsToastify.error('There was an issue getting regions');
			}
		})();
	}, [reservationFilters]);

	useEffect(() => {
		let newControl = topSearchBarForm.get('regionIds');
		newControl.value = [...selectedCategory];
		setTopSearchBarForm(topSearchBarForm.clone().update(newControl));
	}, [selectedCategory, setSelectedCategory]);

	useEffect(() => {
		if (!user) {
			const data: any = localStorage.getItem('userSearchHistory');
			if (data) {
				setUnknownUserSearchHistoryId(data);
				getUnknownUserSearchHistory(data);
			}
		}
	}, []);

	useEffect(() => {
		user && getUserSearchHistory();
	}, [user]);

	async function getUnknownUserSearchHistory(data: any) {
		try {
			const sessiondata = await userService.getUnknownUserSearchHistory(JSON.stringify(data));
			setUnknownUserSearchHistoryData(sessiondata);
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	async function getUserSearchHistory() {
		try {
			const searchHistory = await userService.getUserSearchHistory();
			setUserSearchHistory(searchHistory);
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	async function onApplyClick() {
		try {
			const sessionId = moment(new Date()).valueOf().toString();

			if (!user) {
				if (!unknownUserSearchHistoryId) {
					const data = {
						sessionId: sessionId,

						startDate: topSearchBarForm.get('startDate').value as string,
						endDate: topSearchBarForm.get('endDate').value as string,
						regionIds: topSearchBarForm.get('regionIds').value as number[]
					};

					const sessiondata = await userService.saveSearchHistory(data);

					localStorage.setItem('userSearchHistory', sessionId);

					setUnknownUserSearchHistoryData(sessiondata);
				} 
				//else {
				//	const updateData = {
				//		sessionId: unknownUserSearchHistoryId,
				//		startDate: topSearchBarForm.get('startDate').value as string,
				//		endDate: topSearchBarForm.get('endDate').value as string,
				//		regionIds: topSearchBarForm.get('regionIds').value as number[]
				//	};

				//	const updateSessiondata = await userService.updateSaveSearchActivity(updateData);
				//	setUnknownUserSearchHistoryData(updateSessiondata);
				//}
			}
			if (user) {
				const sessionId = moment(new Date()).valueOf().toString();
				const data = {
					sessionId: sessionId,
					...reservationFilters,
					startDate: topSearchBarForm.get('startDate').value as string,
					endDate: topSearchBarForm.get('endDate').value as string,
					adultCount: topSearchBarForm.get('adultCount').value as number,
					childCount: topSearchBarForm.get('childCount').value as number,
					regionIds: topSearchBarForm.get('regionIds').value as number[]
				};

				const compareArrays = (arr1: number[], arr2: number[]) => {
					return arr1.some((element, index) => element != arr2[index]);
				};

				const previousSearch = userSearchHistory[0].sessionSearchHistory;

				if (
					data.startDate != previousSearch.startDate ||
					data.endDate != previousSearch.endDate ||
					data.adultCount != previousSearch.adultCount ||
					data.childCount != previousSearch.childCount ||
					compareArrays(data.regionIds, previousSearch.regionIds)
				) {
					(async () => {
						await userService.saveSearchHistory(data);
						getUserSearchHistory();
					})();
				}
			}

			setReservationFilters((prev) => {
				const newFilter: Misc.ReservationFilters = {
					...prev,
					startDate: topSearchBarForm.get('startDate').value as string,
					endDate: topSearchBarForm.get('endDate').value as string,
					adultCount: topSearchBarForm.get('adultCount').value as number,
					childCount: topSearchBarForm.get('childCount').value as number,
					regionIds: topSearchBarForm.get('regionIds').value as number[]
				};

				setSearchRegionParameter('');

				// WebUtils.updateUrlParams(newFilter);
				return newFilter;
			});
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	async function onApplyClickResumeYourJourney() {
		try {
			setSelectedCategory([...unknownUserSearchHistoryData.regionIds]);
			onDatesChange(moment(unknownUserSearchHistoryData.startDate), moment(unknownUserSearchHistoryData.endDate));
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	async function onApplyClickUserResumeYourJourney(object: any, index: number) {
		setSearchHistoryShow(searchHistoryShow + 1);
		const sessionId = moment(new Date()).valueOf().toString();
		const data = {
			sessionId: sessionId,
			...object
		};
		index != 0 &&
			(async () => {
				await userService.saveSearchHistory(data);
				getUserSearchHistory();
			})();
		setReservationFilters((prev) => {
			const newFilter: Misc.ReservationFilters = {
				...prev,
				...object
			};
			setSearchRegionParameter('');

			return newFilter;
		});

		onDatesChange(moment(object.startDate), moment(object.endDate));
		if (object.destinationName) {
			const destinationName = object.destinationName.split(' ').join('-').toLocaleLowerCase();
			navigate(`/destination/${destinationName}`, {
				state: {
					...reservationFilters,
					destinationId: object.destinationId
				}
			});
		}
		setPopupOpened(false);
	}

	function updateTopSearchBarForm(control: RsFormControl) {
		if (control.key === 'adultCount' && labelInputRef.current) {
			const dataGuestCount = !!control.value ? `${control.value} guests` : '';
			labelInputRef.current.setAttribute('data-guest-count', dataGuestCount);
		}
		setTopSearchBarForm(topSearchBarForm.clone().update(control));
	}

	function updateDatesForSearchBarForm(key: 'startDate' | 'endDate', value: any) {
		let newControl = topSearchBarForm.get(key);
		newControl.value = value;
		setTopSearchBarForm(topSearchBarForm.clone().update(newControl));
	}

	function onDatesChange(startDate: moment.Moment | null, endDate: moment.Moment | null): void {
		setStartDateControl(startDate);
		setEndDateControl(endDate);
		if (endDate === null) {
			setIsDisabled(true);
			rsToastify.error('Make sure you select a check out date!', 'No Check Out Date Selected');
		} else {
			setIsDisabled(false);
		}
		updateDatesForSearchBarForm('startDate', formatFilterDateForServer(startDate, 'start'));
		updateDatesForSearchBarForm('endDate', formatFilterDateForServer(endDate, 'end'));
	}

	function totalGuests(): number {
		return (
			((topSearchBarForm.get('adultCount').value as number) || reservationFilters.adultCount) +
			((topSearchBarForm.get('childCount').value as number) || reservationFilters.childCount)
		);
	}

	const onChangeCategory = (event: any, item: any) => {
		let val: string = event.target?.value;
		// props.onChangeSortBy(event.target.value);
		if (event.target?.checked && !regionNameList.includes(item.name)) {
			setSelectedCategory([...selectedCategory, item.id]);
			setRegionNameList([...regionNameList, item.name]);
		} else if (!event.target?.checked && regionNameList.includes(item.name)) {
			setSelectedCategory(selectedCategory.filter((o: any) => o != item.id));
			setRegionNameList(regionNameList.filter((o: any) => o != item.name));
		}
	};

	function regionListBuilder(searchValue: string) {
		if (!ObjectUtils.isArrayWithData(regionList)) return;

		let filteredRegion = regionList.filter((regionObj: any) => {
			return regionObj.name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1;
		});

		if (filteredRegion.length === 0) {
			return 'No Region Found';
		}

		return filteredRegion.map((item, index) => {
			let isSelected = topSearchBarForm.get('regionIds').value === item.id;
			return (
				<label htmlFor={`regionCheckBox${index}`}>
					<Box
						key={item.id}
						className={`regionItem ${isSelected ? 'selected' : ''}`}
						onClick={() => {
							// let newControl = topSearchBarForm.get('regionIds');
							// newControl.value = [item.id];
							// setTopSearchBarForm(topSearchBarForm.clone().update(newControl));
							// setPopupOpened(false);
						}}
						display={'flex'}
						alignItems={'center'}
					>
						<div className={`rsLabelCheckboxFilterBar  ''}`}>
							<label className={'checkboxContainer'}>
								<input
									id={`regionCheckBox${index}`}
									value={item.name}
									type={'checkbox'}
									className={'checkboxInput'}
									checked={selectedCategory.includes(item.id)}
									onChange={(e) => onChangeCategory(e, item)}
								/>
							</label>
							<Label variant={'h6'} lineClamp={2} className="titleLabel">
								{item.name}
							</Label>
						</div>
					</Box>
				</label>
			);
		});
	}

	function renderRegionLabel() {
		let regionId = topSearchBarForm.get('regionIds').value as number[];
		if (!ObjectUtils.isArrayWithData(regionId)) return 'Where are you going?';
		const tempRegionList: any = [];
		regionId.map((item1: number, index: number) => {
			const regionObj = regionList.find((item: any) => item.id == item1);
			if (regionObj) {
				tempRegionList.push(regionObj.name);
			}
		});
		let regionlist = tempRegionList ? tempRegionList.join() : '';
		return 'Where are you going?';
	}

	function savedSearches() {
		try {
			const tempRegionList: any = [];
			unknownUserSearchHistoryData?.regionIds?.map((item1: number, index: number) => {
				const regionObj = regionList?.find((item: any) => item.id == item1);
				if (regionObj) {
					tempRegionList.push(regionObj.name);
				}
			});

			let regionlist = tempRegionList ? tempRegionList.join() : '';

			return (
				<Box
					className="savedSearches"
					onClick={() => {
						onApplyClickResumeYourJourney();
						setSearchHistoryShow(searchHistoryShow + 1);
					}}
				>
					<Label className="savedRegionlist">
						{regionlist.length <= 30 ? `${regionlist}` : `${regionlist.slice(0, 30)}...`}
					</Label>
					<Label className="savedDate">
						{moment(unknownUserSearchHistoryData?.startDate).format('MM/DD/YYYY')} -{' '}
						{moment(unknownUserSearchHistoryData?.endDate).format('MM/DD/YYYY')}
					</Label>
					<Box className="backUpArrowBox" mt={5}>
						<Label className="savedDate">Resume your journey</Label>
						<img src={backUpArrow} className="backUpArrow" alt="backUpArrow" />
					</Box>
				</Box>
			);
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}
	return (
		<Paper boxShadow borderRadius={'20px'} className={'rsTopSearchBarResponsive'}>
			<div className="rsTitleLabel locationInput">
				<Box>
					<Label variant={`h6`} marginBottom={10}>
						Location
					</Label>
					<Label
						onClick={() => setPopupOpened((prevState) => !prevState)}
						className="renderRegionLabel"
						variant={`body1`}
					>
						<input
							className="searchInputField"
							onChange={(e) => {
								if (popupOpened === false) setPopupOpened(true);
								setSearchRegionParameter(e.target.value);
							}}
							value={searchRegionParameter}
							placeholder={renderRegionLabel() || ''}
						/>
					</Label>
				</Box>
				<Box
					boxRef={dropdownRef}
					className={`popupBox ${popupOpened ? 'show' : 'hide'}`}
					padding={'24px'}
					id={'dropdownBoxId'}
				>
					<div className={'regionList'}>
						{!user &&
						unknownUserSearchHistoryId &&
						unknownUserSearchHistoryData &&
						!isFetchingResults &&
						searchHistoryShow === 0 ? (
							<>
								{savedSearches()} <hr />
							</>
						) : null}
						{regionListBuilder(searchRegionParameter)}
						<hr />
					</div>
					<Box className="cardFooter">
						<LabelButton
							variant="buttonSm"
							label="Clear"
							look="none"
							onClick={() => {
								setSearchRegionParameter('');
								setRegionName('');
								setSelectedCategory([]);
								setRegionNameList([]);
								setReservationFilters((prev) => {
									const newFilter: Misc.ReservationFilters = {
										...prev,
										regionIds: []
									};

									// WebUtils.updateUrlParams(newFilter);
									return newFilter;
								});
								setPopupOpened(false);
							}}
						/>
						{/* <LabelButton
						className={'applyButton'}
						look={'containedPrimary'}
						variant={'buttonSm'}
						label={'Apply'}
						onClick={() => {						
							let newControl = topSearchBarForm.get('regionIds');
							newControl.value = selectedCategory;
							setTopSearchBarForm(topSearchBarForm.clone().update(newControl));
						}}
					/> */}
					</Box>
				</Box>
				{!!user && !isFetchingResults && !!userSearchHistory && userSearchHistory.length > 0 && (
					<Box
						boxRef={dropdownRef}
						className={`popupBox ${popupOpened ? 'show' : 'hide'} resumeJourneyBox`}
						padding={'24px'}
						id={'resumeYourJourney'}
					>
						<div className={'resumeJourney'}>
							<Label variant="h6" marginBottom={15}>
								Resume Your Journey
							</Label>
							{userSearchHistory.map((item, index) => {
								const obj = item.sessionSearchHistory;
								let regionlist = '';
								if (!!obj.regionIds && obj.regionIds.length > 0) {
									let array = obj.regionIds.map((o: number) => {
										let obj2 = regionList.find((item: any) => item.id == o);
										return obj2 ? obj2.name : '';
									});
									regionlist = array.join();
								}

								return (
									<React.Fragment key={index}>
										<Box
											className="historyItem"
											onClick={() => onApplyClickUserResumeYourJourney(obj, index)}
										>
											{!!obj.destinationName && !!obj.destinationId && (
												<Label variant={'body1'}>
													{obj.destinationName.length <= 30
														? `${obj.destinationName}`
														: `${obj.destinationName.slice(0, 30)}...`}
												</Label>
											)}
											{!!regionlist && regionlist.length > 0 && (
												<Label variant={'body1'}>
													{regionlist.length <= 30
														? `${regionlist}`
														: `${regionlist.slice(0, 30)}...`}
												</Label>
											)}
											<Label variant={'body1'} lineClamp={2} className="historySearchDate">
												{moment(item.sessionSearchHistory.startDate).format('MM/DD/YYYY')}
												{' - '}
												{moment(item.sessionSearchHistory.endDate).format('MM/DD/YYYY')}
											</Label>
										</Box>
										<hr style={{ color: '#f1f1f1', marginTop: 12, marginBottom: 12 }} />
									</React.Fragment>
								);
							})}
						</div>
					</Box>
				)}
			</div>
			<hr />
			<DateRangeSelector
				startDate={startDateControl}
				endDate={endDateControl}
				onDatesChange={onDatesChange}
				monthsToShow={2}
				focusedInput={focusedInput}
				onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
				startDateLabel={'Check in'}
				endDateLabel={'Check out'}
				startDatePlaceholderText={'Add dates'}
				endDatePlaceholderText={'Add dates'}
				labelVariant={'h6'}
				showSeparator
			/>
			<hr />
			<TitleLabel
				title={'Guests'}
				label={`${topSearchBarForm.get('adultCount').value} Adults, ${
					topSearchBarForm.get('childCount').value
				} Children`}
				titleVariant={'h6'}
				labelVariant={'body1'}
				titleLabelRef={labelInputRef}
			>
				<Counter
					title={'Adults'}
					control={topSearchBarForm.get('adultCount')}
					updateControl={updateTopSearchBarForm}
					minCount={1}
					maxCount={99}
					labelMarginRight={84}
				/>
				<Counter
					title={'Children'}
					control={topSearchBarForm.get('childCount')}
					updateControl={updateTopSearchBarForm}
					labelMarginRight={84}
					minCount={0}
					maxCount={99}
				/>
			</TitleLabel>
			<Button
				className={'searchButton'}
				look={'none'}
				onClick={() => {
					onApplyClick();
					setSearchHistoryShow(searchHistoryShow + 1);
				}}
				disabled={isDisabled}
			>
				<Icon iconImg={'icon-search'} color={themes.white} size={36} />
			</Button>
		</Paper>
	);
};

export default TopSearchBarResponsive;
