// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rsTermsOfUsePage .centerAlign{margin-top:80px;text-align:center}.rsTermsOfUsePage ol{list-style:lower-alpha}.rsTermsOfUsePage a{font-weight:bold;text-decoration:underline !important}
`, "",{"version":3,"sources":["webpack://./src/pages/termsOfUsePage/TermsOfUsePage.scss"],"names":[],"mappings":"AAEA,+BAEE,eAAgB,CAChB,iBAAkB,CAHpB,qBAME,sBAAuB,CANzB,oBASE,gBAAiB,CACjB,oCAAqC","sourcesContent":["@import '../../themes/themes';\n\n.rsTermsOfUsePage {\n\t.centerAlign {\n\t\tmargin-top: 80px;\n\t\ttext-align: center;\n\t}\n\tol {\n\t\tlist-style: lower-alpha;\n\t}\n\ta {\n\t\tfont-weight: bold;\n\t\ttext-decoration: underline !important;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primaryTextColor": `var(--primaryTextColor)`,
	"secondaryTextColor": `var(--secondaryTextColor)`,
	"successGreen": `var(--successGreen)`,
	"adminPink": `var(--adminPink)`,
	"warningRed": `var(--warningRed)`,
	"buttonLinkColor": `var(--buttonLinkColor)`,
	"secondaryButtonLinkColor": `var(--secondaryButtonLinkColor)`,
	"iconAndHighlightTextColor": `var(--iconAndHighlightTextColor)`,
	"primaryBackgroundColor": `var(--primaryBackgroundColor)`,
	"secondaryBackgroundColor": `var(--secondaryBackgroundColor)`,
	"errorColor": `var(--errorColor)`,
	"primaryBoxShadowColor": `var(--primaryBoxShadowColor)`,
	"greyLineColor": `var(--greyLineColor)`,
	"thinGreyLineColor": `var(--thinGreyLineColor)`,
	"white": `var(--white)`,
	"lightestGray": `var(--lightestGray)`,
	"backgroundGray": `var(--backgroundGray)`,
	"borderGray": `var(--borderGray)`,
	"medGray2": `var(--medGray2)`,
	"medGray2Active": `var(--medGray2Active)`,
	"black": `var(--black)`,
	"primaryCalendarColor": `var(--primaryCalendarColor)`,
	"secondaryCalendarColor": `var(--secondaryCalendarColor)`
};
export default ___CSS_LOADER_EXPORT___;
