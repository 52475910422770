import React, { Dispatch, memo, useCallback } from 'react';
import { Map, Marker, InfoWindow } from 'google-maps-react';
import './gMap.scss';
import useIsAtBreakpoint from '../../customHooks/useIsAtBreakpoint';

interface MapProps extends google.maps.MapOptions {
	style: { [key: string]: string };
	isModalOpen?: boolean;
	setShowMapModal?: Dispatch<React.SetStateAction<boolean>>;
	onClickPicker: Function;
	destinationDetails: Api.Destination.Res.Details;
	currentLocation: {
		latitude: number;
		longitude: number;
	};
}

const GoogleMap: React.FC<MapProps> = (props) => {
	const isMobile = useIsAtBreakpoint();
	const [center, setCenter] = React.useState<google.maps.LatLngLiteral | null>(null);

	const containerStyle = {
		position: 'absolute',
		width: 'inherit',
		height: 'inherit'
	};

	const [activeMarker, setActiveMarker] = React.useState<any | null>(null);

	const marker = new google.maps.Marker();
	let map!: google.maps.Map;

	const getCurrentLocation = () => {
		return { lat: props.currentLocation.latitude, lng: props.currentLocation.longitude };
	};

	React.useEffect(() => {
		setCenter({ lat: props.currentLocation.latitude, lng: props.currentLocation.longitude });
	}, [props.currentLocation]);

	// React.useEffect(() => {
	// 	if (props.currentLocation.latitude == 0 || props.currentLocation.latitude == 0) {
	// 		setCenter({
	// 			lat: props.servicesList && props.servicesList.length > 0 ? props.servicesList[0].latitude : 0,
	// 			lng: props.servicesList && props.servicesList.length > 0 ? props.servicesList[0].longitude : 0
	// 		});
	// 	}
	// }, [props.servicesList]);

	const MarkerIcon = useCallback(() => {
		return (
			<Marker
				position={getCurrentLocation()}
				title={props.destinationDetails.name}
				onClick={() => {
					if (props.isModalOpen !== undefined && props.setShowMapModal !== undefined) {
						if (props.isModalOpen === false) {
							props.setShowMapModal(true);
						}
					}
				}}
				icon={{
					url: require('../../images/googleMapIcons/mapMarkerBlue.png')
				}}
			/>
		);
	}, [props.destinationDetails]);

	return (
		<div style={props.style}>
			<Map
				google={google}
				containerStyle={containerStyle}
				disableDefaultUI={true}
				minZoom={3}
				maxZoom={12}
				// center={
				// 	props.servicesList && props.servicesList.length > 0
				// 		? { lat: props.servicesList[0].latitude, lng: props.servicesList[0].latitude }
				// 		: undefined
				// }
				// center={}
				// centerAroundCurrentLocation
				onCenterChanged={(e) => {
					e && e.center && setCenter(e.center);
				}}
				center={center ? center : getCurrentLocation()}
			>
				{/* {props.servicesList &&
					props.servicesList?.map((o, i) => (
						<Marker
							title={'The marker`s title will appear as a tooltip.'}
							position={{ lat: o.latitude, lng: o.longitude }}
							key={i}
							icon={{
								url: require('../../images/homePage/mapMarkerBlue.png')
							}}
							onClick={() => {
								props.onClickPicker(o);
								!isMobile && setActiveMarker(o);
							}}
							// onMouseover={() => {
							// 	setActiveMarker(o);
							// }}
						/>
					))} */}

				<InfoWindow
					// onClose={()=>setActiveMarker(null)}
					position={{
						lat: activeMarker ? activeMarker.latitude : 0,
						lng: activeMarker ? activeMarker.longitude : 0
					}}
					visible={activeMarker ? true : false}
					google={google}
					marker={marker}
					map={map}
				>
					<div className="brandInfoOnHover">
						<img
							style={{ width: 250 }}
							src={
								activeMarker?.heroUrl == null
									? 'https://ik.imagekit.io/spire/noImageFound.png?tr=w-$500,tr=h-500'
									: activeMarker?.heroUrl?.toString()
							}
						/>
						<h2 className="brandTitle">{activeMarker?.name}</h2>
						<p className="brandDescription">{activeMarker?.description}</p>
					</div>
				</InfoWindow>

				{MarkerIcon()}
			</Map>
		</div>
	);
};

export default memo(GoogleMap);
