import * as React from 'react';
import './DestinationImageGallery.scss';
import Img from '@bit/redsky.framework.rs.img';
import Icon from '@bit/redsky.framework.rs.icon';
import Label from '@bit/redsky.framework.rs.label';
import Button from '@bit/redsky.framework.rs.button';
import { useEffect, useState } from 'react';
import { popupController } from '@bit/redsky.framework.rs.996';
import LightBoxCarouselPopup, {
	TabbedCarouselPopupProps
} from '../../popups/lightBoxCarouselPopup/LightBoxCarouselPopup';
import { ObjectUtils } from '../../utils/utils';
import PointsLabelBoxDestination from '../pointsLabelBox/PointsLabelBoxDestination';
import themes from '../../themes/themes.scss';
import { IKImage } from 'imagekitio-react';
import { getPageFinder, undefinedHandler } from '../../utils/undefinedHandler';

interface DestinationImageGalleryProps {
	onGalleryClick: () => void;
	imageData: Api.Media[];
	resortPointsData: any;
}

const DestinationImageGallery: React.FC<DestinationImageGalleryProps> = (props) => {
	const [primaryImage, setPrimaryImage] = useState<Api.Media>();
	const [nonPrimaryImages, setNonPrimaryImages] = useState<Api.Media[]>([]);

	// React.useEffect(() => {
	// 	window.scrollTo(0, 0);
	// }, []);

	useEffect(() => {
		let primaryImg = props.imageData.find((item) => item.isPrimary);
		if (!primaryImg) primaryImg = props.imageData[0];
		let nonPrimaryImgs = props.imageData
			.sort((a: any, b: any) => a.mediaIndex - b.mediaIndex)
			.filter((item) => !item.isPrimary);
		setNonPrimaryImages(nonPrimaryImgs);

		setPrimaryImage(primaryImg);
	}, [props.imageData]);

	function renderPrimaryImage() {
		try {
			if (!primaryImage) return;
			return (
				<IKImage
					className={'imageOne IKImage'}
					src={primaryImage!.urls.imageKit}
					alt={'Primary Destination Image'}
					width={'1177px'}
					height={'auto'}
					loading="lazy"
					onClick={() => {
						popupController.open<TabbedCarouselPopupProps>(LightBoxCarouselPopup, {
							imageData: [primaryImage, ...nonPrimaryImages]
						});
					}}
				/>
			);
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	function renderNonPrimaryImageSrc(index: number): string {
		try {
			if (!ObjectUtils.isArrayWithData(nonPrimaryImages) || nonPrimaryImages.length <= index) {
				return require('../../images/noImageFound.png');
			} else {
				return nonPrimaryImages[index].urls.imageKit;
			}
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
			return '';
		}
	}

	function renderNonPrimaryImages() {
		if (!nonPrimaryImages || !primaryImage) return;
		return (
			<React.Fragment>
				<Img
					className={'imageTwo IKImage'}
					loading="lazy"
					src={renderNonPrimaryImageSrc(0)}
					alt={'Destination Image'}
					width={'612px'}
					height={'auto'}
					onClick={() => {
						if (nonPrimaryImages.length <= 0) return;
						popupController.open<TabbedCarouselPopupProps>(LightBoxCarouselPopup, {
							imageData: [primaryImage, ...nonPrimaryImages],
							defaultImageIndex: 1
						});
					}}
				/>
				<div className={'imageThree '}>
					<Img
						className={'IKImage'}
						loading="lazy"
						src={renderNonPrimaryImageSrc(1)}
						alt={'Destination Image'}
						width={'612px'}
						height={'auto'}
						onClick={() => {
							if (nonPrimaryImages.length <= 1) return;
							popupController.open<TabbedCarouselPopupProps>(LightBoxCarouselPopup, {
								imageData: [primaryImage, ...nonPrimaryImages],
								defaultImageIndex: 2
							});
						}}
					/>
					<Button
						look={'none'}
						className={'imageCountContainer'}
						onClick={(event) => {
							event.stopPropagation();
							props.onGalleryClick();
						}}
					>
						<Icon iconImg={'icon-gallery'} color={themes.white} size={18} />
						<Label variant={'buttonSm'}>{`${props.imageData.length}`}</Label>
					</Button>
				</div>
			</React.Fragment>
		);
	}

	return (
		<div className={'rsDestinationImageGallery'}>
			{/* PointLabeltextTag (don't delete)
				<PointsLabelBoxDestination resortPoint={props.resortPointsData} /> 
			*/}
			{renderPrimaryImage()}
			{renderNonPrimaryImages()}
		</div>
	);
};

export default DestinationImageGallery;
