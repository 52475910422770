// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rsReservationInfoCard{display:flex}.rsReservationInfoCard .rsButton{justify-self:center}.rsReservationInfoCard .rsIcon{margin-right:8px}.rsReservationInfoCard .rsIcon:before{color:var(--primaryTextColor)}
`, "",{"version":3,"sources":["webpack://./src/components/reservationInfoCard/ReservationInfoCard.scss","webpack://./src/themes/_mixins.scss"],"names":[],"mappings":"AAEA,uBACC,YAAa,CADd,iCAIE,mBAAoB,CAJtB,+BAQE,gBAAiB,CARnB,sCAUG,6BCagB","sourcesContent":["@import '../../themes/themes';\n\n.rsReservationInfoCard {\n\tdisplay: flex;\n\n\t.rsButton {\n\t\tjustify-self: center;\n\t}\n\n\t.rsIcon {\n\t\tmargin-right: 8px;\n\t\t&:before {\n\t\t\tcolor: theme-var($primaryTextColor);\n\t\t}\n\t}\n}\n","@import 'variables';\n\n/*\n  include a map in a class\n*/\n@mixin spread-map($map: ()) {\n\t@each $key, $value in $map {\n\t\t#{$key}: $value;\n\t}\n}\n\n/*\n  Returns a CSS variable property specific to themeing.\n  Accepts an optional fallback, and optional map.\n  - SCSS: color: theme-var($--theme-primary, blue)\n  - CSS:  color: var(--theme-primary, blue)\n  Throws error if the variable not part of the map\n*/\n@function theme-var($key, $fallback: null, $map: $defaultTheme) {\n\t@if not map-has-key($map, $key) {\n\t\t@error \"key: '#{$key}', is not a key in map: #{$map}\";\n\t}\n\t@if ($fallback) {\n\t\t@return var($key, $fallback);\n\t} @else {\n\t\t@return var($key);\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primaryTextColor": `var(--primaryTextColor)`,
	"secondaryTextColor": `var(--secondaryTextColor)`,
	"successGreen": `var(--successGreen)`,
	"adminPink": `var(--adminPink)`,
	"warningRed": `var(--warningRed)`,
	"buttonLinkColor": `var(--buttonLinkColor)`,
	"secondaryButtonLinkColor": `var(--secondaryButtonLinkColor)`,
	"iconAndHighlightTextColor": `var(--iconAndHighlightTextColor)`,
	"primaryBackgroundColor": `var(--primaryBackgroundColor)`,
	"secondaryBackgroundColor": `var(--secondaryBackgroundColor)`,
	"errorColor": `var(--errorColor)`,
	"primaryBoxShadowColor": `var(--primaryBoxShadowColor)`,
	"greyLineColor": `var(--greyLineColor)`,
	"thinGreyLineColor": `var(--thinGreyLineColor)`,
	"white": `var(--white)`,
	"lightestGray": `var(--lightestGray)`,
	"backgroundGray": `var(--backgroundGray)`,
	"borderGray": `var(--borderGray)`,
	"medGray2": `var(--medGray2)`,
	"medGray2Active": `var(--medGray2Active)`,
	"black": `var(--black)`,
	"primaryCalendarColor": `var(--primaryCalendarColor)`,
	"secondaryCalendarColor": `var(--secondaryCalendarColor)`
};
export default ___CSS_LOADER_EXPORT___;
