import * as React from 'react';
import './SigninPopup.scss';
import { FormEvent, useState, useEffect } from 'react';
import { Box, Popup, popupController } from '@bit/redsky.framework.rs.996';
import Label from '@bit/redsky.framework.rs.label/dist/Label';
import Paper from '../../components/paper/Paper';
import Icon from '@bit/redsky.framework.rs.icon';
import { useRecoilState, useRecoilValue } from 'recoil';
import globalState from '../../state/globalState';
import { RsFormControl, RsFormGroup, RsValidator, RsValidatorEnum } from '@bit/redsky.framework.rs.form';
import { PopupProps } from '@bit/redsky.framework.rs.996/dist/popup/Popup';
import LabelInput from '../../components/labelInput/LabelInput';
import serviceFactory from '../../services/serviceFactory';
import UserService from '../../services/user/user.service';
import SignupPopup, { SignupPopupProps } from '../signup/SignupPopup';
import LabelButton from '../../components/labelButton/LabelButton';
import SpinningLoaderPopup, { SpinningLoaderPopupProps } from '../spinningLoaderPopup/SpinningLoaderPopup';
import ForgotPasswordPopup, { ForgotPasswordPopupProps } from '../forgotPasswordPopup/ForgotPasswordPopup';
import OnboardingFlowOne from '../../components/onboardingFlow/OnboardingFlowOne';
import router from '../../utils/router';
import { rsToastify } from '@bit/redsky.framework.rs.toastify';
import VerifyEmailPopup, { VerifyEmailPopupProps } from '../verifyEmailPopup/VerifyEmailPopup';
import { WebUtils } from '../../utils/utils';
import OnboardingFlowOtp, { OnboardingFlowOtpProps } from '../../components/onboardingFlow/OnboardingFlowOtp';

export interface SigninPopupProps extends PopupProps {
	successCallback?: (data: any) => any;
	user?: boolean;
}

const SigninPopup: React.FC<SigninPopupProps> = (props) => {
	// const userRole = useRecoilValue<string>(globalState.userRoleId);
	const user = useRecoilValue<Api.User.Res.Detail | undefined>(globalState.user);
	const userService = serviceFactory.get<UserService>('UserService');
	const [errorMessage, setErrorMessage] = useState<string>('');
	const [signinForm, setSigninForm] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl('primaryEmail', '', [
				new RsValidator(RsValidatorEnum.EMAIL, 'Please enter a valid email address')
			]),
			new RsFormControl('password', '', [new RsValidator(RsValidatorEnum.REQ, 'Please enter a password')])
		])
	);
	const fullProps = popupController.convertProps(props);

	useEffect(() => {
		window.history.pushState(null, 'SigninPopup Close', window.location.pathname + window.location.search);
		window.addEventListener('popstate', (e: PopStateEvent) => {
			e.preventDefault();
			popupController.close(SigninPopup);
		});
		const id = router.subscribeToBeforeRouterNavigate(async () => {
			router.getCurrentPath();
			return true;
		});
		return () => {
			router.unsubscribeFromBeforeRouterNavigate(id);
			window.removeEventListener('popstate', (e: PopStateEvent) => {
				e.preventDefault();
				popupController.close(SigninPopup);
			});
		};
	}, []);

	function updateForm(control: RsFormControl) {
		setSigninForm(signinForm.clone().update(control));
		if (errorMessage !== '') {
			setErrorMessage('');
		}
	}

	async function signIn(event: FormEvent<HTMLFormElement>) {
		event.preventDefault();
		if (!(await signinForm.isValid())) {
			setSigninForm(signinForm.clone());
			setErrorMessage('Missing information');
			return;
		}
		const { primaryEmail, password } = signinForm.toModel<{ primaryEmail: string; password: string }>();
		try {
			popupController.open<SpinningLoaderPopupProps>(SpinningLoaderPopup, {});
			const userDetails = await userService.loginUserByPassword(primaryEmail, password);
			setErrorMessage('');
			rsToastify.success('Login successful');

			if (props.successCallback) props.successCallback(userDetails.data);
			// popupController.closeAll();
			popupController.close(SpinningLoaderPopup);
			popupController.close(SigninPopup);

			if (userDetails.data.userRoleId === 4 && userDetails.data.paymentMethods?.length == 0) {
				popupController.open(OnboardingFlowOne);
			}
		} catch (e: any) {
			popupController.close(SpinningLoaderPopup);
			if (e.response.data.msg == 'User not verified') {
				popupController.close(SigninPopup);
				const userDetails = await userService.loginUserByPasswordOtpAuth(primaryEmail, password);
				if (!userDetails.data.isVerified) {
					popupController.open<OnboardingFlowOtpProps>(OnboardingFlowOtp, {
						userResponse: userDetails.data,
						successCallback: () => {
							if (props.successCallback) props.successCallback(userDetails.data);
						}
					});
					return;
				}
			} else {
				setErrorMessage(WebUtils.getRsErrorMessage(e, 'Invalid username/password'));
			}
		}
	}
	return (
		<Popup {...props} preventCloseByBackgroundClick>
			<Paper className={'rsSigninPopup'} position={'relative'}>
				<Icon
					iconImg={'icon-close'}
					onClick={() => {
						popupController.closeById(fullProps.popupId);
					}}
					size={16}
					className={'closeBtn'}
					cursorPointer
				/>
				<Box className={'popupTitle'}>
					<img src={`../../images/rentyl-rewards.png`} className="rr-logo" alt="Rentyl Reward" />
				</Box>
				<Box className={'popupBody'}>
					<form onSubmit={signIn}>
						<LabelInput
							title={'Email Address'}
							inputType={'email'}
							control={signinForm.get('primaryEmail')}
							updateControl={updateForm}
							labelVariant={'h6'}
						/>
						<LabelInput
							title={'Password'}
							inputType={'password'}
							control={signinForm.get('password')}
							updateControl={updateForm}
							labelVariant={'h6'}
						/>
						<LabelButton
							look={'containedPrimary'}
							label={'Sign in'}
							variant={'buttonMdLg'}
							buttonType={'submit'}
						/>
					</form>
					<Label className={'errorMessage'} variant={'body2'} color={'red'}>
						{errorMessage}
					</Label>
					<LabelButton
						className={'forgotPasswordText'}
						look={'none'}
						variant={'h6'}
						label={'Forgot password?'}
						onClick={() => {
							popupController.closeById(fullProps.popupId);
							popupController.open<ForgotPasswordPopupProps>(ForgotPasswordPopup);
						}}
					/>
					<Box className={'orOption'}>
						<hr />
						<Label variant={'h6'} className={'medGray2'}>
							Or
						</Label>
						<hr />
					</Box>
					<Label className={'noAccount'} variant={'body1'} mb={18}>
						No account? Save time on your next booking.
					</Label>
					<LabelButton
						className={'yellow'}
						look={'containedPrimary'}
						onClick={() => {
							popupController.closeById(fullProps.popupId);
							popupController.open<SignupPopupProps>(SignupPopup, {
								primaryEmail: signinForm.get('primaryEmail').value as string,
								password: signinForm.get('password').value as string
							});
						}}
						label={'Sign up'}
						variant={'buttonMdLg'}
					/>
					<Box p={4} mt={8} display="flex" justifyContent="center" className="loginPopupQuestion">
						Have question?{' '}
						<a
							href="https://rentylresorts.com/contact-us/"
							style={{ marginLeft: 6, textDecoration: 'none' }}
						>
							Contact Us
						</a>
						.
					</Box>
				</Box>
			</Paper>
		</Popup>
	);
};

export default SigninPopup;
