import * as React from 'react';
import './PromoAgentCode.scss';
import { Box } from '@bit/redsky.framework.rs.996';
import { RsFormControl } from '@bit/redsky.framework.rs.form';
import useIsAtBreakpoint from '../../customHooks/useIsAtBreakpoint';
interface PromoAgentCodeProps {
	updateFilterForm: (control: RsFormControl | undefined) => void;
	savePromoCodeDatas: any;
	saveAgentId?: any;
	AgentId?: string;
	promoCode?: string;
	redeemPoints: boolean;
	promoCodeType: any;
	promoCodeTypeData?: any;
}

const PromoAgentCode: React.FC<PromoAgentCodeProps> = (props) => {
	const isMobile = useIsAtBreakpoint();
	return (
		<Box className={'rsPriceSlider'}>
			<Box display={isMobile ? 'block' : 'flex'} flexDirection={'row'} gap={10}>
				<select
					value={props.promoCodeTypeData}
					className="priceslider-promodropdown"
					name="promoType"
					onChange={(e) => {
						const selectElement = e.target.value;
						props.promoCodeType(selectElement);
					}}
				>
					<option value="PR">Promo Code</option>
					<option value="GC">Group Code</option>
				</select>

				<input
					type="text"
					className="priceslider-promofield"
					onChange={(e) => {
						const value = e.target.value;
						props.savePromoCodeDatas(value);
					}}
					value={props.promoCode}
					placeholder="Enter Code"
					name="promoCode"
				/>
			</Box>

			<input
				type="text"
				className="priceslider-promofield"
				onChange={(e) => {
					const value = e.target.value;
					props.saveAgentId(value);
				}}
				value={props.AgentId}
				placeholder="Agent ID"
				name="agentId"
			/>
		</Box>
	);
};

export default PromoAgentCode;
