import React, { useEffect, useState } from 'react';
import MainRouter from './Router';
import './App.scss';
import './icons/rentylIcons/style.css';
import './icons/siteIcons/style.css';

// The following components need to be added to the top level dom since they are full screen overlays
import useLoginState, { LoginStatus } from './customHooks/useLoginState';
import 'aos/dist/aos.css';
import useCompanyInfo from './customHooks/useCompanyInfo';
import { useUpdateExistingPages } from './customHooks/useUpdateExistingPages';
import { ToastContainer, rsToastify } from '@bit/redsky.framework.rs.toastify';
import globalState, { setRecoilExternalValue } from './state/globalState';
import useAfterRouterNavigateSubscription from './customHooks/useAfterRouterNavigateSubscription';
import TagManager from 'react-gtm-module';
import useIsAtBreakpoint from './customHooks/useIsAtBreakpoint';
import classNames from 'classnames';
import UpdateAvail from './components/updateAvail/UpdateAvail';
import { useRecoilState, useRecoilValue } from 'recoil';
import { IKContext } from 'imagekitio-react';
import { StringUtils } from './utils/utils';
import serviceFactory from './services/serviceFactory';
import UserService from './services/user/user.service';
import { popupController } from '@bit/redsky.framework.rs.996';
import SessionManagementPopup from './popups/sessionManagementPopup/SessionManagementPopup';
import { SessionManagementPopupProps } from './popups/sessionManagementPopup/SessionManagementPopup';
import { get } from 'lodash';
import { getPageFinder, undefinedHandler } from './utils/undefinedHandler';
const io = require('socket.io-client');

function App() {
	const loginStatus = useLoginState();
	const isMobile = useIsAtBreakpoint();
	const isCompanyLoaded = useCompanyInfo();
	const userService = serviceFactory.get<UserService>('UserService');

	const [user, setUser] = useRecoilState<Api.User.Res.Detail | undefined>(globalState.user);
	const [userTierValue, setUserTierValue] = useRecoilState<number>(globalState.userTierValue);
	const [sessionTime, setSessionTime] = useState<Api.PointsCost.Res.Get>();

	let inactivityTimer: any = null;
	let expirationTimer: any = null;
	let expirationTimerSessionManagementPopup: any = null;

	const handleInteraction = () => {
		resetTimers();
	};

	React.useEffect(() => {
		if (user) getTierDetails();
	}, [user]);

	useEffect(() => {
		const removeItemInterval = setInterval(() => {
			const TempAnonymousUser: any = JSON.parse(localStorage.getItem('TempAnonymousUser') ?? 'null');

			if (TempAnonymousUser) {
				localStorage.removeItem('TempAnonymousUser'); // Replace 'key' with the key of the item you want to remove
			}
		}, 30 * 60 * 1000); // 5 minutes in milliseconds

		// Clean up the interval when the component unmounts or changes
		return () => clearInterval(removeItemInterval);
	}, []);

	const getTierDetails = async () => {
		var tierValue = 0;
		try {
			const tierDetails: any = await userService.getTierDetails();

			tierDetails?.map((tierDetail: any) => {
				if (tierDetail.id == user?.tierId) {
					tierValue = tierDetail.accrualRate;
				}
			});

			setUserTierValue(tierValue);
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	};

	const resetTimers = () => {
		clearTimeout(inactivityTimer);
		clearTimeout(expirationTimer);
		clearTimeout(expirationTimerSessionManagementPopup);

		inactivityTimer = setTimeout(() => {
			popupController.open<SessionManagementPopupProps>(SessionManagementPopup, {
				handleInteraction: () => {
					popupController.close(SessionManagementPopup);
					handleInteraction();
				}
			});
			// rsToastify.success('your session will expire in 1 minutes');
		}, get(sessionTime, 'sessionNotificationsOpenTime', 14) * 60 * 1000);

		expirationTimerSessionManagementPopup = setTimeout(
			() => {
				popupController.close(SessionManagementPopup);
			},
			sessionTime && sessionTime.sessionNotificationsCloseTime && sessionTime.sessionNotificationsOpenTime
				? sessionTime.sessionNotificationsOpenTime * 60 * 1000 +
						sessionTime.sessionNotificationsCloseTime * 1000
				: 845000
		);

		expirationTimer = setTimeout(
			() => {
				// Redirect the user to the login page or do any other desired action her
				// rsToastify.success('Session expired');
				userService.logout();
				if (!window.location.pathname.startsWith('/destination/') && !window.location.pathname.endsWith('/')) {
					window.location.assign('/');
				}
			},
			get(sessionTime, 'sessionExpireTime', 15) * 60 * 1000
			// sessionTime && sessionTime.sessionExpireTime ? sessionTime.sessionExpireTime * 60 * 1000 : 900000
		);
	};

	const addEventListenerHandler = () => {
		handleInteraction();

		document.addEventListener('mousemove', handleInteraction);
		document.addEventListener('keypress', handleInteraction);
		document.addEventListener('click', handleInteraction);
		document.addEventListener('scroll', handleInteraction);

		resetTimers();
	};

	const getPlatformVariable = async () => {
		try {
			const res = await userService.getPointsCost();
			setSessionTime(res);
		} catch (error: any) {
			console.log('check platform error', error);
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	};

	useEffect(() => {
		if (window.productionModeType === 'DEVELOPMENT-SERVER') {
			console.log('10406 Flip'); // to know when my new update has been deploy
		}
		let urlPath = window.location.pathname + window.location.search;
		setRecoilExternalValue<string>(globalState.lastNavigationPath, urlPath);
		getPlatformVariable();
	}, []);

	useEffect(() => {
		if (user) {
			// Reset the timers when the user interacts with the website

			addEventListenerHandler();
			// Clear the event listeners and timers when the component unmounts
			return () => {
				document.removeEventListener('mousemove', handleInteraction);
				document.removeEventListener('keypress', handleInteraction);
				document.removeEventListener('click', handleInteraction);
				document.removeEventListener('scroll', handleInteraction);
				clearTimeout(inactivityTimer);
				clearTimeout(expirationTimer);
				clearTimeout(expirationTimerSessionManagementPopup);
			};
		}
	}, [user]);

	// useUpdateExistingPages();
	useAfterRouterNavigateSubscription();
	const [updateAvail, setUpdateAvail] = useState(false);
	const [company, setCompany] = useRecoilState<Api.Company.Res.GetCompanyAndClientVariables>(globalState.company);
	const [socket, setSocket] = useState(null);

	// Do Not Remove - This is a helpful tool to use to debug the browser logs in you developers console. Uncomment to use.
	// @ts-ignore
	// window.showBrowseLogs = process.env.NODE_ENV === 'development';

	useEffect(() => {
		if (isCompanyLoaded && company && !socket) {
			let socketURL = 'https://' + window.location.host;
			if (socketURL.includes('localhost')) socketURL = 'http://localhost:3001';
			// socketURL = "https://dev.book.rentylresorts.com";
			let socket = io.connect(socketURL + '?companyId=' + company.id, {
				transports: ['websocket']
			});
			socket.on('connect', () => {
				console.log('Socket successfully connected!');
			});
			socket.on('update-available', () => {
				console.log('Message: New version is available!');
				setUpdateAvail(true);
			});
			socket.on('connect_error', () => {
				console.log('Socket connection error!');
			});
			socket.on('connect_failed', function () {
				console.log('Socket connection failed!');
			});
			setSocket(socket);
		}
	}, [isCompanyLoaded, company]);

	const tagManagerArgs = {
		gtmId: window.GTMCodeForGoogle,
		dataLayer: {
			events: {
				booking: 'Booking'
			}
		}
	};
	TagManager.initialize(tagManagerArgs);

	const tagManagerArgsInIt = {
		gtmId: window.GTMCodeForGoogleInit,
		dataLayer: {
			events: {
				booking: 'Booking'
			}
		}
	};
	TagManager.initialize(tagManagerArgsInIt);
	const urlEndpoint = StringUtils.imageKitBaseUrl();
	return (
		<div className={classNames('App', { small: isMobile })}>
			<IKContext urlEndpoint={urlEndpoint}>
				{LoginStatus.UNKNOWN === loginStatus ? null : <MainRouter />}
				<ToastContainer />
				{updateAvail && <UpdateAvail />}
			</IKContext>
		</div>
	);
}

export default App;
