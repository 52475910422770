import React from 'react';
import './PointsLabelText.scss';
import { StringUtils } from '../../../utils/utils';

interface PointsLabelTextProps {
	userPoinData?: number;
}

const PointsLabelText: React.FC<PointsLabelTextProps> = (props) => {
	return (
		<>
			<div className="PointsLabelText-container">
				<div className="PointsLabelText-container_body">
					<div className="PointsLabelText-container_pointname"> Point Balance </div>
					<div className="PointsLabelText-container_pointnumber">
						{' '}
						{StringUtils.addCommasToNumber(props.userPoinData)}{' '}
					</div>
				</div>
			</div>
		</>
	);
};

export default PointsLabelText;
