import * as React from 'react';
import './ConvertToCashPopup.scss';
import { Box, Popup, popupController, PopupProps } from '@bit/redsky.framework.rs.996';
import Paper from '../../components/paper/Paper';
import Label from '@bit/redsky.framework.rs.label';
import AccommodationSearchCard from '../../components/accommodationSearchCardV2/AccommodationSearchCard';
import useIsAtBreakpoint from '../../customHooks/useIsAtBreakpoint';
import { useEffect, useState } from 'react';
import serviceFactory from '../../services/serviceFactory';
import AccommodationService from '../../services/accommodation/accommodation.service';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import globalState from '../../state/globalState';
import { rsToastify } from '@bit/redsky.framework.rs.toastify';
import RsPagedResponseData = RedSky.RsPagedResponseData;
import Button from '@bit/redsky.framework.rs.button';
import Loader from '../../components/loader/Loader';
import { WebUtils } from '../../utils/utils';
import DestinationService from '../../services/destination/destination.service';
import { getPageFinder, undefinedHandler } from '../../utils/undefinedHandler';

export interface ConvertToCashPopupProps extends PopupProps {
	accommodationId: number;
	destinationId: number;
	destinationName: string;
	packageLength: number;
	destinationDetails: Api.Destination.Res.Details | undefined;
	convertToCashCallBack?: () => void;
}

const ConvertToCashPopup: React.FC<ConvertToCashPopupProps> = (props) => {
	let navigate = useNavigate();
	const accommodationService = serviceFactory.get<AccommodationService>('AccommodationService');
	const reservationFilters = useRecoilValue<Misc.ReservationFilters>(globalState.reservationFilters);
	const isMobile = useIsAtBreakpoint();
	const [accommodation, setAccommodation] = useState<Api.Accommodation.Res.Availability | undefined>();
	const destinationService = serviceFactory.get<DestinationService>('DestinationService');

	useEffect(() => {
		async function getAccommodationService() {
			try {
				const response: RsPagedResponseData<Api.Accommodation.Res.MinMaxAvailability> =
					await accommodationService.availability(props.destinationId, {
						...reservationFilters,
						priceRangeMin: 10,
						priceRangeMax: 1000,
						redeemPoints: false,
						pagination: { page: 1, perPage: 100 }
					});
				let accommodation = response.data.find((element: Api.Accommodation.Res.Availability) => {
					return element.id === props.accommodationId;
				});
				if (!accommodation) {
					rsToastify.error('This accommodation isn`t available with a cash booking', 'No longer available');
					popupController.close(ConvertToCashPopup);
					return navigate('/');
				}
				setAccommodation(accommodation);
			} catch (e) {
				rsToastify.error(`Could not get cash accommodations. ${e}`, 'No cash accommodation available.');
				popupController.closeAll();
				return navigate('/');
			}
		}

		getAccommodationService().catch(console.error);
	}, []);

	return (
		<Popup {...props} preventCloseByBackgroundClick>
			{!accommodation ? (
				<Loader />
			) : (
				<Paper className={'rsConvertToCashPopup'}>
					<Box className={'titleContainer'}>
						<Label variant={isMobile ? 'h5' : 'h3'} className={'primaryTextColor'}>
							{props.destinationName}
						</Label>
					</Box>
					<Box className={'accommodationCards'}>
						<AccommodationSearchCard
							destinationDetails={props.destinationDetails}
							accommodation={accommodation}
							resortPoint={0}
							destinationId={props.destinationId}
							showInfoIcon={false}
							packageLength={props.packageLength}
							resortPointsPrice={70000}
							loyaltyStatus={'PENDING'}
							upfrontCashRequired={false}
						/>
					</Box>
					<Button
						className={'backToDestinationButton'}
						look="containedPrimary"
						onClick={async () => {
							try {
								const results: Api.Destination.Res.PublicSlim[] =
									await destinationService.getPublicSlim();
								const destinationName = results
									.find((result) => parseInt(result?.id.toString()) === props.destinationId)
									?.name.split(' ')
									.join('-')
									.toLocaleLowerCase();

								navigate(`/destination/${destinationName}`, {
									state: {
										...reservationFilters,
										destinationId: props.destinationId
									}
								});
								// navigate(`/destination/details?di=${props.destinationId}`);
								popupController.close(ConvertToCashPopup);
							} catch (error: any) {
								const message = `Getting error :${error.message} on ${getPageFinder(
									window.location.pathname
								)} page.`;
								undefinedHandler(message);
							}
						}}
					>
						Back to Destinations
					</Button>
				</Paper>
			)}
		</Popup>
	);
};

export default ConvertToCashPopup;
