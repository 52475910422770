// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rsConvertToCashPopup{border-radius:20px;background-color:var(--primaryBackgroundColor);width:clamp(250px, 95%, 1920px);max-height:90vh;overflow-y:auto;overflow-x:hidden}.rsConvertToCashPopup .titleContainer{padding:28px 38px 20px 38px;display:flex;justify-content:space-between;border-bottom:1px solid var(--greyLineColor)}.rsConvertToCashPopup .titleContainer .closeIcon{cursor:pointer}.rsConvertToCashPopup .backToDestinationButton{margin:24px;font-size:20px;font-weight:500;line-height:24px;color:var(--white)}
`, "",{"version":3,"sources":["webpack://./src/popups/convertToCashPopup/ConvertToCashPopup.scss","webpack://./src/themes/_mixins.scss"],"names":[],"mappings":"AAEA,sBACC,kBAAmB,CACnB,8CCqBkB,CDpBlB,+BAAgC,CAChC,eAAgB,CAChB,eAAgB,CAChB,iBAAkB,CANnB,sCASE,2BAA4B,CAC5B,YAAa,CACb,6BAA8B,CAC9B,4CCWiB,CDvBnB,iDAeG,cAAe,CAflB,+CAoBE,WAAY,CACZ,cAAe,CACf,eAAgB,CAChB,gBAAiB,CACjB,kBCDiB","sourcesContent":["@import 'src/themes/themes';\n\n.rsConvertToCashPopup {\n\tborder-radius: 20px;\n\tbackground-color: theme-var($primaryBackgroundColor);\n\twidth: clamp(250px, 95%, 1920px);\n\tmax-height: 90vh;\n\toverflow-y: auto;\n\toverflow-x: hidden;\n\n\t.titleContainer {\n\t\tpadding: 28px 38px 20px 38px;\n\t\tdisplay: flex;\n\t\tjustify-content: space-between;\n\t\tborder-bottom: 1px solid theme-var($greyLineColor);\n\n\t\t.closeIcon {\n\t\t\tcursor: pointer;\n\t\t}\n\t}\n\n\t.backToDestinationButton {\n\t\tmargin: 24px;\n\t\tfont-size: 20px;\n\t\tfont-weight: 500;\n\t\tline-height: 24px;\n\t\tcolor: theme-var($white);\n\t}\n}\n","@import 'variables';\n\n/*\n  include a map in a class\n*/\n@mixin spread-map($map: ()) {\n\t@each $key, $value in $map {\n\t\t#{$key}: $value;\n\t}\n}\n\n/*\n  Returns a CSS variable property specific to themeing.\n  Accepts an optional fallback, and optional map.\n  - SCSS: color: theme-var($--theme-primary, blue)\n  - CSS:  color: var(--theme-primary, blue)\n  Throws error if the variable not part of the map\n*/\n@function theme-var($key, $fallback: null, $map: $defaultTheme) {\n\t@if not map-has-key($map, $key) {\n\t\t@error \"key: '#{$key}', is not a key in map: #{$map}\";\n\t}\n\t@if ($fallback) {\n\t\t@return var($key, $fallback);\n\t} @else {\n\t\t@return var($key);\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primaryTextColor": `var(--primaryTextColor)`,
	"secondaryTextColor": `var(--secondaryTextColor)`,
	"successGreen": `var(--successGreen)`,
	"adminPink": `var(--adminPink)`,
	"warningRed": `var(--warningRed)`,
	"buttonLinkColor": `var(--buttonLinkColor)`,
	"secondaryButtonLinkColor": `var(--secondaryButtonLinkColor)`,
	"iconAndHighlightTextColor": `var(--iconAndHighlightTextColor)`,
	"primaryBackgroundColor": `var(--primaryBackgroundColor)`,
	"secondaryBackgroundColor": `var(--secondaryBackgroundColor)`,
	"errorColor": `var(--errorColor)`,
	"primaryBoxShadowColor": `var(--primaryBoxShadowColor)`,
	"greyLineColor": `var(--greyLineColor)`,
	"thinGreyLineColor": `var(--thinGreyLineColor)`,
	"white": `var(--white)`,
	"lightestGray": `var(--lightestGray)`,
	"backgroundGray": `var(--backgroundGray)`,
	"borderGray": `var(--borderGray)`,
	"medGray2": `var(--medGray2)`,
	"medGray2Active": `var(--medGray2Active)`,
	"black": `var(--black)`,
	"primaryCalendarColor": `var(--primaryCalendarColor)`,
	"secondaryCalendarColor": `var(--secondaryCalendarColor)`
};
export default ___CSS_LOADER_EXPORT___;
