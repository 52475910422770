import * as React from 'react';
import './TopSearchBar.scss';
import useIsAtBreakpoint from '../../customHooks/useIsAtBreakpoint';
import TopSearchBarMobile from './topSearchBarMobile/TopSearchBarMobile';
import TopSearchBarResponsive from './topSearchBarResponsive/TopSearchBarResponsive';

interface TopSearchBarProps {
	onFilterClick?: () => void;
}

const TopSearchBar: React.FC<TopSearchBarProps> = (props) => {
	const isMobile = useIsAtBreakpoint();

	return isMobile ? <TopSearchBarMobile onFilterClick={props.onFilterClick} /> : <TopSearchBarResponsive />;
};

export default TopSearchBar;
