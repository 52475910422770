// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rsPaginationViewMore{text-align:center;width:-moz-fit-content;width:fit-content}.rsPaginationViewMore .rsLabel{width:-moz-fit-content;width:fit-content}.rsPaginationViewMore .rsLabel:hover{cursor:pointer}
`, "",{"version":3,"sources":["webpack://./src/components/paginationViewMore/PaginationViewMore.scss"],"names":[],"mappings":"AAEA,sBACC,iBAAkB,CAClB,sBAAuB,CACvB,iBAAkB,CAHnB,+BAKE,sBAAuB,CACvB,iBAAkB,CANpB,qCAQG,cAAe","sourcesContent":["@import '../../themes/themes';\n\n.rsPaginationViewMore {\n\ttext-align: center;\n\twidth: -moz-fit-content;\n\twidth: fit-content;\n\t.rsLabel {\n\t\twidth: -moz-fit-content;\n\t\twidth: fit-content;\n\t\t&:hover {\n\t\t\tcursor: pointer;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primaryTextColor": `var(--primaryTextColor)`,
	"secondaryTextColor": `var(--secondaryTextColor)`,
	"successGreen": `var(--successGreen)`,
	"adminPink": `var(--adminPink)`,
	"warningRed": `var(--warningRed)`,
	"buttonLinkColor": `var(--buttonLinkColor)`,
	"secondaryButtonLinkColor": `var(--secondaryButtonLinkColor)`,
	"iconAndHighlightTextColor": `var(--iconAndHighlightTextColor)`,
	"primaryBackgroundColor": `var(--primaryBackgroundColor)`,
	"secondaryBackgroundColor": `var(--secondaryBackgroundColor)`,
	"errorColor": `var(--errorColor)`,
	"primaryBoxShadowColor": `var(--primaryBoxShadowColor)`,
	"greyLineColor": `var(--greyLineColor)`,
	"thinGreyLineColor": `var(--thinGreyLineColor)`,
	"white": `var(--white)`,
	"lightestGray": `var(--lightestGray)`,
	"backgroundGray": `var(--backgroundGray)`,
	"borderGray": `var(--borderGray)`,
	"medGray2": `var(--medGray2)`,
	"medGray2Active": `var(--medGray2Active)`,
	"black": `var(--black)`,
	"primaryCalendarColor": `var(--primaryCalendarColor)`,
	"secondaryCalendarColor": `var(--secondaryCalendarColor)`
};
export default ___CSS_LOADER_EXPORT___;
