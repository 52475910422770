import * as React from 'react';
import './ThankYouCard.scss';
import Label from '@bit/redsky.framework.rs.label';
import { Box } from '@bit/redsky.framework.rs.996';

interface ThankYouCardProps {
	confirmationNumber: string;
}

const ThankYouCard: React.FC<ThankYouCardProps> = (props) => {
	return (
		<div className={'rsThankYouCard'}>
			<Label className={'sectionTitle primaryTextColor'} variant={'buttonMdLg'}>
				Thank you for booking with Rentyl Resorts!
			</Label>
			<Label variant={'body2'} className={'emailConfirmation'}>
				You will receive an email shortly with your booking confirmation.
			</Label>
			<Box display={'flex'} alignItems={'center'} paddingTop={28}>
				<Label variant={'body1B'} className={'reservationNumber'}>
					Confirmation Number :
				</Label>
				<Label variant={'body1'} className={'number'}>
					#{props.confirmationNumber}
				</Label>
			</Box>
		</div>
	);
};

export default ThankYouCard;
