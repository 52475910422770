// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.verifyEmailPage{min-height:80vh;display:flex;justify-content:center;align-items:center}
`, "",{"version":3,"sources":["webpack://./src/pages/verifyEmailPage/VerifyEmailPage.scss"],"names":[],"mappings":"AAAA,iBACC,eAAgB,CAChB,YAAa,CACb,sBAAuB,CACvB,kBAAmB","sourcesContent":[".verifyEmailPage {\n\tmin-height: 80vh;\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
