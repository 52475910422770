import React, { useEffect } from 'react';

export function useScript(url?: string, defer?: boolean, type?: string, text?: string, tag?: string) {
	useEffect(() => {
		const script = document.createElement('script');

		if (url) script.src = url;
		script.defer = !!defer;
		if (type) script.type = type;
		if (text) script.text = text;

		if (tag === 'head') {
			// create SCRIPT tag in the head section
			document.head.appendChild(script);
		} else if (tag === 'body') {
			//create SCRIPT tag in the body section
			document.body.appendChild(script);
		} else {
			document.head.appendChild(script);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [url]);
}

export interface ScriptProps {
	src?: string;
	defer?: boolean;
	type?: string;
	text?: string;
	tag?: string;
}

const Script: React.FC<ScriptProps> = (props) => {
	useScript(props.src, props.defer, props.type, props.text, props.tag);

	return null; // Return null is necessary for the moment.
};
export default Script;
