// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("GothicA1-Black.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("GothicA1-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("GothicA1-ExtraBold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("GothicA1-ExtraLight.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("GothicA1-Light.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("GothicA1-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("GothicA1-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_7___ = new URL("GothicA1-SemiBold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_8___ = new URL("GothicA1-Thin.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face{font-family:'Gothic';src:url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("truetype");font-style:normal;font-weight:900}@font-face{font-family:'Gothic';src:url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("truetype");font-style:normal;font-weight:700}@font-face{font-family:'Gothic';src:url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("truetype");font-style:normal;font-weight:800}@font-face{font-family:'Gothic';src:url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("truetype");font-style:normal;font-weight:200}@font-face{font-family:'Gothic';src:url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format("truetype");font-style:normal;font-weight:300}@font-face{font-family:'Gothic';src:url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format("truetype");font-style:normal;font-weight:500}@font-face{font-family:'Gothic';src:url(${___CSS_LOADER_URL_REPLACEMENT_6___}) format("truetype");font-style:normal;font-weight:400}@font-face{font-family:'Gothic';src:url(${___CSS_LOADER_URL_REPLACEMENT_7___}) format("truetype");font-style:normal;font-weight:600}@font-face{font-family:'Gothic';src:url(${___CSS_LOADER_URL_REPLACEMENT_8___}) format("truetype");font-style:normal;font-weight:100}
`, "",{"version":3,"sources":["webpack://./src/fonts/Gothic/font.scss"],"names":[],"mappings":"AAAA,WACC,oBAAqB,CACrB,8DAAiD,CACjD,iBAAkB,CAClB,eAAgB,CAGjB,WACC,oBAAqB,CACrB,8DAAgD,CAChD,iBAAkB,CAClB,eAAgB,CAGjB,WACC,oBAAqB,CACrB,8DAAqD,CACrD,iBAAkB,CAClB,eAAgB,CAGjB,WACC,oBAAqB,CACrB,8DAAsD,CACtD,iBAAkB,CAClB,eAAgB,CAGjB,WACC,oBAAqB,CACrB,8DAAiD,CACjD,iBAAkB,CAClB,eAAgB,CAGjB,WACC,oBAAqB,CACrB,8DAAkD,CAClD,iBAAkB,CAClB,eAAgB,CAGjB,WACC,oBAAqB,CACrB,8DAAmD,CACnD,iBAAkB,CAClB,eAAgB,CAGjB,WACC,oBAAqB,CACrB,8DAAoD,CACpD,iBAAkB,CAClB,eAAgB,CAGjB,WACC,oBAAqB,CACrB,8DAAgD,CAChD,iBAAkB,CAClB,eAAgB","sourcesContent":["@font-face {\n\tfont-family: 'Gothic';\n\tsrc: url('GothicA1-Black.ttf') format('truetype');\n\tfont-style: normal;\n\tfont-weight: 900;\n}\n\n@font-face {\n\tfont-family: 'Gothic';\n\tsrc: url('GothicA1-Bold.ttf') format('truetype');\n\tfont-style: normal;\n\tfont-weight: 700;\n}\n\n@font-face {\n\tfont-family: 'Gothic';\n\tsrc: url('GothicA1-ExtraBold.ttf') format('truetype');\n\tfont-style: normal;\n\tfont-weight: 800;\n}\n\n@font-face {\n\tfont-family: 'Gothic';\n\tsrc: url('GothicA1-ExtraLight.ttf') format('truetype');\n\tfont-style: normal;\n\tfont-weight: 200;\n}\n\n@font-face {\n\tfont-family: 'Gothic';\n\tsrc: url('GothicA1-Light.ttf') format('truetype');\n\tfont-style: normal;\n\tfont-weight: 300;\n}\n\n@font-face {\n\tfont-family: 'Gothic';\n\tsrc: url('GothicA1-Medium.ttf') format('truetype');\n\tfont-style: normal;\n\tfont-weight: 500;\n}\n\n@font-face {\n\tfont-family: 'Gothic';\n\tsrc: url('GothicA1-Regular.ttf') format('truetype');\n\tfont-style: normal;\n\tfont-weight: 400;\n}\n\n@font-face {\n\tfont-family: 'Gothic';\n\tsrc: url('GothicA1-SemiBold.ttf') format('truetype');\n\tfont-style: normal;\n\tfont-weight: 600;\n}\n\n@font-face {\n\tfont-family: 'Gothic';\n\tsrc: url('GothicA1-Thin.ttf') format('truetype');\n\tfont-style: normal;\n\tfont-weight: 100;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
