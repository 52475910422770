import * as React from 'react';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
interface ShowMoreLess {
	textDescription: string;
	textLength: number;
}

const ShowMoreLess: React.FC<ShowMoreLess> = (props) => {
	const [showLess, setShowLess] = React.useState(true);
	return (
		<>
			{props.textDescription && props.textDescription.length < props.textLength ? (
				props.textDescription
			) : (
				<>
					{showLess && props.textDescription
						? `${props.textDescription.slice(0, props.textLength)}...`
						: props.textDescription}
					&nbsp;
					<span
						className="rsLabel primaryTextColor buttonMdLg"
						style={{ fontSize: 16, display: 'inline-flex', cursor: 'pointer' }}
						onClick={() => setShowLess(!showLess)}
					>
						View {showLess ? 'More' : 'Less'}
						{showLess ? <MdKeyboardArrowDown size="24" /> : <MdKeyboardArrowUp size="24" />}
					</span>
				</>
			)}
		</>
	);
};

export default ShowMoreLess;
