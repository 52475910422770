// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rsItineraryCardMobile{margin-bottom:45px;max-width:300px;gap:6px}.rsItineraryCardMobile .titleAndButton{display:flex;justify-content:space-between;align-items:center;margin-top:10px;margin-bottom:16px}.rsItineraryCardMobile .rsCarouselV2{width:100%;height:187px}.rsItineraryCardMobile .rsCarouselV2 .carouselParent{overflow-x:hidden}.rsItineraryCardMobile .rsCarouselV2 .imageWrapper{width:262px;height:230px}.rsItineraryCardMobile .rsCarouselV2 .imageWrapper img{width:100%;height:100%;object-fit:cover}.rsItineraryCardMobile .bottomRow{display:flex;justify-content:space-between;flex-wrap:wrap;align-items:end}.rsItineraryCardMobile .bottomRow .locationLabel{margin-top:10px}
`, "",{"version":3,"sources":["webpack://./src/components/itineraryCard/itineraryCardMobile/ItineraryCardMobile.scss"],"names":[],"mappings":"AAEA,uBACC,kBAAmB,CACnB,eAAgB,CAChB,OAAQ,CAHT,uCAKE,YAAa,CACb,6BAA8B,CAC9B,kBAAmB,CACnB,eAAgB,CAChB,kBAAmB,CATrB,qCAaE,UAAW,CACX,YAAa,CAdf,qDAgBG,iBAAkB,CAhBrB,mDAmBG,WAAY,CACZ,YAAa,CApBhB,uDAsBI,UAAW,CACX,WAAY,CACZ,gBAAiB,CAxBrB,kCA6BE,YAAa,CACb,6BAA8B,CAC9B,cAAe,CACf,eAAgB,CAhClB,iDAkCG,eAAgB","sourcesContent":["@import '../../../themes/themes';\n\n.rsItineraryCardMobile {\n\tmargin-bottom: 45px;\n\tmax-width: 300px;\n\tgap: 6px;\n\t.titleAndButton {\n\t\tdisplay: flex;\n\t\tjustify-content: space-between;\n\t\talign-items: center;\n\t\tmargin-top: 10px;\n\t\tmargin-bottom: 16px;\n\t}\n\n\t.rsCarouselV2 {\n\t\twidth: 100%;\n\t\theight: 187px;\n\t\t.carouselParent {\n\t\t\toverflow-x: hidden;\n\t\t}\n\t\t.imageWrapper {\n\t\t\twidth: 262px;\n\t\t\theight: 230px;\n\t\t\timg {\n\t\t\t\twidth: 100%;\n\t\t\t\theight: 100%;\n\t\t\t\tobject-fit: cover;\n\t\t\t}\n\t\t}\n\t}\n\t.bottomRow {\n\t\tdisplay: flex;\n\t\tjustify-content: space-between;\n\t\tflex-wrap: wrap;\n\t\talign-items: end;\n\t\t.locationLabel {\n\t\t\tmargin-top: 10px;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primaryTextColor": `var(--primaryTextColor)`,
	"secondaryTextColor": `var(--secondaryTextColor)`,
	"successGreen": `var(--successGreen)`,
	"adminPink": `var(--adminPink)`,
	"warningRed": `var(--warningRed)`,
	"buttonLinkColor": `var(--buttonLinkColor)`,
	"secondaryButtonLinkColor": `var(--secondaryButtonLinkColor)`,
	"iconAndHighlightTextColor": `var(--iconAndHighlightTextColor)`,
	"primaryBackgroundColor": `var(--primaryBackgroundColor)`,
	"secondaryBackgroundColor": `var(--secondaryBackgroundColor)`,
	"errorColor": `var(--errorColor)`,
	"primaryBoxShadowColor": `var(--primaryBoxShadowColor)`,
	"greyLineColor": `var(--greyLineColor)`,
	"thinGreyLineColor": `var(--thinGreyLineColor)`,
	"white": `var(--white)`,
	"lightestGray": `var(--lightestGray)`,
	"backgroundGray": `var(--backgroundGray)`,
	"borderGray": `var(--borderGray)`,
	"medGray2": `var(--medGray2)`,
	"medGray2Active": `var(--medGray2Active)`,
	"black": `var(--black)`,
	"primaryCalendarColor": `var(--primaryCalendarColor)`,
	"secondaryCalendarColor": `var(--secondaryCalendarColor)`
};
export default ___CSS_LOADER_EXPORT___;
