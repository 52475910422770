import * as React from 'react';
import { Box, popupController } from '@bit/redsky.framework.rs.996';
import useIsAtBreakpoint from '../../customHooks/useIsAtBreakpoint';
import RateCodeCardResponsive from './rateCodeCardResponsive/RateCodeCardResponsive';
import RateCodeCardMobile from './rateCodeCardMobile/RateCodeCardMobile';
import RateDetailPopup, { RateDetailPopupProps } from '../../popups/rateDetailPopup/RateDetailPopup';

interface RateCodeCardProps {
	pointPerDollar?: number;
	priceObj: Api.Accommodation.Res.Availability.Price;
	accommodationId: number;
	packageLength?: number;
	destinationId: number;
	pointsEarnable: number;
	loyaltyStatus: Model.LoyaltyStatus;
	upfrontCashRequired: boolean;
	isCustomResort?: boolean;
	phone?: number;
	destinationDetails: Api.Destination.Res.Details | undefined;
	contentList: any;
	campaignLength: number;
	accommodation?: Api.Destination.Res.Accommodation;
}

const RateCodeCard: React.FC<RateCodeCardProps> = (props) => {
	const isMobile = useIsAtBreakpoint();

	const onRateDetailClick = () => {
		popupController.open<RateDetailPopupProps>(RateDetailPopup, {
			priceDetail: props.priceObj,
			contentList: props?.contentList
		});
	};

	return (
		<Box className={'rsRateCodeCard'} width={'100%'}>
			{isMobile ? (
				<RateCodeCardMobile
					pointPerDollar={props.pointPerDollar}
					priceObj={props.priceObj}
					destinationDetails={props.destinationDetails}
					accommodationId={props.accommodationId}
					packageLength={props.packageLength}
					destinationId={props.destinationId}
					accommodation={props.accommodation}
					pointsEarnable={props.pointsEarnable}
					loyaltyStatus={props.loyaltyStatus}
					upfrontCashRequired={props.upfrontCashRequired}
					isCustomResort={props.isCustomResort}
					phone={props.phone}
					onRateDetail={onRateDetailClick}
					campaignLength={props.campaignLength}
				/>
			) : (
				<RateCodeCardResponsive
					pointPerDollar={props.pointPerDollar}
					priceObj={props.priceObj}
					destinationDetails={props.destinationDetails}
					accommodationId={props.accommodationId}
					accommodation={props.accommodation}
					destinationId={props.destinationId}
					packageLength={props.packageLength}
					loyaltyStatus={props.loyaltyStatus}
					upfrontCashRequired={props.upfrontCashRequired}
					isCustomResort={props.isCustomResort}
					phone={props.phone}
					onRateDetail={onRateDetailClick}
					campaignLength={props.campaignLength}
				/>
			)}
		</Box>
	);
};

export default RateCodeCard;
