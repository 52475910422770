import * as React from 'react';
import './Policies.scss';
import Label from '@bit/redsky.framework.rs.label';

interface PoliciesProps {
	checkInTime: string;
	checkOutTime: string;
	bookingDescription: string;
	guaranteePolicy: string;
	cancellationPolicy: string;
}

const Policies: React.FC<PoliciesProps> = (props) => {
	return (
		<div className={'rsPolicies'}>
			<Label variant={'buttonMdLg'} className={'sectionTitle'}>
				Policies
			</Label>
			<div className={'checkInOutContainer'}>
				<div className={'checkInWrapper'}>
					<Label variant={'buttonMdLg'} className={'header'}>
						Check in
					</Label>
					<Label variant={'body2'} className={'time'}>
						After {props.checkInTime}
					</Label>
				</div>
				<div className={'checkOutWrapper'}>
					<Label variant={'buttonMdLg'} className={'header'}>
						Check out
					</Label>
					<Label variant={'body2'} className={'time'}>
						Before {props.checkOutTime}
					</Label>
				</div>
			</div>
			<Label variant={'body2'} className={'reservationInfo'}>
				{props.bookingDescription}
			</Label>
			<div className={'guaranteeSection'}>
				<Label variant={'buttonMdLg'} className={'header'}>
					Guarantee Policy
				</Label>
				<Label variant={'body2'} className={'policyInformation'}>
					{props.guaranteePolicy}
				</Label>
			</div>
			<div className={'cancellationSection'}>
				<Label variant={'buttonMdLg'} className={'header medGray2'}>
					Cancellation Policy
				</Label>
				<Label variant={'body2'} className={'policyInformation'}>
					{props.cancellationPolicy}
				</Label>
			</div>
		</div>
	);
};

export default Policies;
