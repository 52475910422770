import * as React from 'react';
import './ItineraryCard.scss';
import ItineraryCardResponsive from './itineraryCardResponsive/ItineraryCardResponsive';
import ItineraryCardMobile from './itineraryCardMobile/ItineraryCardMobile';
import Media = Api.Media;
import useGetCountryList from '../../customHooks/useGetCountryList';
import useIsAtBreakpoint from '../../customHooks/useIsAtBreakpoint';

interface ItineraryCardProps {
	logo: string;
	title: string;
	address: string;
	reservationDates: { startDate: string | Date; endDate: string | Date };
	destinationExperiences: {
		id: number;
		title: string;
		icon: string;
		description: string;
		isHighlighted: 0 | 1;
		media: Media[];
	}[];
	propertyType: string;
	itineraryId: string;
	maxOccupancy: number;
	amenities: string[];
	totalPoints: number;
	linkPath: string;
	cancelPermitted: 0 | 1;
	itineraryTotal: number;
	paidWithPoints: boolean;
	city: string;
	state: string;
	country: string;
	media: Media[];
	currencyCode: string;
}

const ItineraryCard: React.FC<ItineraryCardProps> = (props) => {
	const isMobile = useIsAtBreakpoint(1400);
	const countryList = useGetCountryList();

	function formatCountry() {
		if (!props.state) {
			let countryName: Api.Country.Res.Country | undefined = countryList.find(
				(item) => item.isoCode === props.country
			);
			if (!countryName) return;
			return countryName.name;
		}
	}

	return isMobile ? (
		<ItineraryCardMobile
			logo={props.logo}
			title={props.title}
			reservationDates={props.reservationDates}
			propertyType={props.propertyType}
			itineraryId={props.itineraryId}
			maxOccupancy={props.maxOccupancy}
			amenities={props.amenities}
			totalPoints={props.totalPoints}
			linkPath={props.linkPath}
			cancelPermitted={props.cancelPermitted}
			itineraryTotal={props.itineraryTotal}
			paidWithPoints={props.paidWithPoints}
			city={props.city}
			state={props.state}
			country={formatCountry() || ''}
			media={props.media}
			currencyCode={props.currencyCode}
		/>
	) : (
		<ItineraryCardResponsive
			title={props.title}
			address={props.address}
			destinationExperiences={props.destinationExperiences}
			reservationDates={props.reservationDates}
			propertyType={props.propertyType}
			itineraryId={props.itineraryId}
			maxOccupancy={props.maxOccupancy}
			amenities={props.amenities}
			totalPoints={props.totalPoints}
			linkPath={props.linkPath}
			cancelPermitted={props.cancelPermitted}
			itineraryTotal={props.itineraryTotal}
			paidWithPoints={props.paidWithPoints}
			media={props.media}
			currencyCode={props.currencyCode}
		/>
	);
};

export default ItineraryCard;
