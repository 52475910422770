// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.small .rsConfirmationImageSummary{display:flex;max-height:230px}.small .rsConfirmationImageSummary .confirmationImage{width:100%;object-fit:cover;border-radius:20px}.small .rsConfirmationImageSummary .confirmationImage.hero{max-height:230px}.small .rsConfirmationImageSummary .confirmationImage.secondary{display:none}.rsConfirmationImageSummary{width:100%;display:grid;grid-template-columns:6.35fr 3.65fr;grid-template-rows:calc(50% - 10px) calc(50% - 10px);gap:20px;box-sizing:border-box;max-height:402px}.rsConfirmationImageSummary .confirmationImage{width:100%;height:100%;object-fit:cover;border-radius:20px}.rsConfirmationImageSummary .confirmationImage.hero{grid-row-start:1;grid-row-end:3}
`, "",{"version":3,"sources":["webpack://./src/components/confirmationImageSummary/ConfirmationImageSummary.scss"],"names":[],"mappings":"AAAA,mCACC,YAAa,CACb,gBAAiB,CAFlB,sDAKE,UAAW,CACX,gBAAiB,CACjB,kBAAmB,CAPrB,2DAUG,gBAAiB,CAVpB,gEAcG,YAAa,CACb,4BAKF,UAAW,CACX,YAAa,CACb,mCAAoC,CACpC,oDAAqD,CACrD,QAAS,CACT,qBAAsB,CACtB,gBAAiB,CAPlB,+CAUE,UAAW,CACX,WAAY,CACZ,gBAAiB,CACjB,kBAAmB,CAbrB,oDAgBG,gBAAiB,CACjB,cAAe","sourcesContent":[".small .rsConfirmationImageSummary {\n\tdisplay: flex;\n\tmax-height: 230px;\n\n\t.confirmationImage {\n\t\twidth: 100%;\n\t\tobject-fit: cover;\n\t\tborder-radius: 20px;\n\n\t\t&.hero {\n\t\t\tmax-height: 230px;\n\t\t}\n\n\t\t&.secondary {\n\t\t\tdisplay: none;\n\t\t}\n\t}\n}\n\n.rsConfirmationImageSummary {\n\twidth: 100%;\n\tdisplay: grid;\n\tgrid-template-columns: 6.35fr 3.65fr;\n\tgrid-template-rows: calc(50% - 10px) calc(50% - 10px);\n\tgap: 20px;\n\tbox-sizing: border-box;\n\tmax-height: 402px;\n\n\t.confirmationImage {\n\t\twidth: 100%;\n\t\theight: 100%;\n\t\tobject-fit: cover;\n\t\tborder-radius: 20px;\n\n\t\t&.hero {\n\t\t\tgrid-row-start: 1;\n\t\t\tgrid-row-end: 3;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
