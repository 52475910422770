import * as React from 'react';
import './FilterBarLimitedMobilePopup.scss';
import { useState } from 'react';
import { Box, Popup, popupController, PopupProps } from '@bit/redsky.framework.rs.996';
import Paper from '../../components/paper/Paper';
import Icon from '@bit/redsky.framework.rs.icon';
import Label from '@bit/redsky.framework.rs.label';
import useIsAtBreakpoint from '../../customHooks/useIsAtBreakpoint';
import LabelRadioButton from '../../components/labelRadioButton/LabelRadioButton';
import { RsFormControl, RsFormGroup, RsValidator, RsValidatorEnum } from '@bit/redsky.framework.rs.form';
import { useRecoilState } from 'recoil';
import globalState from '../../state/globalState';
import PriceSlider from '../../components/priceSlider/PriceSlider';
import Counter from '../../components/counter/Counter';
import Button from '@bit/redsky.framework.rs.button';
import LabelCheckboxFilterBar from '../../components/labelCheckbox/LabelCheckboxFilterBar';
import useFilterOptions from '../../customHooks/useFilterOptions';
import { getPageFinder, undefinedHandler } from '../../utils/undefinedHandler';

export interface FilterBarLimitedMobilePopupProps extends PopupProps {
	reservationFilters: Misc.ReservationFilters;
	onApplyCallback: (newFilter: Misc.ReservationFilters) => void;
	propertyTypes: Model.PropertyType[];
	amenityOptions: Misc.OptionType[];
	closeOtherFilters: () => void;
	externalId: string;
	chainId: number;
	arriveDate: any;
	departDate: any;
}
enum FormKeys {
	SORT_ORDER = 'sortOrder',
	BEDROOM_COUNT = 'bedroomCount',
	BATHROOM_COUNT = 'bathroomCount',
	PRICE_MIN = 'priceRangeMin',
	PRICE_MAX = 'priceRangeMax',
	PROPERTY_TYPES = 'propertyTypeIds',
	AMENITIES = 'amenityIds'
}

const FilterBarLimitedMobilePopup: React.FC<FilterBarLimitedMobilePopupProps> = (props) => {
	const fullProps = popupController.convertProps(props);
	const isMobile = useIsAtBreakpoint();

	const [reservationFilters, setReservationFilters] = useRecoilState<Misc.ReservationFilters>(
		globalState.reservationFilters
	);

	const filterOptions = useFilterOptions(reservationFilters.destinationId);
	const [trackPromoCodeType, setTrackPromoCodeType] = useState<string>('PR');
	const [promoCodeDatas, setPromoCodeDatas] = useState<string>('');
	const [agentIdData, setAgentIdData] = useState<string>('');

	const [otherFilterForm, setOtherFilterForm] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl(FormKeys.SORT_ORDER, props.reservationFilters.sortOrder || 'ASC', []),
			new RsFormControl(FormKeys.BEDROOM_COUNT, props.reservationFilters.bedroomCount || 0, [
				new RsValidator(RsValidatorEnum.REQ, '# Of Bedrooms Required')
			]),
			new RsFormControl(FormKeys.BATHROOM_COUNT, props.reservationFilters.bathroomCount || 0, [
				new RsValidator(RsValidatorEnum.REQ, '# Of Bathrooms Required')
			]),
			new RsFormControl(FormKeys.PRICE_MIN, props.reservationFilters.priceRangeMin || 10, []),
			new RsFormControl(FormKeys.PRICE_MAX, props.reservationFilters.priceRangeMax || 1000, []),
			new RsFormControl(FormKeys.PROPERTY_TYPES, props.reservationFilters.propertyTypeIds || [], []),
			new RsFormControl(FormKeys.AMENITIES, props.reservationFilters.amenityIds || [], [])
		])
	);

	function updateOtherFilterForm(control: RsFormControl) {
		setOtherFilterForm(otherFilterForm.clone().update(control));
	}

	function sanitizePriceFieldsAndUpdate(control: RsFormControl) {
		if (!control) return;
		control.value = control.value.toString().replaceAll(/[^0-9]/g, '');
		updateOtherFilterForm(control);
	}

	function renderSortOrder() {
		return (
			<Box className="sortByContainer borderBottom" paddingBottom={20}>
				<Label variant="h6" paddingBottom={15}>
					Sort By
				</Label>
				<LabelRadioButton
					radioName="highestRadioBtn"
					value="sortHigh"
					checked={otherFilterForm.get(FormKeys.SORT_ORDER).value === 'DESC'}
					text={`Highest Prices`}
					onSelect={() => {
						let sortOrderControlClone = otherFilterForm.getClone(FormKeys.SORT_ORDER);
						sortOrderControlClone.value = 'DESC';
						updateOtherFilterForm(sortOrderControlClone);
					}}
					labelVariant="h6"
					className="priceHighRadio"
				/>
				<LabelRadioButton
					radioName="lowestRadioBtn"
					value="sortLow"
					checked={otherFilterForm.get(FormKeys.SORT_ORDER).value === 'ASC'}
					text="Lowest Prices"
					onSelect={() => {
						let sortOrderControlClone = otherFilterForm.getClone(FormKeys.SORT_ORDER);
						sortOrderControlClone.value = 'ASC';
						updateOtherFilterForm(sortOrderControlClone);
					}}
					labelVariant="h6"
				/>
			</Box>
		);
	}

	function renderPriceSlider() {
		return (
			<Box className="priceSliderContainer" paddingTop={20}>
				<Label variant="h6">{props.reservationFilters.redeemPoints ? 'Points' : 'Price'}</Label>
				<PriceSlider
					updateFilterForm={(control: RsFormControl | undefined) => {
						if (!control) return;
						updateOtherFilterForm(control);
					}}
					minControl={otherFilterForm.get(FormKeys.PRICE_MIN)}
					maxControl={otherFilterForm.get(FormKeys.PRICE_MAX)}
					// promoCodeType={setTrackPromoCodeType}
					// promoCodeTypeData={trackPromoCodeType}
					// savePromoCodeDatas={setPromoCodeDatas}
					// saveAgentId={setAgentIdData}
					// AgentId={agentIdData}
					// promoCode={promoCodeDatas}
					sanitizePriceFieldsAndUpdate={sanitizePriceFieldsAndUpdate}
					redeemPoints={!!props.reservationFilters.redeemPoints}
				/>
			</Box>
		);
	}

	function renderCounters() {
		return (
			<Box className="bedAndBathContainer borderBottom" paddingY={25}>
				<Counter
					title="Bedrooms"
					control={otherFilterForm.get(FormKeys.BEDROOM_COUNT)}
					updateControl={updateOtherFilterForm}
					className={'filterCounter'}
					minCount={0}
					maxCount={99}
					labelMarginRight={5}
					labelVariant="h6"
				/>
				<Counter
					title="Bathrooms"
					control={otherFilterForm.get(FormKeys.BATHROOM_COUNT)}
					updateControl={updateOtherFilterForm}
					className={'filterCounter'}
					minCount={0}
					maxCount={99}
					labelMarginRight={5}
					labelVariant="h6"
				/>
			</Box>
		);
	}

	function clearOtherFilters() {
		let formClone = otherFilterForm.cloneDeep();
		formClone.get(FormKeys.SORT_ORDER).value = 'ASC';
		formClone.get(FormKeys.BEDROOM_COUNT).value = 0;
		formClone.get(FormKeys.BATHROOM_COUNT).value = 0;
		formClone.get(FormKeys.PRICE_MIN).value = props.reservationFilters.redeemPoints ? 1000 : 10;
		formClone.get(FormKeys.PRICE_MAX).value = props.reservationFilters.redeemPoints ? 1430000 : 1000;
		formClone.get(FormKeys.PROPERTY_TYPES).value = [];
		formClone.get(FormKeys.AMENITIES).value = [];

		setTrackPromoCodeType('PR');
		setPromoCodeDatas('');
		setAgentIdData('');
		setOtherFilterForm(formClone);
		// onApplyClick()
		// popupController.closeById(fullProps.popupId);
	}

	function onApplyClick() {
		try {
			// 200VisaEGiftCard
			if (trackPromoCodeType === 'PR' && promoCodeDatas !== '' && agentIdData === '') {
				const synxisPromoUrl = `https://be.synxis.com/?arrive=${props.arriveDate}&chain=${props.chainId}&depart=${props.departDate}&level=chain&nights=3&promo=${promoCodeDatas}`;
				window.open(synxisPromoUrl, '_blank');
			}

			if (trackPromoCodeType === 'GC' && promoCodeDatas !== '' && agentIdData === '') {
				const synxisPromoUrl = `https://be.synxis.com/?arrive=${props.arriveDate}&chain=${props.chainId}&depart=${props.departDate}&level=chain&nights=3&group=${promoCodeDatas}`;
				window.open(synxisPromoUrl, '_blank');
			}

			if (trackPromoCodeType !== '' && promoCodeDatas === '' && agentIdData !== '') {
				const synxisPromoUrl = `https://be.synxis.com/?arrive=${props.arriveDate}&chain=${props.chainId}&depart=${props.departDate}&agencyid=${agentIdData}&level=chain&nights=3`;
				window.open(synxisPromoUrl, '_blank');
			}

			if (trackPromoCodeType === 'PR' && promoCodeDatas !== '' && agentIdData !== '') {
				const synxisPromoUrl = `https://be.synxis.com/?arrive=${props.arriveDate}&chain=${props.chainId}&depart=${props.departDate}&agencyid=${agentIdData}&level=chain&nights=3&promo=${promoCodeDatas}`;
				window.open(synxisPromoUrl, '_blank');
			}

			if (trackPromoCodeType === 'GC' && promoCodeDatas !== '' && agentIdData !== '') {
				const synxisPromoUrl = `https://be.synxis.com/?arrive=${props.arriveDate}&chain=${props.chainId}&depart=${props.departDate}&agencyid=${agentIdData}&level=chain&nights=3&group=${promoCodeDatas}`;
				window.open(synxisPromoUrl, '_blank');
			}

			const filterValues: Misc.ReservationFilters = otherFilterForm.toModel<Misc.ReservationFilters>();
			const reservationFilters = { ...props.reservationFilters, ...filterValues };
			props.onApplyCallback(reservationFilters);
			// popupController.closeById(fullProps.popupId);
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	function renderAccommodationList() {
		try {
			return props.propertyTypes.map((item: any) => (
				<LabelCheckboxFilterBar
					key={item.name}
					value={item.id}
					text={item.name}
					onSelect={() => {
						let tempControl = otherFilterForm.get(FormKeys.PROPERTY_TYPES);
						tempControl.value = [...(tempControl.value as number[]), item.id];
						updateOtherFilterForm(tempControl);
					}}
					isChecked={(otherFilterForm.get(FormKeys.PROPERTY_TYPES).value as number[]).includes(item.id)}
					onDeselect={() => {
						otherFilterForm.get(FormKeys.PROPERTY_TYPES).value = (
							otherFilterForm.get(FormKeys.PROPERTY_TYPES).value as number[]
						).filter((type) => type !== item.id);
						updateOtherFilterForm(otherFilterForm.get(FormKeys.PROPERTY_TYPES));
					}}
					className="filterCheckbox"
					// textColor={themes.black}
				/>
			));
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	function renderInUnitAmenitiesOptionsList() {
		try {
			return props.amenityOptions.map((item: any) => (
				<LabelCheckboxFilterBar
					key={item.value}
					value={item.value}
					text={item.label}
					onSelect={() => {
						let tempControl = otherFilterForm.getClone(FormKeys.AMENITIES);
						tempControl.value = [...(tempControl.value as number[]), item.value as number];
						updateOtherFilterForm(tempControl);
					}}
					isChecked={(otherFilterForm.get(FormKeys.AMENITIES).value as number[]).includes(
						item.value as number
					)}
					onDeselect={() => {
						let tempControl = otherFilterForm.getClone(FormKeys.AMENITIES);
						tempControl.value = (tempControl.value as number[]).filter((type) => type !== item.value);
						updateOtherFilterForm(tempControl);
					}}
					className="filterCheckbox"
					// textColor={themes.black}
				/>
			));
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	return (
		<Popup {...props} className="FilterBarLimitedMobilePopup">
			<Paper className={'FilterBarLimitedMobilePopupContainer'}>
				<Box className={'titleContainer'}>
					<Label variant={isMobile ? 'h5' : 'h3'} className={'primaryTextColor'}>
						Filters
					</Label>
					<Icon
						className={'closeIcon'}
						iconImg={'icon-close'}
						size={isMobile ? 16 : 32}
						onClick={() => {
							popupController.closeById(fullProps.popupId);
						}}
					/>
				</Box>

				<Box className={'cardBody'}>
					<Box className={'sectionOne'}>
						{renderSortOrder()}
						{renderCounters()}
						{renderPriceSlider()}
					</Box>

					<Box className="sectionTwo">
						<Label mb={16} variant="h6">
							Accommodation
						</Label>
						{renderAccommodationList()}
					</Box>
					<Box className="sectionThree">
						<Label mb={16} variant="h6">
							In Unit Amenities
						</Label>
						{renderInUnitAmenitiesOptionsList()}
					</Box>
				</Box>
				<Box className="cardFooter">
					<Button look="none" className="rsButton none rsLabelButton" onClick={clearOtherFilters}>
						<div className="rsLabel buttonSm"> Clear</div>
					</Button>
					<Button look="containedPrimary" className="filterFooterApplyBtn" onClick={onApplyClick}>
						Apply
					</Button>
				</Box>
			</Paper>
		</Popup>
	);
};

export default FilterBarLimitedMobilePopup;
