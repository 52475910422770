import { useState } from 'react';
import axios from 'axios';
import { sha256 } from 'js-sha256';
import { NumberUtils } from '../utils/utils';

const endPointUri: string =
	'https://graph.facebook.com/v15.0/3182756498471727/events?access_token=EAAH0Ak1sS3QBAHA2hZBH2KM4qxqfwbSeGyQvUSGOqpSzUt7ZAjsZCTxxUSlFA3OsT7mapNQZASNhx7UHKxMPCL7ZCcJhFs3EVDcDcxS7aM60AQZBKHhKqZA3ah6lOm3QAw5wmtCZAaStGmKE08tZA7N2zRnBr5yqE7aZCMGHaTEOIdLC45T0yopH2Frf4QCA1jraIZD&__cppo=1';
const event_time: number = Math.round(new Date().getTime() / 1000);
const rand_id: string = JSON.stringify(Date.now() * 2);
const testCode: string = 'TEST7685';

function handleHashing(text: string) {
	const hash = sha256(text);
	return hash;
}

const addDotToShowCent = (amount: Number) => {
	const amountConvertedToString = amount.toString();

	const last2Str = amountConvertedToString.slice(-2); // remove the last two digit
	const amountWithoutLast2Str = amountConvertedToString.slice(0, -2); //delete last 2 from number

	//join now
	const newAmount = Number(amountWithoutLast2Str + '.' + last2Str);
	return newAmount.toFixed(2);
};

async function checkAvailability(productData: any) {
	if (window.productionModeType === 'PRODUCTION-SERVER') {
		let productContents = [
			{
				name: productData.name,
				resortId: productData.id,
				externalId: productData.externalId,
				chainId: productData.chainId,
				minPrice: NumberUtils.centsToDollars(productData.minAccommodationPriceInCents),
				maxPrice: NumberUtils.centsToDollars(productData.maxAccommodationPriceInCents),
				identifier: 'RENTYL',
				domain: window.location.protocol + '//' + window.location.hostname
			}
		];

		axios
			.post(endPointUri, {
				data: [
					{
						event_name: 'ViewContent',
						event_id: JSON.stringify(Date.now()),
						event_time: event_time,
						user_data: {
							em: [null],
							ph: [null]
						},
						custom_data: {
							content_ids: rand_id,
							content_data: productContents,
							currency: productData.currencyCode,
							value: NumberUtils.centsToDollars(productData.minAccommodationPriceInCents)
						}
					}
				]
				// test_event_code : testCode
			})
			.then(function (response) {})
			.catch(function (error) {
				console.log(error);
			});
	}
}

async function addToCart(productData: any) {
	if (window.productionModeType === 'PRODUCTION-SERVER') {
		let productContents = [
			{
				name: productData.name,
				resortId: productData.id,
				externalId: productData.externalId,
				chainId: productData.chainId,
				identifier: 'RENTYL',
				domain: window.location.protocol + '//' + window.location.hostname
			}
		];

		axios
			.post(endPointUri, {
				data: [
					{
						event_name: 'AddToCart',
						event_id: JSON.stringify(Date.now()),
						event_time: event_time,
						user_data: {
							em: [null],
							ph: [null]
						},
						custom_data: {
							content_ids: rand_id,
							content_data: productContents
						}
					}
				],
				test_event_code: testCode
			})
			.then(function (response) {})
			.catch(function (error) {
				console.log(error);
			});
	}
}

async function purchase(productData: any) {
	if (window.productionModeType === 'PRODUCTION-SERVER') {
		const averageDailyRateData = productData.stays[0].priceDetail.accommodationDailyCostsInCents;
		let averageDailyRate: any = {};

		for (const key in averageDailyRateData) {
			if (averageDailyRateData.hasOwnProperty(key)) {
				averageDailyRate[key] = NumberUtils.centsToDollars(averageDailyRateData[key]);
			}
		}

		let productContents = [
			{
				name: productData.destination.name,
				resortId: productData.destination.id,
				identifier: 'RENTYL',
				domain: window.location.protocol + '//' + window.location.hostname
			}
		];

		axios
			.post(endPointUri, {
				data: [
					{
						event_name: 'Purchase',
						event_id: JSON.stringify(Date.now()),
						event_time: event_time,
						event_source_url: window.location.protocol + '//' + window.location.hostname,
						action_source: 'website',
						user_data: {
							em: [handleHashing(productData.stays[0].guest.email)],
							ph: [handleHashing(productData.stays[0].guest.phone)],
							fn: [handleHashing(productData.stays[0].guest.firstName)],
							ln: [handleHashing(productData.stays[0].guest.lastName)]
						},
						custom_data: {
							orderId: productData.stays[0].externalConfirmationId,
							currency: 'USD',
							value: NumberUtils.centsToDollars(productData.stays[0].priceDetail.subtotalInCents),
							ADR: JSON.stringify(averageDailyRate),
							content_data: productContents
						}
					}
				]
			})
			.then(function (response) {})
			.catch(function (error) {
				console.log(error);
			});
	}
}

export default {
	checkAvailability,
	addToCart,
	purchase
};
