import * as React from 'react';
import './QuestionnairePopup.scss';
import { Box, Popup, popupController, PopupProps } from '@bit/redsky.framework.rs.996';
import Paper from '../../components/paper/Paper';
import Label from '@bit/redsky.framework.rs.label';
import Icon from '@bit/redsky.framework.rs.icon';
import serviceFactory from '../../services/serviceFactory';
import LabelRadioButton from '../../components/labelRadioButton/LabelRadioButton';
import { useEffect, useState } from 'react';
import Button from '@bit/redsky.framework.rs.button';
import { rsToastify } from '@bit/redsky.framework.rs.toastify';
import SystemService from '../../services/system/system.service';
import axios from 'axios';
import { useRecoilValue } from 'recoil';
import globalState from '../../state/globalState';
import themes from '../../themes/themes.scss';
import SpinningLoaderPopup from '../spinningLoaderPopup/SpinningLoaderPopup';

export interface QuestionnairePopupProps extends PopupProps {
	confirmationNumber: string;
	guestName: string;
	firstName: string;
	lastName: string;
	email: string;
	arrivalDate: Date | string;
	departureDate: Date | string;
	adultCount: number;
	childCount: number;
	destinationName: string;
	price: {
		baseRate: number;
		total: number;
	};
	userId: number;
}

const QuestionnairePopup: React.FC<QuestionnairePopupProps> = (props) => {
	const fullProps = popupController.convertProps(props);
	const questionnaireService = serviceFactory.get<SystemService>('SystemService');
	const [selectedOption, setSelectedOption] = useState<Api.System.SurveyAnswer>('socialMedia');
	const [loading, setLoading] = useState<boolean>(false);
	const [ip, setIP] = useState<string>('');
	const checkoutUser = useRecoilValue<Misc.Checkout | undefined>(globalState.checkoutUser);
	const [confirmationNumber, setConfirmationNumber] = useState<string>('');

	const getIPAddress = async () => {
		const res = await axios.get('https://geolocation-db.com/json/');
		setIP(res.data.IPv4);
	};
	const getConfirmationNumber = async () => {
		setConfirmationNumber(props.confirmationNumber);
	};
	useEffect(() => {
		getIPAddress();
		getConfirmationNumber();
	}, []);

	async function handleContinueClick() {
		popupController.open(SpinningLoaderPopup);
		let surveyResponseData = {
			surveyAnswer: selectedOption,
			confirmationNumber: confirmationNumber,
			firstName: props.firstName,
			lastName: props.lastName,
			email: props.email,
			ipAddress: ip,
			bookingEngine: 'synxis',
			adultCount: props.adultCount,
			childCount: props.childCount,
			arrivalDate: props.arrivalDate,
			departureDate: props.departureDate,
			destinationName: props.destinationName,
			price: props.price,
			userId: props.userId
		};
		try {
			await questionnaireService.sendSurvey(surveyResponseData);
			rsToastify.success('Thank you for the response!', 'Submitted!');
		} catch (error) {
			rsToastify.error('Survey response failed, try again.', 'Server Error');
		}

		// setLoading(true);
		// try {
		// 	const surveyData: Api.System.Req.Survey = {
		// 		surveyAnswer: selectedOption,
		// 		firstName: checkoutUser!.personal?.firstName,
		// 		lastName: checkoutUser!.personal?.lastName,
		// 		email: checkoutUser!.personal?.email,
		// 		ipAddress: ip,
		// 		confirmationNumber: confirmationNumber,
		// 		bookingEngine: 'synxis'
		// 	};
		// 	// await questionnaireService.sendSurvey(surveyData);
		// 	rsToastify.success('Thank you for the response!', 'Submitted!');
		// } catch (e) {
		// 	rsToastify.success('Thank you for the response!', 'Submitted!');
		// }
		popupController.close(SpinningLoaderPopup);
		popupController.closeById(fullProps.popupId);
	}

	async function handleClosePopup() {
		popupController.open(SpinningLoaderPopup);
		try {
			let surveyResponseData = {
				surveyAnswer: null,
				confirmationNumber: confirmationNumber,
				firstName: props.firstName,
				lastName: props.lastName,
				email: props.email,
				ipAddress: ip,
				bookingEngine: 'synxis',
				adultCount: props.adultCount,
				childCount: props.childCount,
				arrivalDate: props.arrivalDate,
				departureDate: props.departureDate,
				destinationName: props.destinationName,
				price: props.price,
				userId: props.userId
			};
			await questionnaireService.sendSurvey(surveyResponseData);
		} catch (error) {}
		popupController.close(SpinningLoaderPopup);
		popupController.closeById(fullProps.popupId);
	}

	return (
		<Popup {...props} preventCloseByBackgroundClick>
			<Paper className={'rsQuestionnairePopup'}>
				<Box className="cardHeader">
					<Label variant={'h6'} color={themes.primaryTextColor}>
						How did you hear about us?
					</Label>
					<Icon cursorPointer iconImg="icon-close" size={16} onClick={handleClosePopup} />
				</Box>
				<Box className="cardBody">
					<Box className="questionnaireColumn">
						<LabelRadioButton
							radioName={'questionnaireRadio'}
							value={'socialMedia'}
							checked={selectedOption === 'socialMedia'}
							text={'Social Media (Facebook, Instagram, Twitter, LinkedIn)'}
							onSelect={(value) => {
								setSelectedOption(value as Api.System.SurveyAnswer);
							}}
							labelVariant={'h6'}
							className={'questionnaireRadio'}
						/>
						<LabelRadioButton
							radioName={'questionnaireRadio'}
							value={'googleOrYoutubeAds'}
							checked={selectedOption === 'googleOrYoutubeAds'}
							text={'Google or YouTube Ads'}
							onSelect={(value) => {
								setSelectedOption(value as Api.System.SurveyAnswer);
							}}
							labelVariant={'h6'}
							className={'questionnaireRadio'}
						/>
						<LabelRadioButton
							radioName={'questionnaireRadio'}
							value={'websiteBannerAds'}
							checked={selectedOption === 'websiteBannerAds'}
							text={'Website/Banner Ads'}
							onSelect={(value) => {
								setSelectedOption(value as Api.System.SurveyAnswer);
							}}
							labelVariant={'h6'}
							className={'questionnaireRadio'}
						/>
						<LabelRadioButton
							radioName={'questionnaireRadio'}
							value={'friendsOrFamily'}
							checked={selectedOption === 'friendsOrFamily'}
							text={'Friends or Family'}
							onSelect={(value) => {
								setSelectedOption(value as Api.System.SurveyAnswer);
							}}
							labelVariant={'h6'}
							className={'questionnaireRadio'}
						/>
						<LabelRadioButton
							radioName={'questionnaireRadio'}
							value={'television'}
							checked={selectedOption === 'television'}
							text={'TV'}
							onSelect={(value) => {
								setSelectedOption(value as Api.System.SurveyAnswer);
							}}
							labelVariant={'h6'}
							className={'questionnaireRadio'}
						/>
						<LabelRadioButton
							radioName={'questionnaireRadio'}
							value={'email'}
							checked={selectedOption === 'email'}
							text={'Email'}
							onSelect={(value) => {
								setSelectedOption(value as Api.System.SurveyAnswer);
							}}
							labelVariant={'h6'}
							className={'questionnaireRadio'}
						/>
						<LabelRadioButton
							radioName={'questionnaireRadio'}
							value={'magazineOrPrintArticles'}
							checked={selectedOption === 'magazineOrPrintArticles'}
							text={'Magazine or other print articles'}
							onSelect={(value) => {
								setSelectedOption(value as Api.System.SurveyAnswer);
							}}
							labelVariant={'h6'}
							className={'questionnaireRadio'}
						/>
						<LabelRadioButton
							radioName={'questionnaireRadio'}
							value={'other'}
							checked={selectedOption === 'other'}
							text={'Other - not listed above'}
							onSelect={(value) => {
								setSelectedOption(value as Api.System.SurveyAnswer);
							}}
							labelVariant={'h6'}
							className={'questionnaireRadio'}
						/>
						<Button
							className={'continueButton yellow'}
							look={'containedPrimary'}
							onClick={handleContinueClick}
							disabled={loading}
						>
							<Label variant={'buttonMdLg'}>
								{loading ? (
									<i className="fa fa-circle-o-notch fa-spin" style={{ fontSize: '24px' }}></i>
								) : (
									'Continue'
								)}
							</Label>
						</Button>
					</Box>
				</Box>
			</Paper>
		</Popup>
	);
};

export default QuestionnairePopup;
