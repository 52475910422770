// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rsIconLabel.top{width:fit-content;width:-moz-fit-content;justify-content:center;align-items:center;text-align:center}.rsIconLabel.top .icon{display:flex;justify-content:center;margin-bottom:3px}.rsIconLabel.right{width:fit-content;width:-moz-fit-content;display:flex;flex-direction:row-reverse;align-items:center}.rsIconLabel.right .icon{margin-left:10px}.rsIconLabel.bottom{width:fit-content;width:-moz-fit-content;display:flex;flex-direction:column-reverse}.rsIconLabel.bottom .icon{align-self:center;margin-top:3px}.rsIconLabel.left{display:flex;align-items:center}.rsIconLabel.left .label{margin-left:10px}
`, "",{"version":3,"sources":["webpack://./src/components/iconLabel/IconLabel.scss"],"names":[],"mappings":"AAEA,iBACC,iBAAkB,CAClB,sBAAuB,CACvB,sBAAuB,CACvB,kBAAmB,CACnB,iBAAkB,CALnB,uBAOE,YAAa,CACb,sBAAuB,CACvB,iBAAkB,CAClB,mBAID,iBAAkB,CAClB,sBAAuB,CACvB,YAAa,CACb,0BAA2B,CAC3B,kBAAmB,CALpB,yBAOE,gBAAiB,CACjB,oBAID,iBAAkB,CAClB,sBAAuB,CACvB,YAAa,CACb,6BAA8B,CAJ/B,0BAME,iBAAkB,CAClB,cAAe,CACf,kBAID,YAAa,CACb,kBAAmB,CAFpB,yBAIE,gBAAiB","sourcesContent":["@import 'src/themes/themes';\n\n.rsIconLabel.top {\n\twidth: fit-content;\n\twidth: -moz-fit-content;\n\tjustify-content: center;\n\talign-items: center;\n\ttext-align: center;\n\t.icon {\n\t\tdisplay: flex;\n\t\tjustify-content: center;\n\t\tmargin-bottom: 3px;\n\t}\n}\n\n.rsIconLabel.right {\n\twidth: fit-content;\n\twidth: -moz-fit-content;\n\tdisplay: flex;\n\tflex-direction: row-reverse;\n\talign-items: center;\n\t.icon {\n\t\tmargin-left: 10px;\n\t}\n}\n\n.rsIconLabel.bottom {\n\twidth: fit-content;\n\twidth: -moz-fit-content;\n\tdisplay: flex;\n\tflex-direction: column-reverse;\n\t.icon {\n\t\talign-self: center;\n\t\tmargin-top: 3px;\n\t}\n}\n\n.rsIconLabel.left {\n\tdisplay: flex;\n\talign-items: center;\n\t.label {\n\t\tmargin-left: 10px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primaryTextColor": `var(--primaryTextColor)`,
	"secondaryTextColor": `var(--secondaryTextColor)`,
	"successGreen": `var(--successGreen)`,
	"adminPink": `var(--adminPink)`,
	"warningRed": `var(--warningRed)`,
	"buttonLinkColor": `var(--buttonLinkColor)`,
	"secondaryButtonLinkColor": `var(--secondaryButtonLinkColor)`,
	"iconAndHighlightTextColor": `var(--iconAndHighlightTextColor)`,
	"primaryBackgroundColor": `var(--primaryBackgroundColor)`,
	"secondaryBackgroundColor": `var(--secondaryBackgroundColor)`,
	"errorColor": `var(--errorColor)`,
	"primaryBoxShadowColor": `var(--primaryBoxShadowColor)`,
	"greyLineColor": `var(--greyLineColor)`,
	"thinGreyLineColor": `var(--thinGreyLineColor)`,
	"white": `var(--white)`,
	"lightestGray": `var(--lightestGray)`,
	"backgroundGray": `var(--backgroundGray)`,
	"borderGray": `var(--borderGray)`,
	"medGray2": `var(--medGray2)`,
	"medGray2Active": `var(--medGray2Active)`,
	"black": `var(--black)`,
	"primaryCalendarColor": `var(--primaryCalendarColor)`,
	"secondaryCalendarColor": `var(--secondaryCalendarColor)`
};
export default ___CSS_LOADER_EXPORT___;
