// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rsAccountLandingPage{padding:230px 95px 100px;box-sizing:border-box;background-color:var(--lightestGray) !important}.rsAccountLandingPage .accountNavigationTileWrapper{display:grid;grid-template-columns:repeat(auto-fit, minmax(356px, 379px));justify-content:space-between;row-gap:30px;max-width:1920px;margin-left:auto;margin-right:auto}.small .rsAccountLandingPage{padding:145px 17px 100px}.small .rsAccountLandingPage .accountNavigationTileWrapper{display:grid;grid-template-columns:100%;justify-content:center;row-gap:30px}
`, "",{"version":3,"sources":["webpack://./src/pages/accountLandingPage/AccountLandingPage.scss"],"names":[],"mappings":"AAEA,sBACC,wBAAyB,CACzB,qBAAsB,CACtB,+CAAqD,CAHtD,oDAME,YAAa,CACb,4DAA6D,CAC7D,6BAA8B,CAC9B,YAAa,CACb,gBAAiB,CACjB,gBAAiB,CACjB,iBAAkB,CAClB,6BAKA,wBAAyB,CAF3B,2DAKG,YAAa,CACb,0BAA2B,CAC3B,sBAAuB,CACvB,YAAa","sourcesContent":["@import '../../themes/themes';\n\n.rsAccountLandingPage {\n\tpadding: 230px 95px 100px;\n\tbox-sizing: border-box;\n\tbackground-color: theme-var($lightestGray) !important;\n\n\t.accountNavigationTileWrapper {\n\t\tdisplay: grid;\n\t\tgrid-template-columns: repeat(auto-fit, minmax(356px, 379px));\n\t\tjustify-content: space-between;\n\t\trow-gap: 30px;\n\t\tmax-width: 1920px;\n\t\tmargin-left: auto;\n\t\tmargin-right: auto;\n\t}\n}\n\n.small {\n\t.rsAccountLandingPage {\n\t\tpadding: 145px 17px 100px;\n\n\t\t.accountNavigationTileWrapper {\n\t\t\tdisplay: grid;\n\t\t\tgrid-template-columns: 100%;\n\t\t\tjustify-content: center;\n\t\t\trow-gap: 30px;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primaryTextColor": `var(--primaryTextColor)`,
	"secondaryTextColor": `var(--secondaryTextColor)`,
	"successGreen": `var(--successGreen)`,
	"adminPink": `var(--adminPink)`,
	"warningRed": `var(--warningRed)`,
	"buttonLinkColor": `var(--buttonLinkColor)`,
	"secondaryButtonLinkColor": `var(--secondaryButtonLinkColor)`,
	"iconAndHighlightTextColor": `var(--iconAndHighlightTextColor)`,
	"primaryBackgroundColor": `var(--primaryBackgroundColor)`,
	"secondaryBackgroundColor": `var(--secondaryBackgroundColor)`,
	"errorColor": `var(--errorColor)`,
	"primaryBoxShadowColor": `var(--primaryBoxShadowColor)`,
	"greyLineColor": `var(--greyLineColor)`,
	"thinGreyLineColor": `var(--thinGreyLineColor)`,
	"white": `var(--white)`,
	"lightestGray": `var(--lightestGray)`,
	"backgroundGray": `var(--backgroundGray)`,
	"borderGray": `var(--borderGray)`,
	"medGray2": `var(--medGray2)`,
	"medGray2Active": `var(--medGray2Active)`,
	"black": `var(--black)`,
	"primaryCalendarColor": `var(--primaryCalendarColor)`,
	"secondaryCalendarColor": `var(--secondaryCalendarColor)`
};
export default ___CSS_LOADER_EXPORT___;
