import * as React from 'react';
import './FilterBarAllFilters.scss';
import { NumberUtils, ObjectUtils, WebUtils } from '../../utils/utils';
import { useRecoilState, useRecoilValue } from 'recoil';
import globalState from '../../state/globalState';
import Box from '@bit/redsky.framework.rs.996/dist/box/Box';
import FilterBarDropDown from '../filterBarDropDown/FilterBarDropDown';
import LabelCheckboxFilterBar from '../labelCheckbox/LabelCheckboxFilterBar';
import { useEffect, useState } from 'react';
import Counter from '../counter/Counter';
import LabelRadioButton from '../labelRadioButton/LabelRadioButton';
import Label from '@bit/redsky.framework.rs.label';
import { RsFormControl, RsFormGroup, RsValidator, RsValidatorEnum } from '@bit/redsky.framework.rs.form';
import LabelButton from '../labelButton/LabelButton';
import useFilterOptions from '../../customHooks/useFilterOptions';
import PriceSlider from '../priceSlider/PriceSlider';
import RedeemPointsToggle from '../redeemPointsToggle/RedeemPointsToggle';
import useIsAtBreakpoint from '../../customHooks/useIsAtBreakpoint';
import RegionService from '../../services/region/region.service';
import serviceFactory from '../../services/serviceFactory';
import { rsToastify } from '@bit/redsky.framework.rs.toastify';
import PromoAgentCode from '../promoAgentCode/PromoAgentCode';
import { getPageFinder, undefinedHandler } from '../../utils/undefinedHandler';

interface FilterBarV2Props {
	availabilityTotal: number;
	chainId: number;
}

const TIMEOUT_INTERVAL = 1000;

enum FormKeys {
	PROPERTY_TYPES = 'propertyTypeIds',
	BEDROOM_COUNT = 'bedroomCount',
	BATHROOM_COUNT = 'bathroomCount',
	PRICE_MAX = 'priceRangeMax',
	PRICE_MIN = 'priceRangeMin',
	EXPERIENCE_IDS = 'experienceIds',
	AMENITY_IDS = 'amenityIds',
	SORT_ORDER = 'sortOrder'
}

const FilterBarAllFilters: React.FC<FilterBarV2Props> = (props) => {
	const search = window.location.search;
	const size = Array.from(new URLSearchParams(search)).length;
	const isLessThan1845 = useIsAtBreakpoint(1845);
	const isLessThan1525 = useIsAtBreakpoint(1525);
	const isLessThan1280 = useIsAtBreakpoint(1280);
	let timeout: number;
	const filterOptions = useFilterOptions();
	const [reservationFilters, setReservationFilters] = useRecoilState<Misc.ReservationFilters>(
		globalState.reservationFilters
	);
	const [keyCounter, setKeyCounter] = useState<number>(1);
	const [trackPromoCodeType, setTrackPromoCodeType] = useState<string>('PR');
	const [promoCodeDatas, setPromoCodeDatas] = useState<string>('');
	const [agentIdData, setAgentIdData] = useState<string>('');
	// const regionName = useRecoilValue<any | []>(globalState.regionNameList);
	const [regionName, setRegionName] = useRecoilState<any | []>(globalState.regionNameList);
	const [regionNameId, setRegionNameId] = useRecoilState<any | []>(globalState.regionNameListId);
	const [regionList, setRegionList] = useState<any>([]);

	const companyVariables = useRecoilValue<Api.Company.Res.GetCompanyAndClientVariables>(globalState.company);
	const [propertyTypes, setPropertyTypes] = useState<Model.PropertyType[]>(filterOptions.propertyTypes);
	const [experienceOptions, setExperienceOptions] = useState<Misc.OptionType[]>(filterOptions.experiences);
	const [amenityOptions, setAmenityOptions] = useState<Misc.OptionType[]>(filterOptions.amenities);
	const [filterForm, setFilterForm] = useState<RsFormGroup>(
		new RsFormGroup([
			//propertyTypeIds are the text accommodationType on the front end.
			//We already have accommodationType and this was already listed as propertyType on the backend.
			new RsFormControl(FormKeys.PROPERTY_TYPES, reservationFilters.propertyTypeIds || [], []),
			new RsFormControl(FormKeys.BEDROOM_COUNT, reservationFilters.bedroomCount || 0, [
				new RsValidator(RsValidatorEnum.REQ, '# Of Bedrooms Required')
			]),
			new RsFormControl(FormKeys.BATHROOM_COUNT, reservationFilters.bathroomCount || 0, [
				new RsValidator(RsValidatorEnum.REQ, '# Of Bathrooms Required')
			]),
			new RsFormControl(FormKeys.PRICE_MAX, reservationFilters.priceRangeMax || 1000, []),
			new RsFormControl(FormKeys.PRICE_MIN, reservationFilters.priceRangeMin || 1, []),
			new RsFormControl(FormKeys.EXPERIENCE_IDS, reservationFilters.experienceIds || [], []),
			new RsFormControl(FormKeys.AMENITY_IDS, reservationFilters.amenityIds || [], []),
			new RsFormControl(FormKeys.SORT_ORDER, reservationFilters.sortOrder || 'ASC', [])
		])
	);
	const [topSearchBarForm, setTopSearchBarForm] = useState<RsFormGroup>(
		new RsFormGroup([new RsFormControl('regionIds', reservationFilters.regionIds || [], [])])
	);
	const regionService = serviceFactory.get<RegionService>('RegionService');

	useEffect(() => {
		setAmenityOptions(filterOptions.amenities);
		setExperienceOptions(filterOptions.experiences);
		setPropertyTypes(filterOptions.propertyTypes);
	}, [filterOptions]);

	useEffect(() => {
		/**
		 * This useEffect grabs the current url params on first page load and sets the search Query Object.
		 */
		//get all filterForm controls to update
		let {
			propertyTypeControl,
			bedroomCountControl,
			bathroomCountControl,
			priceRangeMinControl,
			priceRangeMaxControl,
			experienceIdsControl,
			amenityIdsControl,
			sortOrderControl
		} = getAllControls();

		//set values from the url params
		propertyTypeControl.value = reservationFilters.propertyTypeIds || [];
		bedroomCountControl.value = reservationFilters.bedroomCount || 0;
		bathroomCountControl.value = reservationFilters.bathroomCount || 0;
		priceRangeMinControl.value = reservationFilters.priceRangeMin || 10;
		priceRangeMaxControl.value = reservationFilters.priceRangeMax || 1000;
		experienceIdsControl.value = reservationFilters.experienceIds || [];
		amenityIdsControl.value = reservationFilters.amenityIds || [];
		sortOrderControl.value = reservationFilters.sortOrder || reservationFilters.sortOrder;

		updateAllControls([
			propertyTypeControl,
			bedroomCountControl,
			bathroomCountControl,
			priceRangeMinControl,
			priceRangeMaxControl,
			experienceIdsControl,
			amenityIdsControl,
			sortOrderControl
		]);
		(async () => {
			try {
				let regions: Api.Region.Res.Get[] = await regionService.getAllRegions();
				const tempRegionList: any = [];
				const tempRegionListId: any = [];

				reservationFilters.regionIds?.map((item1: any) => {
					const regionObj = regions.find((item) => item.id == item1);
					if (regionObj) {
						tempRegionList.push(regionObj.name);
						tempRegionListId.push(regionObj.id);
					}
				});
				setRegionName(tempRegionList);
				setRegionNameId(tempRegionListId);
				setRegionList(tempRegionList);
			} catch (e: any) {
				rsToastify.error('There was an issue getting regions');
				const message = `Getting error :${e.message} on ${getPageFinder(window.location.pathname)} page.`;
				undefinedHandler(message);
			}
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reservationFilters]);

	useEffect(() => {
		if (size !== 0 && keyCounter) {
			WebUtils.updateUrlParams({
				...reservationFilters,
				...filterForm.toModel()
			});
		}

		(async () => {
			try {
				let regions: Api.Region.Res.Get[] = await regionService.getAllRegions();
				const tempRegionList: any = [];
				const tempRegionListId: any = [];

				reservationFilters.regionIds?.map((item1: any) => {
					const regionObj = regions.find((item) => item.id == item1);
					if (regionObj) {
						tempRegionList.push(regionObj.name);
						tempRegionListId.push(regionObj.id);
					}
				});
				setRegionName(tempRegionList);
				setRegionNameId(tempRegionListId);
				setRegionList(tempRegionList);
			} catch (e: any) {
				rsToastify.error('There was an issue getting regions');
				const message = `Getting error :${e.message} on ${getPageFinder(window.location.pathname)} page.`;
				undefinedHandler(message);
			}
		})();
	}, [reservationFilters]);

	function sanitizePriceFieldsAndUpdate(control: RsFormControl) {
		if (!control) return;
		control.value = control.value.toString().replaceAll(/[^0-9]/g, '');
		updateFilterFormWithTimeout(control);
	}

	function updateFilterFormWithTimeout(control: RsFormControl | undefined) {
		if (timeout) window.clearTimeout(timeout);
		timeout = window.setTimeout(() => updateFilterForm(control), TIMEOUT_INTERVAL);
	}

	function updateFilterForm(control: RsFormControl | undefined) {
		if (!control) return;
		filterForm.update(control);
		setFilterForm(filterForm.clone());
	}

	function onApplyClick() {
		filterForm.updateInitialValues();
		setReservationFilters((prev) => {
			const form: {
				propertyTypeIds: number[];
				bedroomCount: number;
				bathroomCount: number;
				priceRangeMax: number;
				priceRangeMin: number;
				experienceIds: number[];
				amenityIds: number[];
			} = filterForm.toModel();
			return { ...prev, ...form, sortOrder: reservationFilters.sortOrder };
		});
	}

	function updateRedeemPointsToggle(redeemPoints: boolean) {
		let minPrice = filterForm.get(FormKeys.PRICE_MIN);
		let maxPrice = filterForm.get(FormKeys.PRICE_MAX);
		if (redeemPoints) {
			minPrice.value = 1000;
			maxPrice.value = 1430000;
		} else {
			minPrice.value = 10;
			maxPrice.value = 1000;
		}
		filterForm.updateInitialValues();
		setReservationFilters({
			...reservationFilters,
			priceRangeMin: minPrice.value,
			priceRangeMax: maxPrice.value,
			redeemPoints: !reservationFilters.redeemPoints
		});
	}

	function checkForFilters(): boolean {
		let {
			propertyTypeControl,
			bedroomCountControl,
			bathroomCountControl,
			priceRangeMinControl,
			priceRangeMaxControl,
			experienceIdsControl,
			amenityIdsControl
		} = getAllControls();

		if (ObjectUtils.isArrayWithData(propertyTypeControl.value as number[])) {
			return true;
		}
		if ((bedroomCountControl.value as number) > 0) return true;
		if ((bathroomCountControl.value as number) > 0) return true;
		if ((priceRangeMinControl.value as number) > 10 && !reservationFilters.redeemPoints) return true;
		if ((priceRangeMaxControl.value as number) < 1000 && !reservationFilters.redeemPoints) return true;
		if (
			(priceRangeMinControl.value as number) >
				NumberUtils.centsToPoints(1000, companyVariables.redemptionRatio) &&
			reservationFilters.redeemPoints
		)
			return true;
		if (
			(priceRangeMaxControl.value as number) <
				NumberUtils.centsToPoints(100000, companyVariables.redemptionRatio) &&
			reservationFilters.redeemPoints
		)
			return true;
		if (ObjectUtils.isArrayWithData(experienceIdsControl.value as number[])) {
			return true;
		}
		return ObjectUtils.isArrayWithData(amenityIdsControl.value as number[]);
	}

	function clearAll() {
		let {
			propertyTypeControl,
			bedroomCountControl,
			bathroomCountControl,
			priceRangeMinControl,
			priceRangeMaxControl,
			experienceIdsControl,
			amenityIdsControl,
			sortOrderControl
		} = getAllControls();

		propertyTypeControl.value = [];
		bedroomCountControl.value = 0;
		bathroomCountControl.value = 0;
		if (!reservationFilters.redeemPoints) {
			priceRangeMinControl.value = 10;
			priceRangeMaxControl.value = 1000;
		} else {
			priceRangeMinControl.value = 1000;
			priceRangeMaxControl.value = 1430000;
		}
		experienceIdsControl.value = [];
		amenityIdsControl.value = [];
		sortOrderControl.value = reservationFilters.sortOrder;

		updateAllControls([
			propertyTypeControl,
			bedroomCountControl,
			bathroomCountControl,
			priceRangeMinControl,
			priceRangeMaxControl,
			experienceIdsControl,
			amenityIdsControl,
			sortOrderControl
		]);
		setKeyCounter((prevState) => prevState + 1);
		//update all controls
		setTimeout(() => {
			onApplyClick();
		}, 300);
	}

	function getAllControls(): { [key: string]: RsFormControl } {
		let propertyTypeControl = filterForm.get(FormKeys.PROPERTY_TYPES);
		let bedroomCountControl = filterForm.get(FormKeys.BEDROOM_COUNT);
		let bathroomCountControl = filterForm.get(FormKeys.BATHROOM_COUNT);
		let priceRangeMinControl = filterForm.get(FormKeys.PRICE_MIN);
		let priceRangeMaxControl = filterForm.get(FormKeys.PRICE_MAX);
		let experienceIdsControl = filterForm.get(FormKeys.EXPERIENCE_IDS);
		let amenityIdsControl = filterForm.get(FormKeys.AMENITY_IDS);
		let sortOrderControl = filterForm.get(FormKeys.SORT_ORDER);
		return {
			propertyTypeControl,
			bedroomCountControl,
			bathroomCountControl,
			priceRangeMinControl,
			priceRangeMaxControl,
			experienceIdsControl,
			amenityIdsControl,
			sortOrderControl
		};
	}

	function updateAllControls(controls: RsFormControl[]) {
		let formClone = filterForm.clone();
		controls.forEach((control) => {
			formClone.update(control);
		});
		setFilterForm(formClone);
	}

	function resortOnClearCallback() {
		let tempControl = filterForm.get(FormKeys.EXPERIENCE_IDS);
		tempControl.value = [];
		updateFilterForm(tempControl);
	}

	function bedBathOnClearCallBack() {
		let bedroom = filterForm.get(FormKeys.BEDROOM_COUNT);
		bedroom.value = 0;
		let bathroom = filterForm.get(FormKeys.BATHROOM_COUNT);
		bathroom.value = 0;
		let copyForm = filterForm.clone();
		copyForm.update(bedroom);
		copyForm.update(bathroom);
		setFilterForm(copyForm);
	}

	function accommodationOnClearCallBack() {
		let tempControl = filterForm.get(FormKeys.PROPERTY_TYPES);
		tempControl.value = [];
		updateFilterForm(tempControl);
	}

	function renderAccommodationList() {
		return propertyTypes.map((item) => (
			<LabelCheckboxFilterBar
				key={item.name}
				value={item.id}
				text={item.name}
				onSelect={() => {
					let tempControl = filterForm.get(FormKeys.PROPERTY_TYPES);
					tempControl.value = [...(tempControl.value as number[]), item.id as number];
					updateFilterForm(tempControl);
				}}
				isChecked={(filterForm.get(FormKeys.PROPERTY_TYPES).value as number[]).includes(item.id as number)}
				onDeselect={() => {
					filterForm.get(FormKeys.PROPERTY_TYPES).value = (
						filterForm.get(FormKeys.PROPERTY_TYPES).value as number[]
					).filter((type) => type !== item.id);
					updateFilterForm(filterForm.get(FormKeys.PROPERTY_TYPES));
				}}
				className="filterCheckbox"
			/>
		));
	}

	function renderResortExperiencesOptionsList() {
		return experienceOptions.map((item) => (
			<LabelCheckboxFilterBar
				key={item.value}
				value={item.value}
				text={item.label}
				onSelect={() => {
					let tempControl = filterForm.get(FormKeys.EXPERIENCE_IDS);
					tempControl.value = [...(tempControl.value as number[]), item.value as number];
					updateFilterForm(tempControl);
				}}
				isChecked={(filterForm.get(FormKeys.EXPERIENCE_IDS).value as number[]).includes(item.value as number)}
				onDeselect={() => {
					filterForm.get(FormKeys.EXPERIENCE_IDS).value = (
						filterForm.get(FormKeys.EXPERIENCE_IDS).value as number[]
					).filter((type) => type !== item.value);
					updateFilterForm(filterForm.get(FormKeys.EXPERIENCE_IDS));
				}}
				className="filterCheckbox"
			/>
		));
	}

	function renderInUnitAmenitiesOptionsList() {
		return amenityOptions.map((item) => (
			<LabelCheckboxFilterBar
				key={item.value}
				value={item.value}
				text={item.label}
				onSelect={() => {
					let tempControl = filterForm.get(FormKeys.AMENITY_IDS);
					tempControl.value = [...(tempControl.value as number[]), item.value as number];
					updateFilterForm(tempControl);
				}}
				isChecked={(filterForm.get(FormKeys.AMENITY_IDS).value as number[]).includes(item.value as number)}
				onDeselect={() => {
					filterForm.get(FormKeys.AMENITY_IDS).value = (
						filterForm.get(FormKeys.AMENITY_IDS).value as number[]
					).filter((type) => type !== item.value);
					updateFilterForm(filterForm.get(FormKeys.AMENITY_IDS));
				}}
				className="filterCheckbox"
			/>
		));
	}

	function renderBedBathCounter() {
		return (
			<Box className="bedroomWrapper">
				<Counter
					title="Bedrooms"
					labelVariant={'body1'}
					control={filterForm.get(FormKeys.BEDROOM_COUNT)}
					updateControl={updateFilterForm}
					className={'filterCounter'}
					minCount={0}
					maxCount={99}
					labelMarginRight={5}
				/>
				<Counter
					title="Bathrooms"
					labelVariant={'body1'}
					control={filterForm.get(FormKeys.BATHROOM_COUNT)}
					updateControl={updateFilterForm}
					className={'filterCounter'}
					minCount={0}
					maxCount={99}
					labelMarginRight={5}
				/>
			</Box>
		);
	}

	function renderOtherFilters() {
		return (
			<FilterBarDropDown
				onChangeCallBack={onApplyClick}
				onClearCallback={() => {
					let tempControl = filterForm.get(FormKeys.AMENITY_IDS);
					tempControl.value = [];
					updateFilterForm(tempControl);
					if (isLessThan1845) {
						resortOnClearCallback();
					}
					if (isLessThan1525) {
						bedBathOnClearCallBack();
					}
					if (isLessThan1280) {
						accommodationOnClearCallBack();
					}
				}}
				onClickOutsideCallback={() => {
					filterForm.get(FormKeys.AMENITY_IDS).resetToInitial();
					setFilterForm(filterForm.clone());
				}}
				title={<Label variant="h6">Other Filters</Label>}
				dropdownContentClassName="inUnitAmenitiesCheckboxContentBody"
				icon="icon-slider"
				removeFilterByLabel
				hasIconSpinAnimation={false}
			>
				<Box className={'otherFiltersWrapper'}>
					<Box>
						<Label variant="h5" paddingLeft={24} paddingTop={24}>
							In Unit Amenities
						</Label>
						<Box className="inUnitAmenitiesWrapper">{renderInUnitAmenitiesOptionsList()}</Box>
					</Box>
					{isLessThan1845 && (
						<Box>
							<Label variant="h5" paddingLeft={24} paddingTop={24}>
								Resort Experiences
							</Label>
							<Box className="inUnitAmenitiesWrapper">{renderResortExperiencesOptionsList()}</Box>
						</Box>
					)}
					{isLessThan1525 && (
						<Box>
							<Label variant="h5" paddingLeft={24} paddingTop={24}>
								Bed/Bath
							</Label>
							<Box>{renderBedBathCounter()}</Box>
						</Box>
					)}
					{isLessThan1280 && (
						<Box>
							<Label variant="h5" paddingLeft={24} paddingTop={24}>
								Accommodations
							</Label>
							<Box className="inUnitAmenitiesWrapper">{renderAccommodationList()}</Box>
						</Box>
					)}
				</Box>
			</FilterBarDropDown>
		);
	}

	return (
		<div className="rsFilterBarAllFilters">
			<Box className={'filterRow'}>
				<Box className="filterBarLeft">
					<FilterBarDropDown
						onChangeCallBack={onApplyClick}
						onClearCallback={() => {
							let minPrice = filterForm.get(FormKeys.PRICE_MIN);
							let maxPrice = filterForm.get(FormKeys.PRICE_MAX);
							minPrice.value = reservationFilters.redeemPoints ? 1000 : 10;
							maxPrice.value = reservationFilters.redeemPoints ? 1430000 : 1000;
							let formCopy = filterForm.clone();
							formCopy.update(minPrice);
							formCopy.update(maxPrice);
							setFilterForm(formCopy);
							setKeyCounter((prevState) => prevState + 1);
						}}
						onClickOutsideCallback={() => {
							filterForm.get(FormKeys.PRICE_MIN).resetToInitial();
							filterForm.get(FormKeys.PRICE_MAX).resetToInitial();
							setFilterForm(filterForm.clone());
							setKeyCounter((prevState) => prevState + 1);
						}}
						title={reservationFilters.redeemPoints ? 'Point' : 'Price'}
						dropdownContentClassName="destinationFilterDropdown"
						hasIconSpinAnimation
						promoCodeType={trackPromoCodeType}
						setPromoCodeType={setTrackPromoCodeType}
						savePromoCodeDatas={setPromoCodeDatas}
						saveAgentId={setAgentIdData}
						AgentId={agentIdData}
						promoCode={promoCodeDatas}
						chainId={props.chainId}
					>
						<PriceSlider
							key={`priceSlider${keyCounter}`}
							updateFilterForm={updateFilterForm}
							minControl={filterForm.get(FormKeys.PRICE_MIN)}
							maxControl={filterForm.get(FormKeys.PRICE_MAX)}
							// promoCodeType={setTrackPromoCodeType}
							// savePromoCodeDatas={setPromoCodeDatas}
							// saveAgentId={setAgentIdData}
							// AgentId={agentIdData}
							// promoCode={promoCodeDatas}
							sanitizePriceFieldsAndUpdate={sanitizePriceFieldsAndUpdate}
							redeemPoints={!!reservationFilters.redeemPoints}
						/>
					</FilterBarDropDown>
					{!isLessThan1280 && (
						<FilterBarDropDown
							onChangeCallBack={onApplyClick}
							onClearCallback={accommodationOnClearCallBack}
							onClickOutsideCallback={() => {
								filterForm.get(FormKeys.PROPERTY_TYPES).resetToInitial();
								setFilterForm(filterForm.clone());
							}}
							title={
								!ObjectUtils.isArrayWithData(filterForm.get(FormKeys.PROPERTY_TYPES).value)
									? 'Accommodations'
									: `Accommodations( ${
											(filterForm.get(FormKeys.PROPERTY_TYPES).value as number[]).length
									  } )`
							}
							dropdownContentClassName="destinationFilterDropdown"
							hasIconSpinAnimation
						>
							<Box padding="24px" className="filterWrapper">
								{renderAccommodationList()}
							</Box>
						</FilterBarDropDown>
					)}
					{!isLessThan1525 && (
						<FilterBarDropDown
							onChangeCallBack={onApplyClick}
							onClearCallback={bedBathOnClearCallBack}
							onClickOutsideCallback={() => {
								filterForm.get(FormKeys.BATHROOM_COUNT).resetToInitial();
								filterForm.get(FormKeys.BEDROOM_COUNT).resetToInitial();
								setFilterForm(filterForm.clone());
							}}
							title={'Bed / Bath'}
							dropdownContentClassName="destinationFilterDropdown"
							hasIconSpinAnimation
						>
							{renderBedBathCounter()}
						</FilterBarDropDown>
					)}
					{!isLessThan1845 && (
						<FilterBarDropDown
							onChangeCallBack={onApplyClick}
							onClearCallback={resortOnClearCallback}
							onClickOutsideCallback={() => {
								filterForm.get(FormKeys.EXPERIENCE_IDS).resetToInitial();
								setFilterForm(filterForm.clone());
							}}
							title={
								!ObjectUtils.isArrayWithData(filterForm.get(FormKeys.EXPERIENCE_IDS).value)
									? 'Resort Experiences'
									: `Resort Experiences( ${
											(filterForm.get(FormKeys.EXPERIENCE_IDS).value as number[]).length
									  } )`
							}
							dropdownContentClassName="destinationFilterDropdown"
							hasIconSpinAnimation
							dropdownFooterClass={'flex-row'}
						>
							<Box className="filterWrapper" padding={24}>
								{renderResortExperiencesOptionsList()}
							</Box>
						</FilterBarDropDown>
					)}
					{renderOtherFilters()}
				</Box>
				<hr />

				<FilterBarDropDown
					onChangeCallBack={onApplyClick}
					isPromotion
					onClearCallback={() => {
						// let formCopy = filterForm.clone();
						// setFilterForm(formCopy);
						setKeyCounter((prevState) => prevState + 1);
					}}
					onClickOutsideCallback={() => {
						// filterForm.get(FormKeys.PRICE_MIN).resetToInitial();
						// filterForm.get(FormKeys.PRICE_MAX).resetToInitial();
						// setFilterForm(filterForm.clone());
						setKeyCounter((prevState) => prevState + 1);
					}}
					title="Promo/Agent code"
					dropdownContentClassName="destinationFilterDropdown"
					hasIconSpinAnimation
					arriveDate={reservationFilters.startDate}
					departDate={reservationFilters.endDate}
					promoCodeType={trackPromoCodeType}
					setPromoCodeType={setTrackPromoCodeType}
					savePromoCodeDatas={setPromoCodeDatas}
					saveAgentId={setAgentIdData}
					AgentId={agentIdData}
					promoCode={promoCodeDatas}
					chainId={props.chainId}
					hide={true}
				>
					<PromoAgentCode
						key={`PromoAgentCode${keyCounter}`}
						updateFilterForm={updateFilterForm}
						promoCodeType={setTrackPromoCodeType}
						savePromoCodeDatas={setPromoCodeDatas}
						saveAgentId={setAgentIdData}
						AgentId={agentIdData}
						promoCode={promoCodeDatas}
						redeemPoints={!!reservationFilters.redeemPoints}
					/>
				</FilterBarDropDown>

				<Box className={'filterBarRight'}>
					{/* <Box>
						<FilterBarDropDown
							onChangeCallBack={onApplyClick}
							isSortField
							onClearCallback={() => {
								let tempControl = filterForm.get(FormKeys.SORT_ORDER);
								tempControl.value = 'ASC';
								updateFilterForm(tempControl);
							}}
							title={reservationFilters.sortOrder === 'DESC' ? 'Highest Prices' : 'Lowest Prices'}
							dropdownContentClassName="pricesDropdown"
							hasIconSpinAnimation
						>
							<Box padding={24}>
								<LabelRadioButton
									radioName="highestRadioBtn"
									value="sortHigh"
									checked={reservationFilters.sortOrder === 'DESC'}
									text={`Highest Prices`}
									onSelect={() => {
										setReservationFilters({
											...reservationFilters,
											sortOrder: 'DESC'
										});
									}}
									labelVariant="h6"
									className="priceHighRadio"
								/>
								<LabelRadioButton
									radioName="lowestRadioBtn"
									value="sortLow"
									checked={reservationFilters.sortOrder === 'ASC'}
									text="Lowest Prices"
									onSelect={() => {
										setReservationFilters({
											...reservationFilters,
											sortOrder: 'ASC'
										});
									}}
									labelVariant="h6"
								/>
							</Box>
						</FilterBarDropDown>
					</Box> */}
					<RedeemPointsToggle
						redeemPoints={reservationFilters.redeemPoints as boolean}
						updateRedeemPoints={updateRedeemPointsToggle}
					/>
				</Box>
			</Box>

			<Box className={'bottomFilterBorder'}>
				<Box className={'bottomFilterBar'}>
					<Box className={'destinationCount'}>
						<Label variant={'body1'}>
							{props.availabilityTotal} {props.availabilityTotal ? '+' : ''}{' '}
							{props.availabilityTotal <= 1 ? 'resort' : 'resorts'}{' '}
							{regionList && regionList.length > 0 ? 'in' : ''}
						</Label>
						{regionList && regionList.length > 0 && reservationFilters.regionIds && (
							<Label variant={'buttonSm'} className={'countTextRegion'}>
								{regionList.join()}
							</Label>
						)}
					</Box>

					<Box className={'clearFilter'}>
						{checkForFilters() && (
							<LabelButton
								look={'none'}
								label={'Clear All Filters'}
								className={'clearAllButton'}
								variant={'h6'}
								onClick={clearAll}
							/>
						)}
					</Box>
					<Box>
						<FilterBarDropDown
							onChangeCallBack={onApplyClick}
							className={'sortDropDownBtn'}
							isSortField
							onClearCallback={() => {
								let tempControl = filterForm.get(FormKeys.SORT_ORDER);
								tempControl.value = 'ASC';
								updateFilterForm(tempControl);
							}}
							title={
								reservationFilters.sortOrder === 'DESC'
									? 'Sort By Highest Prices'
									: 'Sort By Lowest Prices'
							}
							dropdownContentClassName="pricesDropdown"
							hasIconSpinAnimation
						>
							<Box padding={24}>
								<LabelRadioButton
									radioName="highestRadioBtn"
									value="sortHigh"
									checked={reservationFilters.sortOrder === 'DESC'}
									text={`Highest Prices`}
									onSelect={() => {
										setReservationFilters({
											...reservationFilters,
											sortOrder: 'DESC'
										});
									}}
									labelVariant="h6"
									className="priceHighRadio"
								/>
								<LabelRadioButton
									radioName="lowestRadioBtn"
									value="sortLow"
									checked={reservationFilters.sortOrder === 'ASC'}
									text="Lowest Prices"
									onSelect={() => {
										setReservationFilters({
											...reservationFilters,
											sortOrder: 'ASC'
										});
									}}
									labelVariant="h6"
								/>
							</Box>
						</FilterBarDropDown>
					</Box>
				</Box>
			</Box>
		</div>
	);
};

export default FilterBarAllFilters;
