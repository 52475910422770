// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.body-overall-container{overflow:hidden}.mapModalContainer{position:fixed;z-index:999;left:0;top:0;width:100%;height:100%;overflow:hidden;background-color:#000;background-color:rgba(0,0,0,0.4)}.mapModal-Header-container{position:relative;background:white;width:100%;height:3rem}.mapModal-closeBtn{position:absolute;top:50%;transform:translateY(-50%);margin-left:15px;color:#3662d8}.mapModal-title{line-height:3rem;margin-left:45px;font-size:1rem;font-weight:500}
`, "",{"version":3,"sources":["webpack://./src/components/googleMap/components/mapModal/mapModal.scss"],"names":[],"mappings":"AAAA,wBACC,eAAgB,CAChB,mBAGA,cAAe,CACf,WAAY,CACZ,MAAO,CACP,KAAM,CACN,UAAW,CACX,WAAY,CACZ,eAAgB,CAChB,qBAA8B,CAC9B,gCAAoC,CACpC,2BAGA,iBAAkB,CAClB,gBAAiB,CACjB,UAAW,CACX,WAAY,CACZ,mBAGA,iBAAkB,CAClB,OAAQ,CACR,0BAA2B,CAC3B,gBAAiB,CACjB,aAAc,CACd,gBAGA,gBAAiB,CACjB,gBAAiB,CACjB,cAAe,CACf,eAAgB","sourcesContent":[".body-overall-container {\n\toverflow: hidden;\n}\n\n.mapModalContainer {\n\tposition: fixed;\n\tz-index: 999;\n\tleft: 0;\n\ttop: 0;\n\twidth: 100%;\n\theight: 100%;\n\toverflow: hidden;\n\tbackground-color: rgb(0, 0, 0);\n\tbackground-color: rgba(0, 0, 0, 0.4);\n}\n\n.mapModal-Header-container {\n\tposition: relative;\n\tbackground: white;\n\twidth: 100%;\n\theight: 3rem;\n}\n\n.mapModal-closeBtn {\n\tposition: absolute;\n\ttop: 50%;\n\ttransform: translateY(-50%);\n\tmargin-left: 15px;\n\tcolor: #3662d8;\n}\n\n.mapModal-title {\n\tline-height: 3rem;\n\tmargin-left: 45px;\n\tfont-size: 1rem;\n\tfont-weight: 500;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
