import * as React from 'react';
import Label from '@bit/redsky.framework.rs.label';
import moment from 'moment';
import { useRecoilState, useSetRecoilState } from 'recoil';
import AccommodationService from '../../services/accommodation/accommodation.service';
import serviceFactory from '../../services/serviceFactory';
import globalState from '../../state/globalState';
import { useEffect, useState } from 'react';
import SpinningLoader from '../../components/loader/Loader';
import { NumberUtils, StringUtils } from '../../utils/utils';
import './FlexibleRates.scss';
import Icon from '@bit/redsky.framework.rs.icon';
import { Box, popupController } from '@bit/redsky.framework.rs.996';
import TagManager from 'react-gtm-module';
import { getPageFinder, undefinedHandler } from '../../utils/undefinedHandler';
import metaCapi from '../../customHooks/useMetaCapi';
import { useNavigate } from 'react-router-dom';
import DestinationService from '../../services/destination/destination.service';
import Accordion from '@bit/redsky.framework.rs.accordion';
import Select from 'react-select';
import FlexibleRatesResponsive from './flexibleRatesResponsive/FlexibleRatesResponsive';
import FlexibleRatesMobile from './flexibleRatesMobile/FlexibleRatesMobile';
import { WebUtils } from '../../utils/utils';
import { rsToastify } from '@bit/redsky.framework.rs.toastify';
import useIsAtBreakpoint from '../../customHooks/useIsAtBreakpoint';
import { get } from 'lodash';

export interface FlexibleRatesProps {
	destinationId: number;
	packageLength?: number;
	loyaltyStatus: Model.LoyaltyStatus;
	upfrontCashRequired: boolean;
	isCustomResort?: boolean;
	phone?: number;
	destinationDetails: Api.Destination.Res.Details | undefined;
}

const differentDate = (date: string | Date, differenceDay: number) => {
	return moment(date, 'YYYY-MM-DD')
		.set('date', moment(date, 'YYYY-MM-DD').get('date') + differenceDay)
		.format('YYYY-MM-DD');
};

const FlexibleRates: React.FC<FlexibleRatesProps> = (props) => {
	const accommodationService = serviceFactory.get<AccommodationService>('AccommodationService');
	const destinationService: DestinationService = serviceFactory.get<DestinationService>('DestinationService');
	const setVerifiedAccommodation = useSetRecoilState<Api.Reservation.Res.Verification | undefined>(
		globalState.verifiedAccommodation
	);
	let navigate = useNavigate();

	const [accommodationStartDate, setAccommodationStartDate] = useState<any>([]);
	const [accommodationFlexibleRates, setAccommodationFlexibleRates] = useState<any>({});
	const [accommodationListLoading, setAccommodationListLoading] = useState<boolean>(false);
	const [accommodationRateLoading, setAccommodationRateLoading] = useState<boolean>(false);
	const [reservationFilters, setReservationFilters] = useRecoilState<Misc.ReservationFilters>(
		globalState.reservationFilters
	);
	const [isOpenFlexibleRate, setIsOpenFlexibleRate] = useState<boolean>(false);
	const [selectAccommodation, setSelectAccommodation] = useState<any>('');
	const [accommodationSelector, setAccommodationSelector] = useState<any>([]);
	const [minmax, setMinMax] = useState<any>({
		min: null,
		max: null
	});
	const isMobile = useIsAtBreakpoint();

	const getAccommodationRate = async () => {
		setAccommodationRateLoading(true);
		try {
			let accommodationRate = await accommodationService.getAccommodationRangeRate({
				...reservationFilters,
				destinationId: props.destinationId,
				accommodationId: selectAccommodation
			});
			let obj: any = {};
			Object.keys(accommodationRate).map((key) => {
				let arr = accommodationRate[key].map((o: any) => {
					let prices = o.prices;
					if (!!prices) {
						prices = prices.sort((a: any, b: any) => {
							return a.grandTotalCents - b.grandTotalCents;
						});
					}
					return { ...o, prices: prices };
				});

				obj = {
					...obj,
					[key]: [...arr]
				};
			});
			setAccommodationFlexibleRates(obj);
			setAccommodationRateLoading(false);
		} catch (error: any) {
			setAccommodationFlexibleRates(null);
			setAccommodationRateLoading(false);
		}
	};

	async function getAvailabilityList() {
		if (!props.destinationId) return;

		setAccommodationListLoading(true);
		try {
			const searchQueryObj: Misc.ReservationFilters = { ...reservationFilters };
			let key: keyof Misc.ReservationFilters;
			for (key in searchQueryObj) {
				if (searchQueryObj[key] === undefined) delete searchQueryObj[key];
			}
			let destinationId: number = props.destinationId;
			let result: any = await accommodationService.availabilityList(destinationId, searchQueryObj);

			let accommodationNames = result.map((accommodation: any) => {
				return {
					value: accommodation.id,
					label: accommodation.name
				};
			});

			setAccommodationSelector(accommodationNames);
			setAccommodationListLoading(false);
		} catch (e) {
			setAccommodationListLoading(false);
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unable to get available accommodations.'), 'Server Error');
		}
	}

	const googleAddToCartHandler = (productData: any, item?: any, prices?: any) => {
		const add_to_cart = {
			id: productData,
			item_name: get(item, 'name', null),
			item_id: get(item, 'externalSystemId', item.id),
			price: NumberUtils.centsToDollars(prices.accommodationTotalInCents),
			item_brand: props.destinationDetails?.name,
			item_category: get(item, 'contentLists.RoomCategoryList.0.Name', null),
			item_category2: get(item, 'contentLists.RoomCategoryList.0.Name', null),
			item_list_name: 'Rooms',
			quantity: 1,
			arrival_date: reservationFilters.startDate,
			depart_date: reservationFilters.endDate,
			total_cost: NumberUtils.centsToDollars(prices.grandTotalCents),
			currency: prices.rate.currencyCode
		};

		try {
			TagManager.dataLayer({
				dataLayer: {
					event: 'add_to_cart',
					ecommerce: null
				}
			});

			TagManager.dataLayer({
				dataLayer: {
					event: 'add_to_cart',
					ecommerce: {
						detail: {
							products: [add_to_cart]
						}
					}
				}
			});
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	};

	async function onBookNow(sDate: string, eDate: string, rate: any, item?: any, prices?: any) {
		if (!props.destinationId) return;
		try {
			if (props.isCustomResort) {
				window.open(`tel:+${props.phone}`, '_self');
			} else {
				setVerifiedAccommodation(undefined);
				if (props.upfrontCashRequired) {
					const publicSlim: Api.Destination.Res.PublicSlim[] = await destinationService.getPublicSlim();

					const destinationPublicSlim: Api.Destination.Res.PublicSlim | undefined = publicSlim.find(
						(publicSlim) => publicSlim.id === props.destinationId
					);

					if (!destinationPublicSlim) return;
					window.open(
						`https://be.synxis.com/?chain=${destinationPublicSlim.chainId}&hotel=${
							destinationPublicSlim.externalSystemId
						}&adult=${reservationFilters.adultCount}&child=0&bedrooms=${
							reservationFilters.bedroomCount || 1
						}&currency=USD&arrive=${reservationFilters.startDate}&depart=${
							reservationFilters.endDate
						}&level=chain&locale=en-US&rooms=1`,
						'_blank'
					);
					navigate('/reservation/availability');
					return;
				}
				if (props.loyaltyStatus !== 'ACTIVE') {
					setReservationFilters({ ...reservationFilters, redeemPoints: false });
				}
				let data: any = { ...reservationFilters, redeemPoints: props.loyaltyStatus === 'ACTIVE' };
				let newRoom: Misc.StayParams = {
					uuid: Date.now(),
					adults: data.adultCount,
					children: data.childCount || 0,
					accommodationId: selectAccommodation,
					arrivalDate: sDate,
					departureDate: eDate,
					packages: [],
					rateCode: rate.code
				};
				let isRedeemPoints = reservationFilters.redeemPoints ? '&redeemPoints=true' : '';
				data = StringUtils.setAddPackagesParams({ destinationId: props.destinationId, newRoom });
				popupController.closeAll();
				const checkoutData = {
					destinationId: props.destinationId,
					stays: [newRoom]
				};

				//google analytics add to cart
				googleAddToCartHandler(props.destinationId, item, prices);
				metaCapi.addToCart(props.destinationDetails);

				if (props.packageLength === 0) {
					navigate(`/booking/checkout?data=${encodeURI(JSON.stringify(checkoutData))}${isRedeemPoints}`);
				} else {
					navigate(`/booking/packages?data=${data}${isRedeemPoints}`);
				}
			}
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	useEffect(() => {
		(async () => {
			props.destinationId && (await getAvailabilityList());
		})();
	}, [reservationFilters, props.destinationId]);

	useEffect(() => {
		if (
			!!reservationFilters.startDate &&
			!!reservationFilters.endDate &&
			selectAccommodation &&
			props.destinationId
		) {
			setMinMax({
				min: null,
				max: null
			});
			let startDateArray = [
				differentDate(reservationFilters.startDate, -3),
				differentDate(reservationFilters.startDate, -2),
				differentDate(reservationFilters.startDate, -1),
				differentDate(reservationFilters.startDate, 0),
				differentDate(reservationFilters.startDate, 1),
				differentDate(reservationFilters.startDate, 2),
				differentDate(reservationFilters.startDate, 3)
			];
			setAccommodationStartDate(startDateArray);
			getAccommodationRate();
		}
	}, [selectAccommodation]);

	useEffect(() => {
		if (accommodationFlexibleRates ?? false) {
			let array: number[][] = Object.values(accommodationFlexibleRates).map((subArray: any) => {
				return subArray.map((o: any) => {
					if (!!o.prices && o.prices.length > 0) {
						return o.prices[0].grandTotalCents;
					} else {
						return null;
					}
				});
			});

			if (array.length > 0) {
				const flattenedArray = array.flat();
				const filteredArray = flattenedArray.filter((value) => value !== null && value !== undefined);
				const min = Math.min(...filteredArray);
				const max = Math.max(...filteredArray);

				setMinMax({
					min: min,
					max: max
				});
			}
		}
	}, [accommodationFlexibleRates]);

	return (
		<div className="flexibleRate">
			<Accordion
				className="flexibleDateAccordion"
				hideChevron
				titleReact={
					<Box display={'flex'} alignItems={'center'}>
						<Label variant={'buttonMdLg'} className={'primaryTextColor'} mr={20}>
							{'Flexible dates? Click here!'}
						</Label>
						<Icon iconImg={isOpenFlexibleRate ? 'icon-chevron-up' : 'icon-chevron-down'} cursorPointer />
					</Box>
				}
				isOpen={false}
				onClick={() => setIsOpenFlexibleRate(!isOpenFlexibleRate)}
				hideHoverEffect
			>
				{accommodationListLoading ? (
					<div style={{ marginTop: '3rem' }}>
						<SpinningLoader />
					</div>
				) : (
					<Box>
						<Select
							options={accommodationSelector}
							onChange={(accommodation: any) => setSelectAccommodation(accommodation?.value)}
							className="selectAccommodation"
						/>
						{accommodationRateLoading ? (
							<div style={{ marginTop: '3rem' }}>
								<SpinningLoader />
							</div>
						) : isMobile ? (
							<FlexibleRatesMobile
								onRateClick={onBookNow}
								departureDateArray={accommodationStartDate}
								tableData={accommodationFlexibleRates}
								minMaxPrice={minmax}
							/>
						) : (
							<FlexibleRatesResponsive
								onRateClick={onBookNow}
								departureDateArray={accommodationStartDate}
								tableData={accommodationFlexibleRates}
								minMaxPrice={minmax}
							/>
						)}
					</Box>
				)}
			</Accordion>
		</div>
	);
};

export default FlexibleRates;
