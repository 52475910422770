import globalState, { getRecoilExternalValue } from '../state/globalState';

//Function to load flip.to script  to page
async function setFlipInit(resortData: Api.Destination.Res.Details) {
	let setFliptoRegionId: any = [];
	// resortData.regions.forEach((flipToregionId: any) => {
	// 	setFliptoRegionId.push(flipToregionId.id.toString());
	// });

	const fliptoScript = `
    !function(b,e){(b[e]=b[e]||[]).push({flipto:{
        bookingEngine: 'rentyl',
        companyCode: 'YX',
        code: '${resortData.id}',
        fallbackCode: '${resortData.id}',
        language: 'en'
        }})}(window,'fliptoDataLayer');`;

	const script = document.createElement('script');
	script.text = fliptoScript;
	document.head.appendChild(script);
}

// Function to Load flip.to script on every page
async function loadFlipToScript() {
	let setFliptoDestinationId: string = '';
	let setFliptoRegionId: any = [];

	const reservationFilters = getRecoilExternalValue<Misc.ReservationFilters>(globalState.reservationFilters);
	if (reservationFilters.destinationId !== undefined) {
		setFliptoDestinationId = JSON.stringify(reservationFilters.destinationId);
	}

	if (reservationFilters.flipToregionIds !== undefined) {
		reservationFilters.flipToregionIds.forEach((flipToregionId: any) => {
			setFliptoRegionId.push(flipToregionId.id.toString());
		});
	}

	const fliptoScript = `
		 !function(b,e){(b[e]=b[e]||[]).push({flipto:{
            bookingEngine: 'rentyl',
            companyCode: 'YX',
            code: '${setFliptoDestinationId}',
            fallbackCode: '${setFliptoRegionId}',
            language: 'en'
            }})}(window,'fliptoDataLayer');`;

	const script = document.createElement('script');
	script.text = fliptoScript;
	document.head.appendChild(script);
}

export default {
	setFlipInit,
	loadFlipToScript
};
