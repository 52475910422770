import * as React from 'react';
import { Popup, popupController, PopupProps } from '@bit/redsky.framework.rs.996';
import { useNavigate } from 'react-router-dom';
import router from '../../utils/router';
import { NumberUtils, ObjectUtils, StringUtils } from '../../utils/utils';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import globalState from '../../state/globalState';
import AccommodationPopup from './accomodationPopup/AccommodationPopup';
import MobileLightBox, { MobileLightBoxProps } from '../mobileLightBox/MobileLightBox';
import MobileAccommodationOverviewPopup from './mobileAccomodationPopup/MobileAccommodationOverviewPopup';
import useIsAtBreakpoint from '../../customHooks/useIsAtBreakpoint';
import IconLabel from '../../components/iconLabel/IconLabel';
import { useEffect } from 'react';
import metaCapi from '../../customHooks/useMetaCapi';
import TagManager from 'react-gtm-module';
import { IKImage } from 'imagekitio-react';
import { getPageFinder, undefinedHandler } from '../../utils/undefinedHandler';
import { get } from 'lodash';

export interface AccommodationOverviewPopupProps extends PopupProps {
	pointPerDollar?: number;
	destinationName: string;
	packageCount: number | undefined;
	accommodationStay: Api.Accommodation.Res.Availability;
	destinationDetails: Api.Destination.Res.Details | undefined;
	loyaltyStatus: Model.LoyaltyStatus;
	pointsEarnable: number;
	resortPointsPrice: number;
	isCustomResort?: boolean;
	phone?: number;
}

const AccommodationOverviewPopup: React.FC<AccommodationOverviewPopupProps> = (props) => {
	let navigate = useNavigate();
	const setVerifiedAccommodation = useSetRecoilState<Api.Reservation.Res.Verification | undefined>(
		globalState.verifiedAccommodation
	);
	const verifiedAccommodation = useRecoilValue<Api.Reservation.Res.Verification | undefined>(
		globalState.verifiedAccommodation
	);
	const reservationFilters = useRecoilValue<Misc.ReservationFilters>(globalState.reservationFilters);
	const fullProps = popupController.convertProps(props);

	useEffect(() => {
		const id = router.subscribeToBeforeRouterNavigate(async (newPath) => {
			popupController.closeById(fullProps.popupId);
			return newPath !== '/booking/packages' && newPath !== '/booking/checkout';
		});
		window.addEventListener('popstate', (event: PopStateEvent) => {
			event.preventDefault();
			popupController.closeById(fullProps.popupId);
		});

		return () => {
			router.unsubscribeFromBeforeRouterNavigate(id);
			window.removeEventListener('popstate', (event: PopStateEvent) => {
				event.preventDefault();
				popupController.closeById(fullProps.popupId);
			});
		};
	}, []);

	function closeSelf() {
		popupController.closeById(fullProps.popupId);
	}
	const isMobile = useIsAtBreakpoint();

	function getAccommodationImages() {
		if (!props.accommodationStay.media) return;
		return props.accommodationStay.media.map((item) => item.urls.imageKit);
	}

	function handleFloorPlanExpand() {
		try {
			let featureData: Misc.ImageTabProp[] = props.accommodationStay.layout.map(
				({
					title,
					media,
					media: {
						title: mediaTitle,
						description,
						urls: { imageKit: imagePath }
					}
				}) => ({
					name: title,
					title: mediaTitle,
					imagePath,
					description,
					buttonLabel: mediaTitle,
					otherMedia: [media]
				})
			);
			popupController.open<MobileLightBoxProps>(MobileLightBox, {
				featureData: featureData,
				floorPlanClass: true
			});
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	const googleAddToCartHandler = (productData: any) => {
		const add_to_cart = {
			id: productData,
			item_name: props.accommodationStay.name,
			item_id: get(props, 'accommodationStay.externalSystemId', props?.accommodationStay?.id),
			price: NumberUtils.centsToDollars(Number(props.accommodationStay.prices[0].baseRates)),
			item_brand: props.destinationDetails?.name,
			item_category: get(props, 'accommodationStay.contentLists.RoomCategoryList.0.Name', null),
			item_category2: get(props, 'accommodationStay.contentLists.RoomCategoryList.0.Name', null),
			item_list_name: 'Rooms',
			quantity: 1,
			arrival_date: reservationFilters.startDate,
			depart_date: reservationFilters.endDate,
			total_cost: NumberUtils.centsToDollars(props.accommodationStay.prices[0].grandTotalCents),
			currency: props.accommodationStay.prices[0].rate.currencyCode
		};
		try {
			TagManager.dataLayer({
				dataLayer: {
					event: 'add_to_cart',
					ecommerce: null
				}
			});

			TagManager.dataLayer({
				dataLayer: {
					event: 'add_to_cart',
					ecommerce: {
						detail: {
							products: [add_to_cart]
						}
					}
				}
			});
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	};

	function handleReserveStay() {
		try {
			if (props.isCustomResort) {
				window.open(`tel:+${props.phone}`, '_self');
			} else {
				setVerifiedAccommodation(undefined);
				let data: any = { ...reservationFilters };
				let newRoom: Misc.StayParams = {
					uuid: Date.now(),
					adults: data.adultCount,
					children: data.childCount || 0,
					accommodationId: props.accommodationStay.id,
					arrivalDate: data.startDate,
					departureDate: data.endDate,
					packages: [],
					rateCode: props.accommodationStay.prices[0].rate.code
				};
				let isRedeemPoints = reservationFilters.redeemPoints ? '&redeemPoints=true' : '';
				data = StringUtils.setAddPackagesParams({
					destinationId: props.accommodationStay.destinationId,
					newRoom
				});
				popupController.close(AccommodationOverviewPopup);
				const checkoutData = {
					destinationId: reservationFilters.destinationId,
					stays: [newRoom]
				};

				googleAddToCartHandler(reservationFilters.destinationId);
				metaCapi.addToCart(props.destinationDetails);
				if (props.packageCount === 0) {
					//if there is no packages
					navigate(`/booking/checkout?data=${encodeURI(JSON.stringify(checkoutData))}${isRedeemPoints}`);
				} else {
					navigate(`/booking/packages?data=${data}${isRedeemPoints}`);
				}
			}
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	function renderAccommodationSize() {
		try {
			const sizeObj = ObjectUtils.smartParse(props.accommodationStay.size);
			if (Object.keys(sizeObj).length === 0) return;
			const range: string = sizeObj.min === sizeObj.max ? `${sizeObj.min}` : `${sizeObj.min} to ${sizeObj.max}`;
			return `${range} ${sizeObj.units === 'SquareFeet' ? `ft` : sizeObj.units}`;
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	function renderAmenities() {
		if (!props.accommodationStay) return <></>;
		return props.accommodationStay.amenities.map((amenity) => {
			return (
				<IconLabel
					className={'amenityItem'}
					key={amenity.id}
					iconImg={amenity.icon}
					iconPosition={'top'}
					labelVariant={'body1'}
					iconSize={42}
					labelName={amenity.title}
				/>
			);
		});
	}

	function renderLayoutImages(): React.ReactNodeArray {
		if (!props.accommodationStay.layout) return [<div />];
		return props.accommodationStay.layout.map((layout) => {
			return (
				<div className={'imageTile'}>
					<IKImage loading="lazy" src={layout.media.urls.imageKit} alt={layout.title} />
				</div>
			);
		});
	}

	return (
		<Popup {...props}>
			{isMobile ? (
				<MobileAccommodationOverviewPopup
					destinationDetails={props.destinationDetails}
					pointPerDollar={props.pointPerDollar}
					getAccommodationImages={getAccommodationImages}
					destinationName={props.destinationName}
					packageCount={props.packageCount}
					handleFloorPlanExpand={handleFloorPlanExpand}
					handleReserveStay={handleReserveStay}
					renderAmenities={renderAmenities}
					renderAccommodationSize={renderAccommodationSize}
					popUp={AccommodationOverviewPopup}
					accommodationStay={props.accommodationStay}
					loyaltyStatus={props.loyaltyStatus}
					pointsEarnable={props.pointsEarnable}
					resortPointsPrice={props.resortPointsPrice}
					renderLayoutImages={renderLayoutImages}
					closeSelf={closeSelf}
					isCustomResort={props.isCustomResort}
					phone={props.phone}
				/>
			) : (
				<AccommodationPopup
					destinationDetails={props.destinationDetails}
					pointPerDollar={props.pointPerDollar}
					renderLayoutImages={renderLayoutImages}
					renderAmenities={renderAmenities}
					packageCount={props.packageCount}
					renderAccommodationSize={renderAccommodationSize}
					handleReserveStay={handleReserveStay}
					popUp={AccommodationOverviewPopup}
					destinationName={props.destinationName}
					accommodationStay={props.accommodationStay}
					loyaltyStatus={props.loyaltyStatus}
					pointsEarnable={props.pointsEarnable}
					resortPointsPrice={props.resortPointsPrice}
					closeSelf={closeSelf}
					isCustomResort={props.isCustomResort}
					phone={props.phone}
				/>
			)}
		</Popup>
	);
};
export default AccommodationOverviewPopup;
