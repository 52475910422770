import { Service } from '../Service';
import http from '../../utils/http';
import { RsResponseData } from '@bit/redsky.framework.rs.http';
import { NumberUtils, WebUtils } from '../../utils/utils';
import FilterQueryValue = RedSky.FilterQueryValue;

export default class AccommodationService extends Service {
	async getAccommodationDetails(data: Api.Accommodation.Req.Details): Promise<Api.Accommodation.Res.Details> {
		let response = await http.get<RsResponseData<Api.Accommodation.Res.Details>>('accommodation/details', data);
		return response.data.data;
	}

	async getByPage(filter?: FilterQueryValue[]): Promise<RedSky.RsPagedResponseData<Api.Accommodation.Res.GetByPage>> {
		let res = await http.get<RedSky.RsPagedResponseData<Api.Accommodation.Res.GetByPage>>(
			'accommodation/paged',
			WebUtils.convertDataForUrlParams(filter)
		);
		return res.data.data;
	}

	async availability(
		destinationId: number,
		searchQueryObj: Misc.ReservationFilters
	): Promise<Api.Accommodation.Res.MinMaxAvailability> {
		if (!searchQueryObj.redeemPoints && searchQueryObj.priceRangeMin)
			searchQueryObj.priceRangeMin = NumberUtils.dollarsToCents(searchQueryObj.priceRangeMin);
		if (
			(searchQueryObj.redeemPoints && searchQueryObj.priceRangeMax === 1430000) ||
			(!searchQueryObj.redeemPoints && searchQueryObj.priceRangeMax === 1000)
		)
			delete searchQueryObj.priceRangeMax;
		if (!searchQueryObj.redeemPoints && searchQueryObj.priceRangeMax)
			searchQueryObj.priceRangeMax = NumberUtils.dollarsToCents(searchQueryObj.priceRangeMax);
		if (!searchQueryObj.chainId) delete searchQueryObj.chainId;
		let res = await http.get<Api.Accommodation.Res.MinMaxAvailability>('accommodation/availability', {
			...searchQueryObj,
			destinationId
		});
		return res.data.data;
	}

	async availabilityList(destinationId: number, searchQueryObj: Misc.ReservationFilters): Promise<any> {
		if (!searchQueryObj.redeemPoints && searchQueryObj.priceRangeMin)
			searchQueryObj.priceRangeMin = NumberUtils.dollarsToCents(searchQueryObj.priceRangeMin);
		if (
			(searchQueryObj.redeemPoints && searchQueryObj.priceRangeMax === 1430000) ||
			(!searchQueryObj.redeemPoints && searchQueryObj.priceRangeMax === 1000)
		)
			delete searchQueryObj.priceRangeMax;
		if (!searchQueryObj.redeemPoints && searchQueryObj.priceRangeMax)
			searchQueryObj.priceRangeMax = NumberUtils.dollarsToCents(searchQueryObj.priceRangeMax);
		if (!searchQueryObj.chainId) delete searchQueryObj.chainId;
		let res = await http.get<any>('accommodation/availability/list', {
			...searchQueryObj,
			destinationId
		});
		return res.data.data;
	}

	async getAccommodationRangeRate(searchQueryObj: any): Promise<any> {
		let res = await http.get<Api.Accommodation.Res.MinMaxAvailability>('accommodation/availability/date/range', {
			...searchQueryObj
		});
		return res.data.data;
	}

	async getManyAccommodationDetails(accommodationIds: number[]): Promise<Api.Accommodation.Res.Details[]> {
		let accommodations: Api.Accommodation.Res.Details[] = [];
		for (let id of accommodationIds) {
			let res = await http.get<RsResponseData<Api.Accommodation.Res.Details>>('accommodation/details', {
				accommodationId: id
			});
			accommodations.push(res.data.data);
		}
		return accommodations;
	}

	async getAllAmenities(): Promise<Api.Amenity.Res.Get[]> {
		const res = await http.get<RsResponseData<Api.Amenity.Res.Get[]>>('accommodation/amenity');
		return res.data.data;
	}

	async getBookingPolicy(
		destinationId: number,
		searchQueryObj: Misc.ReservationFilters,
		currentDestination?: number,
		accommodationId?: number
	): Promise<any> {
		const res = await http.get<any>('/accommodation/availability/content', {
			...searchQueryObj,
			destinationId,
			accommodationId
		});
		return res.data.data;
	}
}
