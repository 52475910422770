import React, { useEffect, useState } from 'react';
import useIsAtBreakpoint from '../../customHooks/useIsAtBreakpoint';
import DestinationSearchResultCardMobile from './destinationSearchResultCardMobile/DestinationSearchResultCardMobile';
import DestinationSearchResultCardResponsive from './destinationSearchResultCardResponsive/DestinationSearchResultCardResponsive';
import useGetCountryList from '../../customHooks/useGetCountryList';
import router from '../../utils/router';
import { WebUtils } from '../../utils/utils';
import { useRecoilState } from 'recoil';
import globalState from '../../state/globalState';

export interface DestinationSearchResultCardProps {
	className?: string;
	destinationObj: Api.Destination.Res.AvailabilitySummary;
	countryList: Api.Country.Res.Country[];
	picturePaths: string[];
	pointPerDollar: number;
	onAddCompareClick?: () => void;
	onRemoveCompareClick?: () => void;
	onGalleryClick: () => void;
	FavoriteDestinationsIcon?: string;
	isAccommodations: number;
}

const DestinationSearchResultCard: React.FC<DestinationSearchResultCardProps> = (props) => {
	const isMobile = useIsAtBreakpoint();

	function formatLocation() {
		if (!props.destinationObj) return;
		if (!props.destinationObj.state) {
			let countryName: Api.Country.Res.Country | undefined = props.countryList.find(
				(item) => item.isoCode === props.destinationObj.country
			);
			if (!countryName) return;
			return `${props.destinationObj.city}, ${countryName.name}`;
		} else {
			return `${props.destinationObj.city}, ${props.destinationObj.state}`;
		}
	}

	return isMobile ? (
		<DestinationSearchResultCardMobile
			pointPerDollar={props.pointPerDollar}
			isAccommodations={props.isAccommodations}
			destinationObj={props.destinationObj}
			FavoriteDestinationsIcon={props.FavoriteDestinationsIcon}
			picturePaths={props.picturePaths}
			onAddCompareClick={props.onAddCompareClick}
			onRemoveCompareClick={props.onRemoveCompareClick}
			onGalleryClick={props.onGalleryClick}
			locationInfo={formatLocation() || ''}
			className={props.className}
		/>
	) : (
		<DestinationSearchResultCardResponsive
			pointPerDollar={props.pointPerDollar}
			isAccommodations={props.isAccommodations}
			destinationObj={props.destinationObj}
			picturePaths={props.picturePaths}
			FavoriteDestinationsIcon={props.FavoriteDestinationsIcon}
			onAddCompareClick={props.onAddCompareClick}
			onRemoveCompareClick={props.onRemoveCompareClick}
			onGalleryClick={props.onGalleryClick}
			locationInfo={formatLocation() || ''}
			className={props.className}
		/>
	);
};

export default DestinationSearchResultCard;
