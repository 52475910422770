import { StringUtils } from '../utils/utils';
import { get } from 'lodash';

//Function to calculate point earning
function pointEarningCalculation(
	destinationObj: any,
	pointPerDollar: number,
	userTierValue?: any,
	user?: any,
	campaignLength?: number
): string {
	let calc = 0;
	let pointEarnText;

	if (
		destinationObj?.campaigns === undefined ||
		destinationObj.campaigns === null ||
		destinationObj.campaigns.length === 0 ||
		campaignLength === 0
	) {
		const pointValueMultiplier = 1;
		const bulkPoint = 0;

		if (user) {
			calc =
				(Number(StringUtils.formatMoney(destinationObj?.minAccommodationPriceInCents).replace(/,/g, '')) *
					pointPerDollar *
					pointValueMultiplier +
					bulkPoint) *
				(1 + userTierValue / 100);
		} else {
			calc =
				Number(StringUtils.formatMoney(destinationObj?.minAccommodationPriceInCents).replace(/,/g, '')) *
					pointPerDollar *
					1 +
				0;
		}

		pointEarnText =
			'You could earn from ' +
			StringUtils.addCommasToNumber(Math.floor(calc * 100) / 100) +
			' points for this stay';

		return pointEarnText;
	}

	var campaignsData = destinationObj.campaigns?.sort(function (a: any, b: any) {
		return new Date(b.createdOn).valueOf() - new Date(a.createdOn).valueOf();
	});

	if (campaignsData[0]?.pointMultiplier === 0) {
		return '';
	}

	const pointValueMultiplier = get(campaignsData, '0.pointMultiplier', 1);
	const bulkPoint = get(campaignsData, '0.bulkPoint', 0);

	const currentDate = new Date();
	const startDate = new Date(campaignsData[0]?.startOn);
	const endDate = new Date(campaignsData[0]?.endOn);

	const daysOfTheWeek = campaignsData[0]?.activeOnDays;

	if ((daysOfTheWeek === undefined || daysOfTheWeek === '') && campaignsData[0]?.alwaysActive !== 1) {
		if (user) {
			calc =
				(Number(StringUtils.formatMoney(destinationObj?.minAccommodationPriceInCents).replace(/,/g, '')) *
					pointPerDollar *
					pointValueMultiplier +
					bulkPoint) *
				(1 + userTierValue / 100);
		} else {
			calc =
				Number(StringUtils.formatMoney(destinationObj?.minAccommodationPriceInCents).replace(/,/g, '')) *
					pointPerDollar *
					1 +
				0;
		}

		pointEarnText =
			'You could earn from ' +
			StringUtils.addCommasToNumber(Math.floor(calc * 100) / 100) +
			' points for this stay';

		return pointEarnText;
	}

	const arrDaysOfTheWeek = daysOfTheWeek.split(',');
	let getTodayDates = new Date().getDay();
	let count = 0;

	if (getTodayDates === 0) {
		getTodayDates = 7;
	}

	arrDaysOfTheWeek.forEach((item: any) => {
		if (getTodayDates === Number(item)) {
			count++;
		}
	});

	if (count !== 1) {
		if (user) {
			calc =
				(Number(StringUtils.formatMoney(destinationObj?.minAccommodationPriceInCents).replace(/,/g, '')) *
					pointPerDollar *
					pointValueMultiplier +
					bulkPoint) *
				(1 + userTierValue / 100);
		} else {
			calc =
				Number(StringUtils.formatMoney(destinationObj?.minAccommodationPriceInCents).replace(/,/g, '')) *
					pointPerDollar *
					1 +
				0;
		}

		pointEarnText =
			'You could earn from ' +
			StringUtils.addCommasToNumber(Math.floor(calc * 100) / 100) +
			' points for this stay';
		return pointEarnText;
	}

	if (campaignsData.length === 0) {
		if (user) {
			calc =
				(Number(StringUtils.formatMoney(destinationObj?.minAccommodationPriceInCents).replace(/,/g, '')) *
					pointPerDollar *
					pointValueMultiplier +
					bulkPoint) *
				(1 + userTierValue / 100);
		} else {
			calc =
				Number(StringUtils.formatMoney(destinationObj?.minAccommodationPriceInCents).replace(/,/g, '')) *
					pointPerDollar *
					1 +
				0;
		}
	} else {
		if (campaignsData[0]?.alwaysActive === 1) {
			if (user) {
				calc =
					(Number(StringUtils.formatMoney(destinationObj?.minAccommodationPriceInCents).replace(/,/g, '')) *
						pointPerDollar *
						pointValueMultiplier +
						bulkPoint) *
					(1 + userTierValue / 100);
			} else {
				calc =
					Number(StringUtils.formatMoney(destinationObj?.minAccommodationPriceInCents).replace(/,/g, '')) *
						pointPerDollar *
						campaignsData[0]?.pointMultiplier +
					campaignsData[0]?.bulkPoint;
			}
		} else {
			// Check if the current date is between the start and end dates
			if (currentDate >= startDate && currentDate <= endDate) {
				if (user) {
					calc =
						(Number(
							StringUtils.formatMoney(destinationObj?.minAccommodationPriceInCents).replace(/,/g, '')
						) *
							pointPerDollar *
							pointValueMultiplier +
							bulkPoint) *
						(1 + userTierValue / 100);
				} else {
					calc =
						Number(
							StringUtils.formatMoney(destinationObj?.minAccommodationPriceInCents).replace(/,/g, '')
						) *
							pointPerDollar *
							campaignsData[0]?.pointMultiplier +
						campaignsData[0]?.bulkPoint;
				}
			} else {
				if (user) {
					calc =
						(Number(
							StringUtils.formatMoney(destinationObj?.minAccommodationPriceInCents).replace(/,/g, '')
						) *
							pointPerDollar *
							pointValueMultiplier +
							bulkPoint) *
						(1 + userTierValue / 100);
				} else {
					calc =
						Number(
							StringUtils.formatMoney(destinationObj?.minAccommodationPriceInCents).replace(/,/g, '')
						) *
							pointPerDollar *
							1 +
						0;
				}
			}
		}
	}

	pointEarnText =
		'You could earn from ' + StringUtils.addCommasToNumber(Math.floor(calc * 100) / 100) + ' points for this stay';
	return pointEarnText;
}

function rewardPointsCalculation(
	destinationObj: any,
	rewardPoints: number | undefined,
	userTierValue?: any,
	user?: any,
	message?: boolean
): string {
	const pointMultiplier = !!destinationObj.campaigns[0] ? destinationObj?.campaigns[0]?.pointMultiplier : 1;
	const bulkPoint = !!destinationObj.campaigns[0] ? destinationObj.campaigns[0]?.bulkPoint : 0;
	const userTierMultiplier = !!user ? 1 + userTierValue / 100 : 1;
	const rewardPointsPerDollar = rewardPoints ? rewardPoints : 0;
	const dollarAmount = Number(StringUtils.formatMoney(destinationObj.minAccommodationPriceInCents).replace(/,/g, ''));

	/* 
		type == point multiplier   // (Points Offered)
		dollarAmount * pointMultiplier * userTierMultiplier

		type == bulk point 
		((dollarAmount * global pointPerDollar) + bulkPoint) * userTierMultiplier

		if (type == undefined || null)
			dollarAmount * rewardPointsPerDollar * pointMultiplier + bulkPoint) * userTierMultiplier
		*/

	let cal = 0;
	if (!!destinationObj?.campaigns[0] && destinationObj?.campaigns[0]?.pointStatus == 0) {
		cal = dollarAmount * pointMultiplier * userTierMultiplier;
	} else if (!!destinationObj?.campaigns[0] && destinationObj?.campaigns[0]?.pointStatus == 1) {
		cal = (dollarAmount * rewardPointsPerDollar + bulkPoint) * userTierMultiplier;
	} else {
		cal = (dollarAmount * rewardPointsPerDollar * pointMultiplier + bulkPoint) * userTierMultiplier;
	}
	if (!!message) {
		return `you could receive ${StringUtils.addCommasToNumber(Math.floor(cal * 100) / 100)} points for this stay`;
	}
	return `you could earn from ${StringUtils.addCommasToNumber(Math.floor(cal * 100) / 100)} points for this stay`;
}

export default {
	pointEarningCalculation,
	rewardPointsCalculation
};
