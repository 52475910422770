import { Service } from '../Service';
import http from '../../utils/http';

export default class SystemService extends Service {
	start() {}
	async sendSurvey(data: Api.System.Req.Survey): Promise<Api.System.Res.Survey> {
		let response = await http.post<Api.System.Res.Survey>('system/survey', data);
		return response.data;
	}
	async sendAnonymousUserInfo(data: any): Promise<any> {
		let response = await http.post<any>('system/anonymous-user-info', data);
		return response.data;
	}

	async upDateAnonymousUserInfo(data: any, id: any): Promise<any> {
		let response = await http.post<any>('system/anonymous-user-info-update', { data: data, id: id });
		return response.data;
	}

	async getAnonymousUserInfo(data: any): Promise<any> {
		let response = await http.get<any>('system/anonymous-user-info', { data: data });
		return response.data;
	}

	async sendAnonymousReservation(data: any): Promise<any> {
		let response = await http.post<any>('system/anonymous-user-reservation', data);
		return response.data;
	}
	async upDateAnonymousReservation(data: any, id: number): Promise<any> {
		let response = await http.post<any>('system/anonymous-user-reservation-update', { data: data, id: id });
		return response.data;
	}
}
