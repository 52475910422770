import React, { ReactNode, useEffect, useState } from 'react';

import './Banner.scss';
import { Box, popupController } from '@bit/redsky.framework.rs.996';
import Button from '@bit/redsky.framework.rs.button';
import { useRecoilState, useRecoilValue } from 'recoil';
import globalState from '../../state/globalState';
import SigninPopup from '../../popups/signin/SigninPopup';
import Label from '@bit/redsky.framework.rs.label';
import useMerchantReward from '../../customHooks/useMerchantReward';
import serviceFactory from '../../services/serviceFactory';
import DestinationService from '../../services/destination/destination.service';
import { getPageFinder, undefinedHandler } from '../../utils/undefinedHandler';

import useIsAtBreakpoint from '../../customHooks/useIsAtBreakpoint';
import SignupPopup, { SignupPopupProps } from '../../popups/signup/SignupPopup';
import LabelCheckboxFilterBar from '../labelCheckbox/LabelCheckboxFilterBar';
import useIsAtSize from '../../customHooks/useIsAtSize';

export interface BannerProps {
	bookingData: Api.Reservation.Res.Verification;
	destinationId?: number;
	signUp: any;
	onSelect: (value: string | number, text: string | number | ReactNode) => void;
	onDeselect: (value: string | number, text: string | number | ReactNode) => void;
}

const Banner: React.FC<BannerProps> = (props) => {
	const destinationService = serviceFactory.get<DestinationService>('DestinationService');
	const isMobile = useIsAtBreakpoint();

	const size = useIsAtSize();
	const user = useRecoilValue<Api.User.Res.Detail | undefined>(globalState.user);
	const [campaignVariable, setCampaignVariable] = useState<any>([]);
	const [rewardPointPerDollar, setRewardPointPerDollar] = useState<number>(0);
	const userTierValue = useRecoilValue<number>(globalState.userTierValue);
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const resortId: any = urlParams.get('data');
	const [reservationFilters, setReservationFilters] = useRecoilState<Misc.ReservationFilters>(
		globalState.reservationFilters
	);

	useEffect(() => {
		getCampaignVariables();
		getPlatformVariables();

		window.scrollTo(0, 0);
	}, []);

	async function getPlatformVariables() {
		try {
			const rewardPointPerDollar = (await destinationService.getPlatformVariables()).rewardPointsPerDollar; //global reward point per dollar
			setRewardPointPerDollar(rewardPointPerDollar);
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	async function getCampaignVariables() {
		try {
			const urlParams = new URLSearchParams(window.location.search);
			const urlData = urlParams.get('data');
			const urlDataToObject = JSON.parse(urlData || '{}');

			let campaignVariables;

			if (props.destinationId) {
				campaignVariables = await destinationService.getCampaignByDestination(
					props.destinationId,
					reservationFilters.startDate,
					reservationFilters.endDate,
					props.bookingData.accommodationId,
					props.bookingData.rateCode
				); //global reward point per dollar
			} else {
				campaignVariables = await destinationService.getCampaignByDestination(
					urlDataToObject.destinationId,
					reservationFilters.startDate,
					reservationFilters.endDate,
					props.bookingData.accommodationId,
					props.bookingData.rateCode
				); //global reward point per dollar
			}

			setCampaignVariable(campaignVariables);
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	const SmallHendelar = () => {
		return (
			<>
				<Box className="signupTxt-2">
					<Box className="popupTitleText-2">
						<Box className="logo-container-2">
							<img
								src={`../../images/rentyl-rewards-small.svg`}
								onClick={() => {
									// if (!user) {
									// 	popupController.open(SigninPopup);
									// } else {
									// 	navigate('/account');
									// }
								}}
								className="rr-logo-small-2"
								alt="Rentyl Reward"
							/>
							<img
								src={`../../images/spire-small.svg`}
								onClick={() => {
									// if (!user) {
									// 	popupController.open(SigninPopup);
									// } else {
									// 	navigate('/account');
									// }
								}}
								className="rr-logo-small-2"
								alt="Rentyl Reward"
							/>
						</Box>
						<Label variant={'buttonMdLg'} className={'bannerText-2'}>
							Sign up and start earning today{' '}
							{useMerchantReward.rewardPointsCalculation(
								{
									campaigns: campaignVariable,
									minAccommodationPriceInCents: props.bookingData.prices.accommodationTotalInCents
								},
								rewardPointPerDollar,
								userTierValue,
								user,
								true
							)}{' '}
						</Label>
					</Box>
					<div className={`rsLabelCheckboxFilterBar-2`}>
						<Label variant={'body2'} className={'bannerText2-2'}>
							Sign me up for Spire Loyalty by Rentyl Rewards. I confirm I am at least 18 years old or
							older and I agree to the terms and conditions.
						</Label>
						<label className={'checkboxContainer'}>
							<input
								value={''}
								type={'checkbox'}
								className={`checkboxInput`}
								onChange={(e) => {
									let inputValue = e.target as HTMLInputElement;
									if (inputValue.checked) props.onSelect('', '');
									else props.onDeselect('', '');
								}}
								checked={props.signUp}
							/>
						</label>
					</div>
				</Box>
			</>
		);
	};

	const BigHendelar = () => {
		return (
			<>
				<Box className="logo-container">
					<img
						src={`../../images/rentyl-rewards-small.svg`}
						onClick={() => {
							// if (!user) {
							// 	popupController.open(SigninPopup);
							// } else {
							// 	navigate('/account');
							// }
						}}
						className="rr-logo-small"
						alt="Rentyl Reward"
					/>
					<img
						src={`../../images/spire-small.svg`}
						onClick={() => {
							// if (!user) {
							// 	popupController.open(SigninPopup);
							// } else {
							// 	navigate('/account');
							// }
						}}
						className="rr-logo-small"
						alt="Rentyl Reward"
					/>
				</Box>

				<Box className="signupTxt">
					<Box className="popupTitleText">
						<Label variant={'buttonMdLg'} className={'bannerText'}>
							Sign up and start earning today{' '}
							{useMerchantReward.rewardPointsCalculation(
								{
									campaigns: campaignVariable,
									minAccommodationPriceInCents: props.bookingData.prices.accommodationTotalInCents
								},
								rewardPointPerDollar,
								userTierValue,
								user,
								true
							)}{' '}
						</Label>
						<Label variant={'body2'} className={'bannerText2'}>
							Sign me up for Spire Loyalty by Rentyl Rewards. I confirm I am at least 18 years old or
							older and I agree to the terms and conditions.
						</Label>
					</Box>
					<div className={`rsLabelCheckboxFilterBar`}>
						<label className={'checkboxContainer'}>
							<input
								value={''}
								type={'checkbox'}
								className={`checkboxInput`}
								onChange={(e) => {
									let inputValue = e.target as HTMLInputElement;
									if (inputValue.checked) props.onSelect('', '');
									else props.onDeselect('', '');
								}}
								checked={props.signUp}
							/>
						</label>
					</div>
				</Box>
			</>
		);
	};

	let DisplayComponent;
	if (size < 675) {
		DisplayComponent = SmallHendelar;
	} else if (size >= 676 && size < 1160) {
		DisplayComponent = BigHendelar;
	} else if (size >= 1161 && size < 1323) {
		DisplayComponent = SmallHendelar;
	} else {
		DisplayComponent = BigHendelar;
	}

	return (
		<Box className="rsBanner">
			<DisplayComponent />
		</Box>
	);
};

export default Banner;
