import * as React from 'react';
import './PackageCard.scss';
import { Box } from '@bit/redsky.framework.rs.996';
import Icon from '@bit/redsky.framework.rs.icon';
import Label from '@bit/redsky.framework.rs.label';

export interface PackageCardProps {
	package: Api.UpsellPackage.Res.Complete;
	removePackage: (id: number) => void;
}

const PackageCard: React.FC<PackageCardProps> = (props) => {
	return (
		<Box className={'rsPackageCard'} display={'flex'}>
			<Icon
				iconImg={'icon-close'}
				onClick={() => {
					props.removePackage(props.package.id);
				}}
				size={20}
				cursorPointer
			/>
			<Label variant={'h6'}>{props.package.title}</Label>
			<Box display={'flex'} justifyContent={'space-between'}>
				<Label variant={'h6'}>x 1</Label>
				<Label variant={'h6'}>+{props.package.priceDetail.amountPoints}</Label>{' '}
			</Box>
		</Box>
	);
};

export default PackageCard;
