// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rsTermsAndConditionsPage p{padding-left:10px}.rsTermsAndConditionsPage ol{padding-left:25px}.rsTermsAndConditionsPage li{padding-left:5px}.rsTermsAndConditionsPage .centerAlign{text-align:center;margin-top:80px}.rsTermsAndConditionsPage a{text-decoration:underline !important}.rsTermsAndConditionsPage a:hover{cursor:pointer}.rsTermsAndConditionsPage .roman{list-style:lower-roman}.rsTermsAndConditionsPage .letters{list-style:upper-alpha}
`, "",{"version":3,"sources":["webpack://./src/pages/termsAndConditionsPage/TermsAndConditionsPage.scss"],"names":[],"mappings":"AAEA,4BAEE,iBAAkB,CAFpB,6BAKE,iBAAkB,CALpB,6BAQE,gBAAiB,CARnB,uCAWE,iBAAkB,CAClB,eAAgB,CAZlB,4BAgBE,oCAAqC,CAhBvC,kCAkBG,cAAe,CAlBlB,iCAuBE,sBAAuB,CAvBzB,mCA2BE,sBAAuB","sourcesContent":["@import '../../themes/themes';\n\n.rsTermsAndConditionsPage {\n\tp {\n\t\tpadding-left: 10px;\n\t}\n\tol {\n\t\tpadding-left: 25px;\n\t}\n\tli {\n\t\tpadding-left: 5px;\n\t}\n\t.centerAlign {\n\t\ttext-align: center;\n\t\tmargin-top: 80px;\n\t}\n\n\ta {\n\t\ttext-decoration: underline !important;\n\t\t&:hover {\n\t\t\tcursor: pointer;\n\t\t}\n\t}\n\n\t.roman {\n\t\tlist-style: lower-roman;\n\t}\n\n\t.letters {\n\t\tlist-style: upper-alpha;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primaryTextColor": `var(--primaryTextColor)`,
	"secondaryTextColor": `var(--secondaryTextColor)`,
	"successGreen": `var(--successGreen)`,
	"adminPink": `var(--adminPink)`,
	"warningRed": `var(--warningRed)`,
	"buttonLinkColor": `var(--buttonLinkColor)`,
	"secondaryButtonLinkColor": `var(--secondaryButtonLinkColor)`,
	"iconAndHighlightTextColor": `var(--iconAndHighlightTextColor)`,
	"primaryBackgroundColor": `var(--primaryBackgroundColor)`,
	"secondaryBackgroundColor": `var(--secondaryBackgroundColor)`,
	"errorColor": `var(--errorColor)`,
	"primaryBoxShadowColor": `var(--primaryBoxShadowColor)`,
	"greyLineColor": `var(--greyLineColor)`,
	"thinGreyLineColor": `var(--thinGreyLineColor)`,
	"white": `var(--white)`,
	"lightestGray": `var(--lightestGray)`,
	"backgroundGray": `var(--backgroundGray)`,
	"borderGray": `var(--borderGray)`,
	"medGray2": `var(--medGray2)`,
	"medGray2Active": `var(--medGray2Active)`,
	"black": `var(--black)`,
	"primaryCalendarColor": `var(--primaryCalendarColor)`,
	"secondaryCalendarColor": `var(--secondaryCalendarColor)`
};
export default ___CSS_LOADER_EXPORT___;
