import { Service } from '../Service';
import globalState, { getRecoilExternalValue } from '../../state/globalState';

export default class ChatbotService extends Service {
	async getChatbot(): Promise<void> {
		const pathname = window?.location?.pathname;
		if (shouldDisplayChatbot(pathname)) {
			await this.initChatbot(pathname);
		} else {
			this.removeChatbot();
		}
	}

	private initChatbot(path: any) {
		path = path
			.split('/')[2]
			.split('-')
			.map((word: any) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(' ');
		const recoilCheckoutUser = getRecoilExternalValue<Api.User.Res.Detail | undefined>(globalState.user);
		var initESW = function (gslbBaseURL: any) {
			window.embedded_svc.settings.displayHelpButton = true;
			window.embedded_svc.settings.language = 'en-US';
			window.embedded_svc.settings.defaultMinimizedText = 'Chat With Us';
			// window.embedded_svc.settings.loadingText = "Loading..."
			window.embedded_svc.settings.prepopulatedPrechatFields = {
				FirstName: recoilCheckoutUser?.firstName,
				LastName: recoilCheckoutUser?.lastName,
				Email: recoilCheckoutUser?.primaryEmail,
				Resort_Select__c: path
			};
			window.embedded_svc.settings.enabledFeatures = ['LiveAgent'];
			window.embedded_svc.settings.entryFeature = 'LiveAgent';
			window.embedded_svc.init(
				'https://ndmhospitality.my.salesforce.com',
				'https://ndmhospitality.my.salesforce-sites.com/liveAgentSetupFlow',
				gslbBaseURL,
				'00D6g0000083Dn0',
				'Chat_Presence_Config',
				{
					baseLiveAgentContentURL: 'https://c.la2-c1-ia5.salesforceliveagent.com/content',
					deploymentId: '5726g000000HjuI',
					buttonId: '5736g000000HlTe',
					baseLiveAgentURL: 'https://d.la2-c1-ia5.salesforceliveagent.com/chat',
					eswLiveAgentDevName: 'Chat_Presence_Config',
					isOfflineSupportEnabled: false
				}
			);
		};
		if (!window.embedded_svc) {
			var s = document.createElement('script');
			s.setAttribute('src', 'https://ndmhospitality.my.salesforce.com/embeddedservice/5.0/esw.min.js');
			s.id = 'chat';
			s.onload = function () {
				initESW(null);
			};
			document.body.appendChild(s);
		} else {
			initESW(null);
		}
		showHelpButtons();
	}

	private removeChatbot() {
		const scriptElement = document.getElementById('chat');
		if (scriptElement) {
			scriptElement.remove();
		}
		hideHelpButtons();
	}
}

const helpButtons = document.getElementsByClassName('embeddedServiceHelpButton') as HTMLCollectionOf<HTMLElement>;
function showHelpButtons() {
	for (const button of helpButtons) {
		button.style.display = 'block';
	}
}

async function hideHelpButtons() {
	const chatbot = document.getElementsByClassName('dockableContainer') as HTMLCollectionOf<HTMLElement>;
	if (helpButtons && chatbot) {
		// window.embedded_svc.liveAgentAPI.clearSession();
		await window.embedded_svc?.liveAgentAPI?.endChat();
		for (const button of helpButtons) {
			button.style.display = 'none';
		}
	}
}

function shouldDisplayChatbot(pathname: string) {
	const allowedPaths = [
		'/destination/encore-resort-at-reunion',
		'/destination/spectrum-resort-orlando',
		"/destination/the-bear's-den-resort-orlando",
		'/destination/margaritaville-cottages',
		'/destination/largo-resort',
		'/destination/margaritaville-hotel-orlando',
		'/destination/margaritaville-cottages-orlando',
		'/destination/margaritaville-resort-orlando',
		'/destination/margaritaville-cottages-orlando-by-rentyl'
	];

	// Margaritaville Hotel Orlando
	// Margaritaville Cottages Orlando
	// Encore Resort at Reunion
	// The Bear's Den Resort Orlando
	// Spectrum Resort Orlando
	// Largo Resort
	return allowedPaths.includes(pathname);
}
