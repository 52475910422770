import * as React from 'react';
import './PriceSlider.scss';
import { Box } from '@bit/redsky.framework.rs.996';
import Slider, { SliderMode } from '@bit/redsky.framework.rs.slider';
import LabelInputFilterBar from '../labelInput/LabelInputFilterBar';
import { RsFormControl } from '@bit/redsky.framework.rs.form';
interface PriceSliderProps {
	updateFilterForm: (control: RsFormControl | undefined) => void;
	maxControl: RsFormControl;
	minControl: RsFormControl;
	sanitizePriceFieldsAndUpdate: (control: RsFormControl) => void;
	// savePromoCodeDatas: any;
	// saveAgentId?: any;
	// AgentId?: string;
	// promoCode?: string;
	redeemPoints: boolean;
	// promoCodeType: any;
	// promoCodeTypeData?: any;
}

const PriceSlider: React.FC<PriceSliderProps> = (props) => {
	function renderMinClassName() {
		if (
			(Number(props.minControl.value) >= 1000 && !props.redeemPoints) ||
			(Number(props.minControl.value) >= 1430000 && props.redeemPoints)
		) {
			return ' andGreater';
		} else {
			return '';
		}
	}
	function renderMaxClassName() {
		if (
			(Number(props.maxControl.value) >= 1000 && !props.redeemPoints) ||
			(Number(props.maxControl.value) >= 1430000 && props.redeemPoints)
		) {
			return ' andGreater';
		} else {
			return '';
		}
	}
	function renderPointsOrCash() {
		if (props.redeemPoints) {
			return ' point';
		} else {
			return ' cash';
		}
	}

	return (
		<Box className={'rsPriceSlider'}>
			<Slider
				key={!props.redeemPoints ? `sliderCash` : `sliderPoint`}
				range={[10, !props.redeemPoints ? 1000 : 1430000]}
				minControl={props.minControl}
				maxControl={props.maxControl}
				sliderIcons={'icon-hamburger-menu'}
				rotate={90}
				sliderStep={10}
				updateMinControl={props.updateFilterForm}
				updateMaxControl={props.updateFilterForm}
				mode={SliderMode.COLLISION}
				handleStyle={{ border: '1px solid black', borderRadius: '50%' }}
				railClass="priceSliderRail"
				sliderClass="priceSlider"
			/>
			<div className="minMaxDiv">
				<LabelInputFilterBar
					className={`priceMin${renderMinClassName()}${renderPointsOrCash()}`}
					inputType="text"
					title={props.redeemPoints ? 'min point' : 'min price'}
					control={props.minControl}
					updateControl={props.sanitizePriceFieldsAndUpdate}
				/>
				<hr className="divider" />
				<LabelInputFilterBar
					className={`priceMax${renderMaxClassName()}${renderPointsOrCash()}`}
					inputType="text"
					title={props.redeemPoints ? 'max point' : 'max price'}
					control={props.maxControl}
					updateControl={props.sanitizePriceFieldsAndUpdate}
				/>
			</div>

			{/* <Box display={'flex'} flexDirection={'row'} gap={1}>
				<select
					value={props.promoCodeTypeData}
					className="priceslider-promodropdown"
					name="promoType"
					onChange={(e) => {
						const selectElement = e.target.value;
						props.promoCodeType(selectElement);
					}}
				>
					<option value="PR">Promo Code</option>
					<option value="GC">Group Code</option>
				</select>

				<input
					type="text"
					className="priceslider-promofield"
					onChange={(e) => {
						const value = e.target.value;
						props.savePromoCodeDatas(value);
					}}
					value={props.promoCode}
					placeholder="Enter Code"
					name="promoCode"
				/>
			</Box> */}

			{/* <input
				type="text"
				className="priceslider-promofield"
				onChange={(e) => {
					const value = e.target.value;
					props.saveAgentId(value);
				}}
				value={props.AgentId}
				placeholder="Agent ID"
				name="agentId"
			/> */}
		</Box>
	);
};

export default PriceSlider;
