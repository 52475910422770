import React, { useEffect } from 'react';

export function useNoScript(url?: string, defer?: boolean, type?: string, text?: any, tag?: string) {
	useEffect(() => {
		const noScript = document.createElement('noscript');
		noScript.innerHTML = text;

		if (tag === 'head') {
			// create NOSCRIPT tag in the head section
			document.head.appendChild(noScript);
		} else if (tag === 'body') {
			//create NOSCRIPT tag in the body section
			document.body.appendChild(noScript);
		} else {
			document.head.appendChild(noScript);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [url]);
}

export interface ScriptProps {
	src?: string;
	defer?: boolean;
	type?: string;
	text?: string;
	tag?: string;
}

const NoScript: React.FC<ScriptProps> = (props) => {
	useNoScript(props.src, props.defer, props.type, props.text, props.tag);

	return null; // Return null is necessary for the moment.
};
export default NoScript;
