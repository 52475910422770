// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rsUserBasicInfoPaper{padding:24px 26px}.rsUserBasicInfoPaper .loadingBarContainer{height:35px;border-radius:20px;width:446px;background:#f7f9fd;overflow:hidden}.rsUserBasicInfoPaper .loadingBarContainer .loadingBar{height:100%;background:var(--primaryTextColor)}.rsUserBasicInfoPaper .rsLabelButton.contained.primary{background-color:var(--secondaryTextColor);padding:16px 46px;box-sizing:border-box}.rsUserBasicInfoPaper .rsLabelButton.contained.primary .rsLabel{color:var(--primaryTextColor)}.rsUserBasicInfoPaper .rsLabelButton.contained.primary:hover{background-color:var(--secondaryTextColor)}
`, "",{"version":3,"sources":["webpack://./src/components/userBasicInfoPaper/UserBasicInfoPaper.scss","webpack://./src/themes/_mixins.scss"],"names":[],"mappings":"AACA,sBACC,iBAAkB,CADnB,2CAGE,WAAY,CACZ,kBAAmB,CACnB,WAAY,CACZ,kBAAmB,CACnB,eAAgB,CAPlB,uDASG,WAAY,CACZ,kCCcgB,CDxBnB,uDAiBI,0CCOe,CDNf,iBAAkB,CAClB,qBAAsB,CAnB1B,gEAqBK,6BCGc,CDxBnB,6DAwBK,0CCAc","sourcesContent":["@import '../../themes/themes';\n.rsUserBasicInfoPaper {\n\tpadding: 24px 26px;\n\t.loadingBarContainer {\n\t\theight: 35px;\n\t\tborder-radius: 20px;\n\t\twidth: 446px;\n\t\tbackground: #f7f9fd;\n\t\toverflow: hidden;\n\t\t.loadingBar {\n\t\t\theight: 100%;\n\t\t\tbackground: theme-var($primaryTextColor);\n\t\t}\n\t}\n\n\t.rsLabelButton {\n\t\t&.contained {\n\t\t\t&.primary {\n\t\t\t\tbackground-color: theme-var($secondaryTextColor);\n\t\t\t\tpadding: 16px 46px;\n\t\t\t\tbox-sizing: border-box;\n\t\t\t\t.rsLabel {\n\t\t\t\t\tcolor: theme-var($primaryTextColor);\n\t\t\t\t}\n\t\t\t\t&:hover {\n\t\t\t\t\tbackground-color: theme-var($secondaryTextColor);\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n}\n","@import 'variables';\n\n/*\n  include a map in a class\n*/\n@mixin spread-map($map: ()) {\n\t@each $key, $value in $map {\n\t\t#{$key}: $value;\n\t}\n}\n\n/*\n  Returns a CSS variable property specific to themeing.\n  Accepts an optional fallback, and optional map.\n  - SCSS: color: theme-var($--theme-primary, blue)\n  - CSS:  color: var(--theme-primary, blue)\n  Throws error if the variable not part of the map\n*/\n@function theme-var($key, $fallback: null, $map: $defaultTheme) {\n\t@if not map-has-key($map, $key) {\n\t\t@error \"key: '#{$key}', is not a key in map: #{$map}\";\n\t}\n\t@if ($fallback) {\n\t\t@return var($key, $fallback);\n\t} @else {\n\t\t@return var($key);\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primaryTextColor": `var(--primaryTextColor)`,
	"secondaryTextColor": `var(--secondaryTextColor)`,
	"successGreen": `var(--successGreen)`,
	"adminPink": `var(--adminPink)`,
	"warningRed": `var(--warningRed)`,
	"buttonLinkColor": `var(--buttonLinkColor)`,
	"secondaryButtonLinkColor": `var(--secondaryButtonLinkColor)`,
	"iconAndHighlightTextColor": `var(--iconAndHighlightTextColor)`,
	"primaryBackgroundColor": `var(--primaryBackgroundColor)`,
	"secondaryBackgroundColor": `var(--secondaryBackgroundColor)`,
	"errorColor": `var(--errorColor)`,
	"primaryBoxShadowColor": `var(--primaryBoxShadowColor)`,
	"greyLineColor": `var(--greyLineColor)`,
	"thinGreyLineColor": `var(--thinGreyLineColor)`,
	"white": `var(--white)`,
	"lightestGray": `var(--lightestGray)`,
	"backgroundGray": `var(--backgroundGray)`,
	"borderGray": `var(--borderGray)`,
	"medGray2": `var(--medGray2)`,
	"medGray2Active": `var(--medGray2Active)`,
	"black": `var(--black)`,
	"primaryCalendarColor": `var(--primaryCalendarColor)`,
	"secondaryCalendarColor": `var(--secondaryCalendarColor)`
};
export default ___CSS_LOADER_EXPORT___;
