// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.verifyEmailPopup{width:clamp(356px, 90vw, 600px);background-color:var(--primaryBackgroundColor);border-radius:20px;max-height:90vh;overflow:hidden}.verifyEmailPopup .titleContainer{padding:24px;display:flex;justify-content:space-between;align-items:center;border-bottom:1px solid var(--greyLineColor)}.verifyEmailPopup .titleContainer .closeIcon{cursor:pointer}.verifyEmailPopup .body{padding:24px}.verifyEmailPopup .body .imageContainer{width:clamp(80px, 40%, 130px);margin:auto}.verifyEmailPopup .body .imageContainer .image{width:100% !important;height:auto}.verifyEmailPopup .body .clickBtn{color:var(--primaryTextColor);cursor:pointer;font-weight:600}
`, "",{"version":3,"sources":["webpack://./src/popups/verifyEmailPopup/VerifyEmailpopup.scss","webpack://./src/themes/_mixins.scss"],"names":[],"mappings":"AAEA,kBACC,+BAAgC,CAChC,8CCqBkB,CDpBlB,kBAAmB,CACnB,eAAgB,CAChB,eAAgB,CALjB,kCAQE,YAAa,CACb,YAAa,CACb,6BAA8B,CAC9B,kBAAmB,CACnB,4CCWiB,CDvBnB,6CAeG,cAAe,CAflB,wBAoBE,YAAa,CApBf,wCAsBG,6BAA8B,CAC9B,WAAY,CAvBf,+CA0BI,qBAAsB,CACtB,WAAY,CA3BhB,kCAgCG,6BCTgB,CDUhB,cAAe,CACf,eAAgB","sourcesContent":["@import '../../themes/themes';\n\n.verifyEmailPopup {\n\twidth: clamp(356px, 90vw, 600px);\n\tbackground-color: theme-var($primaryBackgroundColor);\n\tborder-radius: 20px;\n\tmax-height: 90vh;\n\toverflow: hidden;\n\n\t.titleContainer {\n\t\tpadding: 24px;\n\t\tdisplay: flex;\n\t\tjustify-content: space-between;\n\t\talign-items: center;\n\t\tborder-bottom: 1px solid theme-var($greyLineColor);\n\n\t\t.closeIcon {\n\t\t\tcursor: pointer;\n\t\t}\n\t}\n\n\t.body {\n\t\tpadding: 24px;\n\t\t.imageContainer {\n\t\t\twidth: clamp(80px, 40%, 130px);\n\t\t\tmargin: auto;\n\n\t\t\t.image {\n\t\t\t\twidth: 100% !important;\n\t\t\t\theight: auto;\n\t\t\t}\n\t\t}\n\n\t\t.clickBtn {\n\t\t\tcolor: theme-var($primaryTextColor);\n\t\t\tcursor: pointer;\n\t\t\tfont-weight: 600;\n\t\t}\n\t}\n}\n","@import 'variables';\n\n/*\n  include a map in a class\n*/\n@mixin spread-map($map: ()) {\n\t@each $key, $value in $map {\n\t\t#{$key}: $value;\n\t}\n}\n\n/*\n  Returns a CSS variable property specific to themeing.\n  Accepts an optional fallback, and optional map.\n  - SCSS: color: theme-var($--theme-primary, blue)\n  - CSS:  color: var(--theme-primary, blue)\n  Throws error if the variable not part of the map\n*/\n@function theme-var($key, $fallback: null, $map: $defaultTheme) {\n\t@if not map-has-key($map, $key) {\n\t\t@error \"key: '#{$key}', is not a key in map: #{$map}\";\n\t}\n\t@if ($fallback) {\n\t\t@return var($key, $fallback);\n\t} @else {\n\t\t@return var($key);\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primaryTextColor": `var(--primaryTextColor)`,
	"secondaryTextColor": `var(--secondaryTextColor)`,
	"successGreen": `var(--successGreen)`,
	"adminPink": `var(--adminPink)`,
	"warningRed": `var(--warningRed)`,
	"buttonLinkColor": `var(--buttonLinkColor)`,
	"secondaryButtonLinkColor": `var(--secondaryButtonLinkColor)`,
	"iconAndHighlightTextColor": `var(--iconAndHighlightTextColor)`,
	"primaryBackgroundColor": `var(--primaryBackgroundColor)`,
	"secondaryBackgroundColor": `var(--secondaryBackgroundColor)`,
	"errorColor": `var(--errorColor)`,
	"primaryBoxShadowColor": `var(--primaryBoxShadowColor)`,
	"greyLineColor": `var(--greyLineColor)`,
	"thinGreyLineColor": `var(--thinGreyLineColor)`,
	"white": `var(--white)`,
	"lightestGray": `var(--lightestGray)`,
	"backgroundGray": `var(--backgroundGray)`,
	"borderGray": `var(--borderGray)`,
	"medGray2": `var(--medGray2)`,
	"medGray2Active": `var(--medGray2Active)`,
	"black": `var(--black)`,
	"primaryCalendarColor": `var(--primaryCalendarColor)`,
	"secondaryCalendarColor": `var(--secondaryCalendarColor)`
};
export default ___CSS_LOADER_EXPORT___;
