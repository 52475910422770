import * as React from 'react';
import Box from '@bit/redsky.framework.rs.996/dist/box/Box';
import Label from '@bit/redsky.framework.rs.label';
import Switch from '@bit/redsky.framework.rs.switch';
import { useRecoilState } from 'recoil';
import globalState from '../../state/globalState';
import { popupController } from '@bit/redsky.framework.rs.996';
import SigninPopup, { SigninPopupProps } from '../../popups/signin/SigninPopup';
import './RedeemPointsToggle.scss';
import SpinningLoaderPopup from '../../popups/spinningLoaderPopup/SpinningLoaderPopup';

interface RedeemPointsToggleProps {
	redeemPoints: boolean;
	updateRedeemPoints: (redeemPoints: boolean) => void;
}

const RedeemPointsToggle: React.FC<RedeemPointsToggleProps> = (props) => {
	const [user, setUser] = useRecoilState<Api.User.Res.Detail | undefined>(globalState.user);

	return (
		<Box className="redeemToggle rsRedeemPointsToggle">
			<Box
				display={'flex'}
				flexDirection={'column'}
				alignItems={'center'}
				justifyContent={'flex-start'}
				textAlign={'center'}
			>
				<Label variant={'subtitle1'}>Redeem Points</Label>
				{/* <Switch
					// value={toggleButton ? 1 : 0}
					checked={!!reservationFilters.redeemPoints && user?.userRoleId === 9 ? true : false}
					labelPosition={'top'}
					// className={'toggleButton'}
					label={'{"Off":"On" }'}
					className={`toggleButton ${toggleButton ? '' : 'disableCss'}`}
					onChange={(e) => {
						// console.log('user==>', user);
						console.log('props.reservationFilters==>eee', e);

						if (user && user.userRoleId === 9) {
							props.updateRedeemPoints(!props.redeemPoints);
							settoggleButton(e);

							// popupController.open<SigninPopupProps>(SigninPopup, {
							// 	user: true,
							// 	successCallback: () => {
							// 		console.log('user==>', props.redeemPoints);
							// 		// props.updateRedeemPoints(!props.redeemPoints);

							// 		setReservationFilters((prev) => {
							// 			const priceRangeMin: number = prev.redeemPoints ? 10 : 1000;
							// 			const priceRangeMax: number = prev.redeemPoints ? 1000 : 143000;
							// 			return {
							// 				...prev,
							// 				priceRangeMin,
							// 				priceRangeMax,
							// 				redeemPoints: e
							// 			};
							// 		});
							// 	}
							// });
						} else {
							// props.onClose();
							popupController.open<SigninPopupProps>(SigninPopup, {
								user: true,
								successCallback: (data: any) => {
									console.log('user==>', data);
									// props.updateRedeemPoints(!props.redeemPoints);

									if (data === 9) {
										setReservationFilters((prev) => {
											const priceRangeMin: number = prev.redeemPoints ? 10 : 1000;
											const priceRangeMax: number = prev.redeemPoints ? 1000 : 143000;
											return {
												...prev,
												priceRangeMin,
												priceRangeMax,
												redeemPoints: e
											};
										});
									} else {
										setReservationFilters((prev) => {
											const priceRangeMin: number = prev.redeemPoints ? 10 : 1000;
											const priceRangeMax: number = prev.redeemPoints ? 1000 : 143000;
											return {
												...prev,
												priceRangeMin,
												priceRangeMax
											};
										});
									}
								}
							});
							// console.log('user.userRoleId==>', user.userRoleId);
							// if (user.userRoleId == 9) {
							// 	props.updateRedeemPoints(!props.redeemPoints);
							// }
						}
					}}
				/> */}
				<label class="switch">
					<input
						type="checkbox"
						checked={props.redeemPoints ? true : false}
						onChange={(e: any) => {
							let paymentMethods = 0;
							user?.paymentMethods.map((item) => {
								if (item.metaData.offsiteLoyaltyEnrollment == 1) {
									paymentMethods = 1;
								}
							});

							// TEMPORARY CODE IS COMMENT FOR FIDEL
							// if (user && paymentMethods === 1) {
							if (user) {
								props.updateRedeemPoints(!props.redeemPoints);
								// } else if (user && paymentMethods !== 1) {
								// alert('you are not eligible');
							} else {
								// props.onClose();
								popupController.open<SigninPopupProps>(SigninPopup, {
									user: true,
									successCallback: (data: any) => {
										// props.updateRedeemPoints(!props.redeemPoints);

										let newPaymentMethods = 0;
										data?.paymentMethods.map((item: any) => {
											if (item.metaData.offsiteLoyaltyEnrollment == 1) {
												newPaymentMethods = 1;
											}
										});

										// TEMPORARY CODE IS COMMENT FOR FIDEL
										// if (data && newPaymentMethods === 1) {
										if (data) {
											props.updateRedeemPoints(!props.redeemPoints);
										} else {
											popupController.close(SpinningLoaderPopup);
										}
									}
								});
							}
						}}
					/>
					<span class="slider round"></span>
				</label>
			</Box>
		</Box>
	);
};

export default RedeemPointsToggle;
