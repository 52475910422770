import * as React from 'react';
import './DestinationPackageTile.scss';
import Paper from '../paper/Paper';
import Box from '@bit/redsky.framework.rs.996/dist/box/Box';
import Label from '@bit/redsky.framework.rs.label/dist/Label';
import { StringUtils } from '@bit/redsky.framework.rs.utils';
import LabelButton from '../labelButton/LabelButton';
import { useRecoilValue } from 'recoil';
import globalState from '../../state/globalState';
import Img from '@bit/redsky.framework.rs.img';
import Icon from '@bit/redsky.framework.rs.icon';
import { popupController } from '@bit/redsky.framework.rs.996';
import MobilePackageDescriptionPopup, {
	MobilePackageDescriptionPopupProps
} from '../../popups/mobilePackageDescriptionPopup/MobilePackageDescriptionPopup';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { IKImage } from 'imagekitio-react';
import { getPageFinder, undefinedHandler } from '../../utils/undefinedHandler';

interface DestinationPackageTileProps {
	title: string;
	description: string;
	prices: Api.UpsellPackage.Res.PriceDetail;
	imgPaths: string[];
	onAddPackage?: () => void;
	text?: string;
	isAdded?: boolean;
	isMobile?: boolean;
}

const DestinationPackageTile: React.FC<DestinationPackageTileProps> = (props) => {
	const company = useRecoilValue<Api.Company.Res.GetCompanyAndClientVariables>(globalState.company);
	const reservationFilters = useRecoilValue<Misc.ReservationFilters>(globalState.reservationFilters);
	const [disablePackage, setDisablePackage] = useState<boolean>(false);
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const resortId: any = urlParams.get('data');

	useEffect(() => {
		try {
			if (reservationFilters.redeemPoints && company.allowPointBooking) {
				setDisablePackage(false);
			} else if (!reservationFilters.redeemPoints && company.allowCashBooking) {
				setDisablePackage(false);
			} else {
				setDisablePackage(true);
			}
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}, [reservationFilters, company]);

	const buttonhandler = (brandType: any) => {
		let companyid;
		if (brandType) {
			companyid = JSON.parse(brandType).themeId;
		} else {
			companyid = null;
		}

		let bgColor = '';
		switch (companyid) {
			case 3:
				bgColor = '#e51c14';
				break;
			case 78:
				bgColor = '#5d995d';
				break;
			case 19:
				bgColor = '#5d995d';
				break;
			case 9:
				bgColor = '#907326';
				break;
			case 10:
				bgColor = '#2a8293';
				break;
			default:
				bgColor = '#ffa022';
				break;
		}

		return (
			<LabelButton
				backgroundColor={bgColor}
				look={'none'}
				variant={'buttonSm'}
				disabled={disablePackage}
				label={
					props.text && (
						<Label display="flex" className="addPackButtonText" variant="buttonSm" color="#fff">
							<Icon
								iconImg={!props.isAdded ? 'icon-plus' : 'icon-solid-check'}
								size={20}
								color="#fff"
								className="addPackageButtonIcon"
							/>
							{!props.isMobile && !disablePackage && props.text}
							{!props.isMobile && disablePackage && 'Unavailable'}
						</Label>
					)
				}
				className={classNames('addButton', { packageAdded: props.isAdded })}
				onClick={props.onAddPackage}
			/>
		);
	};

	function renderPointsOrCash() {
		try {
			if (!reservationFilters.redeemPoints && company.allowCashBooking) {
				return (
					<Box className="priceAndTextLabelContainer">
						<Label variant={props.isMobile ? 'body1B' : 'h2'} marginBottom={9}>
							<span className="priceFont">${StringUtils.formatMoney(props.prices.amountAfterTax)}</span>{' '}
							{props.isMobile && '/ stay'}
						</Label>
						{!props.isMobile && (
							<Label variant="body1" className={'primaryTextColor'}>
								Per Stay
							</Label>
						)}
					</Box>
				);
			} else if (reservationFilters.redeemPoints && company.allowPointBooking) {
				return (
					<Box className="priceAndTextLabelContainer">
						<Label variant={props.isMobile ? 'body1B' : 'h2'} marginBottom={9}>
							<span className="priceFont yellowText">
								{StringUtils.addCommasToNumber(props.prices.amountPoints)}pts
							</span>{' '}
							{props.isMobile && '/ stay'}
						</Label>
						{!props.isMobile && (
							<Label variant="body1" className={'primaryTextColor'}>
								Per Stay
							</Label>
						)}
					</Box>
				);
			}
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	return (
		<Paper className={'rsDestinationPackageTile'} borderRadius={'4px'} position={'relative'}>
			<Box className={'imageWrapper'}>
				<IKImage
					loading="lazy"
					src={props.imgPaths[0].replace('/redsky/', '/')}
					alt={`package-${props.title}`}
					width={props.isMobile ? 327 : 500}
					height={props.isMobile ? 219 : 600}
				/>
			</Box>
			<Box width={props.isMobile ? '100%' : 550} paddingLeft={props.isMobile ? 0 : 44}>
				<Label
					className="titleAndIcon"
					variant={props.isMobile ? 'h6' : 'buttonMdLg'}
					color="#001933"
					marginY={10}
				>
					{props.title}
					{props.isMobile && (
						<Icon
							iconImg={'icon-info-outline'}
							size={23}
							color="#000"
							onClick={() => {
								popupController.open<MobilePackageDescriptionPopupProps>(
									MobilePackageDescriptionPopup,
									{
										packageImage: props.imgPaths[0],
										description: props.description,
										addPackage: props.onAddPackage!,
										isAdded: props.isAdded!
									}
								);
							}}
						/>
					)}
				</Label>
				{!props.isMobile && (
					<Label
						variant={'body1'}
						marginBottom={13}
						showMoreButton
						showMoreText={
							<Label variant={'buttonMdLg'} color="#001933">
								View More
								<Icon iconImg="icon-chevron-down" className="viewTextIcon" />
							</Label>
						}
						lineClamp={3}
						showLessText={
							<Label variant={'buttonMdLg'} color="#001933">
								View Less
								<Icon iconImg="icon-chevron-up" className="viewTextIcon" />
							</Label>
						}
					>
						<Box width={'372px'} className={'descriptionBox'}>
							{props.description}
						</Box>
					</Label>
				)}
			</Box>
			<Box className="priceAndButtonContainer" width={props.isMobile ? '100%' : 'auto'}>
				{renderPointsOrCash()}
				{buttonhandler(resortId)}
			</Box>
		</Paper>
	);
};

export default DestinationPackageTile;
