import React, { useEffect, useRef, useState } from 'react';
import './BookingFlowAddPackagePage.scss';
import { Box, Page, popupController } from '@bit/redsky.framework.rs.996';
import { useNavigate } from 'react-router-dom';
import router from '../../utils/router';
import DestinationPackageTile from '../../components/destinationPackageTile/DestinationPackageTile';
import LabelButton from '../../components/labelButton/LabelButton';
import serviceFactory from '../../services/serviceFactory';
import { DateUtils, NumberUtils, ObjectUtils, StringUtils, WebUtils } from '../../utils/utils';
import PackageService from '../../services/package/package.service';
import { rsToastify } from '@bit/redsky.framework.rs.toastify';
import RsPagedResponseData = RedSky.RsPagedResponseData;
import useIsAtBreakpoint from '../../customHooks/useIsAtBreakpoint';
import PaginationViewMore from '../../components/paginationViewMore/PaginationViewMore';
import BookingSummaryCard from '../../components/bookingSummaryCard/BookingSummaryCard';
import { useRecoilState, useRecoilValue } from 'recoil';
import globalState from '../../state/globalState';
import ReservationsService from '../../services/reservations/reservations.service';
import Label from '@bit/redsky.framework.rs.label/dist/Label';
import NotEnoughPointsPopup, {
	NotEnoughPointsPopupProps
} from '../../popups/notEnoughPointsPopup/NotEnoughPointsPopup';
import Loader from '../../components/loader/Loader';
import Icon from '@bit/redsky.framework.rs.icon';
import DestinationService from '../../services/destination/destination.service';
import { getPageFinder, undefinedHandler } from '../../utils/undefinedHandler';
import Accordion from '@bit/redsky.framework.rs.accordion';
import classNames from 'classnames';
import ConfirmBoxPopup, { ConfirmBoxPopupProps } from '../../popups/confirmBoxPopup/ConfirmBoxPopup';
import TagManager from 'react-gtm-module';
import AccommodationService from '../../services/accommodation/accommodation.service';
import { get } from 'lodash';

const BookingFlowAddPackagePage = () => {
	let navigate = useNavigate();
	const filterRef = useRef<HTMLElement>(null);
	const isMobile = useIsAtBreakpoint();
	const reservationFilters = useRecoilValue<Misc.ReservationFilters>(globalState.reservationFilters);
	const [verifiedAccommodation, setVerifiedAccommodation] = useRecoilState<
		Api.Reservation.Res.Verification | undefined
	>(globalState.verifiedAccommodation);
	const user = useRecoilValue<Api.User.Res.Detail | undefined>(globalState.user);
	const reservationService = serviceFactory.get<ReservationsService>('ReservationsService');
	const packageService = serviceFactory.get<PackageService>('PackageService');
	const params = router.getPageUrlParams<{ data: any }>([{ key: 'data', default: 0, type: 'string', alias: 'data' }]);
	const accommodationService = serviceFactory.get<AccommodationService>('AccommodationService');
	params.data = ObjectUtils.smartParse(params.data as unknown as string);
	const [userTempPoints, setUserTempPoints] = useState<number>(0);
	const [page, setPage] = useState<number>(1);
	const perPage = 5;
	const [loading, setLoading] = useState<boolean>(true);
	const [total, setTotal] = useState<number>(0);
	const [totalcount, setTotalcount] = useState<number>(0);
	const [addedPackages, setAddedPackages] = useState<Api.UpsellPackage.Res.Complete[]>([]);
	const [groupPackages, setGroupPackages] = useState<any>([]);
	const [filterPackagesIds, setfilterPackagesIds] = useState<any>([]);
	const [availablePackages, setAvailablePackages] = useState<Api.UpsellPackage.Res.Complete[]>([]);
	const destinationService: DestinationService = serviceFactory.get<DestinationService>('DestinationService');
	const [hideTaxesAndFees, setHideTaxesAndFees] = useState<boolean>(true);
	const [second, setSecond] = useState(600);
	let myInterval: any;

	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const resortId: any = urlParams.get('data');

	useEffect(() => {
		myInterval = setInterval(async () => {
			if (second == 0) {
				popupController.open<ConfirmBoxPopupProps>(ConfirmBoxPopup, {});
				clearInterval(myInterval);
			} else {
				setSecond(second - 1);
			}
		}, 1000);
		return () => {
			clearInterval(myInterval);
		};
	}, []);

	useEffect(() => {
		window.scrollTo(0, 0);
		if (!params.data.newRoom) {
			rsToastify.error('Invalid URL given, returning to home', 'Invalid URL');
			navigate('/reservation/availability');
			return;
		}

		async function getAddedPackages() {
			try {
				if (!ObjectUtils.isArrayWithData(params.data.newRoom!.packages)) return;
				const addedPackages = await packageService.getPackagesByIds({
					destinationId: params.data.destinationId,
					packageIds: params.data.newRoom!.packages,
					startDate: params.data.newRoom!.arrivalDate,
					endDate: params.data.newRoom!.departureDate
				});
				setAddedPackages(addedPackages.data);
			} catch (err: any) {
				rsToastify.error("Couldn't get added packages", 'Service Call Error');
				const message = `Getting error :${err.message} on ${getPageFinder(window.location.pathname)} page.`;
				undefinedHandler(message);
			}
		}
		getAddedPackages().catch(console.error);
	}, []);

	useEffect(() => {
		async function getPackages() {
			try {
				if (!params.data.newRoom) return;
				const request: Api.UpsellPackage.Req.Availability = {
					destinationId: params.data.destinationId,
					startDate: params.data.newRoom.arrivalDate,
					endDate: params.data.newRoom.departureDate
					// pagination: { page, perPage }
				};
				if (reservationFilters.redeemPoints) {
					request.filter = { column: 'canUsePoints', value: 1 };
				}
				setLoading(true);
				const response = await packageService.getAvailable(request);

				if (response.total === 0) {
					throw new Error('no available packages');
				}
				setAvailablePackages((prevState) => {
					return [...prevState, ...response.data];
				});
				const results: any = await destinationService.getGroupPackages(params.data.destinationId);

				const filterPackagesId: any = [];

				response.data.map((item: any) => {
					filterPackagesId.push(item.id);
				});

				if (results && results?.groupPackages) {
					results?.groupPackages.map((data: any) => {
						data.isOpne = false;
						data.packages.map((i: any, index: any) => {
							if (filterPackagesId.includes(i.id)) {
								let filter = response.data.filter((d: any) => d.id == i.id);
								data.isShow = true;
								data.packages[index] = filter[0];
							} else {
								data.packages[index] = data.packages[index];
							}
						});
					});

					setTotalcount(totalcount + 1);

					if (totalcount < 1) {
						setGroupPackages(results.groupPackages);
					}

					setfilterPackagesIds((prevState: any) => {
						return [...prevState, ...filterPackagesId];
					});

					setTotal(response.total || 0);
				} else {
					setTotalcount(totalcount + 1);

					setfilterPackagesIds((prevState: any) => {
						return [...prevState, ...filterPackagesId];
					});
					const data = [
						{
							description: 'other Packages',
							id: 'otherPackages',
							isOpne: false,
							isShow: true,
							packages: response.data
						}
					];

					setGroupPackages(data);
				}
			} catch (e: any) {
				if (!params.data.newRoom) return;
				let stays: Misc.StayParams[] = params.data.stays || [];
				stays.push(params.data.newRoom);
				let isRedeemPoints = reservationFilters.redeemPoints ? '&redeemPoints=true' : '';
				navigate(
					`/booking/checkout?data=${encodeURI(
						JSON.stringify({
							destinationId: params.data.destinationId,
							stays
						})
					)}${isRedeemPoints}`
				);
				console.error('Cannot get a list of add-on packages.');
				const message = `Getting error :${e.message} on ${getPageFinder(window.location.pathname)} page.`;
				undefinedHandler(message);
			}
			setLoading(false);
		}
		getPackages().catch(console.error);
	}, [page]);

	useEffect(() => {
		let cancelRequest = false;
		async function verifyAvailability() {
			if (!params.data.newRoom) return;
			try {
				let verifyData: Api.Reservation.Req.Verification = {
					accommodationId: params.data.newRoom.accommodationId,
					destinationId: params.data.destinationId,
					adultCount: params.data.newRoom.adults,
					childCount: params.data.newRoom.children,
					arrivalDate: params.data.newRoom.arrivalDate,
					departureDate: params.data.newRoom.departureDate,
					numberOfAccommodations: 1
				};
				if (params.data.newRoom.rateCode) verifyData.rateCode = params.data.newRoom.rateCode;

				let response = await reservationService.verifyAvailability(verifyData);
				if (cancelRequest) return;
				// response.arrivalDate = DateUtils.displayUserDate(response.arrivalDate, 'MM-DD-YYYY');
				// response.departureDate = DateUtils.displayUserDate(response.departureDate, 'MM-DD-YYYY');
				setVerifiedAccommodation(response);
				setUserTempPoints((user ? user.availablePoints : 0) - response.prices.grandTotalPoints);
			} catch (e: any) {
				const results: Api.Destination.Res.PublicSlim[] = await destinationService.getPublicSlim();

				let destinationId;

				if (reservationFilters.destinationId) {
					destinationId = results.find(
						(result) => parseInt(result?.id.toString()) === reservationFilters.destinationId
					)?.id;
				} else {
					destinationId = results.find(
						(result) => parseInt(result?.id.toString()) === params.data.destinationId
					)?.id;
				}

				const destinationName = results
					.find((result) => parseInt(result?.id.toString()) === reservationFilters.destinationId)
					?.name.split(' ')
					.join('-')
					.toLocaleLowerCase();

				navigate(`/destination/${destinationName}`, {
					state: {
						...reservationFilters,
						destinationId: destinationId
					}
				});
				// await navigate(`/destination/details${WebUtils.createUrlParams(reservationFilters)}`);
				rsToastify.error(
					'Your selected accommodation is no longer available for these dates.',
					'No Longer Available'
				);
				const message = `Getting error :${e.message} on ${getPageFinder(window.location.pathname)} page.`;
				undefinedHandler(message);
			}
			return () => {
				cancelRequest = true;
			};
		}
		verifyAvailability().catch(console.error);
	}, []);

	useEffect(() => {
		const tempPoints =
			(user?.availablePoints || 0) -
			addedPackages.reduce((total, item) => total + item.priceDetail.amountPoints, 0) -
			(verifiedAccommodation?.prices.grandTotalPoints || 0);
		setUserTempPoints(tempPoints);
	}, [user]);

	useEffect(() => {
		try {
			if (!reservationFilters.redeemPoints || !verifiedAccommodation) return;
			if (userTempPoints < 0) {
				popupController.open<NotEnoughPointsPopupProps>(NotEnoughPointsPopup, {
					verifiedAccommodation,
					userTempPoints,
					addedPackages,
					destinationId: params.data.destinationId,
					removePackage: (id: number, points: number) => {
						setAddedPackages((prev) => prev.filter((item) => item.id !== id));
						setUserTempPoints((prev) => prev + points);
					}
				});
			}
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}, [userTempPoints, reservationFilters.redeemPoints]);

	function renderAvailablePackages() {
		try {
			const packageIds = addedPackages.map((item) => item.id);
			return availablePackages
				.sort(function (a, b) {
					return a?.packagesIndex - b?.packagesIndex;
				})
				.map((item) => {
					return (
						<DestinationPackageTile
							isMobile={isMobile}
							key={item.id}
							title={item.title || item.externalTitle}
							description={item.description}
							prices={item.priceDetail}
							imgPaths={item.media.map((item, index) => {
								return item.urls.imageKit;
							})}
							onAddPackage={() => {
								if (packageIds.includes(item.id)) {
									setAddedPackages((prevState) => {
										return prevState.filter((p) => p.id !== item.id);
									});
									setUserTempPoints((prev) => prev + item.priceDetail.amountPoints);
								} else {
									let newPackages = [...addedPackages, item];
									setAddedPackages(newPackages);
									setUserTempPoints((prev) => prev - item.priceDetail.amountPoints);
								}
							}}
							text={packageIds.includes(item.id) ? 'Added to Stay' : 'Add to My Stay'}
							isAdded={packageIds.includes(item.id)}
						/>
					);
				});
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	const googleAddToCartHandler = async (productData?: any) => {
		const destinationId = JSON.parse(params.data);

		const searchQueryObj: Misc.ReservationFilters = { ...reservationFilters };
		let key: keyof Misc.ReservationFilters;
		for (key in searchQueryObj) {
			if (searchQueryObj[key] === undefined) delete searchQueryObj[key];
		}
		const results: RsPagedResponseData<Api.Accommodation.Res.MinMaxAvailability> =
			await accommodationService.availability(destinationId.destinationId, searchQueryObj);

		const accommodationStay = results?.data.find((stay: any) => stay.id === verifiedAccommodation?.accommodationId);

		const prices = accommodationStay?.prices.find(
			(stay: any) => stay.rate.code === verifiedAccommodation?.rateCode
		);

		const begin_checkout = {
			item_name: verifiedAccommodation?.accommodationName,
			item_id: get(accommodationStay, 'externalSystemId', verifiedAccommodation?.accommodationId),
			price: NumberUtils.centsToDollars(prices?.accommodationTotalInCents),
			item_brand: verifiedAccommodation?.destinationName,
			item_category: get(accommodationStay, 'contentLists.RoomCategoryList.0.Name', null),
			item_category2: get(accommodationStay, 'contentLists.RoomCategoryList.0.Name', null),
			item_list_name: 'Rooms',
			quantity: 1,
			arrival_date: reservationFilters.startDate,
			depart_date: reservationFilters.endDate,
			total_cost: NumberUtils.centsToDollars(prices.grandTotalCents),
			currency: prices.rate.currencyCode
		};

		try {
			TagManager.dataLayer({
				dataLayer: {
					event: 'begin_checkout',
					ecommerce: null
				}
			});

			TagManager.dataLayer({
				dataLayer: {
					event: 'begin_checkout',
					ecommerce: {
						detail: {
							products: [begin_checkout]
						}
					}
				}
			});
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	};

	function renderContinueBtn(brandType: any) {
		let companyid;
		if (brandType) {
			companyid = JSON.parse(brandType).themeId;
		} else {
			companyid = null;
		}

		let bgColor = '';
		switch (companyid) {
			case 3:
				bgColor = 'encorereunion';
				// bgColor = '#e51c14';
				break;
			case 78:
				bgColor = 'cottagesbyrentyl';
				break;
			case 19:
				bgColor = 'cottagesbyrentyl';
				break;
			case 9:
				bgColor = 'bearsdenorlando';
				break;
			case 10:
				bgColor = 'resortsbyspectrum';
				break;
			default:
				bgColor = 'yellow';
				break;
		}

		return (
			<LabelButton
				className={`continueButton ${bgColor}`}
				look={'none'}
				variant={'buttonMdLg'}
				label={'Continue to Book'}
				onClick={() => {
					try {
						if (!params.data.newRoom) return;
						let newStay: Misc.StayParams = params.data.newRoom;
						newStay.packages = addedPackages.map((item) => item.id);
						let stays: Misc.StayParams[] = params.data.stays || [];
						stays.push(newStay);
						let bookingParams: Misc.BookingParams = {
							destinationId: params.data.destinationId,
							stays
						};
						setVerifiedAccommodation((prev) => {
							if (!prev) return;
							return { ...prev, upsellPackages: addedPackages };
						});
						let isRedeemPoints = reservationFilters.redeemPoints ? '&redeemPoints=true' : '';
						googleAddToCartHandler();
						navigate(`/booking/checkout?data=${encodeURI(JSON.stringify(bookingParams))}${isRedeemPoints}`);
					} catch (error: any) {
						const message = `Getting error :${error.message} on ${getPageFinder(
							window.location.pathname
						)} page.`;
						undefinedHandler(message);
					}
				}}
			/>
		);
	}

	function renderBreadCrumb() {
		return (
			<div className={!isMobile ? 'packagePageBreadcrumbs' : 'smallPackagePageBreadcrumbs'}>
				<div className={'backBarWrapper'}>
					<div className={!isMobile ? 'breadcrumbs' : 'smallBreadCrumbs'}>
						<Label
							variant={!isMobile ? 'buttonMdLg' : 'caption'}
							className={!isMobile ? 'enhance' : 'stageTitle'}
						>
							Enhance Your Stay
						</Label>
					</div>
					<div className={'backButtonWrapper'} onClick={() => navigate(-1)}>
						<Icon className={'backButton'} iconImg={'icon-chevron-thin-down'} size={!isMobile ? 38 : 25} />
					</div>
				</div>
			</div>
		);
	}

	function allAreEqual(array: any) {
		const result = array.every((element: any) => {
			if (element === array[0]) {
				return true;
			}
		});

		return result;
	}

	const dataChecker = (items: any) => {
		const temp = items.map((item: any) => {
			if (filterPackagesIds.includes(item.id)) {
				return true;
			} else {
				return false;
			}
		});

		const isEqual = !allAreEqual(temp);

		return isEqual;
	};

	return (
		<Page className={'rsBookingFlowAddPackagePage'}>
			{renderBreadCrumb()}
			<div className={'rs-page-content-wrapper  page-content-wrapper '}>
				<Box display="flex" gap={isMobile ? 0 : 80} flexDirection={isMobile ? 'column' : 'row'}>
					{ObjectUtils.isArrayWithData(availablePackages) && verifiedAccommodation ? (
						<Box className="packageSection">
							{isMobile && renderContinueBtn(resortId)}
							<div ref={filterRef} />
							<div style={{ justifyContent: 'center' }}>
								{groupPackages.map((items: any) => {
									const packageIds = addedPackages.map((item) => item.id);

									let isTrue = false;
									items.packages.map((x: any) => {
										if (filterPackagesIds.includes(x.id)) isTrue = true;
									});

									// if (items.id !== 'otherPackages') {
									if (items.packages.length != 0 && isTrue) {
										return (
											<Box
												style={{
													borderTop:
														items.id === 'otherPackages'
															? '1px solid var(--borderGray)'
															: 0,
													borderBottom:
														items.id !== 'otherPackages' ? '1px solid var(--borderGray)' : 0
												}}
											>
												<Accordion
													hideChevron
													hideHoverEffect
													isOpen={items.id === 'otherPackages' ? true : items.isOpne}
													titleReact={
														<Box
															className={
																!isMobile
																	? 'packagePageBreadcrumbs2'
																	: 'smallPackagePageBreadcrumbs2'
															}
															style={{
																width: '100%'
															}}
															// display={'flex'}
														>
															{items.id !== 'otherPackages' && (
																// <div
																// 	className={
																// 		!isMobile
																// 			? 'packagePageBreadcrumbs2'
																// 			: 'smallPackagePageBreadcrumbs'
																// 	}
																// >
																<div
																	style={{
																		display: 'flex',
																		alignItems: 'center',
																		paddingRight: !isMobile ? 15 : 0,
																		justifyContent: 'space-between'
																	}}
																	className={'backBarWrapper2'}
																>
																	<div
																		className={
																			!isMobile
																				? 'breadcrumbs'
																				: 'smallBreadCrumbs'
																		}
																	>
																		<Label
																			variant={
																				!isMobile ? 'buttonMdLg' : 'caption'
																			}
																			className={
																				!isMobile ? 'enhance' : 'stageTitle'
																			}
																		>
																			{items.title}
																		</Label>
																		<p
																			style={{
																				marginBottom: 5,
																				fontSize: 13,
																				margin: !isMobile ? 3 : 0
																			}}
																			// variant={!isMobile ? 'buttonMdLg' : 'caption'}
																			className={
																				!isMobile ? 'enhance' : 'stageTitle'
																			}
																		>
																			{items.description}
																		</p>
																	</div>

																	<div
																		style={{
																			marginRight: 10
																		}}
																	>
																		{items.isOpne ? (
																			<Icon
																				iconImg={'icon-chevron-up'}
																				cursorPointer
																			/>
																		) : (
																			<Icon
																				iconImg={'icon-chevron-down'}
																				cursorPointer
																			/>
																		)}
																	</div>
																</div>
																// </div>
															)}
														</Box>
													}
													onClick={() => {
														const index33 = groupPackages.findIndex(
															(obj: any) => obj.id === items.id
														);

														const opnepackagesCategory = [...groupPackages];

														opnepackagesCategory[index33].isOpne =
															!opnepackagesCategory[index33].isOpne;

														setGroupPackages(
															JSON.parse(JSON.stringify(opnepackagesCategory))
														);
													}}
												>
													{items.isOpne &&
														items.packages.map((item: any) => {
															if (filterPackagesIds.includes(item.id)) {
																return (
																	<DestinationPackageTile
																		isMobile={isMobile}
																		key={item.id}
																		title={item.title || item.externalTitle}
																		description={item.description}
																		prices={item.priceDetail}
																		imgPaths={item.media.map(
																			(item: any, index: any) => {
																				return item.urls.imageKit;
																			}
																		)}
																		onAddPackage={() => {
																			if (packageIds.includes(item.id)) {
																				setAddedPackages((prevState) => {
																					return prevState.filter(
																						(p) => p.id !== item.id
																					);
																				});
																				setUserTempPoints(
																					(prev) =>
																						prev +
																						item.priceDetail.amountPoints
																				);
																			} else {
																				let newPackages = [
																					...addedPackages,
																					item
																				];
																				setAddedPackages(newPackages);
																				setUserTempPoints(
																					(prev) =>
																						prev -
																						item.priceDetail.amountPoints
																				);
																			}
																		}}
																		text={
																			packageIds.includes(item.id)
																				? 'Added to Stay'
																				: 'Add to My Stay'
																		}
																		isAdded={packageIds.includes(item.id)}
																	/>
																);
															}
														})}
													{items.id === 'otherPackages' &&
														items.packages.map((item: any) => {
															if (filterPackagesIds.includes(item.id)) {
																return (
																	<DestinationPackageTile
																		isMobile={isMobile}
																		key={item.id}
																		title={item.title || item.externalTitle}
																		description={item.description}
																		prices={item.priceDetail}
																		imgPaths={item.media.map(
																			(item: any, index: any) => {
																				return item.urls.imageKit;
																			}
																		)}
																		onAddPackage={() => {
																			if (packageIds.includes(item.id)) {
																				setAddedPackages((prevState) => {
																					return prevState.filter(
																						(p) => p.id !== item.id
																					);
																				});
																				setUserTempPoints(
																					(prev) =>
																						prev +
																						item.priceDetail.amountPoints
																				);
																			} else {
																				let newPackages = [
																					...addedPackages,
																					item
																				];
																				setAddedPackages(newPackages);
																				setUserTempPoints(
																					(prev) =>
																						prev -
																						item.priceDetail.amountPoints
																				);
																			}
																		}}
																		text={
																			packageIds.includes(item.id)
																				? 'Added to Stay'
																				: 'Add to My Stay'
																		}
																		isAdded={packageIds.includes(item.id)}
																	/>
																);
															}
														})}
												</Accordion>
											</Box>
										);
									}
								})}
							</div>
							<Box className={'packageButtons'}>
								{/* {loading ? (
									<Loader />
								) : (
									<PaginationViewMore
										selectedRowsPerPage={perPage}
										total={total}
										currentPageNumber={page}
										viewMore={(num) => {
											setPage(num);
										}}
										text={
											<Label variant="buttonMdLg" className="loadMoreButton">
												Load More Packages
											</Label>
										}
									/>
								)} */}
								<Box></Box>
								{renderContinueBtn(resortId)}
							</Box>
						</Box>
					) : (
						<Loader />
					)}
					<Box className="bookingSummarySection">
						{verifiedAccommodation ? (
							<Box className="bookingCardWrapper">
								{renderContinueBtn(resortId)}
								<BookingSummaryCard
									onRedeem={false}
									bookingData={{ ...verifiedAccommodation, upsellPackages: addedPackages }}
									canHide={isMobile}
									usePoints={!!reservationFilters.redeemPoints}
								/>
							</Box>
						) : (
							<Loader />
						)}
					</Box>
				</Box>
			</div>
		</Page>
	);
};

export default BookingFlowAddPackagePage;
