// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.switch{position:relative;display:inline-block;width:96px;height:42px}.switch input{opacity:0;width:0;height:0}.slider{position:absolute;cursor:pointer;top:0;left:0;right:0;bottom:0;background-color:#ccc;-webkit-transition:0.4s;transition:0.4s}.slider:before{position:absolute;content:'';height:33px;width:33px;left:4px;bottom:4px;background-color:white;-webkit-transition:0.4s;transition:0.4s}input:checked+.slider{background-color:var(--primaryTextColor)}input:focus+.slider{box-shadow:0 0 1pxvar --primaryTextColor}input:checked+.slider:before{-webkit-transform:translateX(53px);-ms-transform:translateX(53px);transform:translateX(53px)}.slider.round{border-radius:34px}.slider.round:before{border-radius:50%}
`, "",{"version":3,"sources":["webpack://./src/components/redeemPointsToggle/RedeemPointsToggle.scss"],"names":[],"mappings":"AAAA,QACC,iBAAkB,CAClB,oBAAqB,CACrB,UAAW,CACX,WAAY,CACZ,cAGA,SAAU,CACV,OAAQ,CACR,QAAS,CACT,QAGA,iBAAkB,CAClB,cAAe,CACf,KAAM,CACN,MAAO,CACP,OAAQ,CACR,QAAS,CACT,qBAAsB,CACtB,uBAAwB,CACxB,eAAgB,CAChB,eAGA,iBAAkB,CAClB,UAAW,CACX,WAAY,CACZ,UAAW,CACX,QAAS,CACT,UAAW,CACX,sBAAuB,CACvB,uBAAwB,CACxB,eAAgB,CAChB,sBAGA,wCAAyC,CACzC,oBAGA,wCAA2C,CAC3C,6BAGA,kCAAmC,CACnC,8BAA+B,CAC/B,0BAA2B,CAC3B,cAIA,kBAAmB,CACnB,qBAGA,iBAAkB","sourcesContent":[".switch {\n\tposition: relative;\n\tdisplay: inline-block;\n\twidth: 96px;\n\theight: 42px;\n}\n\n.switch input {\n\topacity: 0;\n\twidth: 0;\n\theight: 0;\n}\n\n.slider {\n\tposition: absolute;\n\tcursor: pointer;\n\ttop: 0;\n\tleft: 0;\n\tright: 0;\n\tbottom: 0;\n\tbackground-color: #ccc;\n\t-webkit-transition: 0.4s;\n\ttransition: 0.4s;\n}\n\n.slider:before {\n\tposition: absolute;\n\tcontent: '';\n\theight: 33px;\n\twidth: 33px;\n\tleft: 4px;\n\tbottom: 4px;\n\tbackground-color: white;\n\t-webkit-transition: 0.4s;\n\ttransition: 0.4s;\n}\n\ninput:checked + .slider {\n\tbackground-color: var(--primaryTextColor);\n}\n\ninput:focus + .slider {\n\tbox-shadow: 0 0 1pxvar (--primaryTextColor);\n}\n\ninput:checked + .slider:before {\n\t-webkit-transform: translateX(53px);\n\t-ms-transform: translateX(53px);\n\ttransform: translateX(53px);\n}\n\n/* Rounded sliders */\n.slider.round {\n\tborder-radius: 34px;\n}\n\n.slider.round:before {\n\tborder-radius: 50%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
