import { useEffect, useState } from 'react';
import serviceFactory from '../services/serviceFactory';
import { rsToastify } from '@bit/redsky.framework.rs.toastify';
import { WebUtils } from '../utils/utils';
import CountryService from '../services/country/country.service';

export default function useGetCountryList(deps?: any): Api.Country.Res.Country[] {
	const [countryList, setCountryList] = useState<Api.Country.Res.Country[]>([]);
	const countryService = serviceFactory.get<CountryService>('CountryService');

	useEffect(() => {
		(async () => {
			try {
				const countries = await countryService.getAllCountries();
				setCountryList(countries.countries);
			} catch (e) {
				console.error('getCountries', e);
				rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unable to get a list of countries.'), 'Server Error');
			}
		})();
	}, [deps]);

	return countryList;
}
