import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { RecoilRoot } from 'recoil';
import { GlobalStateObserver, GlobalStateInfluencer } from './state/globalState';
import serviceFactory from './services/serviceFactory';

// Run our factory creation at the start
serviceFactory.create();

ReactDOM.render(
	<RecoilRoot>
		<App />
		<GlobalStateObserver />
		<GlobalStateInfluencer />
	</RecoilRoot>,
	document.getElementById('root')
);

serviceWorker.unregister();
