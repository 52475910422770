import React, { useEffect, useState } from 'react';
import './ReservationAvailabilityPage.scss';
import { Box, Page, popupController } from '@bit/redsky.framework.rs.996';
import Label from '@bit/redsky.framework.rs.label';
import serviceFactory from '../../services/serviceFactory';
import { useSearchParams } from 'react-router-dom';
import ComparisonDrawer from '../../popups/comparisonDrawer/ComparisonDrawer';
import useIsAtBreakpoint from '../../customHooks/useIsAtBreakpoint';
import globalState from '../../state/globalState';
import { useRecoilValue, useRecoilState } from 'recoil';
import { ObjectUtils } from '../../utils/utils';
import FilterReservationPopup, {
	FilterReservationPopupProps
} from '../../popups/filterReservationPopup/FilterReservationPopup';
import DestinationSearchResultCard from '../../components/destinationSearchResultCard/DestinationSearchResultCard';
import DestinationService from '../../services/destination/destination.service';
import ComparisonService from '../../services/comparison/comparison.service';
import { rsToastify } from '@bit/redsky.framework.rs.toastify';
import TopSearchBar from '../../components/topSearchBar/TopSearchBar';
import FilterBarAllFilters from '../../components/filterBar/FilterBarAllFilters';
import PaginationViewMore from '../../components/paginationViewMore/PaginationViewMore';
import MobileLightBox, { MobileLightBoxProps } from '../../popups/mobileLightBox/MobileLightBox';
import LightBoxCarouselPopup, {
	TabbedCarouselPopupProps
} from '../../popups/lightBoxCarouselPopup/LightBoxCarouselPopup';
import Loader from '../../components/loader/Loader';
import CountryService from '../../services/country/country.service';
import { WebUtils } from '../../utils/utils';
import RegionService from '../../services/region/region.service';
import CallToActionPopup, { CallToActionPopupProps } from '../../popups/callToActionPopup/CallToActionPopup';
import UserService from '../../services/user/user.service';
import { getPageFinder, undefinedHandler } from '../../utils/undefinedHandler';

const ReservationAvailabilityPage: React.FC = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const isMobile = useIsAtBreakpoint();
	const reservationFilters = useRecoilValue<Misc.ReservationFilters>(globalState.reservationFilters);
	const destinationService = serviceFactory.get<DestinationService>('DestinationService');
	const comparisonService = serviceFactory.get<ComparisonService>('ComparisonService');
	const perPage = 5;
	const [isFetchingResults, setIsFetchingResults] = useRecoilState<boolean>(globalState.isFetchingResults);
	const [page, setPage] = useState<number>(1);
	const [availabilityTotal, setAvailabilityTotal] = useState<number>(0);
	const [destinations, setDestinations] = useState<Api.Destination.Res.AvailabilitySummary[]>([]);
	const [pointPerDollar, setPointPerDollar] = useState<number>(0);
	// const regionName = useRecoilValue<any>(globalState.regionNameList);
	const [regionName, setRegionName] = useRecoilState<any | []>(globalState.regionNameList);
	const [regionNameId, setRegionNameId] = useRecoilState<any | []>(globalState.regionNameListId);
	const [regionList, setRegionList] = useState<any>([]);
	// const [callToActionPopupShow, setCallToActionPopup] = useRecoilState<number>(globalState.callToActionPopup);

	const [countryList, setCountryList] = useRecoilState<Misc.CountryList>(globalState.countryList);
	const countryService = serviceFactory.get<CountryService>('CountryService');
	const regionService = serviceFactory.get<RegionService>('RegionService');

	const [user, setUser] = useRecoilState<Api.User.Res.Detail | undefined>(globalState.user);
	const userService = serviceFactory.get<UserService>('UserService');
	const [destinationSelection, setDestinationSelection] = useRecoilState<any>(globalState.destinationSelection);

	const redHeart = '../../images/accountTravelPreferences/redHeart.svg';
	const whiteHeart = '../../images/accountTravelPreferences/whiteHeart.svg';

	useEffect(() => {
		const search = '';
		setSearchParams(search, { replace: true });
		getPlatformVariables();
	}, []);

	// when you click load more button on home
	useEffect(() => {
		let cancelFetch = false;
		if (page === 1) {
			setAvailabilityTotal(0);
			return;
		}
		(async function getNextPage() {
			try {
				setIsFetchingResults(true);
				const res = await getAvailable(page);

				if (cancelFetch) return;
				if (page === 1) setDestinations(res.data);
				else {
					setDestinations((prev) => [...prev, ...res.data]);
					//Added ...destination in showCallToUrgencyPopUp to show urgency popup at last destination
					showCallToUrgencyPopUp([...destinations, ...res.data]);
				}

				setAvailabilityTotal(res.total || 0);
			} catch (e: any) {
				rsToastify.error('Failed to get availability, please try again.', 'Server Error');
				const message = `Getting error :${e.message} on ${getPageFinder(window.location.pathname)} page.`;
				undefinedHandler(message);
			}
			setIsFetchingResults(false);
		})();
		return () => {
			cancelFetch = true;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page]);

	//when page load
	useEffect(() => {
		setDestinations([]);
		setPage(1);
		let cancelFetch = false;
		if (reservationFilters.init) {
			(async function getAvailableReservations() {
				try {
					setIsFetchingResults(true);
					const res = await getAvailable(1);
					!!res.data && res.data.length > 0 && showCallToUrgencyPopUp(res.data);
					if (cancelFetch) return;
					setDestinations(res.data);
					setAvailabilityTotal(res.total || 0);
				} catch (e: any) {
					rsToastify.error('Failed to get availability, please try again.', 'Server Error');
					const message = `Getting error :${e.message} on ${getPageFinder(window.location.pathname)} page.`;
					undefinedHandler(message);
				}
				setIsFetchingResults(false);
			})();
		}

		return () => {
			cancelFetch = true;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reservationFilters]);

	//when you click load more button on home
	// useEffect(() => {
	// 	let cancelFetch = false;
	// 	if (page === 1) {
	// 		setAvailabilityTotal(0);
	// 	}
	// 	if (reservationFilters.init) {
	// 		(async function getNextPage() {
	// 			try {
	// 				setIsFetchingResults(true);
	// 				const res = await getAvailable(page);
	// 				showCallToUrgencyPopUp(res.data);
	// 				if (cancelFetch) return;
	// 				if (page === 1) setDestinations(res.data);
	// 				else setDestinations((prev) => [...prev, ...res.data]);
	// 				setAvailabilityTotal(res.total || 0);
	// 			} catch (e: any) {
	// 				rsToastify.error('Failed to get availability, please try again.', 'Server Error');
	// 				const message = `Getting error :${e.message} on ${getPageFinder(window.location.pathname)} page.`;
	// 				undefinedHandler(message);
	// 			}
	// 			setIsFetchingResults(false);
	// 		})();
	// 	}
	// 	return () => {
	// 		cancelFetch = true;
	// 	};
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [page, reservationFilters]);

	useEffect(() => {
		(async () => {
			try {
				let regions: Api.Region.Res.Get[] = await regionService.getAllRegions();
				const tempRegionList: any = [];
				const tempRegionListId: any = [];
				reservationFilters.regionIds?.map((item1: any) => {
					const regionObj = regions.find((item) => item.id == item1);
					if (regionObj) {
						tempRegionList.push(regionObj.name);
						tempRegionListId.push(regionObj.id);
					}
				});
				setRegionName(tempRegionList);
				setRegionNameId(tempRegionListId);
				setRegionList(tempRegionList);
			} catch (e: any) {
				rsToastify.error('There was an issue getting regions');
				const message = `Getting error :${e.message} on ${getPageFinder(window.location.pathname)} page.`;
				undefinedHandler(message);
			}
		})();
	}, [reservationFilters]);

	useEffect(() => {
		if (!isFetchingResults && destinations.length === 0 && availabilityTotal === 0) {
			// setCallToActionPopup(callToActionPopupShow + 1);
			popupController.open<CallToActionPopupProps>(CallToActionPopup, {});
		} else {
			popupController.close(CallToActionPopup);
		}
	}, [isFetchingResults]);

	const showCallToUrgencyPopUp = (data: Api.Destination.Res.AvailabilitySummary[]) => {
		try {
			let count = 0;
			for (let i = 0; i < data.length; i++) {
				if (data[i].minAccommodationPriceInCents === null && data[i].allowOversell === false) {
					count++;
				}
			}

			if (count === data.length) {
				popupController.open<CallToActionPopupProps>(CallToActionPopup, {});
			}
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	};

	function getAvailable(searchPage: number) {
		const searchQueryObj: Misc.ReservationFilters = { ...reservationFilters };
		let key: keyof Misc.ReservationFilters;
		for (key in searchQueryObj) {
			if (searchQueryObj[key] === undefined) delete searchQueryObj[key];
		}
		searchQueryObj.pagination = { page: searchPage, perPage };
		return destinationService.searchAvailableReservations(searchQueryObj);
	}

	async function getPlatformVariables() {
		const pointPerDollar = (await destinationService.getPlatformVariables()).rewardPointsPerDollar; //global reward point per dollar
		setPointPerDollar(pointPerDollar);
	}

	const preferencesData = async () => {
		try {
			const userFavoriteDestinations = await userService.userFavoriteDestinations(user?.id);

			const FavoriteDestinations = userFavoriteDestinations.data.myFavoriteDestinations;

			if (FavoriteDestinations === undefined || FavoriteDestinations === null || FavoriteDestinations === '') {
				setDestinationSelection([]);
			} else {
				if (user) setDestinationSelection(JSON.parse(FavoriteDestinations));
			}
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	};

	useEffect(() => {
		// if (!user) navigate('/page-not-found');
		if (user) {
			preferencesData();
		}
	}, [user]);

	function renderDestinationSearchResultCards() {
		try {
			if (!ObjectUtils.isArrayWithData(destinations)) return;
			return destinations.map((destination) => {
				let urls: string[] = getImageUrls(destination);

				const heart = destinationSelection.includes(destination?.id) ? redHeart : whiteHeart;

				return (
					<DestinationSearchResultCard
						countryList={countryList.list ? countryList.list : []}
						key={destination.id}
						pointPerDollar={pointPerDollar}
						isAccommodations={destination?.accommodations.length}
						destinationObj={destination}
						FavoriteDestinationsIcon={heart}
						picturePaths={urls.filter(Boolean)}
						onAddCompareClick={() => {
							comparisonService.addToComparison(destination.id).catch(console.error);
						}}
						onRemoveCompareClick={() => {
							comparisonService.removeFromComparison(destination.id);
						}}
						onGalleryClick={() => {
							if (isMobile) {
								popupController.open<MobileLightBoxProps>(MobileLightBox, {
									imageData: destination.media
								});
							} else {
								popupController.open<TabbedCarouselPopupProps>(LightBoxCarouselPopup, {
									imageData: destination.media.sort((a: any, b: any) => a.mediaIndex - b.mediaIndex),
									defaultImageIndex: 0
								});
							}
						}}
					/>
				);
			});
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	function getImageUrls(destination: Api.Destination.Res.AvailabilitySummary): string[] {
		try {
			if (destination.media) {
				let images = destination.media.sort((a: any, b: any) => a.mediaIndex - b.mediaIndex);
				images.sort((a, b) => {
					return b.isPrimary - a.isPrimary;
				});
				return images.map((urlObj) => {
					return urlObj.urls.imageKit?.toString() || urlObj.urls.thumb;
				});
			}
			return [];
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
			return [];
		}
	}

	return (
		<>
			<Page className={'rsReservationAvailabilityPage'}>
				<div className={'rs-page-content-wrapper'}>
					<TopSearchBar
						onFilterClick={() =>
							popupController.open<FilterReservationPopupProps>(FilterReservationPopup, {
								className: 'filterPopup',
								reservationFilters,
								chainId: destinations[0]?.chainId,
								arriveDate: reservationFilters.startDate,
								departDate: reservationFilters.endDate
							})
						}
					/>
					{isMobile && !!availabilityTotal && (
						<>
							<Box className={'destinationCount'}>
								<Label variant={'body1'}>
									{availabilityTotal} {availabilityTotal ? '+' : ''} resort
									{availabilityTotal && availabilityTotal <= 1 ? '' : 's'}{' '}
									{!!regionList && regionList.length > 0 ? 'in' : ''}
								</Label>
							</Box>
							{regionList && regionList.length > 0 && reservationFilters.regionIds && (
								<Label variant={'buttonSm'} className={'countTextRegion'}>
									{regionList.join()}
								</Label>
							)}
						</>
					)}
					<Box className={'filterResultsWrapper'}>
						{!isMobile && (
							<FilterBarAllFilters
								chainId={destinations[0]?.chainId}
								availabilityTotal={availabilityTotal || 0}
							/>
						)}
					</Box>
					<Box className={'searchResultsWrapper'}>
						{!isFetchingResults && destinations.length <= 0 && (
							<Box>
								<Label variant="h5">Hmmm...</Label>
								<Label variant={'h5'} mb={15}>
									We're sorry. We couldn't find any matches to your search.
								</Label>
								<Label variant="h5">Please try again.</Label>
							</Box>
						)}
						{renderDestinationSearchResultCards()}
						{isFetchingResults && <Loader />}
					</Box>
					{!isFetchingResults && (
						<PaginationViewMore
							selectedRowsPerPage={perPage}
							total={availabilityTotal}
							currentPageNumber={page}
							viewMore={(page) => {
								setPage(page);
							}}
							text={
								<Label variant="button" className="loadMoreButton">
									Load More Resorts
								</Label>
							}
						/>
					)}
					<ComparisonDrawer />
				</div>
			</Page>
		</>
	);
};

export default ReservationAvailabilityPage;
