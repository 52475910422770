// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rsMaskedDateRangeSelector{display:grid;grid-template-columns:0 1fr 1fr}.rsMaskedDateRangeSelector .rsDateRangeSelector .startEndLabels,.rsMaskedDateRangeSelector .rsDateRangeSelector .DateInput{display:none}.rsMaskedDateRangeSelector .rsDateRangeSelector .DateRangePicker .DateRangePickerInput_arrow{display:none}.rsMaskedDateRangeSelector .rsDateRangeSelector.responsiveDateRangeSelector{border:none;padding:0}.rsMaskedDateRangeSelector .startDateBox{cursor:pointer}.rsMaskedDateRangeSelector .startDateBox .startContaner .startDateLabel{font-size:13px;color:#888888}.rsMaskedDateRangeSelector .startDateBox .startContaner .rsButton{width:max-content}.rsMaskedDateRangeSelector .startDateBox .rsButton .iconDown{align-self:center;margin-left:auto;margin-right:auto;font-weight:200}.rsMaskedDateRangeSelector .endDateBox{cursor:pointer;display:flex}.rsMaskedDateRangeSelector .endDateBox .endContaner .endDateLabel{font-size:13px;color:#888888}.rsMaskedDateRangeSelector .endDateBox .endContaner .rsButton{width:max-content}.rsMaskedDateRangeSelector .endDateBox .rsButton .iconDown{align-self:center;margin-left:auto;margin-right:auto;font-weight:200}
`, "",{"version":3,"sources":["webpack://./src/components/maskedDateRangeSelector/MaskedDateRangeSelector.scss"],"names":[],"mappings":"AAEA,2BACC,YAAa,CACb,+BAAgC,CAFjC,2HAQG,YAAa,CARhB,6FAYG,YAAa,CAZhB,4EAiBE,WAAY,CACZ,SAAU,CAlBZ,yCAsBE,cAAe,CAtBjB,wEA6BI,cAAe,CACf,aAAc,CA9BlB,kEAkCI,iBAAkB,CAlCtB,6DAwCI,iBAAkB,CAClB,gBAAiB,CACjB,iBAAkB,CAClB,eAAgB,CA3CpB,uCAiDE,cAAe,CACf,YAAa,CAlDf,kEAwDI,cAAe,CACf,aAAc,CAzDlB,8DA6DI,iBAAkB,CA7DtB,2DAmEI,iBAAkB,CAClB,gBAAiB,CACjB,iBAAkB,CAClB,eAAgB","sourcesContent":["@import '../../themes/themes.scss';\n\n.rsMaskedDateRangeSelector {\n\tdisplay: grid;\n\tgrid-template-columns: 0 1fr 1fr;\n\t// max-height: 58px;\n\n\t.rsDateRangeSelector {\n\t\t.startEndLabels,\n\t\t.DateInput {\n\t\t\tdisplay: none;\n\t\t}\n\n\t\t.DateRangePicker .DateRangePickerInput_arrow {\n\t\t\tdisplay: none;\n\t\t}\n\t}\n\n\t.rsDateRangeSelector.responsiveDateRangeSelector {\n\t\tborder: none;\n\t\tpadding: 0;\n\t}\n\n\t.startDateBox {\n\t\tcursor: pointer;\n\t\t// display: flex;\n\t\t// width: 183px;\n\t\t// max-width: 183px;\n\n\t\t.startContaner {\n\t\t\t.startDateLabel {\n\t\t\t\tfont-size: 13px;\n\t\t\t\tcolor: #888888;\n\t\t\t}\n\n\t\t\t.rsButton {\n\t\t\t\twidth: max-content;\n\t\t\t}\n\t\t}\n\n\t\t.rsButton {\n\t\t\t.iconDown {\n\t\t\t\talign-self: center;\n\t\t\t\tmargin-left: auto;\n\t\t\t\tmargin-right: auto;\n\t\t\t\tfont-weight: 200;\n\t\t\t}\n\t\t}\n\t}\n\n\t.endDateBox {\n\t\tcursor: pointer;\n\t\tdisplay: flex;\n\t\t// width: 183px;\n\t\t// max-width: 183px;\n\n\t\t.endContaner {\n\t\t\t.endDateLabel {\n\t\t\t\tfont-size: 13px;\n\t\t\t\tcolor: #888888;\n\t\t\t}\n\n\t\t\t.rsButton {\n\t\t\t\twidth: max-content;\n\t\t\t}\n\t\t}\n\n\t\t.rsButton {\n\t\t\t.iconDown {\n\t\t\t\talign-self: center;\n\t\t\t\tmargin-left: auto;\n\t\t\t\tmargin-right: auto;\n\t\t\t\tfont-weight: 200;\n\t\t\t}\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primaryTextColor": `var(--primaryTextColor)`,
	"secondaryTextColor": `var(--secondaryTextColor)`,
	"successGreen": `var(--successGreen)`,
	"adminPink": `var(--adminPink)`,
	"warningRed": `var(--warningRed)`,
	"buttonLinkColor": `var(--buttonLinkColor)`,
	"secondaryButtonLinkColor": `var(--secondaryButtonLinkColor)`,
	"iconAndHighlightTextColor": `var(--iconAndHighlightTextColor)`,
	"primaryBackgroundColor": `var(--primaryBackgroundColor)`,
	"secondaryBackgroundColor": `var(--secondaryBackgroundColor)`,
	"errorColor": `var(--errorColor)`,
	"primaryBoxShadowColor": `var(--primaryBoxShadowColor)`,
	"greyLineColor": `var(--greyLineColor)`,
	"thinGreyLineColor": `var(--thinGreyLineColor)`,
	"white": `var(--white)`,
	"lightestGray": `var(--lightestGray)`,
	"backgroundGray": `var(--backgroundGray)`,
	"borderGray": `var(--borderGray)`,
	"medGray2": `var(--medGray2)`,
	"medGray2Active": `var(--medGray2Active)`,
	"black": `var(--black)`,
	"primaryCalendarColor": `var(--primaryCalendarColor)`,
	"secondaryCalendarColor": `var(--secondaryCalendarColor)`
};
export default ___CSS_LOADER_EXPORT___;
