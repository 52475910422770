// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rsLabelButton .encorereunion{background-color:#e51c14}.rsLabelButton .cottagesbyrentyl{background-color:#5d995d}.rsLabelButton .bearsdenorlando{background-color:#907326}.rsLabelButton .resortsbyspectrum{background-color:#2a8293}.rsLabelButton .yellow{background-color:var(--secondaryButtonLinkColor)}.rsLabelButton .yellow:hover{background-color:var(--secondaryButtonLinkColor)}
`, "",{"version":3,"sources":["webpack://./src/components/labelButton/LabelButton.scss","webpack://./src/themes/_mixins.scss"],"names":[],"mappings":"AACA,8BAEE,wBAAyB,CAF3B,iCAQE,wBAAyB,CAR3B,gCAcE,wBAAyB,CAd3B,kCAoBE,wBAAyB,CApB3B,uBA2BE,gDCHiB,CDxBnB,6BA6BG,gDCLgB","sourcesContent":["@import '../../themes/themes';\n.rsLabelButton {\n\t.encorereunion {\n\t\tbackground-color: #e51c14;\n\t\t// &:hover {\n\t\t// \tbackground-color: theme-var($secondaryButtonLinkColor);\n\t\t// }\n\t}\n\t.cottagesbyrentyl {\n\t\tbackground-color: #5d995d;\n\t\t// &:hover {\n\t\t// \tbackground-color: theme-var($secondaryButtonLinkColor);\n\t\t// }\n\t}\n\t.bearsdenorlando {\n\t\tbackground-color: #907326;\n\t\t// &:hover {\n\t\t// \tbackground-color: theme-var($secondaryButtonLinkColor);\n\t\t// }\n\t}\n\t.resortsbyspectrum {\n\t\tbackground-color: #2a8293;\n\t\t// &:hover {\n\t\t// \tbackground-color: theme-var($secondaryButtonLinkColor);\n\t\t// }\n\t}\n\n\t.yellow {\n\t\tbackground-color: theme-var($secondaryButtonLinkColor);\n\t\t&:hover {\n\t\t\tbackground-color: theme-var($secondaryButtonLinkColor);\n\t\t}\n\t}\n}\n","@import 'variables';\n\n/*\n  include a map in a class\n*/\n@mixin spread-map($map: ()) {\n\t@each $key, $value in $map {\n\t\t#{$key}: $value;\n\t}\n}\n\n/*\n  Returns a CSS variable property specific to themeing.\n  Accepts an optional fallback, and optional map.\n  - SCSS: color: theme-var($--theme-primary, blue)\n  - CSS:  color: var(--theme-primary, blue)\n  Throws error if the variable not part of the map\n*/\n@function theme-var($key, $fallback: null, $map: $defaultTheme) {\n\t@if not map-has-key($map, $key) {\n\t\t@error \"key: '#{$key}', is not a key in map: #{$map}\";\n\t}\n\t@if ($fallback) {\n\t\t@return var($key, $fallback);\n\t} @else {\n\t\t@return var($key);\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primaryTextColor": `var(--primaryTextColor)`,
	"secondaryTextColor": `var(--secondaryTextColor)`,
	"successGreen": `var(--successGreen)`,
	"adminPink": `var(--adminPink)`,
	"warningRed": `var(--warningRed)`,
	"buttonLinkColor": `var(--buttonLinkColor)`,
	"secondaryButtonLinkColor": `var(--secondaryButtonLinkColor)`,
	"iconAndHighlightTextColor": `var(--iconAndHighlightTextColor)`,
	"primaryBackgroundColor": `var(--primaryBackgroundColor)`,
	"secondaryBackgroundColor": `var(--secondaryBackgroundColor)`,
	"errorColor": `var(--errorColor)`,
	"primaryBoxShadowColor": `var(--primaryBoxShadowColor)`,
	"greyLineColor": `var(--greyLineColor)`,
	"thinGreyLineColor": `var(--thinGreyLineColor)`,
	"white": `var(--white)`,
	"lightestGray": `var(--lightestGray)`,
	"backgroundGray": `var(--backgroundGray)`,
	"borderGray": `var(--borderGray)`,
	"medGray2": `var(--medGray2)`,
	"medGray2Active": `var(--medGray2Active)`,
	"black": `var(--black)`,
	"primaryCalendarColor": `var(--primaryCalendarColor)`,
	"secondaryCalendarColor": `var(--secondaryCalendarColor)`
};
export default ___CSS_LOADER_EXPORT___;
