import * as React from 'react';
import './ForgotPasswordPopup.scss';
import { Box, Popup, popupController, PopupProps } from '@bit/redsky.framework.rs.996';
import Icon from '@bit/redsky.framework.rs.icon';
import Label from '@bit/redsky.framework.rs.label';
import LabelInput from '../../components/labelInput/LabelInput';
import { useEffect, useState } from 'react';
import { RsFormControl, RsFormGroup, RsValidator, RsValidatorEnum } from '@bit/redsky.framework.rs.form';
import LabelButton from '../../components/labelButton/LabelButton';
import serviceFactory from '../../services/serviceFactory';
import UserService from '../../services/user/user.service';
import SigninPopup from '../signin/SigninPopup';
import { rsToastify } from '@bit/redsky.framework.rs.toastify';

export interface ForgotPasswordPopupProps extends PopupProps {}

const ForgotPasswordPopup: React.FC<ForgotPasswordPopupProps> = (props) => {
	const userService = serviceFactory.get<UserService>('UserService');
	const fullProps = popupController.convertProps(props);
	const [recoveryForm, setRecoveryForm] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl('email', '', [
				new RsValidator(RsValidatorEnum.EMAIL, 'Please enter a valid email address')
			])
		])
	);

	useEffect(() => {
		window.history.pushState(null, 'ForgotPasswordPopup Close', window.location.pathname + window.location.search);
		window.addEventListener('popstate', (e: PopStateEvent) => {
			e.preventDefault();
			popupController.close(ForgotPasswordPopup);
		});
		return () => {
			window.removeEventListener('popstate', (e: PopStateEvent) => {
				e.preventDefault();
				popupController.close(ForgotPasswordPopup);
			});
		};
	}, []);

	async function sendRecoveryEmail() {
		try {
			await userService.requestPasswordByEmail(recoveryForm.get('email').value as string);
			popupController.closeById(fullProps.popupId);
			rsToastify.success(
				'Recovery email sent. Please check your email to complete the recovery process.',
				'Success!'
			);
		} catch (e) {
			rsToastify.error(
				'Unable to send recovery email. Please verify the email you have entered is correct.',
				'Error!'
			);
		}
	}

	return (
		<Popup {...props}>
			<Box className={'rsForgotPasswordPopup'}>
				<Icon
					iconImg={'icon-close'}
					onClick={() => {
						popupController.closeById(fullProps.popupId);
					}}
					size={16}
					className={'closeBtn'}
				/>
				<Box className={'popupTitle'}>
					<Label variant={'h5'} className={'primaryTextColor'}>
						Forgot Password
					</Label>
				</Box>
				<Box className={'popupBody'}>
					<LabelInput
						title={'Email Address'}
						inputType={'text'}
						labelVariant={'h6'}
						control={recoveryForm.get('email')}
						updateControl={(control) => setRecoveryForm(recoveryForm.clone().update(control))}
					/>
					<LabelButton
						look={'containedPrimary'}
						variant={'buttonMdLg'}
						label={'Send Reset Link'}
						className={'yellow'}
						onClick={() => {
							sendRecoveryEmail().catch(console.error);
						}}
					/>
					<Box className={'backToLoginText'}>
						<Label variant={'body1'} className={'rememberText'}>
							Remember your password?
						</Label>
						<LabelButton
							className={'primaryTextColor'}
							label={'Back to Log in'}
							variant={'body1'}
							look={'none'}
							onClick={() => {
								popupController.closeById(fullProps.popupId);
								popupController.open(SigninPopup);
							}}
						/>
					</Box>
				</Box>
			</Box>
		</Popup>
	);
};

export default ForgotPasswordPopup;
