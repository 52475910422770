import { Service } from '../Service';
import http, { microServiceHttp } from '../../utils/http';
import { RsResponseData } from '@bit/redsky.framework.rs.http';
import serviceFactory from '../serviceFactory';
import UserService from '../user/user.service';

export default class UserAddressService extends Service {
	private userService!: UserService;

	start() {
		this.userService = serviceFactory.get<UserService>('UserService');
	}

	async create(data: Api.UserAddress.Req.Create): Promise<Api.UserAddress.Res.Create> {
		let res = await microServiceHttp.post<Api.UserAddress.Res.Create>('create-userAddress', data);
		this.refreshUser();
		return res.data;
	}

	async update(data: Api.UserAddress.Req.Update): Promise<Api.UserAddress.Res.Update> {
		let result = await microServiceHttp.put<Api.UserAddress.Res.Update>('update-userAddress', data);
		this.refreshUser();
		return result.data;
	}

	async delete(id: number): Promise<number> {
		let result = await microServiceHttp.delete<number>('delete-userAddress', { id });
		this.refreshUser();
		return result.data;
	}

	private refreshUser() {
		this.userService.refreshUser().catch(console.error);
	}
}
