// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rsImageLabel .heartIcon{margin-left:15px;height:20px;cursor:pointer}.rsImageLabel.top{width:fit-content;width:-moz-fit-content}.rsImageLabel.top .rsImg{display:flex;justify-content:center;margin-bottom:3px}.rsImageLabel.right{width:fit-content;width:-moz-fit-content;display:flex;flex-direction:row-reverse;align-items:center}.rsImageLabel.right .rsImg{margin-left:10px}.rsImageLabel.bottom{width:fit-content;width:-moz-fit-content;display:flex;flex-direction:column-reverse}.rsImageLabel.bottom .rsImg{align-self:center;margin-top:3px}.rsImageLabel.left{display:flex;align-items:center}.rsImageLabel.left .label{margin-left:10px}
`, "",{"version":3,"sources":["webpack://./src/components/imageLabel/ImageLabel.scss"],"names":[],"mappings":"AAEA,yBAEE,gBAAiB,CACjB,WAAY,CACZ,cAAe,CAJjB,kBAQE,iBAAkB,CAClB,sBAAuB,CATzB,yBAYG,YAAa,CACb,sBAAuB,CACvB,iBAAkB,CAdrB,oBAmBE,iBAAkB,CAClB,sBAAuB,CACvB,YAAa,CACb,0BAA2B,CAC3B,kBAAmB,CAvBrB,2BA0BG,gBAAiB,CA1BpB,qBA+BE,iBAAkB,CAClB,sBAAuB,CACvB,YAAa,CACb,6BAA8B,CAlChC,4BAqCG,iBAAkB,CAClB,cAAe,CAtClB,mBA2CE,YAAa,CACb,kBAAmB,CA5CrB,0BA+CG,gBAAiB","sourcesContent":["@import '../../themes/themes';\n\n.rsImageLabel {\n\t.heartIcon {\n\t\tmargin-left: 15px;\n\t\theight: 20px;\n\t\tcursor: pointer;\n\t}\n\n\t&.top {\n\t\twidth: fit-content;\n\t\twidth: -moz-fit-content;\n\n\t\t.rsImg {\n\t\t\tdisplay: flex;\n\t\t\tjustify-content: center;\n\t\t\tmargin-bottom: 3px;\n\t\t}\n\t}\n\n\t&.right {\n\t\twidth: fit-content;\n\t\twidth: -moz-fit-content;\n\t\tdisplay: flex;\n\t\tflex-direction: row-reverse;\n\t\talign-items: center;\n\n\t\t.rsImg {\n\t\t\tmargin-left: 10px;\n\t\t}\n\t}\n\n\t&.bottom {\n\t\twidth: fit-content;\n\t\twidth: -moz-fit-content;\n\t\tdisplay: flex;\n\t\tflex-direction: column-reverse;\n\n\t\t.rsImg {\n\t\t\talign-self: center;\n\t\t\tmargin-top: 3px;\n\t\t}\n\t}\n\n\t&.left {\n\t\tdisplay: flex;\n\t\talign-items: center;\n\n\t\t.label {\n\t\t\tmargin-left: 10px;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primaryTextColor": `var(--primaryTextColor)`,
	"secondaryTextColor": `var(--secondaryTextColor)`,
	"successGreen": `var(--successGreen)`,
	"adminPink": `var(--adminPink)`,
	"warningRed": `var(--warningRed)`,
	"buttonLinkColor": `var(--buttonLinkColor)`,
	"secondaryButtonLinkColor": `var(--secondaryButtonLinkColor)`,
	"iconAndHighlightTextColor": `var(--iconAndHighlightTextColor)`,
	"primaryBackgroundColor": `var(--primaryBackgroundColor)`,
	"secondaryBackgroundColor": `var(--secondaryBackgroundColor)`,
	"errorColor": `var(--errorColor)`,
	"primaryBoxShadowColor": `var(--primaryBoxShadowColor)`,
	"greyLineColor": `var(--greyLineColor)`,
	"thinGreyLineColor": `var(--thinGreyLineColor)`,
	"white": `var(--white)`,
	"lightestGray": `var(--lightestGray)`,
	"backgroundGray": `var(--backgroundGray)`,
	"borderGray": `var(--borderGray)`,
	"medGray2": `var(--medGray2)`,
	"medGray2Active": `var(--medGray2Active)`,
	"black": `var(--black)`,
	"primaryCalendarColor": `var(--primaryCalendarColor)`,
	"secondaryCalendarColor": `var(--secondaryCalendarColor)`
};
export default ___CSS_LOADER_EXPORT___;
