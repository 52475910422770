import { FooterSection } from './Footer';

export const FooterLinks: FooterSection[] = [
	{
		title: 'COMPANY',
		links: [
			{
				text: 'About',
				path: 'https://rentylresorts.com/history/'
			},
			{
				text: 'Careers',
				path: 'https://ndmhospitality.applicantpro.com/jobs/'
			},
			{
				text: 'Partnerships',
				path: 'https://rentylresorts.com/partners/'
			}
		]
	},
	{
		title: 'EXPLORE',
		links: [
			{
				text: 'Resorts',
				path: 'https://rentylresorts.com/resorts/'
			},
			{
				text: 'Deals and Packages',
				path: 'https://rentylresorts.com/deals-packages/'
			}
		]
	},
	{
		title: 'TERMS & POLICIES',
		links: [
			{
				text: 'Privacy policy',
				path: 'https://rentylresorts.com/privacy-policy/'
			},
			{
				text: 'Terms and Conditions',
				path: 'https://rentylresorts.com/terms-conditions/'
			}
		]
	}
];
