import React, { useEffect, useState } from 'react';
import './AccountTravelPreferences.scss';
import { useNavigate } from 'react-router-dom';
import { Page } from '@bit/redsky.framework.rs.996';
import { useRecoilState } from 'recoil';
import SubNavMenu from '../../components/subNavMenu/SubNavMenu';
import globalState from '../../state/globalState';
import LoadingPage from '../loadingPage/LoadingPage';
import serviceFactory from '../../services/serviceFactory';
import { rsToastify } from '@bit/redsky.framework.rs.toastify';
import UserService from '../../services/user/user.service';
import DestinationService from '../../services/destination/destination.service';
import { getPageFinder, undefinedHandler } from '../../utils/undefinedHandler';
import { get } from 'lodash';

const AccountTravelPreferences: React.FC = () => {
	let navigate = useNavigate();
	const [user, setUser] = useRecoilState<Api.User.Res.Detail | undefined>(globalState.user);
	const userService = serviceFactory.get<UserService>('UserService');
	const [screenSize, setScreenSize] = useState<number>(window.screen.width);

	const destinationService = serviceFactory.get<DestinationService>('DestinationService');
	const [destination, setDestination] = useState<any>([]);
	const [destinationSelection, setDestinationSelection] = useRecoilState<any>(globalState.destinationSelection);

	useEffect(() => {
		window.addEventListener('resize', onResize);
		function onResize(event: UIEvent) {
			const screen = event.target as Window;
			renderSize(screen.innerWidth);
		}

		function renderSize(screen: number) {
			setScreenSize(screen);
		}

		renderSize(window.innerWidth);

		return () => {
			window.removeEventListener('resize', onResize);
		};
	}, []);

	useEffect(() => {
		async function getDestinationDetailsUrl() {
			if (user) {
				try {
					const results: any = await destinationService.getFavouriteDestinations();
					setDestination(results);
				} catch (error: any) {
					const message = `Getting error :${error.message} on ${getPageFinder(
						window.location.pathname
					)} page.`;
					undefinedHandler(message);
				}
			}
		}
		getDestinationDetailsUrl();
	}, []);

	const preferencesData = async () => {
		try {
			const preferencesMenuService = await userService.travelPreferencesMenu();
			const userPreferencesService = await userService.userTravelPreferences(user?.id);
			const userFavoriteDestinations = await userService.userFavoriteDestinations(user?.id);

			const preferencesMenu = preferencesMenuService.data.travelPreferences;
			const userPreferences = userPreferencesService.data.myTravelPreferences;
			const FavoriteDestinations = userFavoriteDestinations.data.myFavoriteDestinations;

			if (preferencesMenu !== undefined) {
				setTravelPreferences(preferencesMenu);
			}

			if (FavoriteDestinations === undefined || FavoriteDestinations === null || FavoriteDestinations === '') {
				setDestinationSelection([]);
			} else {
				setDestinationSelection(JSON.parse(FavoriteDestinations));
			}

			if (userPreferences === undefined || userPreferences === null || userPreferences === '') return;
			setUserPreferences(userPreferences);
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	};

	useEffect(() => {
		if (!user) navigate('/page-not-found');
		preferencesData();
	}, [user]);

	const [userPreferences, setUserPreferences] = useState<any>([]);
	const [travelPreferences, setTravelPreferences] = useState<any>([]);

	const displayTravelPreferencesFavouriteDestinations = () => {
		return (
			<div key={5} className="travelpreferences-container animate__animated animate__fadeIn">
				<div className="travelpreferences-body-col">
					<div className="travelpreferences-header-icon">
						<img src={'../../images/accountTravelPreferences/travel5.png'} alt="" />
					</div>
					{/* &amp; */}
					<div className="travelpreferences-body">
						<div className="travelpreferences-header"> Favourite Destinations: </div>

						<div className="travelpreferences-items-container">
							<div className="travelpreferences-items-container-col">
								{destination?.map((id: any, index: any) => {
									return (
										<div
											key={index}
											className={
												destinationSelection && destinationSelection.includes(id.id)
													? 'FavouriteDestinations-items-selected'
													: 'FavouriteDestinations-items'
											}
											onClick={async () => {
												if (!destinationSelection.includes(id.id)) {
													setDestinationSelection((DestinationSelectionList: any) => [
														...DestinationSelectionList,
														id.id
													]);

													try {
														await userService.saveMyFavoriteDestinations(
															JSON.stringify([...destinationSelection, id.id]),
															user?.id
														);
													} catch (error: any) {
														const message = `Getting error :${
															error.message
														} on ${getPageFinder(window.location.pathname)} page.`;
														undefinedHandler(message);
													}
												} else {
													setDestinationSelection(
														destinationSelection.filter((item: any) => item !== id.id)
													);

													try {
														await userService.saveMyFavoriteDestinations(
															JSON.stringify(
																destinationSelection.filter(
																	(item: any) => item !== id.id
																)
															),
															user?.id
														);
													} catch (error: any) {
														const message = `Getting error :${
															error.message
														} on ${getPageFinder(window.location.pathname)} page.`;
														undefinedHandler(message);
													}
												}
											}}
										>
											<span>
												<img
													className="FavouriteDestinations-items-icon"
													src={`../../images/accountTravelPreferences/${
														destinationSelection && destinationSelection.includes(id.name)
															? 'mark.svg'
															: 'plus.svg'
													}`}
													alt=""
												/>
											</span>
											<span>
												<img
													className="FavouriteDestinationsLogo"
													src={get(id, 'logoUrl')}
													alt=""
												/>{' '}
											</span>
											<span className="FavouriteDestinationsHeader">
												{get(id, 'name')}

												{/* <img className="FavouriteDestinationsLogo" src={id.logoUrl} alt="" />{' '} */}
											</span>
										</div>
									);
								})}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
		// });
	};

	let tracker: number;
	let tracker2: number;
	const travelPreferencesHandler = async (category: string, categoryData: string) => {
		tracker = 0;
		tracker2 = 0;

		for (let i = 0; i < userPreferences.length; i++) {
			if (userPreferences[i].travelCategory === category) {
				tracker = 1;
				if (userPreferences[i].selectedPreferences.length === 0) {
					userPreferences[i].selectedPreferences.push(categoryData);
				} else {
					for (let j = 0; j < userPreferences[i].selectedPreferences.length; j++) {
						if (userPreferences[i].selectedPreferences[j] === categoryData) {
							const index = userPreferences[i].selectedPreferences.indexOf(categoryData);
							if (index > -1) {
								tracker2 = 0;
								userPreferences[i].selectedPreferences.splice(index, 1);
								break;
							}
						} else {
							tracker2 = 1;
						}
					}
					if (tracker2 === 1) {
						userPreferences[i].selectedPreferences.push(categoryData);
						break;
					}
				}
			}
		}

		if (tracker !== 1) {
			userPreferences.push({
				travelCategory: category,
				selectedPreferences: [categoryData]
			});
		}

		try {
			await userService.saveMyTravelPreferences(JSON.stringify(userPreferences), user?.id);
			setUserPreferences([...userPreferences]);
		} catch (error: any) {
			rsToastify.error('An error occured. Try again later!');
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	};

	const displayTravelPreferences = () => {
		for (let i = 0; i < travelPreferences.length; i++) {
			for (let j = 0; j < userPreferences.length; j++) {
				if (travelPreferences[i].travelCategory === userPreferences[j].travelCategory) {
					travelPreferences[i].selectedPreferences = userPreferences[j].selectedPreferences;
				}
			}
		}

		return travelPreferences.map((travelPreference: any, index: any) => {
			return (
				<div key={index} className="travelpreferences-container animate__animated animate__fadeIn">
					<div className="travelpreferences-body-col">
						<div className="travelpreferences-header-icon">
							<img src={get(travelPreference, 'travelCategoryIcon')} alt="" />
						</div>
						{/* &amp; */}
						<div className="travelpreferences-body">
							<div className="travelpreferences-header"> {get(travelPreference, 'travelCategory')} </div>

							<div className="travelpreferences-items-container">
								<div className="travelpreferences-items-container-col">
									{get(travelPreference, 'travelPreferences').map((preferencesList: any, i: any) => {
										// console.log(travelPreference['selected-preferences'][i]);
										return (
											<div
												key={i}
												className={
													get(travelPreference, 'selectedPreferences').includes(
														preferencesList
													)
														? 'travelpreferences-items-selected'
														: 'travelpreferences-items'
												}
												onClick={() =>
													travelPreferencesHandler(
														travelPreference.travelCategory,
														preferencesList
													)
												}
											>
												<span>
													<img
														className="travelpreferences-items-icon"
														src={`../../images/accountTravelPreferences/${
															get(travelPreference, 'selectedPreferences').includes(
																preferencesList
															)
																? 'mark.svg'
																: 'plus.svg'
														}`}
														alt=""
													/>{' '}
												</span>
												<span> {preferencesList}</span>
											</div>
										);
									})}
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		});
	};

	return !user ? (
		<LoadingPage />
	) : (
		<Page className={'rsAccountPersonalInfoPage'}>
			<SubNavMenu title={'Travel Preferences'} />
			{screenSize < 596 &&
				travelPreferences &&
				userPreferences &&
				displayTravelPreferencesFavouriteDestinations()}

			{travelPreferences && userPreferences && displayTravelPreferences()}
			{screenSize > 596 &&
				travelPreferences &&
				userPreferences &&
				displayTravelPreferencesFavouriteDestinations()}
		</Page>
	);
};

export default AccountTravelPreferences;
