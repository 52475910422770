import { PopupProps } from '@bit/redsky.framework.rs.996/dist/popup/Popup';
import './NotEnoughPointsPopup.scss';
import * as React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import globalState from '../../state/globalState';
import { Box, Popup, popupController } from '@bit/redsky.framework.rs.996';
import Label from '@bit/redsky.framework.rs.label';
import LabelButton from '../../components/labelButton/LabelButton';
import Paper from '../../components/paper/Paper';
import { useState } from 'react';
import SigninPopup, { SigninPopupProps } from '../signin/SigninPopup';
import { useLocation, useNavigate } from 'react-router-dom';
import { WebUtils } from '../../utils/utils';
import Button from '@bit/redsky.framework.rs.button';
import PackageCard from './packageCard/PackageCard';
import Icon from '@bit/redsky.framework.rs.icon';
import useIsAtBreakpoint from '../../customHooks/useIsAtBreakpoint';
import DestinationService from '../../services/destination/destination.service';
import serviceFactory from '../../services/serviceFactory';
import { getPageFinder, undefinedHandler } from '../../utils/undefinedHandler';

export interface NotEnoughPointsPopupProps extends PopupProps {
	verifiedAccommodation: Api.Reservation.Res.Verification;
	destinationId: number;
	userTempPoints: number;
	addedPackages: Api.UpsellPackage.Res.Complete[];
	removePackage: (id: number, points: number) => void;
}

const NotEnoughPointsPopup: React.FC<NotEnoughPointsPopupProps> = (props) => {
	let navigate = useNavigate();
	const tempdata: any = useLocation().state;
	const destinationService: DestinationService = serviceFactory.get<DestinationService>('DestinationService');

	const fullProps = popupController.convertProps(props);
	const isMobile = useIsAtBreakpoint();
	const [reservationFilters, setReservationFilters] = useRecoilState<Misc.ReservationFilters>(
		globalState.reservationFilters
	);
	const user = useRecoilValue<Api.User.Res.Detail | undefined>(globalState.user);
	const [userTempPoints, setUserTempPoints] = useState<number>(props.userTempPoints);
	const [addedPackages, setAddedPackages] = useState<Api.UpsellPackage.Res.Complete[]>(props.addedPackages);

	function removePackage(id: number) {
		try {
			const item = props.addedPackages.find((item) => item.id === id);
			if (!item) return;
			props.removePackage(id, item.priceDetail.amountPoints);
			setUserTempPoints((prev) => {
				return prev + item.priceDetail.amountPoints;
			});
			setAddedPackages((prev) => prev.filter((item) => item.id !== id));
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	return (
		<Popup {...props} preventCloseByBackgroundClick>
			<Paper className={'rsNotEnoughPointsPopup'}>
				<Box className="popupHeader">
					<Label variant={isMobile ? 'h6' : 'h5'}>Not Enough Points</Label>
				</Box>
				<div className={'rsNotEnoughPoints'}>
					<Label variant={'h6'} mb={22}>
						The packages you have added exceed your available points.
					</Label>
					<Label variant={'h6'}>To continue with your selected packages, convert your booking to cash.</Label>
					<LabelButton
						look={'containedPrimary'}
						variant={'buttonMdLg'}
						label={'View Rates in Dollars'}
						onClick={async () => {
							try {
								setReservationFilters((prev) => {
									return {
										...prev,
										destinationId: props.destinationId,
										redeemPoints: false,
										priceRangeMin: 10,
										priceRangeMax: 1000
									};
								});
								popupController.closeById(fullProps.popupId);

								const results: Api.Destination.Res.PublicSlim[] =
									await destinationService.getPublicSlim();
								const destinationName = results
									.find((result) => parseInt(result?.id.toString()) === props.destinationId)
									?.name.split(' ')
									.join('-')
									.toLocaleLowerCase();

								navigate(`/destination/${destinationName}`, {
									state: {
										...reservationFilters,
										destinationId: props.destinationId
									}
								});
							} catch (error: any) {
								const message = `Getting error :${error.message} on ${getPageFinder(
									window.location.pathname
								)} page.`;
								undefinedHandler(message);
							}

							// navigate(
							// 	`/destination/details${WebUtils.createUrlParams({
							// 		...reservationFilters,
							// 		destinationId: props.destinationId,
							// 		redeemPoints: false,
							// 		priceRangeMin: 10,
							// 		priceRangeMax: 1000
							// 	})}`
							// );
						}}
					/>
					<Box display={'flex'} alignItems={'center'}>
						<div className={'hr'} />
						<Label variant={'h6'} className={'orLabel'}>
							Or
						</Label>
						<div className={'hr'} />
					</Box>
					<Label variant={'h6'} mt={24}>
						To continue booking with points, remove packages until you have enough points to checkout.
					</Label>
					<Box display={'flex'} mt={24}>
						<Label variant={'h6'}> Available Points:&nbsp; </Label>
						<Label variant={'h6'} className={userTempPoints < 0 ? 'red' : 'green'}>
							{userTempPoints < 1 ? '' : '+'}
							{userTempPoints}
						</Label>
					</Box>
					{addedPackages.map((item) => (
						<PackageCard package={item} removePackage={removePackage} />
					))}
					{!user ? (
						<Button
							className={'yellow'}
							look={'containedPrimary'}
							children={<Label variant={'buttonMdLg'}>Sign In</Label>}
							onClick={() => {
								popupController.closeById(fullProps.popupId);
								popupController.open<SigninPopupProps>(SigninPopup, {});
							}}
						/>
					) : (
						<Button
							className={'yellow'}
							look={'containedPrimary'}
							children={<Label variant={'buttonMdLg'}>Continue with Points</Label>}
							disabled={userTempPoints < 0}
							onClick={() => popupController.closeById(fullProps.popupId)}
						/>
					)}
				</div>
			</Paper>
		</Popup>
	);
};

export default NotEnoughPointsPopup;
