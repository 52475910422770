import React, { useState, FC, useEffect, useRef } from 'react';
// import { Box, Popup, popupController, Link } from '@bit/redsky.framework.rs.996';
import './OnboardingFlow.scss';
import useIsAtBreakpoint from '../../customHooks/useIsAtBreakpoint';
import OnboardingFlowTwo from './OnboardingFlowTwo';
import classNames from 'classnames';
import Label from '@bit/redsky.framework.rs.label';
import LabelInput from '../labelInput/LabelInput';
import Icon from '@bit/redsky.framework.rs.icon';
import LabelCheckbox from '../labelCheckbox/LabelCheckbox';
import LabelButton from '../labelButton/LabelButton';
import debounce from 'lodash.debounce';

import { Box, Link, Popup, popupController, PopupProps } from '@bit/redsky.framework.rs.996';
import Paper from '../paper/Paper';
import { RsFormControl, RsFormGroup, RsValidator, RsValidatorEnum } from '@bit/redsky.framework.rs.form';
import serviceFactory from '../../services/serviceFactory';
import PaymentService from '../../services/payment/payment.service';
import { ObjectUtils } from '@bit/redsky.framework.rs.utils';
import SpinningLoaderPopup from '../../popups/spinningLoaderPopup/SpinningLoaderPopup';
import { rsToastify } from '@bit/redsky.framework.rs.toastify';
import ProgressBar from '../progressBar/ProgressBar';

export interface OnboardingFlowOneProps {}

var isCvvLength = 0;
const OnboardingFlowOne: FC<OnboardingFlowOneProps> = (props: OnboardingFlowOneProps) => {
	const fullProps = popupController.convertProps(props);
	const progressbar2 = '../../images/onboardingFlow/progressbar2.svg';
	const card = '../../images/onboardingFlow/card.svg';
	const gift = '../../images/onboardingFlow/gift.svg';
	const money = '../../images/onboardingFlow/money.svg';
	const earn = '../../images/onboardingFlow/earn.svg';
	const secure = '../../images/onboardingFlow/secure.svg';
	const reward = '../../images/onboardingFlow/reward.svg';

	const isMobile = useIsAtBreakpoint();
	const [clickConfirmPointBtn, setClickConfirmPointBtn] = useState<boolean>(false);

	const numberRef = useRef<HTMLElement>(null);
	const cvvRef = useRef<HTMLElement>(null);
	const [isValidForm, setIsValidForm] = useState<boolean>(false);
	const [isValidCard, setIsValidCard] = useState<boolean>(false);
	const [isValidCvv, setIsValidCvv] = useState<boolean>(false);
	const [isAuthorized, setIsAuthorized] = useState<boolean>(false);
	const [existingCardList, setExistingCardList] = useState<Api.User.PaymentMethod[]>([]);
	const [isPrimary, setIsPrimary] = useState<0 | 1>(1);
	const [isFormComplete, setIsFormComplete] = useState<boolean>(false);
	const paymentService = serviceFactory.get<PaymentService>('PaymentService');
	const [creditCardObj, setCreditCardObj] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl('full_name', '', [new RsValidator(RsValidatorEnum.REQ, 'Full name is required')]),
			new RsFormControl('expDate', '', [
				new RsValidator(RsValidatorEnum.REQ, 'Expiration required'),
				new RsValidator(RsValidatorEnum.MIN, 'Expiration too short', 7),
				new RsValidator(RsValidatorEnum.MAX, 'Expiration too long', 7),
				new RsValidator(RsValidatorEnum.CUSTOM, 'Invalid Expiration Date', (control) => {
					let month = parseInt(control.value.toString().slice(0, 2));
					let year = parseInt(control.value.toString().slice(3, 7));
					let currentYear = new Date().getFullYear();
					let currentMonth = new Date().getMonth() + 1;
					if (month > 12) return false;
					if (year === currentYear) return month >= currentMonth;
					else return year > currentYear;
				})
			])
		])
	);

	useEffect(() => {
		setIsFormComplete(isValidCvv && isValidCard && isAuthorized);
	}, [isValidForm, isValidCard, isValidCvv, isAuthorized]);

	useEffect(() => {
		async function init() {
			const gatewayDetails: Api.Payment.Res.PublicData = await paymentService.getGateway();
			window.Spreedly.init(gatewayDetails.publicData.token, {
				numberEl: 'spreedly-number',
				cvvEl: 'spreedly-cvv'
			});
		}
		init().catch(console.error);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	let debounceCvvCardError = debounce(async (element: 'Number' | 'Cvv') => {
		let htmlBlock: HTMLElement | null = document.querySelector(`#${element}`);
		if (!!htmlBlock) htmlBlock.style.color = 'red';
	}, 100);
	let debounceCvvCardSuccess = debounce(async (element: 'Number' | 'Cvv') => {
		let htmlBlock: HTMLElement | null = document.querySelector(`#${element}`);
		if (!!htmlBlock) htmlBlock.style.color = '#001933';
	}, 100);

	useEffect(() => {
		setIsPrimary(ObjectUtils.isArrayWithData(existingCardList) ? 0 : 1);
	}, [existingCardList]);

	useEffect(() => {
		let readyId = paymentService.subscribeToSpreedlyReady(() => {
			if (isMobile) {
				window.Spreedly.setStyle(
					'number',
					'width:100%;font-size: 16px;height: 40px;padding: 0 10px;box-sizing: border-box;border-radius: 0;border: 1px solid #dedede; color: #001933; background-color: #ffffff; transition: border-color 300ms;border-radius: 5px;'
				);
				window.Spreedly.setStyle(
					'cvv',
					'width:100%;font-size: 16px;height: 40px;padding: 0 10px;box-sizing: border-box;border-radius: 0;border: 1px solid #dedede; color: #001933; background-color: #ffffff; text-align: center; transition: border-color 300ms;border-radius: 5px; '
				);
			} else {
				window.Spreedly.setStyle(
					'number',
					'width:100%;font-size: 16px;height: 40px;padding: 0 10px;box-sizing: border-box;border-radius: 0;border: 1px solid #dedede; color: #001933; background-color: #ffffff; transition: border-color 300ms;border-radius: 5px; '
				);
				window.Spreedly.setStyle(
					'cvv',
					'width:100%;font-size: 16px;height: 40px;padding: 0 10px;box-sizing: border-box;border-radius: 0;border: 1px solid #dedede; color: #001933; background-color: #ffffff; text-align: center; transition: border-color 300ms;border-radius: 5px; '
				);
			}
			window.Spreedly.setFieldType('number', 'text');
			window.Spreedly.setNumberFormat('prettyFormat');
		});

		let fieldEventId = paymentService.subscribeToSpreedlyFieldEvent(
			(
				name: 'number' | 'cvv',
				type: 'focus' | 'blur' | 'mouseover' | 'mouseout' | 'input' | 'enter' | 'escape' | 'tab' | 'shiftTab',
				activeEl: 'number' | 'cvv',
				inputProperties: {
					cardType?: string;
					validNumber?: boolean;
					validCvv?: boolean;
					numberLength?: number;
					cvvLength?: number;
				}
			) => {
				if (name === 'number') {
					if (type === 'focus') {
						window.Spreedly.setStyle('number', 'border: 1px solid #004b98;');
					}
					if (type === 'blur') {
						window.Spreedly.setStyle('number', 'border: 1px solid #dedede;');
					}
					if (type === 'mouseover') {
						window.Spreedly.setStyle('number', 'border: 1px solid #004b98;');
					}
					if (type === 'mouseout') {
						window.Spreedly.setStyle('number', 'border: 1px solid #dedede;');
					}

					if (type === 'input' && !inputProperties.validNumber) {
						debounceCvvCardError('Number');
						setIsValidCard(false);
					} else if (type === 'input' && inputProperties.validNumber) {
						debounceCvvCardSuccess('Number');
						setIsValidCard(true);
					}
				}
				if (name === 'cvv') {
					if (inputProperties.cvvLength) isCvvLength = inputProperties.cvvLength;
					if (type === 'focus') {
						window.Spreedly.setStyle('cvv', 'border: 1px solid #004b98;');
					}
					if (type === 'blur') {
						window.Spreedly.setStyle('cvv', 'border: 1px solid #dedede;');
					}
					if (type === 'mouseover') {
						window.Spreedly.setStyle('cvv', 'border: 1px solid #004b98;');
					}
					if (type === 'mouseout') {
						window.Spreedly.setStyle('cvv', 'border: 1px solid #dedede;');
					}
					if (type === 'input' && isCvvLength !== 3 && isCvvLength !== 4) {
						debounceCvvCardError('Cvv');
						setIsValidCvv(false);
					} else if (type === 'input' && (isCvvLength === 3 || isCvvLength === 4)) {
						debounceCvvCardSuccess('Cvv');
						setIsValidCvv(true);
					}
				}
			}
		);

		// Error response codes
		// https://docs.spreedly.com/reference/api/v1/#response-codes
		let errorId = paymentService.subscribeToSpreedlyError((errorMsg) => {
			let errorMessages = errorMsg.map((item) => {
				return item.message;
			});

			popupController.close(SpinningLoaderPopup);
			return rsToastify.error(errorMessages.join(' '), 'Error!');
		});

		let paymentMethodId = paymentService.subscribeToSpreedlyPaymentMethod(
			async (token: string, pmData: Api.Payment.PmData) => {
				if (!pmData?.verification_value) {
					debounceCvvCardError('Cvv');
					return;
				}
				let data: Api.Payment.Req.Create = {
					cardToken: token,
					pmData: pmData,
					isPrimary: isPrimary,
					offsiteLoyaltyEnrollment: isAuthorized ? 1 : 0
				};

				try {
					const result = await paymentService.addPaymentMethod(data);
					if (result) rsToastify.success('Card successfully added.', 'Card Added!');
					skipOnboarding();
					window.Spreedly.reload();
					creditCardObj.resetToInitialValue();
					let newExistingCardList = [
						...existingCardList,
						{
							id: result.id,
							userAddressId: result.userAddressId,
							nameOnCard: result.nameOnCard,
							type: result.type,
							last4: result.last4,
							expirationMonth: result.expirationMonth,
							expirationYear: result.expirationYear,
							cardNumber: result.cardNumber,
							isPrimary: result.isPrimary,
							createdOn: result.createdOn,
							systemProvider: result.systemProvider
						}
					];
					if (result.isPrimary) {
						newExistingCardList = newExistingCardList.map((item) => {
							return { ...item, isPrimary: item.id === result.id ? 1 : 0 };
						});
					}
					newExistingCardList.sort((card1, card2) => card2.isPrimary - card1.isPrimary);
					setExistingCardList(newExistingCardList);
					popupController.close(SpinningLoaderPopup);
				} catch (e: any) {
					console.error(e);
					e.response.data &&
						rsToastify.error(
							typeof e.response.data.msg == 'string' ? e.response.data.msg : 'Something went wrong.',
							e.response.data.err
						);
					popupController.close(SpinningLoaderPopup);
				}
			}
		);

		return () => {
			paymentService.unsubscribeToSpreedlyPaymentMethod(paymentMethodId);
			paymentService.unsubscribeToSpreedlyReady(readyId);
			paymentService.unsubscribeToSpreedlyFieldEvent(fieldEventId);
			paymentService.unsubscribeToSpreedlyError(errorId);
		};
	}, [existingCardList, isAuthorized]);

	function isFormFilledOut(): boolean {
		return (
			!!creditCardObj.get('full_name').value.toString().length &&
			!!creditCardObj.get('expDate').value.toString().length
		);
	}

	async function updateCreditCardObj(control: RsFormControl) {
		if (
			control.key === 'expDate' &&
			!control.value.toString().includes('/') &&
			control.value.toString().length === 4
		) {
			control.value = control.value.toString().slice(0, 2) + '/' + control.value.toString().slice(2, 4);
		}
		if (control.key === 'expDate') {
			const digitsOnly = /[^\d\/]/g;
			control.value = control.value.toString().replace(digitsOnly, '');
		}
		creditCardObj.update(control);
		let isFormValid = await creditCardObj.isValid();
		setIsValidForm(isFormFilledOut() && isFormValid);
		setCreditCardObj(creditCardObj.clone());
	}

	async function save() {
		let newCreditCardObj: any = creditCardObj.toModel();
		newCreditCardObj.month = parseInt(newCreditCardObj.expDate.split('/')[0]);
		newCreditCardObj.year = parseInt(newCreditCardObj.expDate.split('/')[1]);
		newCreditCardObj.isPrimary = isPrimary;
		delete newCreditCardObj.expDate;
		popupController.open(SpinningLoaderPopup);
		window.Spreedly.tokenizeCreditCard(newCreditCardObj);
		setIsFormComplete(false);
	}
	const openNextOnboardingFlow = () => {
		// alert('next onboardomg');
		popupController.close(OnboardingFlowOne);
		popupController.open(OnboardingFlowTwo);
	};

	const skipOnboarding = () => {
		popupController.close(OnboardingFlowOne);
	};

	return (
		<Popup {...props} preventCloseByBackgroundClick>
			<Paper className={'rsOnboardingFlow'}>
				<Box className={'title'}>
					<Label variant={isMobile ? 'h5' : 'h3'} className={'primaryTextColor'}>
						Almost there!
					</Label>
					<ProgressBar totalProgressBar={2} currentStep={2} />
				</Box>
				<Box className={'rsOnboardingFlowBody'}>
					<Box className="bodyInfo">
						Register your card, and every time you use it, you’ll earn points at Rentyl Resorts and many
						other participating Spire Loyalty locations.
					</Box>
					<div className="onboardingflow-body2__biggercircle-container">
						<div className="onboardingflow-body2__biggercircle1 onboarding-colordark">
							<img src={card} className="onboardingflow-body2__biggercircle1img" alt="Add Card" />
							<div className="onboardingflow-body2__biggercircle1label">
								{' '}
								Add your <br />
								credit or debit <br />
								card.{' '}
							</div>
						</div>

						<div className="onboardingflow-body2__biggercircle1 onboarding-colorblue">
							<img src={gift} className="onboardingflow-body2__biggercircle1img" alt="Add Card" />
							<div className="onboardingflow-body2__biggercircle1label"> Buy what you already love. </div>
						</div>

						<div className="onboardingflow-body2__biggercircle1 onboarding-colorgreen">
							<img src={money} className="onboardingflow-body2__biggercircle1img" alt="Add Card" />
							<div className="onboardingflow-body2__biggercircle1label">
								{' '}
								Spire pays
								<br /> you back <br />
								fast!.{' '}
							</div>
						</div>
					</div>
					<Box className="main">
						{/* <Box> */}
						<Box>
							<div
								className="onboardingflow-body2-flow-2"
								style={{ marginTop: '0px', marginLeft: '0px' }}
							>
								<Box className={classNames('newPaymentForm', { boxShadow: isMobile })}>
									<div>
										<form id={'payment-form'} action={'/card-payment'}>
											<div ref={numberRef} id={'spreedly-number'}>
												<Label id={'Number'} variant={'h6'} mb={10}>
													<span className="requiredAsterisk">*</span>Card Number
												</Label>
											</div>
											<LabelInput
												labelVariant={'h6'}
												title={'Name on Card'}
												inputType={'text'}
												control={creditCardObj.get('full_name')}
												updateControl={updateCreditCardObj}
												isRequired
											/>
											<div className={'expDateAndCVV'}>
												<LabelInput
													labelVariant={'h6'}
													className={'creditCardExpInput'}
													maxLength={7}
													title={'Expiration'}
													inputType={'text'}
													control={creditCardObj.get('expDate')}
													updateControl={updateCreditCardObj}
													placeholder={'MM/YYYY'}
													isRequired
												/>
												<div ref={cvvRef} id={'spreedly-cvv'}>
													<Box display={'flex'} alignItems={'center'} mb={8}>
														<Label id={'Cvv'} variant={'h6'} mr={8}>
															<span className="requiredAsterisk">*</span>CVV
														</Label>
														<Box className={'questionMarkWrapper'}>
															<Icon
																iconImg={'icon-solid-question-circle'}
																cursorPointer
																size={16}
															/>
															<div className={'compareToolTip'}>
																<div className={'toolTipTriangle'} />
																<Label className={'caption'}>
																	3-digit security code on back of card
																</Label>
															</div>
														</Box>
													</Box>
												</div>
											</div>
										</form>

										<LabelCheckbox
											className="checkBox"
											value={1}
											labelVariant={'h10'}
											text={
												<React.Fragment>
													* By submitting your card information and checking this box, you
													authorize your payment card network to monitor and share transaction
													data with our service provider to participate in the Spire Loyalty
													program. You also acknowledge and agree that our service provider
													may share certain details of your qualifying transactions with Spire
													Loyalty to enable your participation in the Spire Loyalty program
													and for other purposes in accordance with the{' '}
													<Link
														path={`/legal/terms-and-conditions`}
														external
														target={'blank'}
													>
														<span> Spire Loyalty Terms and Conditions</span>
													</Link>
													,{' '}
													<Link path={'/legal/privacy'} external target={'blank'}>
														<span>Spire Loyalty privacy policy</span>
													</Link>{' '}
													and{' '}
													<Link
														path={'https://fidel.uk/legal/privacy/'}
														external
														target={'blank'}
													>
														<span>Fidel’s Privacy Policy</span>
													</Link>
													. You may opt-out of transaction monitoring on the linked card at
													any time by emailing support@spireloyalty.com or calling (321) 888 –
													3509.
												</React.Fragment>
											}
											isChecked={false}
											onSelect={() => {
												setIsAuthorized(true);
											}}
											onDeselect={() => {
												setIsAuthorized(false);
											}}
											lineClamp={isMobile ? 269 : 13}
										/>

										<div className={'primaryCheckboxAndBtn'}>
											<LabelButton
												look={'containedPrimary'}
												variant={'buttonMdLg'}
												label={'Register My Card'}
												disabled={!isFormComplete}
												onClick={() => {
													save();
												}}
											/>
										</div>

										<br />
									</div>
								</Box>
							</div>
						</Box>

						{/* </Box> */}
						{/* <Box className="rightInfo">
							<Label>More information</Label>
							<Box>
								<Box className="moreDetails">
									<img className="earnIcon icon" src={earn} alt="" />
									<Box className="imageInfo">
										Register your card and every time you use it at a participating Spire location,
										from resorts to restaurants to shopping, you’ll earn points.
									</Box>
								</Box>
								<Box className="moreDetails">
									<img className="secureIcon icon" src={secure} alt="" />
									<Box className="imageInfo">
										Register your card and every time you use it at a participating Spire location,
										from resorts to restaurants to shopping, you’ll earn points.
									</Box>
								</Box>
								<Box className="moreDetails">
									<img className="rewardIcon icon" src={reward} alt="" />
									<Box className="imageInfo">
										Register your card and every time you use it at a participating Spire location,
										from resorts to restaurants to shopping, you’ll earn points.
									</Box>
								</Box>
							</Box>
						</Box> */}
					</Box>

					<div className="onboardingflow-footer">
						<div
							className="onboardingflow-footer__textlabel"
							onClick={skipOnboarding}
							style={{ paddingBottom: '0px', paddingLeft: '0px' }}
						>
							Skip this step
						</div>
					</div>
				</Box>
			</Paper>
		</Popup>
	);

	// return (
	// <Popup {...props} preventCloseByBackgroundClick>
	// 		<div className="onboardingflow-container">
	// 			<div className="onboardingflow-header">
	// 				<div className="onboardingflow-header__label">Almost there!</div>
	// 				<div className="onboarding-progressbar-container">
	// 					<img src={progressbar2} className="onboarding-progressbar" alt="progressbar2" />
	// 					<span className="onboarding-progressbar-title">2/3</span>
	// 				</div>
	// 			</div>

	// 			<div className="onboardingflow-body">
	// 				<div className="onboardingflow-body__toptextlabel">
	// 					To keep earning points, simply register your existing credit or debit card. Rentyl Rewards is
	// 					part of the Spire Loyalty family so each time you use your registered card at a Rentyl Resorts
	// 					property, or at any of the participating Spire Loyalty partners you’ll be earning points towards
	// 					your next resort stay, or rewards with our growing number of merchants…..
	// 				</div>

	// 				<div className="onboardingflow-body-container">
	// 					<div className="onboardingflow-body2">
	// 						<div className="onboardingflow-body2__biggercircle-container">
	// 							<div className="onboardingflow-body2__biggercircle1 onboarding-colordark">
	// 								<img src={card} className="onboardingflow-body2__biggercircle1img" alt="Add Card" />
	// 								<div className="onboardingflow-body2__biggercircle1label">
	// 									{' '}
	// 									Add your <br />
	// 									credit or debit <br />
	// 									card.{' '}
	// 								</div>
	// 							</div>

	// 							<div className="onboardingflow-body2__biggercircle1 onboarding-colorblue">
	// 								<img src={gift} className="onboardingflow-body2__biggercircle1img" alt="Add Card" />
	// 								<div className="onboardingflow-body2__biggercircle1label">
	// 									{' '}
	// 									Buy what you already love.{' '}
	// 								</div>
	// 							</div>

	// 							<div className="onboardingflow-body2__biggercircle1 onboarding-colorgreen">
	// 								<img
	// 									src={money}
	// 									className="onboardingflow-body2__biggercircle1img"
	// 									alt="Add Card"
	// 								/>
	// 								<div className="onboardingflow-body2__biggercircle1label">
	// 									{' '}
	// 									Spire pays
	// 									<br /> you back <br />
	// 									fast!.{' '}
	// 								</div>
	// 							</div>
	// 						</div>

	// 						{/* <OnboardingFlowTwo /> */}

	// 						<div className="onboardingflow-body2-flow-2" style={{margin:"0px"}}>
	// 							<Box className={classNames('newPaymentForm', { boxShadow: isMobile })}>
	// 								<div>
	// 									<form id={'payment-form'} action={'/card-payment'}>
	// 										<div ref={numberRef} id={'spreedly-number'}>
	// 											<Label id={'Number'} variant={'h6'} mb={10}>
	// 												<span className="requiredAsterisk">*</span>Card Number
	// 											</Label>
	// 										</div>
	// 										<LabelInput
	// 											labelVariant={'h6'}
	// 											title={'Name on Card'}
	// 											inputType={'text'}
	// 											// control={creditCardObj.get('full_name')}
	// 											// updateControl={updateCreditCardObj}
	// 											isRequired
	// 										/>
	// 										<div className={'expDateAndCVV'}>
	// 											<LabelInput
	// 												labelVariant={'h6'}
	// 												className={'creditCardExpInput'}
	// 												maxLength={7}
	// 												title={'Expiration'}
	// 												inputType={'text'}
	// 												// control={creditCardObj.get('expDate')}
	// 												// updateControl={updateCreditCardObj}
	// 												placeholder={'MM/YYYY'}
	// 												isRequired
	// 											/>
	// 											<div ref={cvvRef} id={'spreedly-cvv'}>
	// 												<Box display={'flex'} alignItems={'center'} mb={8}>
	// 													<Label id={'Cvv'} variant={'h6'} mr={8}>
	// 														<span className="requiredAsterisk">*</span>CVV
	// 													</Label>
	// 													<Box className={'questionMarkWrapper'}>
	// 														<Icon
	// 															iconImg={'icon-solid-question-circle'}
	// 															cursorPointer
	// 															size={16}
	// 														/>
	// 														<div className={'compareToolTip'}>
	// 															<div className={'toolTipTriangle'} />
	// 															<Label className={'caption'}>
	// 																3-digit security code on back of card
	// 															</Label>
	// 														</div>
	// 													</Box>
	// 												</Box>
	// 											</div>
	// 										</div>
	// 									</form>

	// 									<LabelCheckbox
	// 										value={1}
	// 										labelVariant={'h10'}
	// 										text={
	// 											<React.Fragment>
	// 												* By submitting your card information and checking this box, you
	// 												authorize your payment card network to monitor and share transaction
	// 												data with our service provider to participate in the Spire Loyalty
	// 												program. You also acknowledge and agree that our service provider
	// 												may share certain details of your qualifying transactions with Spire
	// 												Loyalty to enable your participation in the Spire Loyalty program
	// 												and for other purposes in accordance with the{' '}
	// 												<Link
	// 													path={`/legal/terms-and-conditions`}
	// 													external
	// 													target={'blank'}
	// 												>
	// 													<span> Spire Loyalty Terms and Conditions</span>
	// 												</Link>
	// 												,{' '}
	// 												<Link path={'/legal/privacy'} external target={'blank'}>
	// 													<span>Spire Loyalty privacy policy</span>
	// 												</Link>{' '}
	// 												and{' '}
	// 												<Link
	// 													path={'https://fidel.uk/legal/privacy/'}
	// 													external
	// 													target={'blank'}
	// 												>
	// 													<span>Fidel’s Privacy Policy</span>
	// 												</Link>
	// 												. You may opt-out of transaction monitoring on the linked card at
	// 												any time by emailing support@spireloyalty.com or calling (321) 888 –
	// 												3509.
	// 											</React.Fragment>
	// 										}
	// 										isChecked={false}
	// 										// onSelect={() => {
	// 										// 	setIsAuthorized(true);
	// 										// }}
	// 										// onDeselect={() => {
	// 										// 	setIsAuthorized(false);
	// 										// }}
	// 										onSelect={() => {}}
	// 										onDeselect={() => {}}
	// 										lineClamp={isMobile ? 269 : 13}
	// 									/>

	// 									<div className={'primaryCheckboxAndBtn'}>
	// 										<LabelButton
	// 											look={'containedPrimary'}
	// 											variant={'buttonMdLg'}
	// 											label={'Register My Card'}
	// 											// disabled={!isFormComplete}
	// 											// onClick={() => {
	// 											// 	save();
	// 											// }}
	// 										/>
	// 									</div>

	// 									<br />
	// 								</div>
	// 							</Box>
	// 						</div>

	// 						<button className="onboardingflow-body2-btn" onClick={openNextOnboardingFlow}>
	// 							Got it. Let’s Start Earning Points
	// 						</button>
	// 					</div>

	// 					<div className="onboardingflow-body-moreinfo">
	// 						<div className="onboardingflow-body-moreinfo__category1">
	// 							<div className="onboardingflow-body-moreinfo__head">More information</div>

	// 							<div className="onboardingflow-body-moreinfo-col">
	// 								<div className="onboardingflow-body-moreinfo__category-circle-s onboarding-colordark">
	// 									<img className="categoryicon" src={earn} alt="" />
	// 								</div>
	// 								<div className="onboardingflow-body-moreinfo__labeltext onboardingflow-body-moreinfo__labeltext1">
	// 									Register your card and every time you use it at a participating Spire location,
	// 									from resorts to restaurants to shopping, you’ll earn points.
	// 								</div>
	// 							</div>
	// 						</div>

	// 						<div className="onboardingflow-body-moreinfo__category2">
	// 							<div className="onboardingflow-body-moreinfo-col">
	// 								<div className="onboardingflow-body-moreinfo__category-circle onboarding-colororange">
	// 									<img className="categoryicon" src={secure} alt="" />
	// 								</div>
	// 								<div className="onboardingflow-body-moreinfo__labeltext onboardingflow-body-moreinfo__labeltext2">
	// 									Your card is securely stored and never charged by Spire. It’s safe.
	// 								</div>
	// 							</div>
	// 						</div>

	// 						<div className="onboardingflow-body-moreinfo__category3">
	// 							<div className="onboardingflow-body-moreinfo-col">
	// 								<div className="onboardingflow-body-moreinfo__category-circle onboarding-colorgreen">
	// 									<img className="categoryicon" src={reward} alt="" />
	// 								</div>
	// 								<div className="onboardingflow-body-moreinfo__labeltext onboardingflow-body-moreinfo__labeltext3">
	// 									Points and rewards vary by location, but one thing is consistent...great deals!
	// 								</div>
	// 							</div>
	// 						</div>
	// 					</div>
	// 				</div>
	// 			</div>

	// 			<div className="onboardingflow-footer">
	// 				<div className="onboardingflow-footer__textlabel" onClick={skipOnboarding}>
	// 					Skip this step
	// 				</div>
	// 			</div>
	// 		</div>
	// 	</Popup>
	// );
};

export default OnboardingFlowOne;
