import * as React from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import './DestinationDetailsPage.scss';
import { Page, popupController } from '@bit/redsky.framework.rs.996';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import serviceFactory from '../../services/serviceFactory';
import PackageService from '../../services/package/package.service';
import DestinationService from '../../services/destination/destination.service';
import LoadingPage from '../loadingPage/LoadingPage';
import Box from '@bit/redsky.framework.rs.996/dist/box/Box';
import Label from '@bit/redsky.framework.rs.label';
import { ObjectUtils } from '@bit/redsky.framework.rs.utils';
import { StringUtils, WebUtils } from '../../utils/utils';
import FilterBarLimited from '../../components/filterBar/FilterBarLimited';
import AccommodationService from '../../services/accommodation/accommodation.service';
import { useRecoilState, useRecoilValue } from 'recoil';
import globalState, { setRecoilExternalValue } from '../../state/globalState';
import { rsToastify } from '@bit/redsky.framework.rs.toastify';
import PaginationViewMore from '../../components/paginationViewMore/PaginationViewMore';
import SubNavMenu from '../../components/subNavMenu/SubNavMenu';
import DestinationImageGallery from '../../components/destinationImageGallery/DestinationImageGallery';
import LightBoxCarouselPopup, {
	TabbedCarouselPopupProps
} from '../../popups/lightBoxCarouselPopup/LightBoxCarouselPopup';
import DestinationExperienceImageGallery from '../../components/destinationExperienceImageGallery/DestinationExperienceImageGallery';
import Icon from '@bit/redsky.framework.rs.icon';
import SpinningLoader from '../../components/loader/Loader';
import AccommodationSearchCard from '../../components/accommodationSearchCardV2/AccommodationSearchCard';
import AccommodationOverviewPopup, {
	AccommodationOverviewPopupProps
} from '../../popups/accommodationOverviewPopup/AccommodationOverviewPopup';
import router from '../../utils/router';
import LabelButton from '../../components/labelButton/LabelButton';
import DestinationDetailsMobilePopup, {
	DestinationDetailsMobilePopupProps
} from '../../popups/destinationDetailsMobilePopup/DestinationDetailsMobilePopup';
import IconLabel from '../../components/iconLabel/IconLabel';
import RsPagedResponseData = RedSky.RsPagedResponseData;
import { Wrapper, Status } from '@googlemaps/react-wrapper';
import Map from '../../components/googleMap/gMap';
import MapModal from '../../components/googleMap/components/mapModal/mapModal';
import useIsAtBreakpoint from '../../customHooks/useIsAtBreakpoint';
import useGetCountryList from '../../customHooks/useGetCountryList';
import MetaPixelTag from '../../components/metaPixelTag/MetaPixelTag';
import ImageLabel from '../../components/imageLabel/ImageLabel';
import useFlipTo from '../../customHooks/useFlipTo';
import { Rating } from 'react-simple-star-rating';
import ReviewPopup, { ReviewPopupProps } from '../../popups/reviewPopup/ReviewPopup';
import UserService from '../../services/user/user.service';
import HeartLike from '../../components/heartLike/HeartLike';
import { getPageFinder, undefinedHandler } from '../../utils/undefinedHandler';
import SimilarChoiceRecommendation from '../../components/similarChoiceRecommendation/similarChoiceRecommendation';
import moment from 'moment';
import FlexibleRates from '../../components/flexibleRates/FlexibleRates';
import CallToActionPopup, { CallToActionPopupProps } from '../../popups/callToActionPopup/CallToActionPopup';
import { access } from 'fs';
import { geoCoderApiKey } from '../../utils/constant';

interface ObserverAttributes extends NamedNodeMap {
	datasection: { value: 'gallery' | 'overview' | 'experiences' | 'availableStays' };
}

const DestinationDetailsPage: React.FC = () => {
	const navigate = useNavigate();
	const tempdata: any = useLocation();
	const lastUrlPath = useRecoilValue<string>(globalState.lastNavigationPath);
	const galleryRef = useRef<HTMLElement>(null);
	const overviewRef = useRef<HTMLElement>(null);
	const experiencesRef = useRef<HTMLElement>(null);
	const availableStaysRef = useRef<HTMLElement>(null);
	const [activeViewSection, setActiveViewSection] = useState<
		'gallery' | 'overview' | 'experiences' | 'availableStays'
	>('gallery');
	const [reservationFilters, setReservationFilters] = useRecoilState<Misc.ReservationFilters>(
		globalState.reservationFilters
	);

	const imgOptions = {
		rootmargin: '-10% 0px -80% 0px',
		threshold: 0
	};
	const isMobile = useIsAtBreakpoint();
	const countryList = useGetCountryList();
	const packageService = serviceFactory.get<PackageService>('PackageService');
	const destinationService = serviceFactory.get<DestinationService>('DestinationService');
	const accommodationService = serviceFactory.get<AccommodationService>('AccommodationService');
	const userService = serviceFactory.get<UserService>('UserService');

	const [destinationTempId, setDestinationTempId] = useState<any>();

	const params = router.getPageUrlParams<{
		destinationId: number;
		startDate?: string | any;
		endDate?: string | any;
		guests: number;
	}>([
		//Note: don't change the key 'di', because we have a data contract with a 3rd party if the key 'di' is changed it is going to break their architecture.
		{ key: 'di', default: 0, type: 'integer', alias: 'destinationId' },
		{
			key: 'startDate',
			default: reservationFilters.startDate.toString() || '',
			type: 'string',
			alias: 'startDate'
		},
		{ key: 'endDate', default: reservationFilters.endDate.toString() || '', type: 'string', alias: 'endDate' },
		{ key: 'guests', default: reservationFilters.adultCount || 1, type: 'integer', alias: 'guests' }
	]);

	const [resortPackagesCount, setResortPackagesCount] = useState<number | any>(0);
	const [destinationDetails, setDestinationDetails] = useState<Api.Destination.Res.Details>();
	const [availabilityStayList, setAvailabilityStayList] = useState<Api.Accommodation.Res.Availability[]>([]);
	const [availabilityPointsData, setAvailabilityPointsData] = useState<any>();
	const [minStay, setMinStay] = useState<number>();
	const [availabilityPointsPrise, setAvailabilityPointsPrise] = useState<any>();
	const [isFetchingResults, setIsFetchingResults] = useState<boolean>(true);
	const [minPricePoints, setMinPricePoints] = useState<number>(0);
	const [minPriceCents, setMinPriceCents] = useState<number>(0);
	const [totalResults, setTotalResults] = useState<number>(0);
	const [currencyCode, setCurrencyCode] = useState<string>('$');
	const [loyaltyStatus, setLoyaltyStatus] = useState<Model.LoyaltyStatus>('PENDING');
	const [page, setPage] = useState<number>(1);
	const [isOtherFilterPopupOpen, setIsOtherFilterPopupOpen] = useState<boolean>(false);
	const [showMapModal, setShowMapModal] = useState<boolean>(false);
	const [pointPerDollar, setPointPerDollar] = useState<number>(0);
	const [searchParams, setSearchParams] = useSearchParams();
	const perPage = 10;
	const GOOGLE_MAPS_API = 'AIzaSyCXwUAf8nshLghmDZkmkBcxgpqC0r18d5s';
	const [center, setCenter] = React.useState<google.maps.LatLngLiteral>();
	const GOOGLE_MAP_ZOOM = 16;
	const [user, setUser] = useRecoilState<Api.User.Res.Detail | undefined>(globalState.user);

	const airport = '../../images/AirPort.svg';
	const location = '../../images/Location.svg';
	const poiToHide: google.maps.MapTypeStyle[] = [
		{
			featureType: 'poi',
			stylers: [{ visibility: 'off' }]
		}
	];

	useEffect(() => {
		window.scrollTo(0, 0);
		getPlatformVariables();
		setReservationFilters((prev) => {
			// return { ...prev, destinationId: undefined };
			return prev;
		});
	}, []);

	useEffect(() => {
		if (isMobile) {
			// const urlParams = WebUtils.createUrlParams(reservationFilters);
			// navigate(`${urlParams}`);
			async function getDestinationDetailsUrl(name: string) {
				try {
					const results: Api.Destination.Res.PublicSlim[] = await destinationService.getPublicSlim();
					const conditionCheck: any = [];

					const data: any = results.map((el) => {
						if (el?.name?.toLocaleLowerCase() == name) {
							popupController.open<DestinationDetailsMobilePopupProps>(DestinationDetailsMobilePopup, {
								pointPerDollar: pointPerDollar,
								destinationId: el.id,
								resortPoint: availabilityPointsData,
								resortPointsPrice: availabilityPointsPrise,
								startDate: reservationFilters.startDate as string,
								endDate: reservationFilters.endDate as string
							});
							conditionCheck.push(el);
							setDestinationTempId(el.id);
						}
					});

					if (conditionCheck && conditionCheck?.length === 0) {
						const e = '';
						rsToastify.error(
							WebUtils.getRsErrorMessage(e, 'Cannot get details for this destination.'),
							'Server Error ',
							{
								onClose: () => {
									navigate('/');
								}
							}
						);
					}
				} catch (error: any) {
					const message = `Getting error :${error.message} on ${getPageFinder(
						window.location.pathname
					)} page.`;
					undefinedHandler(message);
				}
			}

			try {
				if (!tempdata.state) {
					getDestinationDetailsUrl(tempdata.pathname.split('/')[2].split('-').join(' '));
				} else {
					popupController.open<DestinationDetailsMobilePopupProps>(DestinationDetailsMobilePopup, {
						pointPerDollar: pointPerDollar,
						destinationId: tempdata.state.destinationId,
						resortPoint: availabilityPointsData,
						resortPointsPrice: availabilityPointsPrise,
						startDate: reservationFilters.startDate as string,
						endDate: reservationFilters.endDate as string
					});
				}
			} catch (error: any) {
				const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
				undefinedHandler(message);
			}
		}
	}, [isMobile]);

	useEffect(() => {
		!!destinationDetails &&
			reservationFilters.destinationId != destinationDetails.id &&
			setReservationFilters({
				...reservationFilters,
				destinationId: destinationDetails.id
			});
		const sectionObserver = new IntersectionObserver((entries, observer) => {
			entries.forEach((entry) => {
				if (!entry.isIntersecting) {
					return;
				} else {
					let attributes = entry.target.attributes as ObserverAttributes;
					setActiveViewSection(attributes.datasection.value);
				}
			});
		}, imgOptions);

		let section = document.querySelectorAll('.observer');

		section.forEach((item) => {
			sectionObserver.observe(item);
		});

		return () => {
			section.forEach((item) => {
				sectionObserver.unobserve(item);
			});
		};
	}, [destinationDetails]);

	const calculateHighestPointEarnable = (resultData: any) => {
		try {
			const points: number[] = [];
			resultData.data.map((datum: any) => {
				points.push(datum.pointsEarnable);
			});

			const MaxPointEarnable = Math.max(...points);
			setAvailabilityPointsData(MaxPointEarnable);
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	};

	async function getDestinationDetails(id: number, data?: any) {
		// if (isMobile) return;
		try {
			const dest = await destinationService.getDestinationDetails({
				destinationId: id,
				startDate: data && data.startDate ? data.startDate : reservationFilters.startDate,
				endDate: data && data.endDate ? data.endDate : reservationFilters.endDate
			});

			setDestinationDetails(dest);

			/* Flip.to Integration. Don't delete the code */
			useFlipTo.setFlipInit(dest);

			// setRecoilExternalValue<Misc.ReservationFilters>(globalState.reservationFilters, {
			// 	...reservationFilters,
			// 	destinationId: dest.id,
			// 	flipToregionIds: dest.regions
			// });
			setDestinationTempId(id);
			const request: Api.UpsellPackage.Req.Availability = {
				destinationId: id,
				startDate: data && data.startDate ? data.startDate : reservationFilters.startDate,
				endDate: data && data.endDate ? data.endDate : reservationFilters.endDate
			};
			const responsePackages = await packageService.getAvailable(request);
			setResortPackagesCount(responsePackages.total);
			setCenter({
				lat: dest.latitude as number,
				lng: dest.longitude as number
			});
			setLoyaltyStatus(dest.loyaltyStatus);
		} catch (e: any) {
			rsToastify.error(
				WebUtils.getRsErrorMessage(e, 'Cannot get details for this destination.'),
				'Server Error ',
				{
					onClose: () => {
						navigate(-1);
					}
				}
			);
			const message = `Getting error :${e.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	async function getDestinationDetailsUrl(name: string) {
		try {
			const results: Api.Destination.Res.PublicSlim[] = await destinationService.getPublicSlim();
			const conditionCheck: any = [];

			const data: any = results.map((el) => {
				if (el && el.name) {
					if (el.name.toLocaleLowerCase() === name) {
						getDestinationDetails(el.id).catch(console.error);
						conditionCheck.push(el);
						setDestinationTempId(el.id);
					}
				}
			});

			if (conditionCheck && conditionCheck?.length === 0) {
				const e = '';
				rsToastify.error(
					WebUtils.getRsErrorMessage(e, 'Cannot get details for this destination.'),
					'Server Error ',
					{
						onClose: () => {
							navigate('/');
						}
					}
				);
			}
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	useLayoutEffect(() => {
		try {
			if (params && params?.destinationId) {
				getDestinationDetails(params.destinationId, params).catch(console.error);
			} else {
				if (tempdata.pathname.includes('/destination/')) {
					if (!tempdata.state) {
						getDestinationDetailsUrl(tempdata.pathname.split('/')[2].split('-').join(' '));
					} else {
						getDestinationDetails(tempdata.state.destinationId).catch(console.error);
					}
				}
			}
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}, []);

	useEffect(() => {
		reservationFilters &&
			(async () => {
				if (!!destinationTempId && reservationFilters.destinationId != destinationTempId) {
					if (params && params?.destinationId) {
						getDestinationDetails(params.destinationId, params).catch(console.error);
					} else {
						if (tempdata.pathname.includes('/destination/')) {
							if (
								!!destinationDetails &&
								!tempdata.state &&
								reservationFilters.destinationId != destinationDetails.id
							) {
								getDestinationDetailsUrl(tempdata.pathname.split('/')[2].split('-').join(' '));
							} else {
								getDestinationDetails(tempdata.state.destinationId).catch(console.error);
							}
						}
					}
				}
				setPage(1);
				setAvailabilityStayList([]);
				page && (await getAvailableStays(page));
			})();
	}, [reservationFilters, destinationTempId]);

	useEffect(() => {
		page !== 1 && getAvailableStays(page);
	}, [page, destinationTempId]);

	async function handleViewMore(): Promise<void> {
		if (!!searchParams.get('promo')) {
			const search = '';
			setSearchParams(search, { replace: true });
			page && destinationDetails && (await getAvailableStays(page, true));
		}
	}

	async function getAvailableStays(searchPage: number, viewAll: boolean = false) {
		if (!destinationTempId) return;

		try {
			let searchQueryObj: Misc.ReservationFilters = {
				...reservationFilters,
				destinationId: destinationTempId
			};

			if (!!searchParams.get('promo') && !viewAll) {
				let promotion = searchParams.get('promo') || '';
				searchQueryObj = {
					...searchQueryObj,
					accessCode: promotion,
					accessType: !!promotion ? 'promotion' : ''
				};
			}

			let key: keyof Misc.ReservationFilters;
			for (key in searchQueryObj) {
				if (searchQueryObj[key] === undefined) delete searchQueryObj[key];
			}
			searchQueryObj.pagination = { page: searchPage, perPage };
			setIsFetchingResults(true);
			let result: Api.Accommodation.Res.MinMaxAvailability = await accommodationService.availability(
				destinationTempId,
				searchQueryObj
			);

			let minStayMin = Infinity;
			// Iterate through the array to find the minimum minStay value
			result.data.forEach((element: any) => {
				element.prices.forEach((price: any) => {
					if (price.minStay < minStayMin) {
						minStayMin = price.minStay;
					}
				});
			});

			setMinStay(minStayMin);
			calculateHighestPointEarnable(result);
			const pointEarning = result.data[0]?.pointsEarnable;
			const pointMinPrice = result.data[0]?.minPricePoints;
			// setAvailabilityPointsData(pointEarning);
			setAvailabilityPointsPrise(pointMinPrice);
			setTotalResults(result.total || 0);
			if (result.data.length > 0) setCurrencyCode(result.data[0].prices[0]?.rate?.currencyCode);
			setMinPricePoints(result.minPricePoints || 0);
			setMinPriceCents(result.minPriceCents || 0);
			if (searchPage === 1) setAvailabilityStayList(result.data);
			else
				setAvailabilityStayList((prev) => {
					return [...prev, ...result.data];
				});
		} catch (e) {
			rsToastify.error(WebUtils.getRsErrorMessage(e, 'Unable to get available accommodations.'), 'Server Error');
			popupController.open<CallToActionPopupProps>(CallToActionPopup, {});
		}
		setIsFetchingResults(false);
	}

	async function handleOnInfoClick(accommodationId: number) {
		try {
			const accommodationStay = availabilityStayList.find((stay) => stay.id === accommodationId);
			if (!accommodationStay) return;
			popupController.open<AccommodationOverviewPopupProps>(AccommodationOverviewPopup, {
				pointPerDollar: pointPerDollar,
				destinationName: destinationDetails?.name || '',
				accommodationStay,
				destinationDetails: destinationDetails,
				packageCount: resortPackagesCount,
				loyaltyStatus: loyaltyStatus,
				pointsEarnable: accommodationStay.pointsEarnable,
				resortPointsPrice: accommodationStay.minPricePoints,
				isCustomResort: destinationDetails?.isCustomResort
			});
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	function renderLocations() {
		try {
			if (!destinationDetails) return;
			if (!destinationDetails.state) {
				let countryName: Api.Country.Res.Country | undefined = countryList.find(
					(item) => item.isoCode === destinationDetails.country
				);
				if (!countryName) return;
				return (
					<Label variant={'h5'}>
						{destinationDetails.city}, {countryName.name}
					</Label>
				);
			} else {
				return (
					<Label variant={'h5'}>
						{destinationDetails.city}, {destinationDetails.state}
					</Label>
				);
			}
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	function renderMapSource() {
		if (!destinationDetails) return;
		let address = `${destinationDetails.address1} ${destinationDetails.city} ${destinationDetails.state} ${
			destinationDetails.zip
		}, ${destinationDetails.country !== 'BM' ? destinationDetails.country : 'Bermuda'}`;
		address = address.replace(/ /g, '+');
		return encodeURI(`https://www.google.com/maps/dir/?api=1&destination=${address}`);
	}

	function renderExperiences() {
		try {
			if (!destinationDetails) return;
			return destinationDetails.experiences.map((experience) => {
				return (
					<IconLabel
						key={experience.id}
						labelName={experience.title}
						iconImg={experience.icon}
						iconPosition={'top'}
						iconSize={45}
						labelVariant={'body1'}
					/>
				);
			});
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	async function getPlatformVariables() {
		try {
			const pointPerDollar = (await destinationService.getPlatformVariables()).rewardPointsPerDollar; //global reward point per dollar
			setPointPerDollar(pointPerDollar);
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	function renderAccommodations() {
		try {
			if (!destinationDetails || !ObjectUtils.isArrayWithData(availabilityStayList)) return;

			// Filter accommodations based on specific criteria, e.g., price or availability
			const filteredAccommodations = availabilityStayList.filter((accommodationAvailability) => {
				// Example: Filter accommodations with prices greater than 0
				return accommodationAvailability.prices && accommodationAvailability.prices.length > 0;
			});

			// Check if any accommodations match the filtering criteria
			if (filteredAccommodations.length === 0) {
				return <Label variant={'h2'}>No available options.</Label>;
			}

			// Render filtered accommodations
			return filteredAccommodations.map((accommodationAvailability, index) => {
				const promotedRate: any = accommodationAvailability.prices.filter(
					(item) => item.rate.code === destinationDetails?.promotedRate
				);

				const nonPromotedRate = accommodationAvailability.prices.filter(
					(item) => item.rate.code !== destinationDetails?.promotedRate
				);
				if (destinationTempId) {
					return (
						<AccommodationSearchCard
							isOpen={handleViewMore}
							accessCode={searchParams.get('promo') ?? ''}
							key={accommodationAvailability.id}
							promotedRate={
								promotedRate && promotedRate.length === 0 ? nonPromotedRate[0] : promotedRate[0]
							}
							nonPromotedRate={
								promotedRate && promotedRate.length === 0
									? nonPromotedRate.slice(1, nonPromotedRate.length)
									: nonPromotedRate
							}
							pointPerDollar={pointPerDollar}
							destinationDetails={destinationDetails}
							openAccordion={false}
							resortPoint={accommodationAvailability.pointsEarnable}
							resortPointsPrice={accommodationAvailability.minPricePoints}
							packageLength={resortPackagesCount}
							accommodation={accommodationAvailability}
							destinationId={destinationTempId}
							onClickInfoIcon={handleOnInfoClick}
							loyaltyStatus={destinationDetails.loyaltyStatus}
							showInfoIcon
							upfrontCashRequired={!!destinationDetails.upfrontCashRequired}
							isCustomResort={destinationDetails.isCustomResort}
							phone={destinationDetails?.phone}
						/>
					);
				}
			});
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	function renderExperiencesSection() {
		if (!destinationDetails?.experiences) return null;
		const highlightedExperiences = destinationDetails.experiences.filter((experience) => experience.isHighlighted);
		if (!ObjectUtils.isArrayWithData(highlightedExperiences) || highlightedExperiences.length < 3) return null;
		return <DestinationExperienceImageGallery experiences={highlightedExperiences.slice(0, 6)} />;
	}

	function getMinMaxSize() {
		try {
			const sizeList = destinationDetails?.accommodations.map((item: any) => {
				return parseInt(item.size.min);
			});
			if (sizeList?.length) {
				const sortedList: any = sizeList?.sort(function (a, b) {
					return a - b;
				});
				return { minSize: sortedList[0], maxSize: sortedList[sortedList.length - 1] };
			} else return { minSize: 0, maxSize: 0 };
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	function renderAccommodationSizeRange() {
		try {
			let sizeMin: any = 0;
			let sizeMax: any = 0;
			if (destinationDetails?.isCustomResort) {
				const sizeObj = getMinMaxSize();
				sizeMin = sizeObj?.minSize;
				sizeMax = sizeObj?.maxSize;
			} else {
				sizeMin = destinationDetails?.minSquareFt;
				sizeMax = destinationDetails?.maxSquareFt;
			}

			if (!sizeMin && !sizeMax) return;
			return (
				<Label className={'minMaxLabel'} variant={'h5'}>
					{sizeMin === sizeMax || !sizeMin || !sizeMax ? sizeMin || sizeMax : `${sizeMin} to ${sizeMax}`}{' '}
					ft&sup2;
				</Label>
			);
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	function renderPointsOrCash() {
		try {
			if (ObjectUtils.isArrayWithData(availabilityStayList)) {
				if (!reservationFilters.redeemPoints) {
					return StringUtils.formatCurrency(currencyCode) + `${StringUtils.formatMoney(minPriceCents)}`;
				} else if (reservationFilters.redeemPoints && loyaltyStatus === 'ACTIVE') {
					return `${StringUtils.addCommasToNumber(minPricePoints)}pts`;
				} else {
					return StringUtils.formatCurrency(currencyCode) + `${StringUtils.formatMoney(minPriceCents)}`;
				}
			}
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	function renderPoints() {
		if (ObjectUtils.isArrayWithData(availabilityStayList)) {
			return StringUtils.addCommasToNumber(minPricePoints);
		}
	}

	function renderGoogleMapsRenderProp(status: Status) {
		return <h1>{status}</h1>;
	}

	function reviewPopup(event: any) {
		event.stopPropagation();
		return popupController.open<ReviewPopupProps>(ReviewPopup, {
			destinationId: destinationDetails?.id,
			googleTotalReview: destinationDetails?.googleTotalReview,
			googleAllOverReview: destinationDetails?.googleAllOverReview
		});
	}

	return !destinationDetails ? (
		<LoadingPage />
	) : (
		<>
			{showMapModal && (
				<MapModal
					setShowMapModal={setShowMapModal}
					destinationDetails={destinationDetails}
					GOOGLE_MAPS_API={geoCoderApiKey}
					renderGoogleMapsRenderProp={renderGoogleMapsRenderProp}
				/>
			)}
			<Page
				className={`rsDestinationDetailsPage ${
					!isFetchingResults && availabilityStayList.length <= 3 && isOtherFilterPopupOpen
						? 'destination-filter'
						: ''
				}`}
			>
				<div className={'rs-page-content-wrapper'}>
					{!isMobile && (
						<SubNavMenu
							pageRefs={{
								overviewRef: overviewRef,
								experiencesRef: experiencesRef,
								availableStaysRef: availableStaysRef
							}}
							minStay={minStay}
							activeViewSection={activeViewSection}
							destinationDetails={destinationDetails}
						/>
					)}
					<div datasection={'gallery'} ref={galleryRef} className={'gallerySection observer'}>
						<DestinationImageGallery
							resortPointsData={availabilityPointsData}
							imageData={destinationDetails.media}
							onGalleryClick={() => {
								if (!destinationDetails) return;
								popupController.open<TabbedCarouselPopupProps>(LightBoxCarouselPopup, {
									imageData: destinationDetails.media.sort(
										(a: any, b: any) => a.mediaIndex - b.mediaIndex
									)
								});
							}}
						/>
					</div>
					<div datasection={'overview'} ref={overviewRef} className={'overviewSection observer'}>
						<Box className={'titleAndPriceContainer'}>
							<Box className={'logoNameContainer'}>
								<img
									className="destinationLogo"
									src={destinationDetails.logoUrl}
									alt={destinationDetails.name + ' logo'}
								/>

								<Label variant={'h2'}>
									{destinationDetails.name}
									{user && destinationDetails?.id && (
										<HeartLike destinationDetailsId={destinationDetails.id} />
									)}
								</Label>
							</Box>
							<Box className={'destinationPricingContainer'}>
								{availabilityStayList.some(
									(accommodation) => accommodation.prices && accommodation.prices.length > 0
								) && (
									<>
										<Label variant={'body1B'} className={'primaryTextColor'}>
											from
										</Label>
										<Label variant={'h1'} className={'secondaryTextColor'} mt={'8px'} mb={'8px'}>
											{renderPointsOrCash()}
										</Label>
										<Label variant={'body1'} className={'primaryTextColor'}>
											per night
										</Label>
										{!reservationFilters.redeemPoints && (
											<Label variant={'body1'} className={'primaryTextColor'}>
												+taxes and fees
											</Label>
										)}
									</>
								)}
							</Box>
						</Box>
						{/* TEMPORARY CODE IS COMMENT FOR GOOGLE REVIEWS  */}
						{/* {!!destinationDetails.googleAllOverReview && !!destinationDetails.googleTotalReview && (
							<Box
								display={'flex'}
								marginBottom={'10px'}
								alignItems={'center'}
								onClick={(event) => reviewPopup(event)}
								className="googleReviewSection"
							>
								<Label variant={'h5'}>{destinationDetails.googleAllOverReview}</Label>
								<Rating
									initialValue={destinationDetails.googleAllOverReview}
									readonly={true}
									allowFraction={true}
									SVGstyle={{ width: 25, height: 25 }}
									style={{ margin: '0 12px 0 5px' }}
								/>
								<Label variant={'h5'} className="googleReviewFormate">
									{destinationDetails.googleTotalReview + ' Google reviews'}
								</Label>
							</Box>
						)} */}
						<Box className={'destinationDetailsWrapper'}>
							<Box className={'minMaxDescription'}>
								<Box className={'minMaxContainer'}>
									<Box className={'minMaxLabels'}>
										<ImageLabel
											className={'minMaxLabel'}
											labelName={`${
												destinationDetails.minBedroom === destinationDetails.maxBedroom
													? destinationDetails.minBedroom
													: `${destinationDetails.minBedroom}-${destinationDetails.maxBedroom}`
											} Bed`}
											imgSrc={'sleep.png'}
											imgWidth={'40px'}
											imgHeight={'40px'}
											iconPosition={'left'}
											labelVariant={'h5'}
										/>
										<ImageLabel
											className={'minMaxLabel'}
											labelName={`${
												destinationDetails.minBathroom === destinationDetails.maxBathroom
													? destinationDetails.minBathroom
													: `${destinationDetails.minBathroom}-${destinationDetails.maxBathroom}`
											} Bath`}
											imgSrc={'shower.png'}
											imgWidth={'40px'}
											imgHeight={'40px'}
											iconPosition={'left'}
											labelVariant={'h5'}
										/>
										{renderAccommodationSizeRange()}
										<Box className={'cityStateContainer'}>
											<Icon
												className={'locationIcon'}
												iconImg={'icon-location'}
												size={25}
												color={'#FF6469'}
											/>
											{renderLocations()}
										</Box>
									</Box>
								</Box>
								{destinationDetails.description && (
									<Label variant={'body2'} className={'locationDescription'}>
										{destinationDetails.description}
									</Label>
								)}
								<div
									datasection={'experiences'}
									ref={experiencesRef}
									className={'experienceSection observer'}
									mb={63}
								>
									{/* {renderExperiencesSection()} */}
									<Box className={'experiences'}>{renderExperiences()}</Box>
								</div>
							</Box>
							{isMobile && (
								<LabelButton
									className={'yellowButton'}
									look={'containedPrimary'}
									variant={'customTwelve'}
									label={'Reserve Stay'}
									onClick={() => {
										if (!availableStaysRef) return;
										let ref = availableStaysRef.current;
										if (!ref) return;
										window.scrollTo({ top: ref.offsetTop, behavior: 'smooth' });
									}}
								/>
							)}
							{destinationDetails?.pointsOfInterest && destinationDetails?.pointsOfInterest.length && (
								<div className={'pointsOfInterestContainer'}>
									<Label
										className="pointsOfInterestContainerTitle"
										variant={'h6'}
										mt={30}
										ml={20}
										mb={isMobile ? 5 : 10}
									>
										Points of Interest
									</Label>
									{destinationDetails?.pointsOfInterest.map((items: any, index: number) => {
										if (!items?.name) return;
										return (
											<Box
												style={{ borderBottomWidth: index === 4 ? 0 : 1 }}
												className={'interestcityStateContainer'}
											>
												<Box className={'interestContainerLocation'}>
													{/* {index === 4 ? (
														<img className={'locationIcon'} src={airport} alt="" />
													) : (
														<img className={'locationIcon'} src={location} alt="" />
													)} */}
													<img className={'locationIcon'} src={location} alt="" />
													<Label className={'locationName'}>
														{items?.name?.length <= 10
															? `${items?.name}`
															: `${items?.name?.slice(0, 10)}...`}
														<span class="tooltiptext">{items?.name}</span>
													</Label>
												</Box>
												<Box>
													<Label className={'locationDistance'}>
														{items?.distance?.length <= 5
															? `${items?.distance}`
															: `${items?.distance?.slice(0, 5)}...`}
														<span class="tooltiptext">{items?.distance}</span>
													</Label>
												</Box>
											</Box>
										);
									})}
								</div>
							)}

							<div
								title="Double click to open map in fullscreen"
								className="mapBox"
								onClick={(e: any) => {
									if (e.detail === 2) {
										document.body.classList.add('body-overall-container');
										setShowMapModal(true);
									}
								}}
							>
								<Wrapper apiKey={geoCoderApiKey} render={renderGoogleMapsRenderProp}>
									<Map
										style={{
											position: 'relative',
											overflow: 'hidden',
											width: '335px',
											height: '418px',
											borderRadius: '20px'
										}}
										isModalOpen={showMapModal}
										setShowMapModal={setShowMapModal}
										onClickPicker={() => null}
										destinationDetails={destinationDetails}
										// servicesList={props.servicesList}
										currentLocation={{
											latitude:
												destinationDetails.latitude !== undefined
													? destinationDetails.latitude
													: 0,
											longitude:
												destinationDetails.longitude !== undefined
													? destinationDetails.longitude
													: 0
										}}
									/>
								</Wrapper>
							</div>
						</Box>
					</div>

					{!destinationDetails.isActive ? (
						<div datasection={'availableStays'} ref={availableStaysRef} className={'observer'}>
							<Label variant={'h2'} color={'red'} className={'noDestinations'}>
								This destination is currently not accepting reservations from this site.
							</Label>
						</div>
					) : (
						<div
							datasection={'availableStays'}
							className={'availableStays observer'}
							ref={availableStaysRef}
						>
							{/* <hr />
							<Label variant={'h3'} className={'chooseYourAccommodation'} mb={64}>
								Choose your accommodation
							</Label>
							<FilterBarLimited
							
								destinationId={destinationDetails.id}
								loyaltyStatus={loyaltyStatus}
								externalId={destinationDetails.externalId}
								chainId={destinationDetails.chainId}
								isMobile={isMobile}
								setIsOtherFilterPopupOpen={setIsOtherFilterPopupOpen}
								isCustomResort={destinationDetails?.isCustomResort}
							/> */}
							{!!destinationDetails && destinationDetails?.flexibleDateRate && (
								<>
									<hr />
									<FlexibleRates
										destinationId={destinationTempId}
										destinationDetails={destinationDetails}
										packageLength={resortPackagesCount}
										upfrontCashRequired={!!destinationDetails.upfrontCashRequired}
										isCustomResort={destinationDetails.isCustomResort}
										phone={destinationDetails?.phone}
										loyaltyStatus={destinationDetails.loyaltyStatus}
									/>
								</>
							)}

							<hr />
							<div className={'accommodationCardWrapper'}>
								{!isFetchingResults && availabilityStayList.length <= 0 && (
									<Label variant={'h2'}>No available options.</Label>
								)}
								{!isFetchingResults && renderAccommodations()}
								{isFetchingResults && <SpinningLoader />}
							</div>
							<PaginationViewMore
								selectedRowsPerPage={perPage}
								total={totalResults}
								currentPageNumber={page}
								viewMore={(page) => setPage(page)}
								text={
									<Label variant="button" className="loadMoreButton">
										Load More
									</Label>
								}
							/>
						</div>
					)}

					{destinationDetails?.regions[0] && (
						<SimilarChoiceRecommendation
							regionId={destinationDetails?.regions[0]?.id}
							destinationId={destinationDetails?.id}
						/>
					)}
				</div>

				{/* Meta Pixel Integration. Don't delete the code */}
				<MetaPixelTag resortIdForMetaPixel={destinationTempId} />
			</Page>
		</>
	);
};

export default DestinationDetailsPage;
