import * as React from 'react';
import './UserBasicInfoPaper.scss';
import useIsAtBreakpoint from '../../customHooks/useIsAtBreakpoint';
import UserBasicInfoPaperMobile from './userBasicInfoPaperMobile/UserBasicInfoPaperMobile';
import UserBasicInfoPaperResponsive from './userBasicInfoPaperResponsive/UserBasicInfoPaperResponsive';

interface UserBasicInfoPaperProps {
	userData: Api.User.Res.Detail;
	onLogOut: () => void;
}

const UserBasicInfoPaper: React.FC<UserBasicInfoPaperProps> = (props) => {
	const isMobile = useIsAtBreakpoint();
	return isMobile ? (
		<UserBasicInfoPaperMobile userData={props.userData} onLogOut={props.onLogOut} />
	) : (
		<UserBasicInfoPaperResponsive userData={props.userData} onLogOut={props.onLogOut} />
	);
};

export default UserBasicInfoPaper;
