import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './PointsOrCentsBox.scss';
import { Box, popupController } from '@bit/redsky.framework.rs.996';
import Label from '@bit/redsky.framework.rs.label';
import { NumberUtils, StringUtils, WebUtils } from '../../utils/utils';
import LabelButton from '../labelButton/LabelButton';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import globalState from '../../state/globalState';
import serviceFactory from '../../services/serviceFactory';
import DestinationService from '../../services/destination/destination.service';
import metaCapi from '../../customHooks/useMetaCapi';
import TagManager from 'react-gtm-module';
import useMerchantReward from '../../customHooks/useMerchantReward';
import { getPageFinder, undefinedHandler } from '../../utils/undefinedHandler';
import parse from 'html-react-parser';
import { get } from 'lodash';

interface PointsOrCentsBoxProps {
	pointPerDollar?: number;
	priceObj: Misc.Pricing;
	accommodationId: number;
	destinationId: number;
	packageLength?: number;
	loyaltyStatus: Model.LoyaltyStatus;
	upfrontCashRequired: boolean;
	displayFromText?: boolean;
	resortPointsPrice: number;
	isCustomResort?: boolean;
	phone?: number;
	destinationDetails: Api.Destination.Res.Details | undefined;
	campaignLength: number;
	accommodation?: Api.Destination.Res.Accommodation;
}

const PointsOrCentsBox: React.FC<PointsOrCentsBoxProps> = (props) => {
	let navigate = useNavigate();
	const setVerifiedAccommodation = useSetRecoilState<Api.Reservation.Res.Verification | undefined>(
		globalState.verifiedAccommodation
	);
	const user = useRecoilValue<Api.User.Res.Detail | undefined>(globalState.user);
	const userTierValue = useRecoilValue<number>(globalState.userTierValue);
	const destinationService: DestinationService = serviceFactory.get<DestinationService>('DestinationService');
	const [destinationObj, setDestinationObj] = useState<any>({
		campaigns: props?.priceObj?.rate?.campaigns,
		minAccommodationPriceInCents: props.priceObj?.baseRates
	});

	const [pointPerDollar, setPointPerDollar] = useState<number | undefined>(props.pointPerDollar);

	useEffect(() => {
		if (typeof props.pointPerDollar === 'undefined') {
			setPointPerDollar(0);
		} else {
			setPointPerDollar(props.pointPerDollar);
		}
		setDestinationObj({
			campaigns: props?.priceObj?.rate?.campaigns,
			minAccommodationPriceInCents: props.priceObj?.baseRates
		});
	}, []);

	const [reservationFilters, setReservationFilters] = useRecoilState<Misc.ReservationFilters>(
		globalState.reservationFilters
	);

	const googleAddToCartHandler = (productData: any) => {
		const add_to_cart = {
			id: productData,
			item_name: props.accommodation?.name,
			item_id: get(props, 'accommodation.externalSystemId', props?.accommodation?.id),
			price: NumberUtils.centsToDollars(Number(props.priceObj?.baseRates)),
			item_brand: props.destinationDetails?.name,
			item_category: get(props, 'accommodation.contentLists.RoomCategoryList.0.Name', null),
			item_category2: get(props, 'accommodation.contentLists.RoomCategoryList.0.Name', null),
			item_list_name: 'Rooms',
			quantity: 1,
			arrival_date: reservationFilters.startDate,
			depart_date: reservationFilters.endDate,
			total_cost: NumberUtils.centsToDollars(props.priceObj.grandTotalCents),
			currency: props.priceObj.rate.currencyCode
		};

		try {
			TagManager.dataLayer({
				dataLayer: {
					event: 'add_to_cart',
					ecommerce: null
				}
			});

			TagManager.dataLayer({
				dataLayer: {
					event: 'add_to_cart',
					ecommerce: {
						detail: {
							products: [add_to_cart]
						}
					}
				}
			});
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	};

	function renderCampaignMessage() {
		if (props.campaignLength === 0) {
			return '';
		}
		try {
			if (!!props.priceObj.rate.campaigns && props.priceObj.rate.campaigns.length === 0) {
				return '';
			}
			let campaignsData = props.priceObj.rate.campaigns.sort(function (a: any, b: any) {
				return new Date(b.createdOn).valueOf() - new Date(a.createdOn).valueOf();
			});
			let index = campaignsData.length - 1;
			return <>{parse(get(campaignsData, index + '.campaignMessage', ''))}</>;
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	async function onBookNow() {
		try {
			if (props.isCustomResort) {
				window.open(`tel:+${props.phone}`, '_self');
			} else {
				setVerifiedAccommodation(undefined);
				if (props.upfrontCashRequired) {
					const publicSlim: Api.Destination.Res.PublicSlim[] = await destinationService.getPublicSlim();
					const destinationPublicSlim: Api.Destination.Res.PublicSlim | undefined = publicSlim.find(
						(publicSlim) => publicSlim.id === props.destinationId
					);
					if (!destinationPublicSlim) return;
					window.open(
						`https://be.synxis.com/?chain=${destinationPublicSlim.chainId}&hotel=${
							destinationPublicSlim.externalSystemId
						}&adult=${reservationFilters.adultCount}&child=0&bedrooms=${
							reservationFilters.bedroomCount || 1
						}&currency=USD&arrive=${reservationFilters.startDate}&depart=${
							reservationFilters.endDate
						}&level=chain&locale=en-US&rooms=1`,
						'_blank'
					);
					navigate('/reservation/availability');
					return;
				}
				if (props.loyaltyStatus !== 'ACTIVE') {
					setReservationFilters({ ...reservationFilters, redeemPoints: false });
				}
				let data: any = { ...reservationFilters, redeemPoints: props.loyaltyStatus === 'ACTIVE' };
				let newRoom: Misc.StayParams = {
					uuid: Date.now(),
					adults: data.adultCount,
					children: data.childCount || 0,
					accommodationId: props.accommodationId,
					arrivalDate: data.startDate,
					departureDate: data.endDate,
					packages: [],
					rateCode: props.priceObj.rate.code
				};
				let isRedeemPoints = reservationFilters.redeemPoints ? '&redeemPoints=true' : '';
				data = StringUtils.setAddPackagesParams({ destinationId: props.destinationId, newRoom });
				popupController.closeAll();
				const checkoutData = {
					destinationId: props.destinationId,
					stays: [newRoom]
				};

				//google analytics add to cart
				googleAddToCartHandler(props.destinationId);
				metaCapi.addToCart(props.destinationDetails);

				if (props.packageLength === 0) {
					navigate(`/booking/checkout?data=${encodeURI(JSON.stringify(checkoutData))}${isRedeemPoints}`);
				} else {
					navigate(`/booking/packages?data=${data}${isRedeemPoints}`);
				}
			}
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	function renderPriceOrPoints() {
		if (reservationFilters.redeemPoints && props.loyaltyStatus === 'ACTIVE') {
			return (
				<Box className={'pointsContainer'}>
					<Label variant={'body1B'} mb={8}>
						from
					</Label>
					<Label variant={'h2'} className={'secondaryTextColor'} onClick={onBookNow}>
						{props.priceObj ? StringUtils.addCommasToNumber(props.priceObj.pricePoints) : 0}
					</Label>
					<Label variant={'body1'} className={'primaryTextColor'} mb={16}>
						points per night
					</Label>
					<LabelButton
						look={'containedPrimary'}
						variant={'buttonMdLg'}
						label={props.isCustomResort ? 'Call to Book' : 'Book Now'}
						className={'yellow'}
						onClick={onBookNow}
					/>
				</Box>
			);
		} else {
			return (
				<Box className={'priceContainer'}>
					<Label variant={'h2'} className="price" onClick={onBookNow}>
						{props.priceObj
							? StringUtils.formatCurrency(props.priceObj.rate.currencyCode) +
							  StringUtils.formatMoney(props.priceObj.priceCents)
							: 0}
					</Label>
					<Label variant={'body1'} className={'primaryTextColor'}>
						{props.priceObj
							? StringUtils.formatCurrency(props.priceObj.rate.currencyCode) +
							  StringUtils.formatMoney(props.priceObj.grandTotalCents)
							: 0}
					</Label>
					{/* {props.displayFromText && (
						<Label variant={'body1B'} mb={8}>
							or
						</Label>
					)}

					<div className="priceContainer__pointPrice">
						{StringUtils.addCommasToNumber(props.resortPointsPrice)}
					</div>
					<div className="priceContainer__pointPriceName">points</div> */}

					{/* <Label variant={'body1'} className={'primaryTextColor'}>
						per night
					</Label> */}
					<Label variant={'body1'} className={'primaryTextColor'} mb={16}>
						including taxes and fees
					</Label>
					<LabelButton
						look={'containedPrimary'}
						variant={'buttonMdLg'}
						label={props.isCustomResort ? 'Call to Book' : 'Book Now'}
						className={'yellow'}
						onClick={onBookNow}
					/>
					{!reservationFilters.redeemPoints && props.loyaltyStatus === 'ACTIVE' && (
						<div style={{ marginTop: '10px', width: '300px', textAlign: 'right' }}>
							<Label className={'secondaryTextColor alignmentCenter'} variant={'captionBI'}>
								{/* {useMerchantReward.pointEarningCalculation(
									destinationObj,
									pointPerDollar,
									userTierValue,
									user,
									props.campaignLength
								)} */}
								{useMerchantReward.rewardPointsCalculation(
									destinationObj,
									pointPerDollar,
									userTierValue,
									user
								)}
								<br />
							</Label>
						</div>
					)}
					<div className={'campaignMessage'}>{renderCampaignMessage()}</div>
				</Box>
			);
		}
	}

	return <Box className={'rsPointsOrCentsBox'}>{renderPriceOrPoints()}</Box>;
};

export default PointsOrCentsBox;
