// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PointsLabelText-container{margin-top:37px;position:absolute;width:146px;height:100px;right:120px;font-family:'Montserrat';font-style:normal;line-height:20px;text-align:right}.PointsLabelText-container_body{margin-top:25px}.PointsLabelText-container_pointname{font-size:12px}.PointsLabelText-container_pointnumber{color:#ffa022;font-weight:600;font-size:13px;line-height:0.8rem}@media screen and (max-width: 412px){.PointsLabelText-container_body{margin-top:28px}.PointsLabelText-container_pointname{font-size:10px}.PointsLabelText-container_pointnumber{font-size:11px;line-height:0.5rem}}
`, "",{"version":3,"sources":["webpack://./src/components/pointsLabelBox/pointsLabelText/PointsLabelText.scss"],"names":[],"mappings":"AAAA,2BACC,eAAgB,CAChB,iBAAkB,CAClB,WAAY,CACZ,YAAa,CACb,WAAY,CACZ,wBAAyB,CACzB,iBAAkB,CAClB,gBAAiB,CACjB,gBAAiB,CACjB,gCAGA,eAAgB,CAChB,qCAGA,cAAe,CACf,uCAGA,aAAc,CACd,eAAgB,CAChB,cAAe,CACf,kBAAmB,CACnB,qCAGA,gCACC,eAAgB,CAChB,qCAGA,cAAe,CACf,uCAGA,cAAe,CACf,kBAAmB,CACnB","sourcesContent":[".PointsLabelText-container {\n\tmargin-top: 37px;\n\tposition: absolute;\n\twidth: 146px;\n\theight: 100px;\n\tright: 120px;\n\tfont-family: 'Montserrat';\n\tfont-style: normal;\n\tline-height: 20px;\n\ttext-align: right;\n}\n\n.PointsLabelText-container_body {\n\tmargin-top: 25px;\n}\n\n.PointsLabelText-container_pointname {\n\tfont-size: 12px;\n}\n\n.PointsLabelText-container_pointnumber {\n\tcolor: #ffa022;\n\tfont-weight: 600;\n\tfont-size: 13px;\n\tline-height: 0.8rem;\n}\n\n@media screen and (max-width: 412px) {\n\t.PointsLabelText-container_body {\n\t\tmargin-top: 28px;\n\t}\n\n\t.PointsLabelText-container_pointname {\n\t\tfont-size: 10px;\n\t}\n\n\t.PointsLabelText-container_pointnumber {\n\t\tfont-size: 11px;\n\t\tline-height: 0.5rem;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
