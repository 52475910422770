// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rsLabelSelect{background-color:inherit;position:relative}.rsLabelSelect .rsLabel.caption{margin-bottom:10px}.rsLabelSelect .requiredAsterisk{color:var(--warningRed)}.rsLabelSelect .rsSelect{max-width:200px;margin:0}.rsLabelSelect .rsSelect [class*='container']{border-radius:5px}
`, "",{"version":3,"sources":["webpack://./src/components/labelSelect/LabelSelect.scss","webpack://./src/themes/_mixins.scss"],"names":[],"mappings":"AACA,eACC,wBAAyB,CACzB,iBAAkB,CAFnB,gCAKG,kBAAmB,CALtB,iCASE,uBCeiB,CDxBnB,yBAYE,eAAgB,CAChB,QAAS,CAbX,8CAgBE,iBAAkB","sourcesContent":["@import '../../themes/themes';\n.rsLabelSelect {\n\tbackground-color: inherit;\n\tposition: relative;\n\t.rsLabel {\n\t\t&.caption {\n\t\t\tmargin-bottom: 10px;\n\t\t}\n\t}\n\t.requiredAsterisk {\n\t\tcolor: theme-var($warningRed);\n\t}\n\t.rsSelect {\n\t\tmax-width: 200px;\n\t\tmargin: 0;\n\t}\n\t.rsSelect [class*='container'] {\n\t\tborder-radius: 5px;\n\t}\n}\n","@import 'variables';\n\n/*\n  include a map in a class\n*/\n@mixin spread-map($map: ()) {\n\t@each $key, $value in $map {\n\t\t#{$key}: $value;\n\t}\n}\n\n/*\n  Returns a CSS variable property specific to themeing.\n  Accepts an optional fallback, and optional map.\n  - SCSS: color: theme-var($--theme-primary, blue)\n  - CSS:  color: var(--theme-primary, blue)\n  Throws error if the variable not part of the map\n*/\n@function theme-var($key, $fallback: null, $map: $defaultTheme) {\n\t@if not map-has-key($map, $key) {\n\t\t@error \"key: '#{$key}', is not a key in map: #{$map}\";\n\t}\n\t@if ($fallback) {\n\t\t@return var($key, $fallback);\n\t} @else {\n\t\t@return var($key);\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primaryTextColor": `var(--primaryTextColor)`,
	"secondaryTextColor": `var(--secondaryTextColor)`,
	"successGreen": `var(--successGreen)`,
	"adminPink": `var(--adminPink)`,
	"warningRed": `var(--warningRed)`,
	"buttonLinkColor": `var(--buttonLinkColor)`,
	"secondaryButtonLinkColor": `var(--secondaryButtonLinkColor)`,
	"iconAndHighlightTextColor": `var(--iconAndHighlightTextColor)`,
	"primaryBackgroundColor": `var(--primaryBackgroundColor)`,
	"secondaryBackgroundColor": `var(--secondaryBackgroundColor)`,
	"errorColor": `var(--errorColor)`,
	"primaryBoxShadowColor": `var(--primaryBoxShadowColor)`,
	"greyLineColor": `var(--greyLineColor)`,
	"thinGreyLineColor": `var(--thinGreyLineColor)`,
	"white": `var(--white)`,
	"lightestGray": `var(--lightestGray)`,
	"backgroundGray": `var(--backgroundGray)`,
	"borderGray": `var(--borderGray)`,
	"medGray2": `var(--medGray2)`,
	"medGray2Active": `var(--medGray2Active)`,
	"black": `var(--black)`,
	"primaryCalendarColor": `var(--primaryCalendarColor)`,
	"secondaryCalendarColor": `var(--secondaryCalendarColor)`
};
export default ___CSS_LOADER_EXPORT___;
