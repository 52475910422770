import React from 'react';

export interface ProgressBarProps {
	totalProgressBar: number;
	currentStep: number;
}

const ProgressBar = (props: ProgressBarProps) => {
	const progressBarContainer = () => {
		// props.totalProgressBar
		let abc: number[] = [];
		for (let i: number = 1; i <= props.totalProgressBar; i++) {
			abc = [...abc, i];
		}
		let divwidth = 100 / props.totalProgressBar + '%';
		return abc.map((item: number, index) => (
			<div
				className={props.currentStep >= item ? 'progressBar done-progress' : 'progressBar'}
				style={{ width: divwidth }}
			></div>
		));
	};

	return (
		<div className="progressBarContainer">
			{progressBarContainer()}
			<span className="" style={{ color: '#c4c4c4', fontWeight: '700' }}>
				{props.currentStep + '/' + props.totalProgressBar}
			</span>
		</div>
	);
};

export default ProgressBar;
