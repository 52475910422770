import React from 'react';
import globalState, { getRecoilExternalValue } from '../../state/globalState';
import router from '../../utils/router';
import Script from '../../components/script/Script';
import { NumberUtils } from '../../utils/utils';

interface FlipToConfirmationProps {
	transactions: any;
}
const FlipToConfirmation: React.FC<FlipToConfirmationProps> = (props) => {
	const addDotToShowCent = (amount: Number) => {
		const amountConvertedToString = amount.toString();

		const last2Str = amountConvertedToString.slice(-2); // remove the last two digit
		const amountWithoutLast2Str = amountConvertedToString.slice(0, -2); //delete last 2 from number

		//join now
		const newAmount = Number(amountWithoutLast2Str + '.' + last2Str);
		return newAmount.toFixed(2);
	};

	const params = router.getPageUrlParams<{
		regionIds?: string;
	}>([
		//Note: don't change the key 'di', because we have a data contract with a 3rd party if the key 'di' is changed it is going to break their architecture.
		{ key: 'regionIds', default: '', type: 'string', alias: 'regionIds' }
	]);

	function setFlipTo() {
		let setFliptoRegionId: any = [];

		const reservationFilters = getRecoilExternalValue<Misc.ReservationFilters>(globalState.reservationFilters);

		if (reservationFilters.flipToregionIds !== undefined) {
			reservationFilters.flipToregionIds.forEach((flipToregionId: any) => {
				setFliptoRegionId.push(flipToregionId.id.toString());
			});
		}

		return `
		!function(n,e,w){
			w.eventData={category:'Booking_Engine',action:'Load',value:w.amount};
			(n[e]=n[e]||[]).push({flipto:w,event:'flipto.confirmation.load'});
			}(window,'fliptoDataLayer',
			{
			companyCode: 'YX',
			code: '${props.transactions.destination.id}',
			fallbackCode: '${setFliptoRegionId}',
			confirmation: '${props.transactions.stays[0].externalConfirmationId}',
			first: '${props.transactions.stays[0].guest.firstName}',
			last: '${props.transactions.stays[0].guest.lastName}',
			email: '${props.transactions.stays[0].guest.email}',
			startDate: '${props.transactions.stays[0].arrivalDate.substring(0, 10)}',
			endDate: '${props.transactions.stays[0].departureDate.substring(0, 10)}',
			guests: ${props.transactions.stays[0].adultCount + props.transactions.stays[0].childCount},
			adults: ${props.transactions.stays[0].adultCount},
			children: ${props.transactions.stays[0].childCount},
			type: '${props.transactions.stays[0].accommodation.propertyType}',
			rateCode: '${props.transactions.stays[0].rateCode}',
			language: 'en',
			currency: '${props.transactions.stays[0].priceDetail.currencyCode}',
			amount: ${NumberUtils.centsToDollars(props.transactions.stays[0].priceDetail.accommodationTotalInCents)},
			loyalty: '',
			dateFormat: 'yyyy-MM-dd'
			});	
		`;
	}

	return <Script text={setFlipTo()} tag="head" />;
};

export default FlipToConfirmation;
