import React from 'react';
import Script from '../../components/script/Script';

interface PageViewBookingScriptProps {}

const PageViewBookingScript: React.FC<PageViewBookingScriptProps> = () => {
	function buildGtmTagScript() {
		return `
			window.dataLayer = window.dataLayer || [];
			gtag('event', 'page_view', {
			  page_title: 'Checkout Confirmation',
			  page_location: 'https://dev.book.rentylresorts.com/booking/checkout?s=2',
			  page_path: '/booking/checkout?s=2',
			  send_to: 'G-6ZV003866W'
			});`;
	}

	return <Script text={buildGtmTagScript()} tag="head" />;
};

export default PageViewBookingScript;
