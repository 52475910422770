import React from 'react';
import './Footer.scss';
import Label from '@bit/redsky.framework.rs.label';
import Icon from '@bit/redsky.framework.rs.icon';
import { Box, Link, popupController } from '@bit/redsky.framework.rs.996';
import { useNavigate } from 'react-router-dom';
import router from '../../utils/router';
import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import globalState from '../../state/globalState';
import { isRouteUnauthorized } from '../../utils/utils';
import SignupBanner from '../signupBanner/SignupBanner';
import useIsAtBreakpoint from '../../customHooks/useIsAtBreakpoint';
import packageJson from '../../../package.json';
import Script from '../script/Script';
import { get } from 'lodash';
interface FooterLink {
	text: string;
	path: string;
}

export interface FooterSection {
	title: string;
	links: FooterLink[];
}

interface FooterProps {
	links: FooterSection[];
}

const Footer: React.FC<FooterProps> = (props) => {
	const company = useRecoilValue<Api.Company.Res.GetCompanyAndClientVariables>(globalState.company);
	const user = useRecoilValue<Api.User.Res.Detail | undefined>(globalState.user);
	const isMobile = useIsAtBreakpoint();
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const resortId: any = urlParams.get('data');

	useEffect(() => {
		let id = router.subscribeToBeforeRouterNavigate(() => {
			setTimeout(() => {
				window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
			}, 300);
		});
		return () => {
			router.unsubscribeFromBeforeRouterNavigate(id);
		};
	}, []);

	function renderLinks(links: FooterLink[]) {
		return links.map((link: FooterLink, index: number) => {
			if (isRouteUnauthorized(link.path)) return false;
			return (
				<Link
					path={link.path}
					key={index}
					onClick={() => {
						window.open(link.path, '_blank');
					}}
				>
					<Label variant={isMobile ? 'subtitle1' : 'h6'} marginTop={20}>
						{link.text}
					</Label>
				</Link>
			);
		});
	}

	function renderSections(sections: FooterSection[]) {
		return sections.map((section: FooterSection, index) => {
			return (
				<Box className={'footerSection'} key={index} display={'flex'} flexDirection={'column'}>
					<Label variant={isMobile ? 'body1B' : 'h2'} className={'sectionTitles'}>
						{section.title}
					</Label>
					{renderLinks(section.links)}
				</Box>
			);
		});
	}

	const bbbScriptCode = () => {
		return `
			var bbbprotocol = ( ("https:" == document.location.protocol) ? "https://" : "http://" ); (function(){
			var s=document.createElement('script');s.src=bbbprotocol + 'seal-centralflorida.bbb.org' + unescape('%2flogo%2frentyl-resorts-90722612.js');s.type='text/javascript';s.async=true;var st=document.getElementsByTagName('script');st=st[st.length-1];var pt=st.parentNode;pt.insertBefore(s,pt.nextSibling);})();
		`;
	};

	const logoHandler = (brandType: any) => {
		let companyid;
		if (brandType) {
			companyid = JSON.parse(brandType).themeId;
		} else {
			companyid = null;
		}

		let logo = '';
		switch (companyid) {
			case 3:
				logo = '../../images/companylogo/encorereunion.png';
				break;
			case 78:
				logo = '../../images/companylogo/cottagesbyrentyl.png';
				break;
			case 19:
				logo = '../../images/companylogo/cottagesbyrentyl.png';
				break;
			case 9:
				logo = '../../images/companylogo/bearsdenorlando.png';
				break;
			case 10:
				logo = '../../images/companylogo/resortsbyspectrum.png';
				break;
			default:
				logo = company.wideLogoUrl;
				break;
		}

		return <img className={'companyFooterLogo'} src={logo} alt={get(company, 'name')} />;
	};

	return (
		<Box className={'rsFooter'}>
			{!user && <SignupBanner />}
			<Box className={'footerNavigation'}>
				<Box
					className={'companyFooterLogo'}
					onClick={() => {
						popupController.closeAll();
						// navigate('/reservation/availability');
					}}
				>
					{logoHandler(resortId)}
					{/* Social Icon */}
					<div className="contactus-container">
						<div className="contactus-heading">Contact Us</div>
						<div className="contact-email">
							<span>
								<i className="fa-regular fa-envelope fa-icon-size-3"></i>
							</span>
							<span className="contact-email-text">
								<a href="mailto:info@rentylresorts.com">info@rentylresorts.com</a>
							</span>
						</div>

						<div className="contact-callNumber">
							<span>
								<i className="fa-solid fa-phone fa-icon-size-3"></i>
							</span>
							<span className="contact-callNumber-text">
								<a href="tel:321-888-3509">321-888-3509</a>
							</span>
						</div>

						<div className="socialMedia-Icons">
							<Link
								path={'https://www.facebook.com/RentylResorts/'}
								className={'socialMediaLink-d'}
								onClick={() => {
									window.open('https://www.facebook.com/RentylResorts/', '_blank');
								}}
							>
								<i className="fa-brands fa-facebook fa-icon-size-2"></i>
							</Link>
							<Link
								path={'https://www.linkedin.com/company/rentylresorts/'}
								className={'socialMediaLink'}
								onClick={() => {
									window.open('https://www.linkedin.com/company/rentylresorts/', '_blank');
								}}
							>
								<i className="fa-brands fa-linkedin fa-icon-size-2"></i>
							</Link>
							<Link
								path={'https://www.instagram.com/rentylresorts/'}
								className={'socialMediaLink'}
								onClick={() => {
									window.open('https://www.instagram.com/rentylresorts/', '_blank');
								}}
							>
								<i className="fa-brands fa-instagram fa-icon-size-2"></i>
							</Link>
							<Link
								path={'https://twitter.com/rentyl_resorts'}
								className={'socialMediaLink'}
								onClick={() => {
									window.open('https://twitter.com/rentyl_resorts', '_blank');
								}}
							>
								<i className="fa-brands fa-twitter fa-icon-size-2"></i>
							</Link>
							<Link
								path={'https://www.youtube.com/channel/UCxox3mCx9jmhH0AvtnK4lEA'}
								className={'socialMediaLink'}
								onClick={() => {
									window.open('https://www.youtube.com/channel/UCxox3mCx9jmhH0AvtnK4lEA', '_blank');
								}}
							>
								<i className="fa-brands fa-youtube fa-icon-size-2"></i>
							</Link>
							<Link
								path={'https://www.pinterest.com/rentyl_resorts/'}
								className={'socialMediaLink'}
								onClick={() => {
									window.open('https://www.pinterest.com/rentyl_resorts/', '_blank');
								}}
							>
								<i className="fa-brands fa-pinterest fa-icon-size-2"></i>
							</Link>
							<Link
								path={'https://www.tiktok.com/@rentyl_resorts'}
								className={'socialMediaLink'}
								onClick={() => {
									window.open('https://www.tiktok.com/@rentyl_resorts', '_blank');
								}}
							>
								<i className="fa-brands fa-tiktok fa-icon-size-2"></i>
							</Link>
						</div>

						<div className="contactus-footer">
							Leave us a review on {''}
							<a
								href="https://www.google.com/search?hl=en-US&gl=us&q=Rentyl+Resorts,+8011+Fins+Up+Cir,+Kissimmee,+FL+34747&ludocid=7677642533455071313&lsig=AB86z5XNHGYpIvhuLDprUysx2XyA#lrd=0x88dd7d5540343a1f:0x6a8c7734cf050851,3"
								target="_blank"
							>
								Google
							</a>{' '}
							and{' '}
							<a href="https://www.facebook.com/RentylResorts/reviews" target="_blank">
								Facebook
							</a>
							.
						</div>
					</div>
					{/* BBB Logo */}
					<div style={{ marginTop: '10px' }}>
						<a
							id="bbblink"
							className="ruhzbum"
							href="https://www.bbb.org/us/fl/kissimmee/profile/resort/rentyl-resorts-0733-90722612#bbbseal"
							title="Rentyl Resorts, Resort, Kissimmee, FL"
							style={{
								display: 'block',
								position: 'relative',
								overflow: 'hidden',
								width: '150px',
								height: '68px',
								margin: '0px',
								padding: '0px'
							}}
							target="_blank"
						>
							<img
								style={{ padding: '0px', border: 'none' }}
								id="bbblinkimg"
								src="https://seal-centralflorida.bbb.org/logo/ruhzbum/rentyl-resorts-90722612.png"
								width="300"
								height="68"
								alt="Rentyl Resorts, Resort, Kissimmee, FL"
							/>
						</a>
						<Script text={bbbScriptCode()} tag="body" />
					</div>
				</Box>
				<Box className={'footerSections'}>{renderSections(props.links)}</Box>
			</Box>
			<Box className="copyright" display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
				<Label variant={'caption'}>
					&#169; {new Date().getFullYear()} Rentyl Resorts. All rights reserved.
				</Label>
				<div className="appVersion">
					<Label variant={'caption'}>Version: {packageJson.version}</Label>
				</div>
			</Box>
		</Box>
	);
};

export default Footer;
