import { Service } from '../Service';
import rsToasts from '@bit/redsky.framework.toast';
import { ObjectUtils } from '../../utils/utils';
import globalState, { getRecoilExternalValue, setRecoilExternalValue } from '../../state/globalState';
import serviceFactory from '../serviceFactory';
import DestinationService from '../destination/destination.service';
import { rsToastify } from '@bit/redsky.framework.rs.toastify';
import { useRecoilState } from 'recoil';

export default class ComparisonService extends Service {
	async addToComparison(destinationId: number) {
		let comparisonItems = getRecoilExternalValue<Misc.ComparisonState>(globalState.destinationComparison);

		if (
			ObjectUtils.isArrayWithData(comparisonItems.destinationDetails) &&
			comparisonItems.destinationDetails.length === 3
		) {
			setRecoilExternalValue(globalState.destinationComparisonError, false);
			throw rsToastify.error('You can only compare three at a time!', 'Uh ohh,');
		}
		const destinationService = serviceFactory.get<DestinationService>('DestinationService');
		const details = await destinationService.getDestinationDetails({ destinationId });
		comparisonItems = {
			destinationDetails: [
				...(comparisonItems.destinationDetails || []),
				{
					destinationId: details.id,
					accommodationOptions: details.accommodations
						.sort((a: any, b: any) => a.bedroomCount - b.bedroomCount)
						.map((accommodation) => {
							return { value: accommodation.id, label: accommodation.name };
						}),
					selectedAccommodationId: details.accommodations[0].id,
					logo: details.logoUrl,
					title: details.name
				}
			],
			showCompareButton: comparisonItems.showCompareButton
		};
		setRecoilExternalValue<Misc.ComparisonState>(globalState.destinationComparison, comparisonItems);
	}

	removeFromComparison(destinationId: number) {
		let comparisonItems = getRecoilExternalValue<Misc.ComparisonState>(globalState.destinationComparison);
		if (ObjectUtils.isArrayWithData(comparisonItems.destinationDetails)) {
			comparisonItems = {
				destinationDetails: comparisonItems.destinationDetails.filter(
					(destinationDetail) => destinationDetail.destinationId !== destinationId
				),
				showCompareButton: comparisonItems.showCompareButton
			};
		}
		setRecoilExternalValue<Misc.ComparisonState>(globalState.destinationComparison, comparisonItems);
		setRecoilExternalValue(globalState.destinationComparisonError, true);
	}

	changeAccommodation(accommodationId: number, destinationId: number) {
		let comparisonItems = getRecoilExternalValue<Misc.ComparisonState>(globalState.destinationComparison);
		if (ObjectUtils.isArrayWithData(comparisonItems.destinationDetails)) {
			comparisonItems = ObjectUtils.clone(comparisonItems);
			let destinationDetails = comparisonItems.destinationDetails;
			let indexToChange = destinationDetails.findIndex(
				(destination) => destination.destinationId === destinationId
			);
			if (indexToChange < 0) return;
			destinationDetails[indexToChange].selectedAccommodationId = accommodationId;
			comparisonItems.destinationDetails = destinationDetails;
		}
		setRecoilExternalValue<Misc.ComparisonState>(globalState.destinationComparison, comparisonItems);
	}
}
