// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.confirmPopBox{width:clamp(356px, 50vw, 550px);background-color:var(--primaryBackgroundColor);border-radius:10px;padding:50px 25px 25px;position:relative;text-align:center}.confirmPopBox .popupBoxText{font-weight:600}.confirmPopBox .closeBtn{position:absolute;top:24px;right:20px}.confirmPopBox .popupBoxButton{display:flex;justify-content:center}.confirmPopBox .popupBoxButton .Button{color:var(--white);margin:0 25px;font-size:large;width:120px}.confirmPopBox .popupBoxButton .cancelButton{color:var(--primaryTextColor) !important}
`, "",{"version":3,"sources":["webpack://./src/popups/confirmBoxPopup/ConfirmBoxPopup.scss","webpack://./src/themes/_mixins.scss"],"names":[],"mappings":"AACA,eACC,+BAAgC,CAChC,8CAA+C,CAC/C,kBAAmB,CACnB,sBAAuB,CACvB,iBAAkB,CAClB,iBAAkB,CANnB,6BASE,eAAgB,CATlB,yBAYE,iBAAkB,CAClB,QAAS,CACT,UAAW,CAdb,+BAkBE,YAAa,CACb,sBAAuB,CAnBzB,uCAsBG,kBCEgB,CDDhB,aAAc,CACd,eAAgB,CAChB,WAAY,CAzBf,6CA6BG,wCAA8C","sourcesContent":["@import 'src/themes/themes';\n.confirmPopBox {\n\twidth: clamp(356px, 50vw, 550px);\n\tbackground-color: var(--primaryBackgroundColor);\n\tborder-radius: 10px;\n\tpadding: 50px 25px 25px;\n\tposition: relative;\n\ttext-align: center;\n\n\t.popupBoxText {\n\t\tfont-weight: 600;\n\t}\n\t.closeBtn {\n\t\tposition: absolute;\n\t\ttop: 24px;\n\t\tright: 20px;\n\t}\n\n\t.popupBoxButton {\n\t\tdisplay: flex;\n\t\tjustify-content: center;\n\n\t\t.Button {\n\t\t\tcolor: theme-var($white);\n\t\t\tmargin: 0 25px;\n\t\t\tfont-size: large;\n\t\t\twidth: 120px;\n\t\t}\n\n\t\t.cancelButton {\n\t\t\tcolor: theme-var($primaryTextColor) !important;\n\t\t}\n\t}\n}\n","@import 'variables';\n\n/*\n  include a map in a class\n*/\n@mixin spread-map($map: ()) {\n\t@each $key, $value in $map {\n\t\t#{$key}: $value;\n\t}\n}\n\n/*\n  Returns a CSS variable property specific to themeing.\n  Accepts an optional fallback, and optional map.\n  - SCSS: color: theme-var($--theme-primary, blue)\n  - CSS:  color: var(--theme-primary, blue)\n  Throws error if the variable not part of the map\n*/\n@function theme-var($key, $fallback: null, $map: $defaultTheme) {\n\t@if not map-has-key($map, $key) {\n\t\t@error \"key: '#{$key}', is not a key in map: #{$map}\";\n\t}\n\t@if ($fallback) {\n\t\t@return var($key, $fallback);\n\t} @else {\n\t\t@return var($key);\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primaryTextColor": `var(--primaryTextColor)`,
	"secondaryTextColor": `var(--secondaryTextColor)`,
	"successGreen": `var(--successGreen)`,
	"adminPink": `var(--adminPink)`,
	"warningRed": `var(--warningRed)`,
	"buttonLinkColor": `var(--buttonLinkColor)`,
	"secondaryButtonLinkColor": `var(--secondaryButtonLinkColor)`,
	"iconAndHighlightTextColor": `var(--iconAndHighlightTextColor)`,
	"primaryBackgroundColor": `var(--primaryBackgroundColor)`,
	"secondaryBackgroundColor": `var(--secondaryBackgroundColor)`,
	"errorColor": `var(--errorColor)`,
	"primaryBoxShadowColor": `var(--primaryBoxShadowColor)`,
	"greyLineColor": `var(--greyLineColor)`,
	"thinGreyLineColor": `var(--thinGreyLineColor)`,
	"white": `var(--white)`,
	"lightestGray": `var(--lightestGray)`,
	"backgroundGray": `var(--backgroundGray)`,
	"borderGray": `var(--borderGray)`,
	"medGray2": `var(--medGray2)`,
	"medGray2Active": `var(--medGray2Active)`,
	"black": `var(--black)`,
	"primaryCalendarColor": `var(--primaryCalendarColor)`,
	"secondaryCalendarColor": `var(--secondaryCalendarColor)`
};
export default ___CSS_LOADER_EXPORT___;
