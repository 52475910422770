import * as React from 'react';
import './Carousel.scss';
import { useEffect, useRef, useState } from 'react';
import Icon from '@bit/redsky.framework.rs.icon';
import Button from '@bit/redsky.framework.rs.button';
import { ObjectUtils } from '../../utils/utils';
import { getPageFinder, undefinedHandler } from '../../utils/undefinedHandler';

interface CarouselProps {
	children: React.ReactNodeArray;
	showControls?: boolean;
	carouselRefCallBack?: (ref: any) => void;
	imageIndex?: number;
	className?: string;
}

const Carousel: React.FC<CarouselProps> = (props) => {
	const parentRef = useRef<HTMLElement>(null);
	const totalChildren = props.children.length;
	const [imageViewIndex, setImageViewIndex] = useState<number>(1);

	useEffect(() => {
		if (props.imageIndex === undefined) return;
		let val = parentRef.current!.offsetWidth * props.imageIndex;
		parentRef.current!.scrollTo({ top: 0, left: val, behavior: 'smooth' });
	}, [props.imageIndex]);

	useEffect(() => {
		setTimeout(() => {
			parentRef.current!.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
		}, 300);
	}, []);

	function renderChildren() {
		try {
			return props.children.map((item, index) => {
				return (
					<div key={index} className={'carouselChild'}>
						{item}
					</div>
				);
			});
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	function renderButtons() {
		try {
			if (!props.showControls || !ObjectUtils.isArrayWithData(props.children) || props.children.length < 2)
				return;
			return (
				<React.Fragment>
					<Button
						className={'clickLeft'}
						look={'none'}
						onClick={() => {
							try {
								let val = parentRef.current!.scrollLeft - parentRef.current!.offsetWidth;

								setImageViewIndex(imageViewIndex - 1);
								if (imageViewIndex <= 1) {
									val = parentRef.current!.offsetWidth * totalChildren;
									setImageViewIndex(totalChildren);
								}
								parentRef.current!.scrollTo({ top: 0, left: val, behavior: 'smooth' });
								let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
								if (isSafari) parentRef.current!.scrollLeft = val;
							} catch (error: any) {
								const message = `Getting error :${error.message} on ${getPageFinder(
									window.location.pathname
								)} page.`;
								undefinedHandler(message);
							}
						}}
					>
						<Icon iconImg={'icon-white-chevron-left'} color={'#001933'} />
					</Button>
					<Button
						className={'clickRight'}
						look={'none'}
						onClick={() => {
							try {
								let val = parentRef.current!.offsetWidth + parentRef.current!.scrollLeft;
								setImageViewIndex(imageViewIndex + 1);
								if (imageViewIndex >= totalChildren) {
									val = 0;
									setImageViewIndex(1);
								}
								parentRef.current!.scrollTo({ top: 0, left: val, behavior: 'smooth' });
								let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
								if (isSafari) parentRef.current!.scrollLeft = val;
							} catch (error: any) {
								const message = `Getting error :${error.message} on ${getPageFinder(
									window.location.pathname
								)} page.`;
								undefinedHandler(message);
							}
						}}
					>
						<Icon iconImg={'icon-white-chevron-right'} color={'#001933'} />
					</Button>
				</React.Fragment>
			);
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	return (
		<div className={`rsCarousel ${props.className || ''}`}>
			<div ref={parentRef} className={'carouselParent'}>
				{renderChildren()}
			</div>
			{renderButtons()}
		</div>
	);
};

export default Carousel;
