import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import './UpdateAddressPopup.scss';
import { Box, Popup, popupController, PopupProps } from '@bit/redsky.framework.rs.996';
import Icon from '@bit/redsky.framework.rs.icon';
import Label from '@bit/redsky.framework.rs.label';
import Paper from '../../components/paper/Paper';
import LabelButton from '../../components/labelButton/LabelButton';
import { RsFormControl, RsFormGroup, RsValidator, RsValidatorEnum } from '@bit/redsky.framework.rs.form';
import { OptionType } from '@bit/redsky.framework.rs.select';
import serviceFactory from '../../services/serviceFactory';
import CountryService from '../../services/country/country.service';
import { rsToastify } from '@bit/redsky.framework.rs.toastify';
import { StringUtils, WebUtils } from '../../utils/utils';
import LabelInput from '../../components/labelInput/LabelInput';
import LabelSelect from '../../components/labelSelect/LabelSelect';
import LabelCheckbox from '../../components/labelCheckbox/LabelCheckbox';
import router from '../../utils/router';
import useIsAtBreakpoint from '../../customHooks/useIsAtBreakpoint';

export interface UpdateAddressPopupProps extends PopupProps {
	// addressFormData: Misc.UserCheckoutInfo;
	// personalInfo: Misc.UserCheckoutInfo;
	info: any;
	isPersonalInfo: boolean;
	onUpdate: (value: Misc.UserCheckoutInfo) => void;
}

enum FormKeys {
	FIRST_NAME = 'firstName',
	LAST_NAME = 'lastName',
	ADDRESS1 = 'address1',
	ADDRESS2 = 'address2',
	CITY = 'city',
	ZIP = 'zip',
	STATE = 'state',
	COUNTRY = 'country',
	PHONE = 'phone',
	EMAIL = 'email'
}

const UpdateAddressPopup: React.FC<UpdateAddressPopupProps> = (props) => {
	const ref = useRef<HTMLDivElement>(null);
	const isMobile = useIsAtBreakpoint();
	const countryService = serviceFactory.get<CountryService>('CountryService');
	const fullProps = popupController.convertProps(props);
	const [stateList, setStateList] = useState<OptionType[]>([]);
	const [countryList, setCountryList] = useState<OptionType[]>([]);
	const [isPersonalForm, setIsPersonalForm] = useState<boolean>(true);
	const [selectState, setSelectState] = useState<number>(0);
	const [formGroup, setFormGroup] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl(FormKeys.FIRST_NAME, getUserInputData(FormKeys.FIRST_NAME) || '', [
				new RsValidator(RsValidatorEnum.REQ, 'First name is required')
			]),
			new RsFormControl(FormKeys.LAST_NAME, getUserInputData(FormKeys.LAST_NAME) || '', [
				new RsValidator(RsValidatorEnum.REQ, 'Last name is required')
			]),
			new RsFormControl(FormKeys.ADDRESS1, getUserInputData(FormKeys.ADDRESS1) || '', [
				new RsValidator(RsValidatorEnum.REQ, 'Address is required')
			]),
			new RsFormControl(FormKeys.ADDRESS2, getUserInputData(FormKeys.ADDRESS2) || '', []),
			new RsFormControl(FormKeys.CITY, getUserInputData(FormKeys.CITY) || '', [
				new RsValidator(RsValidatorEnum.REQ, 'City is required')
			]),
			new RsFormControl(FormKeys.ZIP, getUserInputData(FormKeys.ZIP) || '', [
				new RsValidator(RsValidatorEnum.REQ, 'Zip is required'),
				new RsValidator(RsValidatorEnum.CUSTOM, 'Invalid Zip Code', (control) => {
					return StringUtils.zipPattern.test(control.value.toString());
				})
			]),
			new RsFormControl(FormKeys.STATE, getUserInputData(FormKeys.STATE) || '', []),
			new RsFormControl(FormKeys.COUNTRY, getUserInputData(FormKeys.COUNTRY) || 'US', [
				new RsValidator(RsValidatorEnum.REQ, 'Country is required')
			]),
			new RsFormControl(FormKeys.PHONE, getUserInputData(FormKeys.PHONE) || '', [
				new RsValidator(RsValidatorEnum.REQ, 'Enter a valid phone number'),
				new RsValidator(RsValidatorEnum.MIN, 'Enter a valid phone number', 7)
			]),
			new RsFormControl(FormKeys.EMAIL, getUserInputData(FormKeys.EMAIL) || '', [
				new RsValidator(RsValidatorEnum.REQ, 'Email Required'),
				new RsValidator(RsValidatorEnum.EMAIL, 'Invalid email')
			])
		])
	);

	function getUserInputData(controlKey: keyof Misc.UserCheckoutInfo) {
		// let data = isPersonalForm ? props.personalInfo : props.addressFormData;
		let data = props.info;
		return data[controlKey];
	}

	useEffect(() => {
		const id = router.subscribeToBeforeRouterNavigate(async () => {
			popupController.closeById(fullProps.popupId);
			router.getCurrentPath();
			return true;
		});
		return () => {
			router.unsubscribeFromBeforeRouterNavigate(id);
		};
	}, []);

	useEffect(() => {
		async function getCountries() {
			try {
				let countries = await countryService.getAllCountries();
				setCountryList(formatStateOrCountryListForSelect(countries.countries));
			} catch (e) {
				console.error('getCountries', e);
				throw rsToastify.error(
					WebUtils.getRsErrorMessage(e, 'Unable to get a list of countries.'),
					'Server Error'
				);
			}
		}
		getCountries().catch(console.error);
	}, []);

	useEffect(() => {
		async function getStates() {
			try {
				let response = await countryService.getStates(`${formGroup.get(FormKeys.COUNTRY).value}` || 'US');
				if (response.states) {
					let newStates = formatStateOrCountryListForSelect(response.states);
					if (newStates.length === 0) {
						setStateList([{ value: 'N/A', label: 'N/A' }]);
					} else {
						setStateList(newStates);
					}
				}
			} catch (e) {
				rsToastify.error(
					WebUtils.getRsErrorMessage(e, 'Unable to get states for the selected country.'),
					'Server Error'
				);
			}
		}
		getStates().catch(console.error);
	}, [countryList, formGroup.get(FormKeys.COUNTRY).value]);

	function formatStateOrCountryListForSelect(statesOrCountries: Misc.IBaseCountry[]) {
		return statesOrCountries.map((item: Misc.IBaseCountry) => {
			return { value: item.isoCode, label: item.name };
		});
	}

	function isFormFilledOut(): boolean {
		return (
			!!formGroup.get(FormKeys.FIRST_NAME).value.toString().trim().length &&
			!!formGroup.get(FormKeys.LAST_NAME).value.toString().trim().length &&
			!!formGroup.get(FormKeys.ADDRESS1).value.toString().trim().length &&
			!!formGroup.get(FormKeys.CITY).value.toString().trim().length &&
			!!formGroup.get(FormKeys.ZIP).value.toString().trim().length &&
			!!formGroup.get(FormKeys.COUNTRY).value.toString().trim().length &&
			(!!formGroup.get(FormKeys.STATE).value.toString().trim().length || stateList.length === 0)
		);
	}

	const ConTwoDecDigit = (digit: any) => {
		return digit.indexOf('.') > 0
			? digit.split('.').length <= 4
				? digit.split('.')[0] + '.' + digit.split('.')[1].substring(-1, 4)
				: digit
			: digit;
	};

	async function updateFormGroup(control: RsFormControl) {
		if (control.key === 'firstName') {
			if (!isNaN(Number(control.value))) {
				// If the value is a number, clear it
				control.value = '';
			} else {
				// Remove special characters and ensure two decimal digits
				control.value = control.value.toString().replace(/[^A-Za-z]/g, '');
				control.value = ConTwoDecDigit(control.value);
			}
		}

		if (control.key === 'lastName') {
			if (!isNaN(Number(control.value))) {
				// If the value is a number, clear it
				control.value = '';
			} else {
				// Remove special characters and ensure two decimal digits
				control.value = control.value.toString().replace(/[^A-Za-z]/g, '');
				control.value = ConTwoDecDigit(control.value);
			}
		}

		if (control.key === FormKeys.COUNTRY) {
			let stateControl = formGroup.get(FormKeys.STATE);
			stateControl.value = '';
			formGroup.update(stateControl);
			setSelectState((prevState) => ++prevState);
		}
		await formGroup.isValid();
		setFormGroup(formGroup.update(control).clone());
	}

	async function handleUpdate() {
		if (!(await formGroup.isValid()) || !isFormFilledOut()) {
			rsToastify.error('Please Check all input fields', 'Error');
			setFormGroup(formGroup.clone());
			return;
		}
		let data: any = formGroup.toModel();
		props.onUpdate(data);
	}

	const scrollToBottom = () => {
		setTimeout(function () {
			ref.current?.scrollTo(0, 350);
		}, 10);
	};

	return (
		<Popup {...props} preventCloseByBackgroundClick>
			<Paper className={'rsUpdateAddressPopup'} borderRadius={'20px'}>
				<Box className="popupHeader">
					<Icon
						iconImg={'icon-close'}
						onClick={() => {
							popupController.close(UpdateAddressPopup);
						}}
						cursorPointer
					/>
					<Label variant={'h5'} className={'primaryTextColor'}>
						{props?.isPersonalInfo ? 'Edit Personal Info' : 'Edit Billing Info'}
					</Label>
				</Box>
				<Box className={'popupBody'} boxRef={ref} pb={1}>
					{/* <LabelCheckbox
						labelVariant={'h6'}
						value={''}
						text={'Use different billing address'}
						onSelect={() => {
							setIsPersonalForm(false);
						}}
						onDeselect={() => {
							setIsPersonalForm(true);
						}}
					/> */}
					<Box className="inputGroup">
						<LabelInput
							labelVariant={'h6'}
							title={'First Name'}
							inputType={'text'}
							control={formGroup.get(FormKeys.FIRST_NAME)}
							updateControl={updateFormGroup}
							isRequired
						/>
						<LabelInput
							labelVariant={'h6'}
							title={'Last Name'}
							inputType={'text'}
							control={formGroup.get(FormKeys.LAST_NAME)}
							updateControl={updateFormGroup}
							isRequired
						/>
					</Box>
					<LabelInput
						labelVariant={'h6'}
						title={'Address Line 1'}
						inputType={'text'}
						control={formGroup.get(FormKeys.ADDRESS1)}
						updateControl={updateFormGroup}
						isRequired
					/>
					<LabelInput
						labelVariant={'h6'}
						title={'Address Line 2'}
						inputType={'text'}
						control={formGroup.get(FormKeys.ADDRESS2)}
						updateControl={updateFormGroup}
					/>
					<Box className="inputGroup">
						<LabelInput
							labelVariant={'h6'}
							title={'City'}
							inputType={'text'}
							control={formGroup.get(FormKeys.CITY)}
							updateControl={updateFormGroup}
							isRequired
						/>
						<LabelInput
							labelVariant={'h6'}
							title={'Zip Code'}
							inputType={'text'}
							control={formGroup.get(FormKeys.ZIP)}
							updateControl={updateFormGroup}
							isRequired
						/>
					</Box>
					<Box className="inputGroup">
						<LabelSelect
							key={`select-${selectState}`}
							labelVariant={'h6'}
							title={'State'}
							updateControl={updateFormGroup}
							options={stateList}
							control={formGroup.get(FormKeys.STATE)}
							isRequired
							onFocus={scrollToBottom}
						/>
						<LabelSelect
							labelVariant={'h6'}
							title={'Country'}
							updateControl={updateFormGroup}
							options={countryList}
							control={formGroup.get(FormKeys.COUNTRY)}
							isRequired
							onFocus={scrollToBottom}
						/>
					</Box>
					{props?.isPersonalInfo && (
						<Box className="inputGroup">
							<LabelInput
								labelVariant={'h6'}
								className={'stretch small'}
								title={'Email Address'}
								inputType={FormKeys.EMAIL}
								control={formGroup.get(FormKeys.EMAIL)}
								updateControl={updateFormGroup}
							/>
							<LabelInput
								className={'phoneInput stretch small'}
								labelVariant={'h6'}
								inputType={'tel'}
								title={'Phone'}
								isPhoneInput
								onChange={(value) => {
									let updatedPhone = formGroup.get(FormKeys.PHONE);
									updatedPhone.value = value;
									setFormGroup(formGroup.clone().update(updatedPhone));
								}}
								initialValue={formGroup.get(FormKeys.PHONE).value.toString()}
							/>
						</Box>
					)}
				</Box>
				<Box className="popupFooter">
					<LabelButton
						label={'UPDATE'}
						look={'containedPrimary'}
						variant={'ButtonMdLg'}
						onClick={handleUpdate}
						disabled={!isFormFilledOut()}
					/>
				</Box>
			</Paper>
		</Popup>
	);
};

export default UpdateAddressPopup;
