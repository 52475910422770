import * as React from 'react';
import './CheckoutBreadcrumbs.scss';
import Icon from '@bit/redsky.framework.rs.icon';
import Label from '@bit/redsky.framework.rs.label';
import useIsAtBreakpoint from '../../customHooks/useIsAtBreakpoint';

interface CheckoutBreadcrumbsProps {
	activeStage: number;
	onBackButtonClick: () => void;
}

const CheckoutBreadcrumbs: React.FC<CheckoutBreadcrumbsProps> = (props) => {
	const isMobile = useIsAtBreakpoint();
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const resortId: any = urlParams.get('data');
	function handleClasses(stage: string, position: number, brandType: any) {
		let companyid;
		if (brandType) {
			companyid = JSON.parse(brandType).themeId;
		} else {
			companyid = null;
		}
		let bgColor = '';
		switch (companyid) {
			case 3:
				bgColor = 'encorereunion';
				// bgColor = '#e51c14';
				break;
			case 78:
				bgColor = 'cottagesbyrentyl';
				break;

			case 19:
				bgColor = 'cottagesbyrentyl';
				break;

			case 9:
				bgColor = 'bearsdenorlando';
				break;
			case 10:
				bgColor = 'resortsbyspectrum';
				break;
			default:
				bgColor = 'active';
				break;
		}

		if (position === props.activeStage) {
			return `${stage} ${bgColor}`;
		}

		return stage;
	}

	function renderBreadCrumbs(brandType: any) {
		return (
			<div className={'breadcrumbs'}>
				<Label variant={'buttonMdLg'} className={handleClasses('info', 0, brandType)}>
					Info
				</Label>
				<div className={'divider'} />
				<Label variant={'buttonMdLg'} className={handleClasses('payment', 1, brandType)}>
					Payment
				</Label>
				<div className={'divider'} />
				<Label variant={'buttonMdLg'} className={handleClasses('reviewAndBook', 2, brandType)}>
					Review & Book
				</Label>
				<div className={'divider'} />
				<Label variant={'buttonMdLg'} className={handleClasses('confirmation', 3, brandType)}>
					Confirmation
				</Label>
			</div>
		);
	}

	function renderSmallBreadCrumbs(brandType: any) {
		return (
			<div className={'smallBreadCrumbs'}>
				<div className={handleClasses('info', 0, brandType)}>
					<Label className={'stageTitle'} variant={'caption'}>
						Info
					</Label>
					<div className={'numberBubble'}>1</div>
				</div>
				<div className={'smallDivider'} />
				<div className={handleClasses('payment', 1, brandType)}>
					<Label className={'stageTitle'} variant={'caption'}>
						Payment
					</Label>
					<div className={'numberBubble'}>2</div>
				</div>
				<div className={'smallDivider'} />
				<div className={handleClasses('reviewAndBook', 2, brandType)}>
					<Label className={'stageTitle'} variant={'caption'}>
						Book
					</Label>
					<div className={'numberBubble'}>3</div>
				</div>
				<div className={'smallDivider'} />
				<div className={handleClasses('confirmation', 3, brandType)}>
					<Label className={'stageTitle'} variant={'caption'}>
						Confirmation
					</Label>
					<div className={'numberBubble'}>4</div>
				</div>
			</div>
		);
	}

	function handleCheckoutTitle() {
		if (props.activeStage > 2) {
			return 'Confirmation';
		}
		return 'Checkout';
	}

	return (
		<div className={'rsCheckoutBreadcrumbs'}>
			<div className={'backBarWrapper'}>
				{!isMobile && renderBreadCrumbs(resortId)}
				<div className={'backButtonWrapper'} onClick={props.onBackButtonClick}>
					<Icon className={'backButton'} iconImg={'icon-chevron-thin-down'} size={isMobile ? 25 : 38} />
				</div>
				<div className={'checkoutTitle'}>
					<Label variant={'ButtonMdLg'}>{handleCheckoutTitle()}</Label>
				</div>
			</div>
			{isMobile && renderSmallBreadCrumbs(resortId)}
		</div>
	);
};

export default CheckoutBreadcrumbs;
