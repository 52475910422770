// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rsTierDescriptionCard{max-width:500px;max-height:260px;height:100%;width:100%}.rsTierDescriptionCard .tierHeader{display:flex;border-bottom:1px solid var(--greyLineColor);padding-bottom:20px}.rsTierDescriptionCard .tierHeader .imageWrapper{max-width:46px;max-height:46px;padding-right:20px}.rsTierDescriptionCard .tierHeader .titleContainer{display:flex;flex-direction:column;justify-content:space-between}.rsTierDescriptionCard .tierHeader .titleContainer .capitalize{text-transform:uppercase}.rsTierDescriptionCard .paragraph{padding-top:20px}
`, "",{"version":3,"sources":["webpack://./src/components/tierDescriptionCard/TierDescriptionCard.scss","webpack://./src/themes/_mixins.scss"],"names":[],"mappings":"AAEA,uBACC,eAAgB,CAChB,gBAAiB,CACjB,WAAY,CACZ,UAAW,CAJZ,mCAME,YAAa,CACb,4CCgBiB,CDfjB,mBAAoB,CARtB,iDAUG,cAAe,CACf,eAAgB,CAChB,kBAAmB,CAZtB,mDAeG,YAAa,CACb,qBAAsB,CACtB,6BAA8B,CAjBjC,+DAmBI,wBAAyB,CAnB7B,kCAwBE,gBAAiB","sourcesContent":["@import 'src/themes/themes';\n\n.rsTierDescriptionCard {\n\tmax-width: 500px;\n\tmax-height: 260px;\n\theight: 100%;\n\twidth: 100%;\n\t.tierHeader {\n\t\tdisplay: flex;\n\t\tborder-bottom: 1px solid theme-var($greyLineColor);\n\t\tpadding-bottom: 20px;\n\t\t.imageWrapper {\n\t\t\tmax-width: 46px;\n\t\t\tmax-height: 46px;\n\t\t\tpadding-right: 20px;\n\t\t}\n\t\t.titleContainer {\n\t\t\tdisplay: flex;\n\t\t\tflex-direction: column;\n\t\t\tjustify-content: space-between;\n\t\t\t.capitalize {\n\t\t\t\ttext-transform: uppercase;\n\t\t\t}\n\t\t}\n\t}\n\t.paragraph {\n\t\tpadding-top: 20px;\n\t}\n}\n","@import 'variables';\n\n/*\n  include a map in a class\n*/\n@mixin spread-map($map: ()) {\n\t@each $key, $value in $map {\n\t\t#{$key}: $value;\n\t}\n}\n\n/*\n  Returns a CSS variable property specific to themeing.\n  Accepts an optional fallback, and optional map.\n  - SCSS: color: theme-var($--theme-primary, blue)\n  - CSS:  color: var(--theme-primary, blue)\n  Throws error if the variable not part of the map\n*/\n@function theme-var($key, $fallback: null, $map: $defaultTheme) {\n\t@if not map-has-key($map, $key) {\n\t\t@error \"key: '#{$key}', is not a key in map: #{$map}\";\n\t}\n\t@if ($fallback) {\n\t\t@return var($key, $fallback);\n\t} @else {\n\t\t@return var($key);\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primaryTextColor": `var(--primaryTextColor)`,
	"secondaryTextColor": `var(--secondaryTextColor)`,
	"successGreen": `var(--successGreen)`,
	"adminPink": `var(--adminPink)`,
	"warningRed": `var(--warningRed)`,
	"buttonLinkColor": `var(--buttonLinkColor)`,
	"secondaryButtonLinkColor": `var(--secondaryButtonLinkColor)`,
	"iconAndHighlightTextColor": `var(--iconAndHighlightTextColor)`,
	"primaryBackgroundColor": `var(--primaryBackgroundColor)`,
	"secondaryBackgroundColor": `var(--secondaryBackgroundColor)`,
	"errorColor": `var(--errorColor)`,
	"primaryBoxShadowColor": `var(--primaryBoxShadowColor)`,
	"greyLineColor": `var(--greyLineColor)`,
	"thinGreyLineColor": `var(--thinGreyLineColor)`,
	"white": `var(--white)`,
	"lightestGray": `var(--lightestGray)`,
	"backgroundGray": `var(--backgroundGray)`,
	"borderGray": `var(--borderGray)`,
	"medGray2": `var(--medGray2)`,
	"medGray2Active": `var(--medGray2Active)`,
	"black": `var(--black)`,
	"primaryCalendarColor": `var(--primaryCalendarColor)`,
	"secondaryCalendarColor": `var(--secondaryCalendarColor)`
};
export default ___CSS_LOADER_EXPORT___;
