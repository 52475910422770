import React from 'react';
import { useNavigate } from 'react-router-dom';
import './DestinationSearchResultCardResponsive.scss';
import { Box, popupController } from '@bit/redsky.framework.rs.996';
import Label from '@bit/redsky.framework.rs.label';
import CarouselV2 from '../../carouselV2/CarouselV2';
import LabelButton from '../../labelButton/LabelButton';
import UserService from '../../../services/user/user.service';
import { NumberUtils, ObjectUtils, StringUtils } from '../../../utils/utils';
import { useRecoilState, useRecoilValue } from 'recoil';
import globalState, { setRecoilExternalValue } from '../../../state/globalState';
import IconLabel from '../../iconLabel/IconLabel';
import AccommodationsPopup, { AccommodationsPopupProps } from '../../../popups/accommodationsPopup/AccommodationsPopup';
import ReviewPopup, { ReviewPopupProps } from '../../../popups/reviewPopup/ReviewPopup';
import Icon from '@bit/redsky.framework.rs.icon';
import ShowMoreLess from '../../showMoreLess/ShowMoreLess';
import metaCapi from '../../../customHooks/useMetaCapi';
import TagManager from 'react-gtm-module';
import themes from '../../../themes/themes.scss';
import { Rating } from 'react-simple-star-rating';
import serviceFactory from '../../../services/serviceFactory';
import useMerchantReward from '../../../customHooks/useMerchantReward';
import parse from 'html-react-parser';
import { get } from 'lodash';
import { getPageFinder, undefinedHandler } from '../../../utils/undefinedHandler';
import Button from '@bit/redsky.framework.rs.button';
import moment from 'moment';
import AccommodationService from '../../../services/accommodation/accommodation.service';

interface DestinationSearchResultCardResponsiveProps {
	className?: string;
	pointPerDollar: number;
	destinationObj: Api.Destination.Res.AvailabilitySummary;
	picturePaths: string[];
	onAddCompareClick?: () => void;
	onGalleryClick: () => void;
	onRemoveCompareClick?: () => void;
	locationInfo: string | undefined;
	FavoriteDestinationsIcon?: string;
	isAccommodations: number;
}

const DestinationSearchResultCardResponsive: React.FC<DestinationSearchResultCardResponsiveProps> = (props) => {
	let navigate = useNavigate();
	const user = useRecoilValue<Api.User.Res.Detail | undefined>(globalState.user);
	const userService = serviceFactory.get<UserService>('UserService');
	const userTierValue = useRecoilValue<number>(globalState.userTierValue);
	const [reservationFilters, setReservationFilters] = useRecoilState<Misc.ReservationFilters>(
		globalState.reservationFilters
	);
	const accommodationService = serviceFactory.get<AccommodationService>('AccommodationService');

	const googleProductViewHandler = (productData: any) => {
		try {
			TagManager.dataLayer({
				dataLayer: {
					event: 'product_view',
					ecommerce: null
				}
			});

			TagManager.dataLayer({
				dataLayer: {
					event: 'product_view',
					ecommerce: {
						detail: {
							products: [
								{
									id: productData.externalId,
									name: productData.name
								}
							]
						}
					}
				}
			});
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	};

	const googleProductViewHandlerViewItemList = async (productData?: any) => {
		const searchQueryObj: Misc.ReservationFilters = {
			...reservationFilters,
			destinationId: props.destinationObj.id
		};
		let key: keyof Misc.ReservationFilters;
		for (key in searchQueryObj) {
			if (searchQueryObj[key] === undefined) delete searchQueryObj[key];
		}
		searchQueryObj.pagination = { page: 1, perPage: 1 };

		let result: Api.Accommodation.Res.MinMaxAvailability = await accommodationService.availability(
			props.destinationObj.id,
			searchQueryObj
		);

		const view_item_list = {
			item_name: get(result, 'data.0.name', null),
			item_id: get(result, 'data.0.externalSystemId', result?.data[0]?.id),
			price: NumberUtils.centsToDollars(get(result, 'data.0.prices.0.baseRates', null)),
			item_brand: props.destinationObj.name,
			item_category: get(result, 'data.0.contentLists.RoomCategoryList.0.Name', null),
			item_category2: get(result, 'data.0.contentLists.RoomCategoryList.0.Name', null),
			item_list_name: 'Rooms',
			arrival_date: searchQueryObj.startDate,
			depart_date: searchQueryObj.endDate
		};

		try {
			TagManager.dataLayer({
				dataLayer: {
					event: 'view_item_list',
					ecommerce: null
				}
			});

			TagManager.dataLayer({
				dataLayer: {
					event: 'view_item_list',
					ecommerce: {
						detail: {
							products: [view_item_list]
						}
					}
				}
			});
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	};

	function setDestinationIdAndRegionIdGlobal() {
		setRecoilExternalValue<Misc.ReservationFilters>(globalState.reservationFilters, {
			...reservationFilters,
			destinationId: props.destinationObj.id,
			flipToregionIds: props.destinationObj.regions
		});
	}

	const UserResortSelectedCampaign = () => {
		try {
			if (props.destinationObj.campaignDefinition !== null && props.destinationObj.campaignDefinition !== '') {
				if (user?.id !== undefined) {
					userService.logUserResortSelectedCampaign(user.id, props.destinationObj);
				} else {
					userService.logUserResortSelectedCampaign('UNKNOWN', props.destinationObj);
				}
			}
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	};

	async function navigateToDestination() {
		try {
			UserResortSelectedCampaign();
			setDestinationIdAndRegionIdGlobal();
			setReservationFilters((prev) => {
				return { ...prev, destinationId: props.destinationObj.id };
			});
			googleProductViewHandlerViewItemList(props.destinationObj);
			googleProductViewHandler(props.destinationObj);
			metaCapi.checkAvailability(props.destinationObj);

			const destinationName = props.destinationObj.name.split(' ').join('-').toLocaleLowerCase();
			if (user) {
				const sessionId = moment(new Date()).valueOf().toString();
				const data = {
					sessionId: sessionId,
					...reservationFilters,
					destinationId: props.destinationObj.id,
					destinationName: props.destinationObj.name
				};
				(async () => await userService.saveSearchHistory(data))();
			}

			navigate(`/destination/${destinationName}`, {
				state: {
					...reservationFilters,
					destinationId: props.destinationObj.id
				}
			});
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	function renderCampaignMessage() {
		try {
			if (
				props.destinationObj?.campaigns === undefined ||
				props.destinationObj?.campaigns === null ||
				props.destinationObj.campaigns.length === 0
			) {
				return '';
			}
			let campaignsData = props.destinationObj?.campaigns.sort(function (a, b) {
				return new Date(b.createdOn).valueOf() - new Date(a.createdOn).valueOf();
			});
			let index = campaignsData.length - 1;
			return <>{parse(get(campaignsData, index + '.campaignMessage', ''))}</>;
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	function pointEarningCalculation(): string {
		try {
			let calc = 0;
			let pointEarnText;

			if (
				props.destinationObj?.campaigns === undefined ||
				props.destinationObj.campaigns === null ||
				props.destinationObj.campaigns.length === 0
			) {
				calc =
					Number(
						StringUtils.formatMoney(props.destinationObj?.minAccommodationPriceInCents).replace(/,/g, '')
					) *
						props?.pointPerDollar *
						1 +
					0;
				pointEarnText = 'You could earn from ' + StringUtils.addCommasToNumber(calc) + ' points for this stay';
				return pointEarnText;
			}

			var campaignsData = props.destinationObj.campaigns?.sort(function (a, b) {
				return new Date(b.createdOn).valueOf() - new Date(a.createdOn).valueOf();
			});

			if (campaignsData[0]?.pointMultiplier === 0) {
				return '';
			}

			const currentDate = new Date();
			const startDate = new Date(campaignsData[0]?.startOn);
			const endDate = new Date(campaignsData[0]?.endOn);

			const daysOfTheWeek = campaignsData[0]?.activeOnDays;

			if ((daysOfTheWeek === undefined || daysOfTheWeek === '') && campaignsData[0]?.alwaysActive !== 1) {
				calc =
					Number(
						StringUtils.formatMoney(props.destinationObj?.minAccommodationPriceInCents).replace(/,/g, '')
					) *
						props?.pointPerDollar *
						1 +
					0;
				pointEarnText = 'You could earn from ' + StringUtils.addCommasToNumber(calc) + ' points for this stay';
				return pointEarnText;
			}

			const arrDaysOfTheWeek = daysOfTheWeek.split(',');
			let getTodayDates = new Date().getDay();
			let count = 0;

			if (getTodayDates === 0) {
				getTodayDates = 7;
			}

			arrDaysOfTheWeek.forEach((item) => {
				if (getTodayDates === Number(item)) {
					count++;
				}
			});

			if (count !== 1) {
				calc =
					Number(
						StringUtils.formatMoney(props.destinationObj?.minAccommodationPriceInCents).replace(/,/g, '')
					) *
						props?.pointPerDollar *
						1 +
					0;

				pointEarnText = 'You could earn from ' + StringUtils.addCommasToNumber(calc) + ' points for this stay';
				return pointEarnText;
			}

			if (campaignsData.length === 0) {
				calc =
					Number(
						StringUtils.formatMoney(props.destinationObj?.minAccommodationPriceInCents).replace(/,/g, '')
					) *
						props?.pointPerDollar *
						1 +
					0;
			} else {
				if (campaignsData[0]?.alwaysActive === 1) {
					calc =
						Number(
							StringUtils.formatMoney(props.destinationObj?.minAccommodationPriceInCents).replace(
								/,/g,
								''
							)
						) *
							props?.pointPerDollar *
							campaignsData[0]?.pointMultiplier +
						campaignsData[0]?.bulkPoint;
				} else {
					// Check if the current date is between the start and end dates
					if (currentDate >= startDate && currentDate <= endDate) {
						calc =
							Number(
								StringUtils.formatMoney(props.destinationObj?.minAccommodationPriceInCents).replace(
									/,/g,
									''
								)
							) *
								props?.pointPerDollar *
								campaignsData[0]?.pointMultiplier +
							campaignsData[0]?.bulkPoint;
					} else {
						calc =
							Number(
								StringUtils.formatMoney(props.destinationObj?.minAccommodationPriceInCents).replace(
									/,/g,
									''
								)
							) *
								props?.pointPerDollar *
								1 +
							0;
					}
				}
			}

			pointEarnText = 'You could earn from ' + StringUtils.addCommasToNumber(calc) + ' points for this stay';
			return pointEarnText;
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);

			return '';
		}
	}

	function renderPricePerNight() {
		if (reservationFilters.redeemPoints && props.destinationObj.loyaltyStatus === 'ACTIVE') {
			return !!props.destinationObj.minAccommodationPriceInPoints ? (
				<Box
					display={'flex'}
					alignItems={props.className === 'short' ? 'flex-start' : 'flex-end'}
					justifyContent={'flex-end'}
					flexDirection={'column'}
				>
					<Label variant={'body1B'} mb={8}>
						from
					</Label>
					<Label variant={props.className !== 'short' ? 'h1' : 'h2'} className={'secondaryTextColor'} mb={8}>
						{StringUtils.addCommasToNumber(get(props, 'destinationObj.minAccommodationPriceInPoints'))}
					</Label>
					{props.className !== 'short' && (
						<>
							<Label variant={'body1'} className={'primaryTextColor'} mb={16}>
								points per night
							</Label>
							<div className={'campaignMessage'}>{renderCampaignMessage()}</div>
						</>
					)}
				</Box>
			) : null;
		} else {
			return !!props.destinationObj.minAccommodationPriceInCents ? (
				<Box
					display={'flex'}
					alignItems={props.className === 'short' ? 'flex-start' : 'flex-end'}
					justifyContent={'flex-end'}
					flexDirection={'column'}
				>
					<Label variant={'body1'}>from</Label>
					<div className="price-button">
						<Label
							variant={props.className === 'short' ? 'h2' : 'h1'}
							mb={8}
							onClick={navigateToDestination}
						>
							{StringUtils.formatCurrency(props.destinationObj.currencyCode)}
							{StringUtils.formatMoney(props.destinationObj.minAccommodationPriceInCents)}
						</Label>
						{props.className === 'short' && (
							<Button
								children={<Label variant={'buttonMdLg'}>Book Now</Label>}
								look={'containedPrimary'}
								className={'yellow discoverSignupButton'}
								onClick={navigateToDestination}
							/>
						)}
					</div>

					{/* <Label variant={'body1B'} mb={8}>
						or
					</Label>

					<div className="priceContainer__pointPrice">
						{StringUtils.addCommasToNumber(props.destinationObj.minAccommodationPriceInPoints)}
					</div>
					<div className="priceContainer__pointPriceName">points</div> */}

					{props.className !== 'short' && (
						<>
							<Label variant={'body1'} className={'primaryTextColor'}>
								per night
							</Label>
							<Label variant={'body1'} mb={16} className={'primaryTextColor'}>
								+taxes and fees
							</Label>
							{!reservationFilters.redeemPoints && props.destinationObj.loyaltyStatus === 'ACTIVE' && (
								<div>
									<Label className={'secondaryTextColor alignmentCenter'} variant={'captionBI'}>
										{/* {useMerchantReward.pointEarningCalculation(
											{
												campaigns: props.destinationObj.campaigns,
												minAccommodationPriceInCents: props.destinationObj.baseRates
											},
											props.pointPerDollar,
											userTierValue,
											user
										)} */}
										{useMerchantReward.rewardPointsCalculation(
											{
												campaigns: props.destinationObj.campaigns,
												minAccommodationPriceInCents: props.destinationObj.baseRates
											},
											props.pointPerDollar,
											userTierValue,
											user
										)}
									</Label>
								</div>
							)}
							{!reservationFilters.redeemPoints && props.destinationObj.loyaltyStatus === 'ACTIVE' && (
								<div className={'campaignMessage'}>{renderCampaignMessage()}</div>
							)}
						</>
					)}
				</Box>
			) : null;
		}
	}

	function renderExperiences() {
		try {
			const destinationExperiences = props.destinationObj.experiences;
			return destinationExperiences
				.filter((experience) => experience.isHighlighted)
				.map((experience) => {
					return (
						<IconLabel
							key={experience.id}
							labelName={experience.title}
							iconImg={experience.icon}
							iconPosition={'top'}
							iconSize={45}
							labelVariant={'body1'}
							disableClampSize
						/>
					);
				});
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	function renderButtons() {
		try {
			return props.destinationObj.propertyTypes.map((button) => {
				return (
					<LabelButton
						key={button.id}
						look={'containedPrimary'}
						variant={'buttonSm'}
						label={button.name}
						onClick={(event) => {
							try {
								UserResortSelectedCampaign();
								// setDestinationIdAndRegionIdGlobal();
								googleProductViewHandler(props.destinationObj);
								metaCapi.checkAvailability(props.destinationObj);
								popupController.open<AccommodationsPopupProps>(AccommodationsPopup, {
									propertyTypeName: button.name,
									pointPerDollar: props.pointPerDollar,
									destinationObj: props.destinationObj,
									destinationId: props.destinationObj.id,
									destinationName: props.destinationObj.name,
									packageCount: 0,
									propertyTypeId: button.id,
									loyaltyStatus: props.destinationObj.loyaltyStatus,
									upfrontCashRequired: !!props.destinationObj.upfrontCashRequired,
									isCustomResort: props.destinationObj.isCustomResort,
									phone: props.destinationObj.phone
								});
								event.stopPropagation();
							} catch (error: any) {
								const message = `Getting error :${error.message} on ${getPageFinder(
									window.location.pathname
								)} page.`;
								undefinedHandler(message);
							}
						}}
					/>
				);
			});
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	function reviewPopup(event: any) {
		event.stopPropagation();
		return popupController.open<ReviewPopupProps>(ReviewPopup, {
			destinationId: props.destinationObj.id,
			googleTotalReview: props.destinationObj.googleTotalReview,
			googleAllOverReview: props.destinationObj.googleAllOverReview
		});
	}

	return (
		<Box className={`rsDestinationSearchResultCardResponsive ${props.className || ''}`}>
			<Box className={'carouselBox'}>
				<CarouselV2
					path={navigateToDestination}
					imgPaths={props.picturePaths}
					isAccommodations={props.isAccommodations}
					resortPoint={props.destinationObj.pointsEarnable}
					onAddCompareClick={() => {
						if (props.onAddCompareClick) props.onAddCompareClick();
					}}
					onGalleryClick={props.onGalleryClick}
					onRemoveCompareClick={() => {
						if (props.onRemoveCompareClick) props.onRemoveCompareClick();
					}}
					destinationObj={props.destinationObj}
					FavoriteDestinationsIcon={props.FavoriteDestinationsIcon}
					destinationId={props.destinationObj.id}
					imageWidth={414}
				/>
				<Box className={'descriptionInformationContainer'}>
					<Label
						variant={'h5'}
						paddingBottom={'10px'}
						className="destinationNameContainer"
						onClick={navigateToDestination}
					>
						{get(props, 'destinationObj.name')}
						{!!!props.destinationObj.minAccommodationPriceInCents && (
							<Label color={themes.errorColor} variant={'h6'} margin={0} marginLeft={20}>
								{'Unavailable for the dates selected.'}
							</Label>
						)}
					</Label>
					<Box
						display={'flex'}
						paddingBottom={'16px'}
						onClick={navigateToDestination}
						className="destination-description"
					>
						<Label variant={'h6'} paddingRight={'40px'}>
							{props.destinationObj.minBedroom === props.destinationObj.maxBedroom
								? props.destinationObj.minBedroom
								: `${props.destinationObj.minBedroom} - ${props.destinationObj.maxBedroom}`}{' '}
							Bedrooms
						</Label>
						<Label
							variant={'h6'}
							paddingRight={
								!!props.destinationObj.googleAllOverReview &&
								!!props.destinationObj.googleTotalReview &&
								props.className !== 'short'
									? '40px'
									: 0
							}
						>
							<Icon iconImg="icon-pin" size={15} color="#FF6469" className="locationIcon" />
							{get(props, 'locationInfo')}
						</Label>
						{/* TEMPORARY CODE IS COMMENT FOR GOOGLE REVIEWS  */}
						{/* {!!props.destinationObj.googleAllOverReview &&
							!!props.destinationObj.googleTotalReview &&
							props.className !== 'short' && (
								<Box
									display={'flex'}
									marginBottom={'10px'}
									alignItems={'center'}
									onClick={(event) => reviewPopup(event)}
								>
									<Label variant={'h6'}>{get(props, 'destinationObj.googleAllOverReview')}</Label>
									<Rating
										initialValue={props.destinationObj.googleAllOverReview}
										readonly={true}
										allowFraction={true}
										SVGstyle={{ width: 18, height: 18 }}
										style={{ margin: '0 8px 0 5px' }}
									/>
									<Label
										color={themes.primaryTextColor}
										variant={'h6'}
										className="googleReviewFormate"
									>
										{get(props, 'destinationObj.googleTotalReview') + ' Google reviews'}
									</Label>
								</Box>
							)} */}
					</Box>
					{props.className !== 'short' && (
						<>
							<Box display={'flex'} mb={'18px'}>
								<Label variant={'body1'} className={'destinationDescription'}>
									<ShowMoreLess
										textDescription={get(props, 'destinationObj.description')}
										textLength={135}
									/>
								</Label>
							</Box>
							<Box className={'featureIcons'} marginBottom={'20px'} onClick={navigateToDestination}>
								{renderExperiences()}
							</Box>
							<div onClick={navigateToDestination}>
								<Label variant={'subtitle1'} mb={4}>
									{ObjectUtils.isArrayWithData(props.destinationObj.propertyTypes) &&
										'Accommodation Types'}
								</Label>
								<Box display={'flex'} gap={'24px'}>
									{renderButtons()}
								</Box>
							</div>
						</>
					)}
				</Box>
			</Box>
			{renderPricePerNight()}
		</Box>
	);
};

export default DestinationSearchResultCardResponsive;
