import React, { useState } from 'react';
import moment from 'moment';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './MaskedDateRangeSelector.scss';
import { Box } from '@bit/redsky.framework.rs.996';
import DateRangeSelector from '../dateRangeSelector/DateRangeSelector';
import Button from '@bit/redsky.framework.rs.button';
import Label, { LabelProps } from '@bit/redsky.framework.rs.label';
import Icon from '@bit/redsky.framework.rs.icon';

export interface MaskedDateRangeSelectorProps {
	onDatesChange: (startDate: moment.Moment | null, endDate: moment.Moment | null) => void;
	startDate: moment.Moment | null;
	endDate: moment.Moment | null;
	startDatePlaceholderText?: string;
	endDatePlaceholderText?: string;
	monthsToShow: number;
	className?: string;
	startDateLabel?: string;
	endDateLabel?: string;
	iconSize?: number;
	minStay?: number;
	labelVariant?: LabelProps['variant'];
	isMobile?: boolean;
}

const MaskedDateRangeSelector: React.FC<MaskedDateRangeSelectorProps> = (props) => {
	const [focusedInput, setFocusedInput] = useState<'startDate' | 'endDate' | null>(null);

	function openDateRangePicker(rangePart: 'startDate' | 'endDate') {
		setFocusedInput(rangePart);
	}

	return (
		<Box className={`rsMaskedDateRangeSelector ${props.className || ''}`}>
			{props.minStay === Infinity ? (
				<DateRangeSelector
					className={'responsiveDateRangeSelector'}
					startDatePlaceholderText={props.startDatePlaceholderText}
					endDatePlaceholderText={props.endDatePlaceholderText}
					onDatesChange={props.onDatesChange}
					startDate={props.startDate}
					endDate={props.endDate}
					focusedInput={focusedInput}
					// iconSize={props.iconSize}
					onFocusChange={(focus) => setFocusedInput(focus)}
					monthsToShow={props.monthsToShow}
					showSeparator={false}
				/>
			) : (
				<DateRangeSelector
					className={'responsiveDateRangeSelector'}
					startDatePlaceholderText={props.startDatePlaceholderText}
					endDatePlaceholderText={props.endDatePlaceholderText}
					onDatesChange={props.onDatesChange}
					startDate={props.startDate}
					endDate={props.endDate}
					focusedInput={focusedInput}
					// iconSize={props.iconSize}
					onFocusChange={(focus) => setFocusedInput(focus)}
					monthsToShow={props.monthsToShow}
					showSeparator={false}
					minimumNights={props.minStay}
				/>
			)}

			<Box className={'startDateBox'} onClick={() => openDateRangePicker('startDate')}>
				<Box className="startContaner">
					{!!props.startDateLabel && <Label className="startDateLabel">{props.startDateLabel}</Label>}
					<Button
						className={'startDateMask'}
						onClick={() => {
							openDateRangePicker('startDate');
						}}
						look={'textPrimary'}
					>
						{props.startDate?.format('MM-DD-YY')}
					</Button>
				</Box>
				<Button look={'none'}>
					<Icon className={'iconDown'} iconImg={'icon-chevron-thin-down'} size={25} />
				</Button>
			</Box>
			<Box className={'endDateBox'} onClick={() => openDateRangePicker('endDate')}>
				<Box className="endContaner">
					{!!props.endDateLabel && <Label className="endDateLabel">{props.endDateLabel}</Label>}
					<Button
						className={'endDateMask'}
						onClick={() => {
							openDateRangePicker('endDate');
						}}
						look={'textPrimary'}
					>
						{props.endDate?.format('MM-DD-YY')}
					</Button>
				</Box>

				<Button look={'none'}>
					<Icon className={'iconDown'} iconImg={'icon-chevron-thin-down'} size={25} />
				</Button>
			</Box>
		</Box>
	);
};

export default MaskedDateRangeSelector;
