import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ScrollToTop from './components/scrollToTop/ScrollToTop';
import Applayer from './Applayer';
import AppBar from './components/appBar/AppBar';
import Footer from './components/footer/Footer';
import { FooterLinks } from './components/footer/FooterLinks';
import AccountAddressPage from './pages/accountAddressPage/AccountAddressPage';
import AccountLandingPage from './pages/accountLandingPage/AccountLandingPage';
import AccountPaymentMethodsPage from './pages/accountPaymentMethodsPage/AccountPaymentMethodsPage';
import AccountPersonalInfoPage from './pages/accountPersonalInfoPage/AccountPersonalInfoPage';
import AccountPointsPage from './pages/accountPointsPage/AccountPointsPage';
import BookingFlowAddPackagePage from './pages/bookingFlowAddPackagePage/BookingFlowAddPackagePage';
import CheckoutFlowPage from './pages/checkoutFlowPage/CheckoutFlowPage';
import DestinationDetailsPage from './pages/destinationDetailsPage/DestinationDetailsPage';
import ExistingItineraryPage from './pages/existingItineraryPage/ExistingItineraryPage';
import NotFoundPage from './pages/notFoundPage/notFoundPage';
import PrivacyPolicyPage from './pages/privacyPolicyPage/PrivacyPolicyPage';
import ResetPasswordPage from './pages/resetPasswordPage/ResetPasswordPage';
import ReservationAvailabilityPage from './pages/reservationAvailabilityPage/ReservationAvailabilityPage';
import ReservationDetailsPage from './pages/reservationDetailsPage/ReservationDetailsPage';
import TermsAndConditionsPage from './pages/termsAndConditionsPage/TermsAndConditionsPage';
import AccountTravelPreferences from './pages/accountTravelPreferences/AccountTravelPreferences';
import TermsOfUsePage from './pages/termsOfUsePage/TermsOfUsePage';
import VerifyEmailPage from './pages/verifyEmailPage/VerifyEmailPage';
import RentylRewardsSignPage from './pages/RentylRewardsSignPage/RentylRewardsSignPage';
import CheckoutPage from './pages/CheckoutPage/CheckoutPage';

const MainRouter = () => {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<ReservationAvailabilityPage />} />
				<Route path="/rentyl-rewards-sign-up" element={<RentylRewardsSignPage />} />
				<Route path="/verify/:userId/:token" element={<VerifyEmailPage />} />
				<Route path="/legal/privacy" element={<PrivacyPolicyPage />} />
				<Route path="/legal/terms-and-conditions" element={<TermsAndConditionsPage />} />
				<Route path="/legal/terms-of-use" element={<TermsOfUsePage />} />
				<Route path="/password-reset" element={<ResetPasswordPage />} />
				<Route path="/reservation/availability" element={<ReservationAvailabilityPage />} />
				<Route path="/reservations" element={<ExistingItineraryPage />} />
				<Route path="/reservations/details" element={<ReservationDetailsPage />} />
				<Route path="/booking" element={<CheckoutFlowPage />} />
				{/* <Route path="/booking/checkout" element={<CheckoutFlowPage />} /> */}
				<Route path="/booking/checkout" element={<CheckoutPage />} />
				<Route path="/booking/checkout/pdf" element={<CheckoutFlowPage />} />
				<Route path="/booking/packages" element={<BookingFlowAddPackagePage />} />
				<Route path="/destination/:name" element={<DestinationDetailsPage />} />
				<Route path="/account" element={<AccountLandingPage />} />
				<Route path="/account/personal-info" element={<AccountPersonalInfoPage />} />
				<Route path="/account/address" element={<AccountAddressPage />} />
				<Route path="/account/points" element={<AccountPointsPage />} />
				<Route path="/account/travel-preferences" element={<AccountTravelPreferences />} />
				<Route path="/account/payment-methods" element={<AccountPaymentMethodsPage />} />
				<Route path="/reservation-not-found" element={<NotFoundPage />} />
				<Route path="*" element={<NotFoundPage />} />
			</Routes>
			<ScrollToTop />
			<AppBar />
			<Footer links={FooterLinks} />
			<Applayer />
		</BrowserRouter>
	);
};

export default MainRouter;
