import React from 'react';
import Script from '../../components/script/Script';
import { getPageFinder, undefinedHandler } from '../../utils/undefinedHandler';
import { NumberUtils } from '../../utils/utils';

interface RulerAnalyticsProps {
	orderDetails: any;
}
const RulerAnalytics: React.FC<RulerAnalyticsProps> = (props) => {
	const addDotToShowCent = (amount: Number) => {
		try {
			const amountConvertedToString = amount.toString();

			const last2Str = amountConvertedToString.slice(-2); // remove the last two digit
			const amountWithoutLast2Str = amountConvertedToString.slice(0, -2); //delete last 2 from number

			//join now
			const newAmount = Number(amountWithoutLast2Str + '.' + last2Str);
			return newAmount.toFixed(2);
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	};

	function RulerThankYouPageTrackingCode() {
		try {
			const averageDailyRateData = props.orderDetails.stays[0].priceDetail.accommodationDailyCostsInCents;
			let averageDailyRate: any = {};

			for (const key in averageDailyRateData) {
				if (averageDailyRateData.hasOwnProperty(key)) {
					averageDailyRate[key] = NumberUtils.centsToDollars(averageDailyRateData[key]);
				}
			}

			return `
                function RulerThankYouPageTracking() {
                    try {
                        if (!RulerAnalyticsVisitorId)
                            throw "Ruler Analytics is not set on the page";
        
                        var RulerAnalyticsPayload = {
                            action: "convert",
                            orderId: "${props.orderDetails.stays[0].externalConfirmationId}",
                            email: "${props.orderDetails.stays[0].guest.email}",
                            revenue: "${NumberUtils.centsToDollars(
								props.orderDetails.stays[0].priceDetail.subtotalInCents
							)}",
                            ADR: ${JSON.stringify(averageDailyRate)},
							resortId: "${props.orderDetails.destination.id}",
							resortName: "${props.orderDetails.destination.name}"
                        };
        
                        RulerAnalytics.trackConversion(RulerAnalyticsPayload,"","",500);
                    } catch (error) {
                        setTimeout(function () {
                            console.error(error);
                            RulerThankYouPageTracking();
                        }, 300);
                    }
                }
                RulerThankYouPageTracking();
         
		`;
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	return (
		<>
			<Script text={RulerThankYouPageTrackingCode()} tag="head" />
		</>
	);
};

export default RulerAnalytics;
