import {
	atom,
	Loadable,
	RecoilState,
	RecoilValue,
	useRecoilCallback,
	useRecoilTransactionObserver_UNSTABLE
} from 'recoil';
import * as React from 'react';
import moment from 'moment';

enum GlobalStateKeys {
	COMPARISON_CARD = 'DestinationComparison',
	COMPARISON_CARD_SHOW = 'destinationComparisonError',
	DESTINATION_SELECTION = 'DestinationSelection',
	USER_TOKEN = 'UserToken',
	USER = 'User',
	CHECKOUT_USER = 'CheckoutUser',
	COMPANY = 'Company',
	VERIFIED_ACCOMMODATION = 'VerifiedAccommodation',
	RESERVATION_FILTERS = 'ReservationFilter',
	CountryList = 'CountryList',
	LAST_NAVIGATION_PATH = 'LastNavigationPath',
	REGION_NAME = 'RegionName',
	CHECKOUT_FORM_HAS_ERROR = 'CheckoutFormHasError',
	REGION_NAME_LIST = 'regionNameList',
	REGION_NAME_LIST_ID = 'regionNameListId',
	CALL_TO_ACTION_POPUP = 'callToActionPopup',
	USER_TIER_VALUE = 'userTierValue',
	IS_AFFIRM_PAY = 'isAffirmPay',
	SEARCH_HISTORY_SHOW = 'searchHistoryShow',
	IS_FETCHINGRESULTS = 'isFetchingResults',
	ANONYMOUSUSERDATA = 'anonymousUserData',
	IS_SAVE_PAYMENT_METHOD_GLOBAL = 'isSavePaymentMethodGlobal'
}

// Change based on project so we don't have classing when developing on localhost (va = Volcanic Admin)
export const KEY_PREFIX = 'spireCust-';

class GlobalState {
	destinationComparison: RecoilState<Misc.ComparisonState>;
	destinationComparisonError: RecoilState<boolean>;
	userToken: RecoilState<string>;
	callToActionPopup: RecoilState<number>;
	user: RecoilState<Api.User.Res.Detail | undefined>;
	userTierValue: RecoilState<number>;
	checkoutUser: RecoilState<Misc.Checkout | undefined>;
	anonymousUserData: any;
	company: RecoilState<Api.Company.Res.GetCompanyAndClientVariables>;
	verifiedAccommodation: RecoilState<Api.Reservation.Res.Verification | undefined>;
	reservationFilters: RecoilState<Misc.ReservationFilters>;
	lastNavigationPath: RecoilState<string>;
	regionName: RecoilState<string>;
	checkoutFormHasError: RecoilState<Misc.CheckoutFormError>;
	regionNameList: RecoilState<any | undefined>;
	regionNameListId: RecoilState<any | undefined>;
	saveToStorageList: string[] = [];
	destinationSelection: RecoilState<number[]>;
	countryList: RecoilState<Misc.CountryList>;
	searchHistoryShow: RecoilState<number>;
	isFetchingResults: RecoilState<boolean>;
	isAffirmPay: RecoilState<number>;
	isSavePaymentMethodGlobal: RecoilState<boolean>;

	constructor() {
		this.destinationComparison = atom<Misc.ComparisonState>({
			key: GlobalStateKeys.COMPARISON_CARD,
			default: this.loadFromLocalStorage<Misc.ComparisonState>(GlobalStateKeys.COMPARISON_CARD, {
				destinationDetails: [],
				showCompareButton: false
			})
		});

		this.destinationComparisonError = atom<boolean>({
			key: GlobalStateKeys.COMPARISON_CARD_SHOW,
			default: true
		});

		this.isSavePaymentMethodGlobal = atom<boolean>({
			key: GlobalStateKeys.IS_SAVE_PAYMENT_METHOD_GLOBAL,
			default: false
		});

		this.destinationSelection = atom<number[]>({
			key: GlobalStateKeys.DESTINATION_SELECTION,
			default: undefined || []
		});

		this.checkoutFormHasError = atom<Misc.CheckoutFormError>({
			key: GlobalStateKeys.CHECKOUT_FORM_HAS_ERROR,
			default: {
				card: true,
				cvv: true
			}
		});

		this.user = atom<Api.User.Res.Detail | undefined>({
			key: GlobalStateKeys.USER,
			default: undefined
		});

		this.checkoutUser = atom<Misc.Checkout | undefined>({
			key: GlobalStateKeys.CHECKOUT_USER,
			default: undefined
		});

		this.userToken = atom<string>({
			key: GlobalStateKeys.USER_TOKEN,
			default: this.loadFromLocalStorage<string>(GlobalStateKeys.USER_TOKEN, '')
		});

		this.callToActionPopup = atom<number>({
			key: GlobalStateKeys.CALL_TO_ACTION_POPUP,
			default: 0
		});

		this.userTierValue = atom<number>({
			key: GlobalStateKeys.USER_TIER_VALUE,
			default: 1
		});

		this.searchHistoryShow = atom<number>({
			key: GlobalStateKeys.SEARCH_HISTORY_SHOW,
			default: 0
		});

		this.anonymousUserData = atom<any>({
			key: GlobalStateKeys.ANONYMOUSUSERDATA,
			default: null
		});

		this.isFetchingResults = atom<boolean>({
			key: GlobalStateKeys.IS_FETCHINGRESULTS,
			default: true
		});

		this.isAffirmPay = atom<number>({
			key: GlobalStateKeys.IS_AFFIRM_PAY,
			default: 0
		});

		this.company = atom<Api.Company.Res.GetCompanyAndClientVariables>({
			key: GlobalStateKeys.COMPANY,
			default: {
				id: 0,
				name: '',
				squareLogoUrl: '',
				wideLogoUrl: '',
				allowPointBooking: 0,
				allowCashBooking: 0,
				customPages: {},
				unauthorizedPages: [],
				redemptionRatio: 0,
				affirmStatus: 1
			}
		});

		this.verifiedAccommodation = atom<Api.Reservation.Res.Verification | undefined>({
			key: GlobalStateKeys.VERIFIED_ACCOMMODATION,
			default: undefined
		});

		// The following is stored in local storage automatically
		this.saveToStorageList = [
			GlobalStateKeys.USER_TOKEN,
			GlobalStateKeys.COMPARISON_CARD,
			GlobalStateKeys.CHECKOUT_USER
		];

		this.reservationFilters = atom<Misc.ReservationFilters>({
			key: GlobalStateKeys.RESERVATION_FILTERS,
			default: {
				startDate: moment(new Date()).add(14, 'days').format('YYYY-MM-DD'),
				endDate: moment(new Date()).add(19, 'days').format('YYYY-MM-DD'),
				adultCount: 1,
				childCount: 0,
				redeemPoints: false,
				sortOrder: 'ASC',
				pagination: { page: 1, perPage: 10 },
				regionIds: [],
				flipToregionIds: [],
				init: false
			}
		});

		this.countryList = atom<Misc.CountryList>({
			key: GlobalStateKeys.CountryList,
			default: { list: null }
		});

		this.regionName = atom<string>({
			key: GlobalStateKeys.REGION_NAME,
			default: ''
		});

		this.regionNameList = atom<any>({
			key: GlobalStateKeys.REGION_NAME_LIST,
			default: ''
		});

		this.regionNameListId = atom<any>({
			key: GlobalStateKeys.REGION_NAME_LIST_ID,
			default: ''
		});

		this.lastNavigationPath = atom<string>({
			key: GlobalStateKeys.LAST_NAVIGATION_PATH,
			default: ''
		});
	}

	private loadFromLocalStorage<T>(key: string, defaultValue: T): T {
		let item = localStorage.getItem(KEY_PREFIX + key);
		if (!item) return defaultValue;
		try {
			item = JSON.parse(item);
		} catch (e) {}
		// @ts-ignore
		return item;
	}
}

export function clearPersistentState() {
	// All we really need to do is clear local storage
	localStorage.clear();
}

export const GlobalStateObserver: React.FC = () => {
	useRecoilTransactionObserver_UNSTABLE(({ snapshot }) => {
		for (const item of snapshot.getNodes_UNSTABLE({ isModified: true })) {
			let value = snapshot.getLoadable(item).contents as string;
			if (process.env.NODE_ENV === 'development') {
				console.log('Recoil item changed: ', item.key);
				console.log('Value: ', value);
			}

			if (globalState.saveToStorageList.includes(item.key)) {
				if (typeof value === 'object') value = JSON.stringify(value);
				localStorage.setItem(KEY_PREFIX + item.key, value);
			}
		}
	});
	return null;
};

const globalState = new GlobalState();
export default globalState;

/**
 * Returns a Recoil state value, from anywhere in the app.
 *
 * Can be used outside of the React tree (outside a React component), such as in utility scripts, etc.

 * <GlobalStateInfluencer> must have been previously loaded in the React tree, or it won't work.
 * Initialized as a dummy function "() => null", it's reference is updated to a proper Recoil state mutator when GlobalStateInfluencer is loaded.
 *
 * @example const lastCreatedUser = getRecoilExternalValue(lastCreatedUserState);
 *
  */
export let getRecoilExternalLoadable: <T>(recoilValue: RecoilValue<T>) => Loadable<T> = () => null as any;

/**
 * Retrieves the value from the loadable. More information about loadables are here:
 * https://recoiljs.org/docs/api-reference/core/Loadable
 * @param recoilValue Recoil value to retrieve its base value
 */
export function getRecoilExternalValue<T>(recoilValue: RecoilValue<T>): T {
	return getRecoilExternalLoadable<T>(recoilValue).getValue();
}

/**
 * Sets a Recoil state value, from anywhere in the app.
 *
 * Can be used outside of the React tree (outside a React component), such as in utility scripts, etc.
 *
 * <RecoilExternalStatePortal> must have been previously loaded in the React tree, or it won't work.
 * Initialized as a dummy function "() => null", it's reference is updated to a proper Recoil state mutator when GlobalStateInfluencer is loaded.
 *
 * NOTE - Recoil value isn't fully changed until some time later.
 *
 * @example setRecoilExternalState(lastCreatedUserState, newUser)
 */
export let setRecoilExternalValue: <T>(
	recoilState: RecoilState<T>,
	valOrUpdater: ((currVal: T) => T) | T
) => void = () => null as any;

export const GlobalStateInfluencer: React.FC = () => {
	useRecoilCallback(({ set, snapshot }) => {
		setRecoilExternalValue = set;
		getRecoilExternalLoadable = snapshot.getLoadable;
		return async () => {};
	})();

	// We need to update the getRecoilExternalLoadable every time there's a new snapshot
	// Otherwise we will load old values from when the component was mounted
	useRecoilTransactionObserver_UNSTABLE(({ snapshot }) => {
		getRecoilExternalLoadable = snapshot.getLoadable;
	});

	return null;
};
