// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.updateAvail{overflow:hidden;background-color:#333;position:fixed;display:flex;justify-content:space-between;bottom:0;z-index:100;width:100%;color:azure;align-items:center;box-shadow:0px -3px 3px gray}.updateAvail p{margin-left:30px;font-size:23px;line-height:5px;font-weight:400}.updateAvail .update-btn{background-color:white;border:none;border-radius:7px;padding:0 24px;height:38px;color:black;white-space:nowrap;font-size:18px;cursor:pointer;line-height:26px;font-weight:500;box-shadow:0 3px 9px -2px black;margin-right:30px}
`, "",{"version":3,"sources":["webpack://./src/components/updateAvail/UpdateAvail.scss"],"names":[],"mappings":"AAEA,aACC,eAAgB,CAChB,qBAAsB,CACtB,cAAe,CACf,YAAa,CACb,6BAA8B,CAC9B,QAAS,CACT,WAAY,CACZ,UAAW,CACX,WAAY,CACZ,kBAAmB,CACnB,4BAA6B,CAX9B,eAcE,gBAAiB,CACjB,cAAe,CACf,eAAgB,CAChB,eAAgB,CAjBlB,yBAqBE,sBAAuB,CACvB,WAAY,CACZ,iBAAkB,CAClB,cAAe,CACf,WAAY,CACZ,WAAY,CACZ,kBAAmB,CACnB,cAAe,CACf,cAAe,CACf,gBAAiB,CACjB,eAAgB,CAChB,+BAAgC,CAChC,iBAAkB","sourcesContent":["@import '../../themes/themes';\n\n.updateAvail {\n\toverflow: hidden;\n\tbackground-color: #333;\n\tposition: fixed;\n\tdisplay: flex;\n\tjustify-content: space-between;\n\tbottom: 0;\n\tz-index: 100;\n\twidth: 100%;\n\tcolor: azure;\n\talign-items: center;\n\tbox-shadow: 0px -3px 3px gray;\n\n\tp {\n\t\tmargin-left: 30px;\n\t\tfont-size: 23px;\n\t\tline-height: 5px;\n\t\tfont-weight: 400;\n\t}\n\n\t.update-btn {\n\t\tbackground-color: white;\n\t\tborder: none;\n\t\tborder-radius: 7px;\n\t\tpadding: 0 24px;\n\t\theight: 38px;\n\t\tcolor: black;\n\t\twhite-space: nowrap;\n\t\tfont-size: 18px;\n\t\tcursor: pointer;\n\t\tline-height: 26px;\n\t\tfont-weight: 500;\n\t\tbox-shadow: 0 3px 9px -2px black;\n\t\tmargin-right: 30px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primaryTextColor": `var(--primaryTextColor)`,
	"secondaryTextColor": `var(--secondaryTextColor)`,
	"successGreen": `var(--successGreen)`,
	"adminPink": `var(--adminPink)`,
	"warningRed": `var(--warningRed)`,
	"buttonLinkColor": `var(--buttonLinkColor)`,
	"secondaryButtonLinkColor": `var(--secondaryButtonLinkColor)`,
	"iconAndHighlightTextColor": `var(--iconAndHighlightTextColor)`,
	"primaryBackgroundColor": `var(--primaryBackgroundColor)`,
	"secondaryBackgroundColor": `var(--secondaryBackgroundColor)`,
	"errorColor": `var(--errorColor)`,
	"primaryBoxShadowColor": `var(--primaryBoxShadowColor)`,
	"greyLineColor": `var(--greyLineColor)`,
	"thinGreyLineColor": `var(--thinGreyLineColor)`,
	"white": `var(--white)`,
	"lightestGray": `var(--lightestGray)`,
	"backgroundGray": `var(--backgroundGray)`,
	"borderGray": `var(--borderGray)`,
	"medGray2": `var(--medGray2)`,
	"medGray2Active": `var(--medGray2Active)`,
	"black": `var(--black)`,
	"primaryCalendarColor": `var(--primaryCalendarColor)`,
	"secondaryCalendarColor": `var(--secondaryCalendarColor)`
};
export default ___CSS_LOADER_EXPORT___;
