import React, { useEffect } from 'react';
import { Box, Page, popupController } from '@bit/redsky.framework.rs.996';
import Label from '@bit/redsky.framework.rs.label/dist/Label';
import useIsAtBreakpoint from '../../customHooks/useIsAtBreakpoint';
import { useRecoilValue } from 'recoil';
import Button from '@bit/redsky.framework.rs.button';
import { useNavigate } from 'react-router-dom';
import './notFoundPage.scss';
import globalState from '../../state/globalState';
import SigninPopup from '../../popups/signin/SigninPopup';

type NotFoundProps = {
	title?: string;
	subTitle?: string;
	secondarySubTitle?: string;
	navigationButtonText?: string;
};

function NotFoundPage(props: NotFoundProps): JSX.Element {
	const isMobile = useIsAtBreakpoint();
	let navigate = useNavigate();
	const user = useRecoilValue<Api.User.Res.Detail | undefined>(globalState.user);
	const reservationNotFoundPath = '/reservation-not-found';
	const currentPagePath = window.location.pathname;
	const {
		title = 'Page Not Found',
		subTitle = "You're in Uncharted Territory!",
		secondarySubTitle = "Let's get you back home.",
		navigationButtonText = 'Home'
	} = props;
	const lessThan1230 = useIsAtBreakpoint(1230);
	function navigateBackHome() {
		navigate('/');
		// if (reservationNotFoundPath !== currentPagePath) {
		// } else {
		// 	if (!user) {
		// 		popupController.open(SigninPopup);
		// 	} else {
		// 		navigate('/account');
		// 	}
		// }
	}
	return (
		<Page className="rsNotFoundPage">
			{!lessThan1230 && <hr className="divider" />}
			{isMobile ? (
				<Box className={'pageNotFoundWrapper'} padding={72}>
					<Label className={'pageNotFound'} variant={'h2'} mb={48} mt={103}>
						{title}
					</Label>
					<Label className={'pageNotFound'} variant={'body1'} mb={5}>
						{subTitle}
					</Label>
					<Label className={'pageNotFound'} variant={'body1'} mb={48}>
						{secondarySubTitle}
					</Label>
					<Button className={'updateButton'} look={'containedPrimary'} onClick={navigateBackHome}>
						<Label variant={'buttonMdLg'}>Home</Label>
					</Button>
				</Box>
			) : (
				<Box className={'pageNotFoundWrapper'} padding={128}>
					<Label className={'pageNotFound'} variant={'h1'} mb={64}>
						{title}
					</Label>
					<Label className={'pageNotFound'} variant={'h3'} mb={5}>
						{subTitle}
					</Label>
					<Label className={'pageNotFound'} variant={'h3'} mb={64}>
						{secondarySubTitle}
					</Label>
					<Button className={'updateButton'} look={'containedPrimary'} onClick={navigateBackHome}>
						<Label variant={'buttonMdLg'}>{navigationButtonText}</Label>
					</Button>
				</Box>
			)}
		</Page>
	);
}

export default NotFoundPage;
