import * as React from 'react';
import './UpdatePasswordPopup.scss';
import { Popup, popupController } from '@bit/redsky.framework.rs.996';
import { PopupProps } from '@bit/redsky.framework.rs.996/dist/popup/Popup';
import Icon from '@bit/redsky.framework.rs.icon';
import Label from '@bit/redsky.framework.rs.label/dist/Label';
import { useEffect, useState } from 'react';
import { RsFormControl, RsFormGroup, RsValidator, RsValidatorEnum } from '@bit/redsky.framework.rs.form';
import LabelInput from '../../components/labelInput/LabelInput';
import { rsToastify } from '@bit/redsky.framework.rs.toastify';
import serviceFactory from '../../services/serviceFactory';
import UserService from '../../services/user/user.service';
import LabelButton from '../../components/labelButton/LabelButton';
import router from '../../utils/router';
import PasswordValidator from '../../components/passwordValidator/PasswordValidator';

export interface UpdatePasswordPopupProps extends PopupProps {}

const UpdatePasswordPopup: React.FC<UpdatePasswordPopupProps> = (props) => {
	const userService = serviceFactory.get<UserService>('UserService');
	const fullProps = popupController.convertProps(props);
	const [canSubmit, setCanSubmit] = useState<boolean>(false);
	const [passwordValidatorCheck, setPasswordValidatorCheck] = useState<boolean>(false);
	const [passwordResetFormGroup, setPasswordResetFormGroup] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl('oldPassword', '', [
				new RsValidator(RsValidatorEnum.REQ, 'Password is required'),
				new RsValidator(RsValidatorEnum.MIN, 'At least 8 characters', 8)
			]),
			new RsFormControl('newPassword', '', [new RsValidator(RsValidatorEnum.REQ, 'Password is required')]),
			new RsFormControl('confirmPassword', '', [
				new RsValidator(RsValidatorEnum.REQ, 'Password is required'),
				new RsValidator(RsValidatorEnum.CUSTOM, 'Passwords do not match', passwordsMatch)
			])
		])
	);

	function passwordsMatch(passwordConfirmControl: RsFormControl): boolean {
		const newPasswordValue = passwordResetFormGroup.get('newPassword').value.toString();
		const confirmationValue = passwordConfirmControl.value.toString();
		return newPasswordValue === confirmationValue;
	}

	useEffect(() => {
		const id = router.subscribeToBeforeRouterNavigate(async () => {
			popupController.closeById(fullProps.popupId);
			router.getCurrentPath();
			return true;
		});
		return () => {
			router.unsubscribeFromBeforeRouterNavigate(id);
		};
	}, []);

	useEffect(() => {
		(async function () {
			const isFormValid: boolean = await passwordResetFormGroup.isValid();
			setCanSubmit(isFormValid && passwordValidatorCheck);
		})();
	}, [passwordResetFormGroup, passwordValidatorCheck]);

	async function handleReset() {
		try {
			const oldPassword = passwordResetFormGroup.get('oldPassword').value.toString();
			const newPassword = passwordResetFormGroup.get('newPassword').value.toString();

			if (!canSubmit) {
				setPasswordResetFormGroup(passwordResetFormGroup.clone());
				rsToastify.error('Invalid password.', 'Failed Resetting Password');
				return;
			}

			await userService.updatePassword({ old: oldPassword, new: newPassword });
			popupController.close(UpdatePasswordPopup);
			rsToastify.success('Password reset was successful', 'Password Successfully Reset');
		} catch (e: any) {
			let message = 'Password reset failed. Try again.';
			if (e?.response?.status === 401) message = 'Current password is wrong.';
			rsToastify.error(message, 'Failed Resetting Password');
		}
	}

	function updateForm(control: RsFormControl) {
		setPasswordResetFormGroup(passwordResetFormGroup.clone().update(control));
	}

	return (
		<Popup {...props}>
			<div className={'rsUpdatePasswordPopup'}>
				<Icon
					iconImg={'icon-close'}
					onClick={() => {
						popupController.closeById(fullProps.popupId);
					}}
					size={12}
					className={'closeBtn'}
				/>
				<div className={'popupTitle'}>
					<Label variant={'h5'} className={'primaryTextColor'}>
						Update Password
					</Label>
				</div>
				<div className={'popupBody'}>
					<LabelInput
						className={'passwordInput'}
						title={'Current Password'}
						inputType={'password'}
						labelVariant={'h6'}
						control={passwordResetFormGroup.get('oldPassword')}
						updateControl={updateForm}
					/>
					<LabelInput
						className={'passwordInput'}
						title={'Create New Password'}
						inputType={'password'}
						labelVariant={'h6'}
						control={passwordResetFormGroup.get('newPassword')}
						updateControl={updateForm}
					/>
					<LabelInput
						className={'passwordInput'}
						title={'Confirm New Password'}
						inputType={'password'}
						labelVariant={'h6'}
						control={passwordResetFormGroup.get('confirmPassword')}
						updateControl={updateForm}
					/>
					<PasswordValidator
						password={passwordResetFormGroup.get('newPassword').value.toString()}
						onValidationStatusUpdate={(isValid) => setPasswordValidatorCheck(isValid)}
					/>

					<LabelButton
						look={'containedPrimary'}
						variant={'buttonMdLg'}
						label={'Update'}
						className={'yellow'}
						disabled={!canSubmit}
						onClick={() => handleReset()}
					/>
				</div>
			</div>
		</Popup>
	);
};

export default UpdatePasswordPopup;
