// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes fadeIn{from{opacity:0}to{opacity:1}}.small .rsSignInPage .container{width:100%}.small .rsSignInPage .container .signInSection{padding:50px 40px 0}.small .rsSignInPage .emailInput .rsInput{max-width:200px;width:200px}.small .rsSignInPage .rsFooter{margin:0;padding:0 20px}
`, "",{"version":3,"sources":["webpack://./src/pages/resetPasswordPage/ResetPasswordPage.scss"],"names":[],"mappings":"AAKA,kBACC,KACC,SAAU,CAEX,GACC,SAAU,CAAA,CAIZ,gCAGG,UAAW,CAHd,+CAKI,mBAAoB,CALxB,0CAUI,eAAgB,CAChB,WAAY,CAXhB,+BAeG,QAAS,CACT,cAAe","sourcesContent":["@import 'src/themes/themes';\n\n.rsResetPasswordPage {\n}\n\n@keyframes fadeIn {\n\tfrom {\n\t\topacity: 0;\n\t}\n\tto {\n\t\topacity: 1;\n\t}\n}\n\n.small {\n\t.rsSignInPage {\n\t\t.container {\n\t\t\twidth: 100%;\n\t\t\t.signInSection {\n\t\t\t\tpadding: 50px 40px 0;\n\t\t\t}\n\t\t}\n\t\t.emailInput {\n\t\t\t.rsInput {\n\t\t\t\tmax-width: 200px;\n\t\t\t\twidth: 200px;\n\t\t\t}\n\t\t}\n\t\t.rsFooter {\n\t\t\tmargin: 0;\n\t\t\tpadding: 0 20px;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primaryTextColor": `var(--primaryTextColor)`,
	"secondaryTextColor": `var(--secondaryTextColor)`,
	"successGreen": `var(--successGreen)`,
	"adminPink": `var(--adminPink)`,
	"warningRed": `var(--warningRed)`,
	"buttonLinkColor": `var(--buttonLinkColor)`,
	"secondaryButtonLinkColor": `var(--secondaryButtonLinkColor)`,
	"iconAndHighlightTextColor": `var(--iconAndHighlightTextColor)`,
	"primaryBackgroundColor": `var(--primaryBackgroundColor)`,
	"secondaryBackgroundColor": `var(--secondaryBackgroundColor)`,
	"errorColor": `var(--errorColor)`,
	"primaryBoxShadowColor": `var(--primaryBoxShadowColor)`,
	"greyLineColor": `var(--greyLineColor)`,
	"thinGreyLineColor": `var(--thinGreyLineColor)`,
	"white": `var(--white)`,
	"lightestGray": `var(--lightestGray)`,
	"backgroundGray": `var(--backgroundGray)`,
	"borderGray": `var(--borderGray)`,
	"medGray2": `var(--medGray2)`,
	"medGray2Active": `var(--medGray2Active)`,
	"black": `var(--black)`,
	"primaryCalendarColor": `var(--primaryCalendarColor)`,
	"secondaryCalendarColor": `var(--secondaryCalendarColor)`
};
export default ___CSS_LOADER_EXPORT___;
