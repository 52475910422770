// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gm-style .gm-style-iw{top:0 !important;left:0 !important;max-width:min-content !important;max-height:min-content !important;min-height:150px !important;padding-top:10px;font-size:13px !important}.gm-style .gm-style-iw-c{width:min-content !important;height:min-content !important;padding:0 !important}.gm-style-iw-t{bottom:65px !important}.gm-style-iw-d{overflow:hidden !important;padding:12px}.brandInfoOnHover .brandTitle{margin:10px 0;font-size:18px;color:#004b98}.brandInfoOnHover .brandDescription{display:-webkit-box;-webkit-box-orient:vertical;-webkit-line-clamp:3;overflow:hidden;margin:0;font-weight:400;color:#001933}
`, "",{"version":3,"sources":["webpack://./src/components/googleMap/gMap.scss"],"names":[],"mappings":"AAAA,uBACC,gBAAiB,CACjB,iBAAkB,CAClB,gCAAiC,CACjC,iCAAkC,CAClC,2BAA4B,CAC5B,gBAAiB,CACjB,yBAA0B,CAC1B,yBAEA,4BAA6B,CAC7B,6BAA8B,CAC9B,oBAAqB,CACrB,eAEA,sBAAuB,CACvB,eAEA,0BAA2B,CAC3B,YAAa,CACb,8BAIC,aAAc,CACd,cAAe,CACf,aAAc,CAJhB,oCAOE,mBAAoB,CACpB,2BAA4B,CAC5B,oBAAqB,CACrB,eAAgB,CAChB,QAAS,CACT,eAAgB,CAChB,aAAc","sourcesContent":[".gm-style .gm-style-iw {\n\ttop: 0 !important;\n\tleft: 0 !important;\n\tmax-width: min-content !important;\n\tmax-height: min-content !important;\n\tmin-height: 150px !important;\n\tpadding-top: 10px;\n\tfont-size: 13px !important;\n}\n.gm-style .gm-style-iw-c {\n\twidth: min-content !important;\n\theight: min-content !important;\n\tpadding: 0 !important;\n}\n.gm-style-iw-t {\n\tbottom: 65px !important;\n}\n.gm-style-iw-d {\n\toverflow: hidden !important;\n\tpadding: 12px;\n}\n\n.brandInfoOnHover {\n\t.brandTitle {\n\t\tmargin: 10px 0;\n\t\tfont-size: 18px;\n\t\tcolor: #004b98;\n\t}\n\t.brandDescription {\n\t\tdisplay: -webkit-box;\n\t\t-webkit-box-orient: vertical;\n\t\t-webkit-line-clamp: 3;\n\t\toverflow: hidden;\n\t\tmargin: 0;\n\t\tfont-weight: 400;\n\t\tcolor: #001933;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
