import * as React from 'react';
import Icon from '@bit/redsky.framework.rs.icon';
import iconJson from '../../icons/rentylIcons/selection.json';

interface MultiColoredIconsProps {
	iconName: string;
	iconSize: number;
	className?: string;
	color?: string;
}

const MultiColoredIcons: React.FC<MultiColoredIconsProps> = (props) => {
	const nameToCompare = props.iconName.replace('cms-', '');
	const newSelectedIcon = iconJson.icons.find((icon: any) => icon.icon.tags[0] === nameToCompare);

	function renderIcon() {
		if (newSelectedIcon && newSelectedIcon.icon.isMulticolor) {
			return (
				<span className={`cms-${props.iconName} rsIcon`} style={{ fontSize: `${props.iconSize}px` }}>
					{newSelectedIcon.icon.paths.map((path: any, index: number) => {
						return <span key={path} className={`path${index + 1} multiColoredIcon`} />;
					})}
				</span>
			);
		} else {
			return (
				<Icon iconImg={props.iconName} size={props.iconSize} color={props.color} className={props.className} />
			);
		}
	}

	return renderIcon();
};

export default MultiColoredIcons;
