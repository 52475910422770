// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rsNotEnoughPointsPopup{width:clamp(342px, 40%, 456px);background:var(--white);border-radius:20px}.rsNotEnoughPointsPopup .popupHeader{text-align:center;padding:24px;border-bottom:1px solid var(--greyLineColor)}.rsNotEnoughPointsPopup .rsNotEnoughPoints{padding:24px;max-height:75vh;overflow:scroll}.rsNotEnoughPointsPopup .rsNotEnoughPoints .red{color:var(--warningRed)}.rsNotEnoughPointsPopup .rsNotEnoughPoints .green{color:var(--successGreen)}.rsNotEnoughPointsPopup .rsBox .hr{width:50%;height:1px;background:var(--borderGray)}.rsNotEnoughPointsPopup .rsBox .orLabel{color:var(--medGray2);margin:0 14px}.rsNotEnoughPointsPopup .rsButton{width:100%;margin:24px 0}.rsNotEnoughPointsPopup .rsButton.yellow{margin-bottom:0}
`, "",{"version":3,"sources":["webpack://./src/popups/notEnoughPointsPopup/NotEnoughPointsPopup.scss","webpack://./src/themes/_mixins.scss"],"names":[],"mappings":"AAEA,wBACC,8BAA+B,CAC/B,uBCqBkB,CDpBlB,kBAAmB,CAHpB,qCAKE,iBAAkB,CAClB,YAAa,CACb,4CCgBiB,CDvBnB,2CAUE,YAAa,CACb,eAAgB,CAChB,eAAgB,CAZlB,gDAeG,uBCQgB,CDvBnB,kDAkBG,yBCKgB,CDvBnB,mCAuBG,SAAU,CACV,UAAW,CACX,4BCFgB,CDvBnB,wCA4BG,qBCLgB,CDMhB,aAAc,CA7BjB,kCAiCE,UAAW,CACX,aAAc,CAlChB,yCAoCG,eAAgB","sourcesContent":["@import '../../themes/themes';\n\n.rsNotEnoughPointsPopup {\n\twidth: clamp(342px, 40%, 456px);\n\tbackground: theme-var($white);\n\tborder-radius: 20px;\n\t.popupHeader {\n\t\ttext-align: center;\n\t\tpadding: 24px;\n\t\tborder-bottom: 1px solid theme-var($greyLineColor);\n\t}\n\t.rsNotEnoughPoints {\n\t\tpadding: 24px;\n\t\tmax-height: 75vh;\n\t\toverflow: scroll;\n\n\t\t.red {\n\t\t\tcolor: theme-var($warningRed);\n\t\t}\n\t\t.green {\n\t\t\tcolor: theme-var($successGreen);\n\t\t}\n\t}\n\t.rsBox {\n\t\t.hr {\n\t\t\twidth: 50%;\n\t\t\theight: 1px;\n\t\t\tbackground: theme-var($borderGray);\n\t\t}\n\t\t.orLabel {\n\t\t\tcolor: theme-var($medGray2);\n\t\t\tmargin: 0 14px;\n\t\t}\n\t}\n\t.rsButton {\n\t\twidth: 100%;\n\t\tmargin: 24px 0;\n\t\t&.yellow {\n\t\t\tmargin-bottom: 0;\n\t\t}\n\t}\n}\n","@import 'variables';\n\n/*\n  include a map in a class\n*/\n@mixin spread-map($map: ()) {\n\t@each $key, $value in $map {\n\t\t#{$key}: $value;\n\t}\n}\n\n/*\n  Returns a CSS variable property specific to themeing.\n  Accepts an optional fallback, and optional map.\n  - SCSS: color: theme-var($--theme-primary, blue)\n  - CSS:  color: var(--theme-primary, blue)\n  Throws error if the variable not part of the map\n*/\n@function theme-var($key, $fallback: null, $map: $defaultTheme) {\n\t@if not map-has-key($map, $key) {\n\t\t@error \"key: '#{$key}', is not a key in map: #{$map}\";\n\t}\n\t@if ($fallback) {\n\t\t@return var($key, $fallback);\n\t} @else {\n\t\t@return var($key);\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primaryTextColor": `var(--primaryTextColor)`,
	"secondaryTextColor": `var(--secondaryTextColor)`,
	"successGreen": `var(--successGreen)`,
	"adminPink": `var(--adminPink)`,
	"warningRed": `var(--warningRed)`,
	"buttonLinkColor": `var(--buttonLinkColor)`,
	"secondaryButtonLinkColor": `var(--secondaryButtonLinkColor)`,
	"iconAndHighlightTextColor": `var(--iconAndHighlightTextColor)`,
	"primaryBackgroundColor": `var(--primaryBackgroundColor)`,
	"secondaryBackgroundColor": `var(--secondaryBackgroundColor)`,
	"errorColor": `var(--errorColor)`,
	"primaryBoxShadowColor": `var(--primaryBoxShadowColor)`,
	"greyLineColor": `var(--greyLineColor)`,
	"thinGreyLineColor": `var(--thinGreyLineColor)`,
	"white": `var(--white)`,
	"lightestGray": `var(--lightestGray)`,
	"backgroundGray": `var(--backgroundGray)`,
	"borderGray": `var(--borderGray)`,
	"medGray2": `var(--medGray2)`,
	"medGray2Active": `var(--medGray2Active)`,
	"black": `var(--black)`,
	"primaryCalendarColor": `var(--primaryCalendarColor)`,
	"secondaryCalendarColor": `var(--secondaryCalendarColor)`
};
export default ___CSS_LOADER_EXPORT___;
