import * as React from 'react';
import './UserBasicInfoPaperMobile.scss';
import Paper from '../../paper/Paper';
import { Box, popupController } from '@bit/redsky.framework.rs.996';
import Label from '@bit/redsky.framework.rs.label';
import { StringUtils } from '../../../utils/utils';
import LabelButton from '../../labelButton/LabelButton';
import LoyaltyTierPopup from '../../../popups/loyaltyTierPopup/LoyaltyTierPopup';
import { useState } from 'react';
import Icon from '@bit/redsky.framework.rs.icon';
import themes from '../../../themes/themes.scss';

interface UserBasicInfoPaperMobileProps {
	userData: Api.User.Res.Detail;
	onLogOut: () => void;
}

const UserBasicInfoPaperMobile: React.FC<UserBasicInfoPaperMobileProps> = (props) => {
	const [visibilityToggle, setVisibilityToggle] = useState<boolean>(true);
	function renderLoadingBarPercent(): string {
		return `${Math.min(
			100,
			Math.floor(
				props?.userData?.lifeTimePoints /
					(props?.userData?.nextTierThreshold ? props?.userData?.nextTierThreshold / 100 : 100)
			)
		)}%`;
	}
	function replaceLettersWithStars(accountNumber: any): string {
		let lastFour = accountNumber.slice(-4);
		return accountNumber.replace(/\d/g, '*').slice(0, -4) + lastFour;
	}

	return (
		<Paper className={'rsUserBasicInfoPaperMobile'} boxShadow borderRadius={'20px'}>
			<Label variant={'buttonMdLg'} mb={8}>
				{`${props?.userData?.firstName} ${props?.userData?.lastName}`},
			</Label>
			<Label variant={'subtitle1'} mb={24}>
				{props?.userData?.primaryEmail}
			</Label>
			<Box display={'flex'} alignItems={'center'} mb={25}>
				<img src={`../../images/tierIcons/${props?.userData?.tierTitle || 'Bronze'}.png`} alt={'Tier Badge'} />
				<Box ml={15}>
					<Label variant={'subtitle1'}>{props?.userData?.tierTitle || 'Bronze'}</Label>
					{props?.userData?.accountNumber && (
						<Box display={'flex'} alignItems={'center'}>
							<Label variant={'subtitle1'} marginRight={'16px'}>
								Account{' '}
								{!visibilityToggle
									? props?.userData?.accountNumber
									: replaceLettersWithStars(props?.userData?.accountNumber)}
							</Label>
							<Icon
								iconImg={visibilityToggle ? 'icon-visibility-false' : 'icon-visibility-true'}
								onClick={() => {
									setVisibilityToggle(!visibilityToggle);
								}}
							/>
						</Box>
					)}
				</Box>
			</Box>
			<Label variant={'h5'} mb={8}>
				Points Available
			</Label>
			<Label variant={'h2'} className={'secondaryTextColor'} color={themes.secondaryTextColor} mb={25}>
				{StringUtils.addCommasToNumber(props?.userData?.availablePoints)}
			</Label>
			<Label variant={'h5'} mb={8}>
				Pending Points
			</Label>
			<Label variant={'h2'} mb={25}>
				{StringUtils.addCommasToNumber(props?.userData?.pendingPoints)}
			</Label>
			<Label variant={'h5'} mb={8}>
				Lifetime Points
			</Label>
			<Label variant={'h2'} mb={25}>
				{StringUtils.addCommasToNumber(props?.userData?.lifeTimePoints)}
			</Label>
			<Box textAlign={'end'}>
				<Label variant={'caption'} mb={8} className={'pointsLevel'}>
					You have reached the {props?.userData?.tierTitle || 'Bronze'} Spire level! Hooray!
				</Label>
				<div className={'loadingBarContainer'}>
					<div className={'loadingBar'} style={{ width: renderLoadingBarPercent() }}>
						<img src={`../../images/Mobile-Chevron-Pattern.svg`} />
					</div>
				</div>
			</Box>

			<Box className={'pointsContainer'}>
				<LabelButton
					look={'containedPrimary'}
					variant={'buttonSm'}
					label={'See Loyalty Tiers'}
					onClick={() => {
						popupController.open(LoyaltyTierPopup);
					}}
				/>
				<Box>
					<Label variant={'body1'} color={'#707070'} ml={'auto'} className={'notYou'}>
						Not you?
					</Label>
					<Label
						variant={'body1B'}
						className={'primaryTextColor'}
						color={themes.primaryTextColor}
						onClick={props.onLogOut}
					>
						Log Out
					</Label>
				</Box>
			</Box>
		</Paper>
	);
};

export default UserBasicInfoPaperMobile;
