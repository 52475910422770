// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rsReservationDetailsPaper{padding:38px 56px 56px;max-width:1090px}.rsReservationDetailsPaper .rsImg{width:100%;height:auto;border-radius:20px;min-height:470px;object-fit:cover}.rsReservationDetailsPaper .rsIconLabel .rsIcon.icon-pin{color:#d2555f}.rsReservationDetailsPaper .infoSection{width:100%;display:grid;grid-template-columns:repeat(auto-fit, minmax(250px, 1fr));column-gap:100px;row-gap:40px}.rsReservationDetailsPaper .amenitiesList{display:flex;gap:10px;flex-wrap:wrap}.rsReservationDetailsPaper .amenitiesIcons{width:130px}.small .rsReservationDetailsPaper{padding:27px 23px 44px}.small .rsReservationDetailsPaper .infoSection{grid-template-columns:repeat(auto-fit, minmax(150px, 1fr));column-gap:50px;row-gap:24px}.small .rsReservationDetailsPaper .amenitiesIcons{margin-right:20px;margin-bottom:30px;min-width:96px}@media screen and (max-width: 389px){.small .rsReservationDetailsPaper .amenitiesList{justify-content:center}}
`, "",{"version":3,"sources":["webpack://./src/components/reservationDetailsPaper/ReservationDetailsPaper.scss"],"names":[],"mappings":"AACA,2BACC,sBAAuB,CACvB,gBAAiB,CAFlB,kCAIE,UAAW,CACX,WAAY,CACZ,kBAAmB,CACnB,gBAAiB,CACjB,gBAAiB,CARnB,yDAaI,aAAc,CAblB,wCAkBE,UAAW,CACX,YAAa,CACb,0DAA2D,CAC3D,gBAAiB,CACjB,YAAa,CAtBf,0CAyBE,YAAa,CACb,QAAS,CACT,cAAe,CA3BjB,2CA8BE,WAAY,CACZ,kCAKA,sBAAuB,CAFzB,+CAIG,0DAA2D,CAC3D,eAAgB,CAChB,YAAa,CANhB,kDAUG,iBAAkB,CAClB,kBAAmB,CACnB,cAAe,CACf,qCAKF,iDAGG,sBAAuB,CACvB","sourcesContent":["@import '../../themes/themes';\n.rsReservationDetailsPaper {\n\tpadding: 38px 56px 56px;\n\tmax-width: 1090px;\n\t.rsImg {\n\t\twidth: 100%;\n\t\theight: auto;\n\t\tborder-radius: 20px;\n\t\tmin-height: 470px;\n\t\tobject-fit: cover;\n\t}\n\t.rsIconLabel {\n\t\t.rsIcon {\n\t\t\t&.icon-pin {\n\t\t\t\tcolor: #d2555f;\n\t\t\t}\n\t\t}\n\t}\n\t.infoSection {\n\t\twidth: 100%;\n\t\tdisplay: grid;\n\t\tgrid-template-columns: repeat(auto-fit, minmax(250px, 1fr));\n\t\tcolumn-gap: 100px;\n\t\trow-gap: 40px;\n\t}\n\t.amenitiesList {\n\t\tdisplay: flex;\n\t\tgap: 10px;\n\t\tflex-wrap: wrap;\n\t}\n\t.amenitiesIcons {\n\t\twidth: 130px;\n\t}\n}\n\n.small {\n\t.rsReservationDetailsPaper {\n\t\tpadding: 27px 23px 44px;\n\t\t.infoSection {\n\t\t\tgrid-template-columns: repeat(auto-fit, minmax(150px, 1fr));\n\t\t\tcolumn-gap: 50px;\n\t\t\trow-gap: 24px;\n\t\t}\n\n\t\t.amenitiesIcons {\n\t\t\tmargin-right: 20px;\n\t\t\tmargin-bottom: 30px;\n\t\t\tmin-width: 96px;\n\t\t}\n\t}\n}\n\n@media screen and (max-width: 389px) {\n\t.small {\n\t\t.rsReservationDetailsPaper {\n\t\t\t.amenitiesList {\n\t\t\t\tjustify-content: center;\n\t\t\t}\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primaryTextColor": `var(--primaryTextColor)`,
	"secondaryTextColor": `var(--secondaryTextColor)`,
	"successGreen": `var(--successGreen)`,
	"adminPink": `var(--adminPink)`,
	"warningRed": `var(--warningRed)`,
	"buttonLinkColor": `var(--buttonLinkColor)`,
	"secondaryButtonLinkColor": `var(--secondaryButtonLinkColor)`,
	"iconAndHighlightTextColor": `var(--iconAndHighlightTextColor)`,
	"primaryBackgroundColor": `var(--primaryBackgroundColor)`,
	"secondaryBackgroundColor": `var(--secondaryBackgroundColor)`,
	"errorColor": `var(--errorColor)`,
	"primaryBoxShadowColor": `var(--primaryBoxShadowColor)`,
	"greyLineColor": `var(--greyLineColor)`,
	"thinGreyLineColor": `var(--thinGreyLineColor)`,
	"white": `var(--white)`,
	"lightestGray": `var(--lightestGray)`,
	"backgroundGray": `var(--backgroundGray)`,
	"borderGray": `var(--borderGray)`,
	"medGray2": `var(--medGray2)`,
	"medGray2Active": `var(--medGray2Active)`,
	"black": `var(--black)`,
	"primaryCalendarColor": `var(--primaryCalendarColor)`,
	"secondaryCalendarColor": `var(--secondaryCalendarColor)`
};
export default ___CSS_LOADER_EXPORT___;
