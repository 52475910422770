import React from 'react';
import { FormEvent, useEffect, useRef, useState } from 'react';
import './CheckOutInfoCard.scss';
import { Box } from '@bit/redsky.framework.rs.996';
import Label from '@bit/redsky.framework.rs.label/dist/Label';
import { RsFormControl, RsFormGroup, RsValidator, RsValidatorEnum } from '@bit/redsky.framework.rs.form';
import LabelInput from '../labelInput/LabelInput';
import LabelSelect from '../labelSelect/LabelSelect';
import { rsToastify } from '@bit/redsky.framework.rs.toastify';
import RsPagedResponseData = RedSky.RsPagedResponseData;

import { NumberUtils, ObjectUtils, StringUtils, WebUtils } from '../../utils/utils';
import serviceFactory from '../../services/serviceFactory';
import CountryService from '../../services/country/country.service';
import LabelButton from '../labelButton/LabelButton';
import LabelCheckboxFilterBar from '../labelCheckbox/LabelCheckboxFilterBar';
import UserService from '../../services/user/user.service';
import { OptionType } from '@bit/redsky.framework.rs.select';
import { useRecoilState, useRecoilValue } from 'recoil';
import globalState from '../../state/globalState';
import classNames from 'classnames';
import useGetCountryList from '../../customHooks/useGetCountryList';
import TagManager from 'react-gtm-module';
import { getPageFinder, undefinedHandler } from '../../utils/undefinedHandler';
import AccommodationService from '../../services/accommodation/accommodation.service';
import router from '../../utils/router';
import { get } from 'lodash';

export interface CheckOutInfoCardProps {
	onContinue: VoidFunction;
	isFormFilledOutCallback: (val: boolean) => void;
	onCheckoutInfoButtonClick?: (buttonRef: React.RefObject<any>) => void;
}

enum FormKeys {
	FIRST_NAME = 'firstName',
	LAST_NAME = 'lastName',
	PHONE = 'phone',
	EMAIL = 'email',
	ADDRESS1 = 'address1',
	ADDRESS2 = 'address2',
	CITY = 'city',
	STATE = 'state',
	ZIP = 'zip',
	COUNTRY = 'country'
}

const CheckOutInfoCard: React.FC<CheckOutInfoCardProps> = (props) => {
	const checkoutInfoButtonRef = useRef<HTMLButtonElement | null>();
	const [verifiedAccommodation, setVerifiedAccommodation] = useRecoilState<
		Api.Reservation.Res.Verification | undefined
	>(globalState.verifiedAccommodation);
	const user = useRecoilValue<Api.User.Res.Detail | undefined>(globalState.user);
	const [checkoutUser, setCheckoutUser] = useRecoilState<Misc.Checkout | undefined>(globalState.checkoutUser);
	const countryService = serviceFactory.get<CountryService>('CountryService');
	const reservationFilters = useRecoilValue<Misc.ReservationFilters>(globalState.reservationFilters);
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const resortId: any = urlParams.get('data');
	const params = router.getPageUrlParams<{
		stage: number;
		data: any;
		reservationId: number;
		destinationId: number;
	}>([
		{ key: 's', default: 0, type: 'integer', alias: 'stage' },
		{ key: 'data', default: 0, type: 'string', alias: 'data' },
		{ key: 'ri', default: '', type: 'string', alias: 'reservationId' }
	]);
	const accommodationService = serviceFactory.get<AccommodationService>('AccommodationService');
	const userService = serviceFactory.get<UserService>('UserService');
	const [signUp, setSignUp] = useState<boolean>(checkoutUser?.shouldCreateUser || false);
	const [stateList, setStateList] = useState<Misc.OptionType[]>([]);
	const countryList = useGetCountryList();
	const [countries, setCountries] = useState<OptionType[]>([]);
	const [infoForm, setInfoForm] = useState<RsFormGroup>(getInfoForm);
	const [phoneError, setPhoneError] = useState<boolean>(false);
	const [isFilledOut, setIsFilledOut] = useState<boolean>(false);
	const [stateKey, setStateKey] = useState<number>(0);
	function getInfoForm() {
		return new RsFormGroup([
			new RsFormControl(FormKeys.FIRST_NAME, checkoutUser?.personal?.firstName || '', [
				new RsValidator(RsValidatorEnum.REQ, 'First name is required')
			]),
			new RsFormControl(FormKeys.LAST_NAME, checkoutUser?.personal?.lastName || '', [
				new RsValidator(RsValidatorEnum.REQ, 'Last name is required')
			]),
			new RsFormControl(FormKeys.EMAIL, checkoutUser?.personal?.email || '', [
				new RsValidator(RsValidatorEnum.EMAIL, 'Enter a valid Email'),
				new RsValidator(RsValidatorEnum.CUSTOM, 'Enter a valid Email', (control) => {
					return StringUtils.customBillingEmailPattern.test(control.value.toString());
				})
			]),
			new RsFormControl(FormKeys.PHONE, checkoutUser?.personal?.phone || '', [
				new RsValidator(RsValidatorEnum.REQ, 'Enter a valid phone number'),
				new RsValidator(RsValidatorEnum.MIN, 'Enter a valid phone number', 7),
				new RsValidator(RsValidatorEnum.CUSTOM, 'Enter a valid phone number', (control) => {
					const firstDigit = control.value.toString().slice(0, 1);
					const secondThreeDigit = control.value.toString().slice(1, 4);
					return !(Number(firstDigit) === 1 && Number(secondThreeDigit) === 0);
				})
			]),
			new RsFormControl(FormKeys.ADDRESS1, checkoutUser?.personal?.address1 || '', [
				new RsValidator(RsValidatorEnum.REQ, 'Address is required')
			]),
			new RsFormControl(FormKeys.ADDRESS2, checkoutUser?.personal?.address2 || '', []),
			new RsFormControl(FormKeys.CITY, checkoutUser?.personal?.city || '', [
				new RsValidator(RsValidatorEnum.REQ, 'City is required')
			]),
			new RsFormControl(FormKeys.ZIP, checkoutUser?.personal?.zip || '', [
				new RsValidator(RsValidatorEnum.REQ, 'Zip is required'),
				new RsValidator(RsValidatorEnum.CUSTOM, 'Invalid Zip Code', (control) => {
					return StringUtils.zipPattern.test(control.value.toString());
				})
			]),
			new RsFormControl(FormKeys.STATE, checkoutUser?.personal?.state || '', [
				new RsValidator(RsValidatorEnum.REQ, 'State is required')
			]),
			new RsFormControl(FormKeys.COUNTRY, checkoutUser?.personal?.country || 'US', [
				new RsValidator(RsValidatorEnum.REQ, 'Country is required')
			])
		]);
	}

	useEffect(() => {
		setInfoForm(getInfoForm());
		setStateKey((prevState) => ++prevState);
	}, [checkoutUser]);

	useEffect(() => {
		(async function checkIfFilledOut() {
			let completed = await infoForm.isValid();
			setIsFilledOut(completed);
			setPhoneError(ObjectUtils.isArrayWithData(infoForm.get('phone').errors));
			props.isFormFilledOutCallback(completed);
		})();
	}, [infoForm]);

	useEffect(() => {
		if (!checkoutInfoButtonRef) return;
		(function bubbleUpButtonRef() {
			if (!props.onCheckoutInfoButtonClick) return;
			props.onCheckoutInfoButtonClick(checkoutInfoButtonRef);
		})();
	}, [checkoutInfoButtonRef]);

	useEffect(() => {
		setCountries(formatStateOrCountryListForSelect(countryList));
	}, [countryList]);

	useEffect(() => {
		let cancelFetch: boolean = false;
		async function getStates() {
			try {
				const states = await countryService.getStates(infoForm.get(FormKeys.COUNTRY).value.toString());
				if (cancelFetch) return;
				if (states.states.length === 0) {
					setStateList([{ value: 'N/A', label: 'N/A' }]);
				} else {
					setStateList(formatStateOrCountryListForSelect(states.states));
				}
			} catch (e: any) {
				rsToastify.error(
					WebUtils.getRsErrorMessage(e, 'Unable to get states for the selected country.'),
					'Server Error'
				);

				const message = `Getting error :${e.message} on ${getPageFinder(window.location.pathname)} page.`;
				undefinedHandler(message);
			}
		}
		getStates().catch(console.error);
		return () => {
			cancelFetch = true;
		};
	}, [infoForm.get(FormKeys.COUNTRY)]);

	function formatStateOrCountryListForSelect(statesOrCountries: any[]) {
		return statesOrCountries.map((item) => {
			return { value: item.isoCode, label: item.name };
		});
	}

	function containsUnwantedCharacters(input: any) {
		var pattern = /[0-9!@#$%^&*()]/;
		return pattern.test(input);
	}

	const ConTwoDecDigit = (digit: any) => {
		return digit.indexOf('.') > 0
			? digit.split('.').length <= 4
				? digit.split('.')[0] + '.' + digit.split('.')[1].substring(-1, 4)
				: digit
			: digit;
	};

	function updateForm(control: RsFormControl) {
		if (control.key === 'firstName') {
			if (!isNaN(Number(control.value))) {
				// If the value is a number, clear it
				control.value = '';
			} else {
				// Remove special characters and ensure two decimal digits
				control.value = control.value.toString().replace(/[^A-Za-z]/g, '');
				control.value = ConTwoDecDigit(control.value);
			}
		}

		if (control.key === 'lastName') {
			if (!isNaN(Number(control.value))) {
				// If the value is a number, clear it
				control.value = '';
			} else {
				// Remove special characters and ensure two decimal digits
				control.value = control.value.toString().replace(/[^A-Za-z]/g, '');
				control.value = ConTwoDecDigit(control.value);
			}
		}

		if (control.key === FormKeys.COUNTRY) {
			const stateControl = infoForm.get(FormKeys.STATE);
			stateControl.value = '';
			infoForm.update(stateControl);
			setStateKey((prevState) => ++prevState);
		}
		setInfoForm(infoForm.clone().update(control));
	}

	function buildCheckoutUser(): Misc.Checkout {
		return {
			...checkoutUser,
			personal: {
				firstName: infoForm.get(FormKeys.FIRST_NAME).value.toString(),
				lastName: infoForm.get(FormKeys.LAST_NAME).value.toString(),
				address1: infoForm.get(FormKeys.ADDRESS1).value.toString(),
				address2: infoForm.get(FormKeys.ADDRESS2).value.toString(),
				zip: infoForm.get(FormKeys.ZIP).value.toString(),
				city: infoForm.get(FormKeys.CITY).value.toString(),
				state: infoForm.get(FormKeys.STATE).value.toString(),
				country: infoForm.get(FormKeys.COUNTRY).value.toString(),
				email: infoForm.get(FormKeys.EMAIL).value.toString(),
				phone: infoForm.get(FormKeys.PHONE).value.toString()
			},
			billing: {
				firstName: infoForm.get(FormKeys.FIRST_NAME).value.toString(),
				lastName: infoForm.get(FormKeys.LAST_NAME).value.toString(),
				address1: infoForm.get(FormKeys.ADDRESS1).value.toString(),
				address2: infoForm.get(FormKeys.ADDRESS2).value.toString(),
				zip: infoForm.get(FormKeys.ZIP).value.toString(),
				city: infoForm.get(FormKeys.CITY).value.toString(),
				state: infoForm.get(FormKeys.STATE).value.toString(),
				country: infoForm.get(FormKeys.COUNTRY).value.toString(),
				email: infoForm.get(FormKeys.EMAIL).value.toString(),
				phone: infoForm.get(FormKeys.PHONE).value.toString()
			},
			shouldCreateUser: signUp
		};
	}

	const googleCheckoutProcess1 = async () => {
		const destinationId = JSON.parse(params.data);

		const searchQueryObj: Misc.ReservationFilters = { ...reservationFilters };
		let key: keyof Misc.ReservationFilters;
		for (key in searchQueryObj) {
			if (searchQueryObj[key] === undefined) delete searchQueryObj[key];
		}
		const results: RsPagedResponseData<Api.Accommodation.Res.MinMaxAvailability> =
			await accommodationService.availability(destinationId.destinationId, searchQueryObj);

		const accommodationStay = results?.data.find((stay: any) => stay.id === verifiedAccommodation?.accommodationId);

		const prices = accommodationStay?.prices.find(
			(stay: any) => stay.rate.code === verifiedAccommodation?.rateCode
		);

		const checkout_step_1 = {
			item_name: verifiedAccommodation?.accommodationName,
			item_id: get(accommodationStay, 'externalSystemId', verifiedAccommodation?.accommodationId),
			price: NumberUtils.centsToDollars(prices?.accommodationTotalInCents),
			item_brand: verifiedAccommodation?.destinationName,
			item_category: get(accommodationStay, 'contentLists.RoomCategoryList.0.Name', null),
			item_category2: get(accommodationStay, 'contentLists.RoomCategoryList.0.Name', null),
			quantity: 1, // we'll consider 1 room as a item
			item_list_name: 'Rooms'
		};

		try {
			TagManager.dataLayer({
				// Clear the previous ecommerce object.
				dataLayer: {
					event: 'checkout-step-1',
					ecommerce: null
				}
			});

			TagManager.dataLayer({
				dataLayer: {
					event: 'checkout-step-1',
					ecommerce: {
						checkout: {
							actionField: {
								step: 1,
								option: 'Info'
							},
							products: [checkout_step_1]
						}
					}
				}
			});
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	};

	async function submitInfo(event: FormEvent<HTMLFormElement>) {
		event.preventDefault();
		try {
			if (!(await infoForm.isValid())) {
				setInfoForm(infoForm.clone());
				rsToastify.error('Please ensure you have filled out all fields', 'Missing or Incorrect Information');
				return;
			}
			const newCheckoutUser = buildCheckoutUser();
			setCheckoutUser(newCheckoutUser);
			await userService.setCheckoutUserInLocalStorage(newCheckoutUser);
			props.onContinue();
		} catch (e: any) {
			rsToastify.error('Payment information is invalid', 'Missing or Incorrect Information');
			const message = `Getting error :${e.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}

		googleCheckoutProcess1();
	}

	const stretchedInputhandler = (brandType: any) => {
		let companyid;
		if (brandType) {
			companyid = JSON.parse(brandType).themeId;
		} else {
			companyid = null;
		}

		let bgColor = '';
		switch (companyid) {
			case 3:
				bgColor = 'encorereunion';
				// bgColor = '#e51c14';
				break;
			case 78:
				bgColor = 'cottagesbyrentyl';
				break;
			case 19:
				bgColor = 'cottagesbyrentyl';
				break;

			case 9:
				bgColor = 'bearsdenorlando';
				break;
			case 10:
				bgColor = 'resortsbyspectrum';
				break;
			default:
				bgColor = 'blue';
				break;
		}
		return (
			<LabelCheckboxFilterBar
				checkboxInputclassName={bgColor}
				value={''}
				isChecked={signUp}
				text={
					'Sign me up for Spire Loyalty by Rentyl Rewards. I confirm I am at least 18 years old or older and I agree to the terms and conditions.'
				}
				onSelect={() => setSignUp(true)}
				onDeselect={() => setSignUp(false)}
				lineClamp={10}
			/>
		);
	};

	const buttonHandler = (brandType: any) => {
		let companyid;
		if (brandType) {
			companyid = JSON.parse(brandType).themeId;
		} else {
			companyid = null;
		}

		let bgColor = '';
		switch (companyid) {
			case 3:
				bgColor = 'encorereunion';
				// bgColor = '#e51c14';
				break;
			case 78:
				bgColor = 'cottagesbyrentyl';
				break;
			case 19:
				bgColor = 'cottagesbyrentyl';
				break;

			case 9:
				bgColor = 'bearsdenorlando';
				break;
			case 10:
				bgColor = 'resortsbyspectrum';
				break;
			default:
				bgColor = 'yellow';
				break;
		}

		return (
			<LabelButton
				className={`${bgColor} primaryButton`}
				look={'none'}
				variant={'buttonMdLg'}
				label={'Review & Book'}
				disabled={!isFilledOut}
				buttonType={'submit'}
				buttonRef={checkoutInfoButtonRef}
			/>
		);
	};

	return (
		<Box className={'rsCheckOutInfoCard'}>
			{!user && (
				<Box className={'message'}>
					<Label variant={'buttonMdLg'} className={'medGray2'}>
						Reserve & Relax
					</Label>
					<Label variant={'body2'}>
						When you book directly with Rentyl Resorts, you can relax knowing you are not only getting the
						best rate but you are also getting the flexibility you want.
					</Label>
				</Box>
			)}
			<form onSubmit={submitInfo}>
				<Box className={'fieldGroup'}>
					<LabelInput
						labelVariant={'h6'}
						title={'First Name'}
						inputType={'text'}
						control={infoForm.get(FormKeys.FIRST_NAME)}
						updateControl={updateForm}
						isRequired
					/>
					<LabelInput
						labelVariant={'h6'}
						title={'Last Name'}
						inputType={'text'}
						control={infoForm.get(FormKeys.LAST_NAME)}
						updateControl={updateForm}
						isRequired
					/>
				</Box>
				<Box className={'fieldGroup stretchedInput'}>
					<LabelInput
						labelVariant={'h6'}
						title={'Address Line 1'}
						inputType={'text'}
						control={infoForm.get(FormKeys.ADDRESS1)}
						updateControl={updateForm}
						isRequired
					/>
				</Box>
				<Box className={'fieldGroup stretchedInput'}>
					<LabelInput
						labelVariant={'h6'}
						title={'Address Line 2'}
						inputType={'text'}
						control={infoForm.get(FormKeys.ADDRESS2)}
						updateControl={updateForm}
					/>
				</Box>
				<Box className={'fieldGroup'}>
					<LabelInput
						labelVariant={'h6'}
						title={'Postal/Zip code'}
						inputType={'text'}
						control={infoForm.get(FormKeys.ZIP)}
						updateControl={updateForm}
						isRequired
					/>
					<LabelInput
						labelVariant={'h6'}
						title={'City'}
						inputType={'text'}
						control={infoForm.get(FormKeys.CITY)}
						updateControl={updateForm}
						isRequired
					/>
				</Box>
				<Box className={'fieldGroup'}>
					<LabelSelect
						key={`select-${stateKey}`}
						title={'State'}
						options={stateList}
						control={infoForm.get(FormKeys.STATE)}
						updateControl={updateForm}
						labelVariant={'h6'}
						isRequired
					/>
					<LabelSelect
						title={'Country'}
						options={countries}
						control={infoForm.get(FormKeys.COUNTRY)}
						updateControl={updateForm}
						labelVariant={'h6'}
						isRequired
					/>
				</Box>
				<Box className={'fieldGroup'}>
					<LabelInput
						labelVariant={'h6'}
						title={'Email'}
						inputType={'text'}
						control={infoForm.get(FormKeys.EMAIL)}
						updateControl={updateForm}
						isRequired
					/>
					<LabelInput
						className={classNames('phoneInput', { phoneError })}
						labelVariant={'h6'}
						inputType={'tel'}
						title={'Phone'}
						isPhoneInput
						isRequired
						onChange={(value) => {
							let updatedPhone = infoForm.get(FormKeys.PHONE);
							updatedPhone.value = value;
							updateForm(updatedPhone);
						}}
						initialValue={infoForm.get(FormKeys.PHONE).value.toString()}
					/>
				</Box>
				{!user && (
					<Box className={'fieldGroup stretchedInput'}>
						{/* <LabelCheckboxFilterBar
							value={''}
							isChecked={signUp}
							text={
								'Sign me up for Spire Loyalty by Rentyl Rewards. I confirm I am at least 18 years old or older and I agree to the terms and conditions.'
							}
							onSelect={() => setSignUp(true)}
							onDeselect={() => setSignUp(false)}
							lineClamp={10}
						/> */}
						{stretchedInputhandler(resortId)}
					</Box>
				)}
				<Box className={'fieldGroup stretchedInput rightSide'}>{buttonHandler(resortId)}</Box>
			</form>
		</Box>
	);
};

export default CheckOutInfoCard;
