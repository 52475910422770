import React, { useState, FC, useEffect } from 'react';
import './RedeemRewardCard.scss';
import globalState from '../../state/globalState';
import { StringUtils } from '../../utils/utils';
import PaymentService from '../../services/payment/payment.service';
import serviceFactory from '../../services/serviceFactory';
import useIsAtBreakpoint from '../../customHooks/useIsAtBreakpoint';
import { useRecoilState, useRecoilValue } from 'recoil';

export interface RedeemRewardCardProps {
	bookingData: Api.Reservation.Res.Verification | undefined;
	onRedemption: (val: string) => void;
}

const RedeemRewardCard: FC<RedeemRewardCardProps> = (props) => {
	const hurray = '../../images/redeemRewardCardImg/hurray.svg';
	const cash = '../../images/redeemRewardCardImg/cash.svg';
	const circle = '../../images/redeemRewardCardImg/circle.svg';
	const rod = '../../images/redeemRewardCardImg/rod.svg';
	const star = '../../images/redeemRewardCardImg/star.svg';

	const isMobile = useIsAtBreakpoint();
	const paymentService = serviceFactory.get<PaymentService>('PaymentService');
	const [clickConfirmPointBtn, setClickConfirmPointBtn] = useState<boolean>(false);
	const user = useRecoilValue<Api.User.Res.Detail | undefined>(globalState.user);
	const checkoutUser = useRecoilState<Misc.Checkout | undefined>(globalState.checkoutUser);

	const getUser4DigtCardNumber = () => {
		const cardId = checkoutUser[0]?.existingCardId;
		const cardPaymentMethod = user?.paymentMethods;

		const last4Digit = cardPaymentMethod?.find((cardData) => cardData.id === cardId);
		return last4Digit?.last4;
	};

	async function redeemPointHandler() {
		setClickConfirmPointBtn(true);
		props.onRedemption('REDEEM');
	}

	const cancelRedemption = () => {
		setClickConfirmPointBtn(false);
		props.onRedemption('UNREDEEM');
	};

	return (
		<div
			className={
				clickConfirmPointBtn && isMobile
					? 'pointredeem-container pointredeem-container-mobile'
					: 'pointredeem-container'
			}
		>
			<div className="pointredeem-container-col-1">
				<div className="pointredeem-container-topheader">
					<div className="container-topheader__cashicon">
						<img src={cash} alt="Cash Image" />
					</div>

					<div className="container-topheader__text">Redeem Rewards?</div>
				</div>

				<div className="pointredeem-container-body">
					<div className="pointredeem-container-body-col-1">
						<div className="container-body__sideliner">
							<div className="container-body__circle">
								<img src={circle} alt="Circle Image" />
							</div>
							<div className="container-body__rod">
								<img src={rod} alt="Rod Image" />
							</div>
							<div className="container-body__circle">
								<img src={circle} alt="Circle Image" />
							</div>
							<div className="container-body__rod">
								<img src={rod} alt="Rod Image" />
							</div>
							<div className="container-body__circle">
								<img src={circle} alt="Circle Image" />
							</div>
						</div>
					</div>

					<div className="pointredeem-container-body-col-2">
						<div className="container-body__point-wrapper">
							<div>
								<span className="container-body__point-redemption"> Do you want to redeem: </span>

								<div className="container-body__point">
									<img src={star} alt="Circle Image" />
									<span className="container-body__point__price">
										{' '}
										{StringUtils.addCommasToNumber(props.bookingData?.prices.grandTotalPoints)}{' '}
									</span>
								</div>
							</div>

							<div className="container-body__price-holder">
								<div className="container-body__pricereceive">And Receive:</div>

								<div className="container-body__pricetag">
									${StringUtils.formatMoney(Number(props.bookingData?.prices.subtotalInCents))}
								</div>
							</div>

							<button type="button" className="container-body__confirmbtn" onClick={redeemPointHandler}>
								{clickConfirmPointBtn ? 'Confirmed' : 'Confirm'}
							</button>
						</div>
					</div>
				</div>
			</div>

			{/* {!clickConfirmPointBtn && isMobile && (
				<div className="pointredeem-container-col-1">
					<div className="pointredeem-container-topheader">
						<div className="container-topheader__cashicon">
							<img src={cash} alt="Cash Image" />
						</div>

						<div className="container-topheader__text">Redeem Rewards?</div>
					</div>

					<div className="pointredeem-container-body">
						<div className="pointredeem-container-body-col-1">
							<div className="container-body__sideliner">
								<div className="container-body__circle">
									<img src={circle} alt="Circle Image" />
								</div>
								<div className="container-body__rod">
									<img src={rod} alt="Rod Image" />
								</div>
								<div className="container-body__circle">
									<img src={circle} alt="Circle Image" />
								</div>
								<div className="container-body__rod">
									<img src={rod} alt="Rod Image" />
								</div>
								<div className="container-body__circle">
									<img src={circle} alt="Circle Image" />
								</div>
							</div>
						</div>

						<div className="pointredeem-container-body-col-2">
							<div className="container-body__point-wrapper">
								<div>
									<span className="container-body__point-redemption"> Do you want to redeem: </span>

									<div className="container-body__point">
										<img src={star} alt="Circle Image" />
										<span className="container-body__point__price">
											{' '}
											{StringUtils.addCommasToNumber(
												props.bookingData?.prices.grandTotalPoints
											)}{' '}
										</span>
									</div>
								</div>

								<div className="container-body__price-holder">
									<div className="container-body__pricereceive">And Receive:</div>

									<div className="container-body__pricetag">
										${StringUtils.formatMoney(Number(props.bookingData?.prices.subtotalInCents))}
									</div>
								</div>

								<button
									type="button"
									className="container-body__confirmbtn"
									onClick={redeemPointHandler}
								>
									{clickConfirmPointBtn ? 'Confirmed' : 'Confirm'}
								</button>
							</div>
						</div>
					</div>
				</div>
			)} */}

			{clickConfirmPointBtn && (
				<>
					<div className="pointredeem-container-col-2">
						<div
							className={
								clickConfirmPointBtn && isMobile
									? 'hooray-container animate__animated animate__fadeIn hooray-container-mobile'
									: 'hooray-container animate__animated animate__fadeIn'
							}
						>
							<div className="hooray-container__header">
								<img src={hurray} alt="hurray" />
								<div className="hooray-container__header-text1">Hooray and Huzzah!</div>
							</div>
							<div className="hooray-container__text2">Your reward is ready.</div>
							<div className="hooray-container__text3">
								To Claim it, use your Visa card ending in{' '}
								<span style={{ color: '#A8CAAC' }}>{getUser4DigtCardNumber()}</span> on your next visit
								to {props.bookingData?.destinationName} and we will automatically credit your card your{' '}
								<span style={{ color: '#A8CAAC' }}>
									${StringUtils.formatMoney(Number(props.bookingData?.prices.subtotalInCents))}
								</span>{' '}
								within 1 week of the purchase.
							</div>

							<div className="cancelredemption">
								<div className="cancelredemption__text1">Changed your mind?</div>
								<div className="cancelredemption__text2" onClick={cancelRedemption}>
									Cancel this redemption
								</div>
								<div className="cancelredemption__hr"></div>
							</div>
						</div>
					</div>
				</>
			)}
		</div>
	);
};

export default RedeemRewardCard;
