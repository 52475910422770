import * as React from 'react';
import './AccommodationSearchCardResponsive.scss';
import { Box, popupController } from '@bit/redsky.framework.rs.996';
import Label from '@bit/redsky.framework.rs.label';
import { useState } from 'react';
import { NumberUtils, ObjectUtils, WebUtils } from '../../../utils/utils';
import CarouselV2 from '../../carouselV2/CarouselV2';
import Accordion from '@bit/redsky.framework.rs.accordion';
import MobileLightBox, { MobileLightBoxProps } from '../../../popups/mobileLightBox/MobileLightBox';
import LightBoxCarouselPopup, {
	TabbedCarouselPopupProps
} from '../../../popups/lightBoxCarouselPopup/LightBoxCarouselPopup';
import PointsOrCentsBox from '../../pointsOrCentsBox/PointsOrCentsBox';
import RateCodeCard from '../../rateCodeCard/RateCodeCard';
import Icon from '@bit/redsky.framework.rs.icon';
import debounce from 'lodash.debounce';
import useIsAtBreakpoint from '../../../customHooks/useIsAtBreakpoint';
import parse from 'html-react-parser';
import ShowMoreLess from '../../showMoreLess/ShowMoreLess';
import themes from '../../../themes/themes.scss';
import RateDetailPopup, { RateDetailPopupProps } from '../../../popups/rateDetailPopup/RateDetailPopup';
import { getPageFinder, undefinedHandler } from '../../../utils/undefinedHandler';
import { get } from 'lodash';
import TagManager from 'react-gtm-module';

interface AccommodationSearchCardResponsiveProps {
	pointPerDollar?: number;
	accommodation: Api.Destination.Res.Accommodation;
	destinationDetails: Api.Destination.Res.Details | undefined;
	destinationId: number;
	openAccordion?: boolean;
	showInfoIcon?: boolean;
	packageLength: number;
	promotedRate?: any;
	nonPromotedRate?: any;
	onClickInfoIcon?: (accommodationId: number) => void;
	loyaltyStatus: Model.LoyaltyStatus;
	upfrontCashRequired: boolean;
	resortPoint: number;
	resortPointsPrice: number;
	isCustomResort?: boolean;
	phone?: number;
	isOpen?: () => void;
	accessCode?: string;
}

const AccommodationSearchCardResponsive: React.FC<AccommodationSearchCardResponsiveProps> = (props) => {
	const isMobile = useIsAtBreakpoint();
	const [isOpen, setIsOpen] = useState<boolean>(props?.openAccordion || false);

	function getImageUrls(): string[] {
		try {
			if (props?.accommodation.media) {
				let images = props?.accommodation.media.sort((a: any, b: any) => a.mediaIndex - b.mediaIndex);
				images.sort((a, b) => {
					return b.isPrimary - a.isPrimary;
				});
				return images.map((urlObj) => {
					return urlObj.urls.imageKit?.toString() || urlObj.urls.thumb;
				});
			}
			return [];
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
			return [];
		}
	}

	const googleAddToCartHandler = (productData?: any) => {
		const view_item = {
			item_name: props.accommodation?.name,
			item_id: get(props, 'accommodation.externalSystemId', props?.accommodation?.id),
			price: NumberUtils.centsToDollars(props.promotedRate.baseRates),
			item_brand: props.destinationDetails?.name,
			item_list_name: 'Rooms',
			currency: props.promotedRate.rate.currencyCode
		};

		try {
			TagManager.dataLayer({
				dataLayer: {
					event: 'view_item',
					ecommerce: null
				}
			});

			TagManager.dataLayer({
				dataLayer: {
					event: 'view_item',
					ecommerce: {
						detail: {
							products: [view_item]
						}
					}
				}
			});
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	};

	function renderImageCarousel() {
		try {
			const urls = getImageUrls();
			return (
				<CarouselV2
					path={() => {}}
					resortPoint={props?.resortPoint}
					imgPaths={urls}
					imageWidth={416}
					hideCompareButton={true}
					onGalleryClick={() => {
						try {
							if (isMobile) {
								popupController.open<MobileLightBoxProps>(MobileLightBox, {
									imageData: props?.accommodation.media
								});
							} else {
								popupController.open<TabbedCarouselPopupProps>(LightBoxCarouselPopup, {
									imageData: props?.accommodation.media.sort(
										(a: any, b: any) => a.mediaIndex - b.mediaIndex
									)
								});
							}
						} catch (error: any) {
							const message = `Getting error :${error.message} on ${getPageFinder(
								window.location.pathname
							)} page.`;
							undefinedHandler(message);
						}
					}}
				/>
			);
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	function renderSquareFootage() {
		try {
			if (!props?.accommodation?.minSquareFt && !props?.accommodation?.maxSquareFt) return;
			if (props?.accommodation?.minSquareFt == 0 && props?.accommodation?.maxSquareFt == 0) {
				return;
			}
			if (
				!props.accommodation.minSquareFt ||
				!props.accommodation.maxSquareFt ||
				props.accommodation.minSquareFt === props.accommodation.maxSquareFt
			) {
				return (
					<Label
						variant={'h6'}
						paddingLeft={10}
						className="borderLeft"
						marginRight={props.promotedRate?.quantityAvailable <= 5 ? 20 : 0}
					>
						{get(props, 'accommodation.minSquareFt') || get(props, 'accommodation.maxSquareFt')} ft&sup2;
					</Label>
				);
			} else {
				return (
					<Label
						variant={'h6'}
						paddingLeft={10}
						className="borderLeft"
						marginRight={props.promotedRate?.quantityAvailable <= 5 ? 20 : 0}
					>
						{!!props.accommodation.minSquareFt && props.accommodation.minSquareFt} to{' '}
						{props.accommodation.maxSquareFt} ft&sup2;
					</Label>
				);
			}
		} catch (error: any) {
			const message = `Getting error :${error.message} on ${getPageFinder(window.location.pathname)} page.`;
			undefinedHandler(message);
		}
	}

	return (
		<Box className={'rsAccommodationSearchCardResponsive'}>
			{!props?.destinationDetails?.allowOversell && props.promotedRate?.quantityAvailable <= 0 ? (
				<></>
			) : (
				<>
					{renderImageCarousel()}
					<Box className={'accommodationContainer'}>
						<Box className={'accommodationDetails'}>
							<Box className={'accommodationDescription'}>
								<Box display={'flex'} alignItems={'center'} paddingBottom={17}>
									<Label variant={'h5'} marginRight={20}>
										{get(props, 'accommodation.name')}
									</Label>

									{props.showInfoIcon && (
										<Label
											className={'viewMoreInfo'}
											variant={'h6'}
											onClick={debounce((value) => {
												if (props.onClickInfoIcon) {
													googleAddToCartHandler();
													return props.onClickInfoIcon(props.accommodation.id);
												}
											}, 500)}
										>
											View More Info
										</Label>
									)}
								</Box>
								<Box className={'detailsTextContainer'}>
									{props.accommodation.maxOccupantCount && (
										<Label paddingRight={10} variant={'h6'} className="borderRight">
											Sleeps {get(props, 'accommodation.maxOccupantCount')}
										</Label>
									)}
									{/* {props.accommodation.bedDetails && (
											<Label variant={'h6'} paddingX={10}>
												{props.accommodation.bedDetails.length} Various Bed Types
											</Label>
										)} */}
									{renderSquareFootage()}
									{!props?.destinationDetails?.allowOversell &&
										!props?.destinationDetails?.isUrgencyMessage &&
										props.promotedRate?.quantityAvailable > 0 &&
										props.promotedRate?.quantityAvailable <= 5 && (
											<Label variant={'h6'} ml={10} color={themes.errorColor}>
												Only{' '}
												{props.promotedRate?.quantityAvailable < 0
													? '0'
													: props.promotedRate?.quantityAvailable}{' '}
												left
											</Label>
										)}

									{/* {props?.destinationDetails?.isUrgencyMessage && (
										<Label ml={5} variant={'h6'} color={themes.errorColor}>
											{`  Only 1 left.`}
										</Label>
									)} */}
								</Box>
								{/* <Box className={'detailsTextContainer'}>Contact: {props.phone}</Box> */}
								<Label variant={'body1'} marginBottom={29}>
									<ShowMoreLess
										textDescription={
											props.accommodation.longDescription || props.accommodation.shortDescription
										}
										textLength={135}
									/>
								</Label>
								<Box className={'rateDescriptionContainer'}>
									<Label variant={'buttonMdLg'} paddingBottom={13}>
										{get(props.promotedRate, 'rate.name')}
									</Label>
									<Label variant={'H6'}>
										{props.promotedRate?.rate?.description
											? parse(get(props.promotedRate, 'rate.description'))
											: ''}
									</Label>
								</Box>
								<Label
									variant={'button'}
									color={themes.errorColor}
									className={'rateDetailBtn'}
									onClick={() => {
										try {
											popupController.open<RateDetailPopupProps>(RateDetailPopup, {
												priceDetail: props.promotedRate,
												contentList: props?.accommodation?.contentLists
											});
										} catch (error: any) {
											const message = `Getting error :${error.message} on ${getPageFinder(
												window.location.pathname
											)} page.`;
											undefinedHandler(message);
										}
									}}
								>
									Rate Details
								</Label>
							</Box>
							<Box className={'priceBox'}>
								<PointsOrCentsBox
									pointPerDollar={props.pointPerDollar}
									destinationDetails={props.destinationDetails}
									accommodation={props.accommodation}
									displayFromText={props.accommodation.prices.length > 1}
									priceObj={props.promotedRate}
									packageLength={props.packageLength}
									resortPointsPrice={props.resortPointsPrice}
									accommodationId={props.accommodation.id}
									destinationId={props.destinationId}
									loyaltyStatus={props.loyaltyStatus}
									upfrontCashRequired={props.upfrontCashRequired}
									isCustomResort={props.isCustomResort}
									phone={props.phone}
									campaignLength={
										!!props.accommodation.campaigns ? props.accommodation.campaigns.length : 0
									}
								/>
							</Box>
						</Box>
						<Box className={'accordionContainer'}>
							{ObjectUtils.isArrayWithData(props.accommodation.prices) && (
								// props.accommodation.prices.length > 1 && (
								<Accordion
									hideChevron
									titleReact={
										<Box display={'flex'} alignItems={'center'}>
											<Label variant={'buttonMdLg'} className={'primaryTextColor'} mr={38}>
												{!!props.accessCode && props.accessCode.length > 0
													? isOpen
														? 'View Less Rates'
														: 'View All Rates'
													: isOpen
													? 'View less rates'
													: 'View more rates'}
											</Label>
											<Icon
												iconImg={'icon-chevron-up'}
												className={`taxIcon ${!isOpen ? 'down' : 'up'}`}
												cursorPointer
											/>
										</Box>
									}
									isOpen={props.openAccordion}
									onClick={() => {
										setIsOpen(!isOpen);
										props.isOpen?.();
									}}
									hideHoverEffect
								>
									{props.nonPromotedRate?.map((priceObj: any) => {
										return (
											<RateCodeCard
												pointPerDollar={props.pointPerDollar}
												key={priceObj?.rate?.code}
												destinationDetails={props.destinationDetails}
												priceObj={priceObj}
												accommodation={props.accommodation}
												destinationId={props.destinationId}
												packageLength={props.packageLength}
												accommodationId={props.accommodation.id}
												pointsEarnable={props.accommodation.pointsEarnable}
												loyaltyStatus={props.loyaltyStatus}
												upfrontCashRequired={props.upfrontCashRequired}
												contentList={props?.accommodation?.contentLists}
												campaignLength={
													!!props.accommodation.campaigns
														? props.accommodation.campaigns.length
														: 0
												}
											/>
										);
									})}
								</Accordion>
							)}
						</Box>
					</Box>
				</>
			)}
		</Box>
	);
};

export default AccommodationSearchCardResponsive;
