import http from './http';

export const getPageFinder = (pageName?: string) => {
	let pageFinder: string = '';
	switch (pageName) {
		case '/account':
			pageFinder = 'Account Page';
			break;
		case '/account/personal-info':
			pageFinder = 'personal-info';
			break;
		case '/account/address':
			pageFinder = 'Address page';
			break;
		case '/account/payment-methods':
			pageFinder = 'payment-methods page';
			break;
		case '/account/points':
			pageFinder = 'points page';
			break;
		case '/account/travel-preferences':
			pageFinder = 'travel-preferences page';
			break;
		case pageName?.startsWith('/destination/') && pageName:
			pageFinder = 'Destination details page';
			break;
		case pageName?.startsWith('/reservations') && pageName:
			pageFinder = 'Reservations page';
			break;
		case pageName?.startsWith('/reservations/') && pageName:
			pageFinder = 'Reservations details page';
			break;
		case pageName?.startsWith('/booking/') && pageName:
			pageFinder = 'Booking  page';
			break;
		default:
			pageFinder = 'home';
			break;
	}
	return pageFinder;
};

export function undefinedHandler(data?: any) {
	(async function getNextPageUndefinedHandler() {
		try {
			let axiosResponse = await http.post<any>('user/undefinedHandler', {
				variables: data
			});
		} catch (error) {}
	})();
	return '';
}
