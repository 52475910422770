import React from 'react';
import Script from '../../components/script/Script';
import NoScript from '../../components/script/NoScript';

interface MetaPixelTagProps {
	resortIdForMetaPixel: number;
}
const MetaPixelTag: React.FC<MetaPixelTagProps> = (props) => {
	let resortPixelId: number;

	const getUrl = window.location.href; //get url
	if (getUrl.split('?')[0].indexOf('https://book.rentylresorts.com') > -1) {
		//live server
		if (props.resortIdForMetaPixel === 3) {
			// Encore Resort at Reunion
			resortPixelId = 1321316944742912;
		} else if (props.resortIdForMetaPixel === 15) {
			// Eagle Trace Resort Orlando
			resortPixelId = 4205763756126691;
		} else if (props.resortIdForMetaPixel === 10) {
			// Spectrum Resort Orlando
			resortPixelId = 437817018177742;
		} else if (props.resortIdForMetaPixel === 9) {
			// Bear's Den
			resortPixelId = 2238099649661937;
		} else if (props.resortIdForMetaPixel === 19) {
			// MRO
			resortPixelId = 464788887803417;
		} else {
			resortPixelId = 0;
		}
	} else {
		//developemnt
		resortPixelId = 0;
	}

	const confirmResortForMeta = (ResortId: number) => {
		if (ResortId === 3) {
			// Encore Resort at Reunion
			return true;
		} else if (ResortId === 15) {
			// Eagle Trace Resort Orlando
			return true;
		} else if (ResortId === 10) {
			// Spectrum Resort Orlando
			return true;
		} else if (ResortId === 9) {
			// Bear's Den
			return true;
		} else if (ResortId === 19) {
			// MRO
			return true;
		} else {
			return false;
		}
	};

	function pixelTagScript() {
		return `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '${resortPixelId}');
            fbq('track', 'PageView');
		`;
	}

	function pixelTagNoScript() {
		return `
        <img height="1" width="1" style="display:none"
        src="https://www.facebook.com/tr?id=${resortPixelId}&ev=PageView&noscript=1"
        />
		`;
	}

	return (
		<>
			{confirmResortForMeta(props.resortIdForMetaPixel) && (
				<>
					<Script text={pixelTagScript()} tag="head" />
					<NoScript text={pixelTagNoScript()} tag="head" />
				</>
			)}
		</>
	);
};

export default MetaPixelTag;
