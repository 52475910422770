// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.priceslider-promofield{margin-top:8px;width:100%;border-radius:5px;font-family:'Montserrat';font-style:normal;font-size:16px;line-height:20px;height:40px;padding:0 10px;box-sizing:border-box;border:1px solid var(--greyLineColor);color:var(--primaryTextColor) !important;outline:none !important;transition:border-color 300ms}.priceslider-promofield:hover,.priceslider-promofield:focus{border-color:var(--buttonLinkColor)}.priceslider-promodropdown{width:100%;height:40px;margin-top:8px;font-family:'Montserrat';font-style:normal;font-weight:200;font-size:16px;background:white;border:1px solid var(--greyLineColor);border-radius:5px;color:var(--primaryTextColor) !important;outline:none !important;transition:border-color 300ms;padding:0 5px}.priceslider-promodropdown:hover,.priceslider-promodropdown:focus{border-color:var(--buttonLinkColor)}.priceslider-promofield ::placeholder{color:var(--primaryTextColor) !important}
`, "",{"version":3,"sources":["webpack://./src/components/promoAgentCode/PromoAgentCode.scss"],"names":[],"mappings":"AAEA,wBAEC,cAAe,CACf,UAAW,CACX,iBAAkB,CAClB,wBAAyB,CACzB,iBAAkB,CAClB,cAAe,CACf,gBAAiB,CACjB,WAAY,CACZ,cAAe,CACf,qBAAsB,CACtB,qCAAsC,CACtC,wCAAyC,CACzC,uBAAwB,CACxB,6BAA8B,CAC9B,4DAIA,mCAAoC,CACpC,2BAGA,UAAW,CACX,WAAY,CACZ,cAAe,CACf,wBAAyB,CACzB,iBAAkB,CAClB,eAAgB,CAChB,cAAe,CACf,gBAAiB,CACjB,qCAAsC,CACtC,iBAAkB,CAClB,wCAAyC,CACzC,uBAAwB,CACxB,6BAA8B,CAC9B,aAAc,CACd,kEAIA,mCAAoC,CACpC,sCAGA,wCAAyC","sourcesContent":["@import 'src/themes/themes';\n\n.priceslider-promofield {\n\t// max-width: none !important;\n\tmargin-top: 8px;\n\twidth: 100%;\n\tborder-radius: 5px;\n\tfont-family: 'Montserrat';\n\tfont-style: normal;\n\tfont-size: 16px;\n\tline-height: 20px;\n\theight: 40px;\n\tpadding: 0 10px;\n\tbox-sizing: border-box;\n\tborder: 1px solid var(--greyLineColor);\n\tcolor: var(--primaryTextColor) !important;\n\toutline: none !important;\n\ttransition: border-color 300ms;\n}\n\n.priceslider-promofield:hover,\n.priceslider-promofield:focus {\n\tborder-color: var(--buttonLinkColor);\n}\n\n.priceslider-promodropdown {\n\twidth: 100%;\n\theight: 40px;\n\tmargin-top: 8px;\n\tfont-family: 'Montserrat';\n\tfont-style: normal;\n\tfont-weight: 200;\n\tfont-size: 16px;\n\tbackground: white;\n\tborder: 1px solid var(--greyLineColor);\n\tborder-radius: 5px;\n\tcolor: var(--primaryTextColor) !important;\n\toutline: none !important;\n\ttransition: border-color 300ms;\n\tpadding: 0 5px;\n}\n\n.priceslider-promodropdown:hover,\n.priceslider-promodropdown:focus {\n\tborder-color: var(--buttonLinkColor);\n}\n\n.priceslider-promofield ::placeholder {\n\tcolor: var(--primaryTextColor) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primaryTextColor": `var(--primaryTextColor)`,
	"secondaryTextColor": `var(--secondaryTextColor)`,
	"successGreen": `var(--successGreen)`,
	"adminPink": `var(--adminPink)`,
	"warningRed": `var(--warningRed)`,
	"buttonLinkColor": `var(--buttonLinkColor)`,
	"secondaryButtonLinkColor": `var(--secondaryButtonLinkColor)`,
	"iconAndHighlightTextColor": `var(--iconAndHighlightTextColor)`,
	"primaryBackgroundColor": `var(--primaryBackgroundColor)`,
	"secondaryBackgroundColor": `var(--secondaryBackgroundColor)`,
	"errorColor": `var(--errorColor)`,
	"primaryBoxShadowColor": `var(--primaryBoxShadowColor)`,
	"greyLineColor": `var(--greyLineColor)`,
	"thinGreyLineColor": `var(--thinGreyLineColor)`,
	"white": `var(--white)`,
	"lightestGray": `var(--lightestGray)`,
	"backgroundGray": `var(--backgroundGray)`,
	"borderGray": `var(--borderGray)`,
	"medGray2": `var(--medGray2)`,
	"medGray2Active": `var(--medGray2Active)`,
	"black": `var(--black)`,
	"primaryCalendarColor": `var(--primaryCalendarColor)`,
	"secondaryCalendarColor": `var(--secondaryCalendarColor)`
};
export default ___CSS_LOADER_EXPORT___;
