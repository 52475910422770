import * as React from 'react';
import './AccommodationPopup.scss';
import { Box, Popup, popupController, PopupProps } from '@bit/redsky.framework.rs.996';
import Paper from '../../../components/paper/Paper';
import Label from '@bit/redsky.framework.rs.label';
import Icon from '@bit/redsky.framework.rs.icon';
import Carousel from '../../../components/carousel/Carousel';
import LabelButton from '../../../components/labelButton/LabelButton';
import DestinationImageGallery from '../../../components/destinationImageGallery/DestinationImageGallery';
import LightBoxCarouselPopup, { TabbedCarouselPopupProps } from '../../lightBoxCarouselPopup/LightBoxCarouselPopup';
import ImageLabel from '../../../components/imageLabel/ImageLabel';
import { useRecoilValue } from 'recoil';
import globalState from '../../../state/globalState';
import { StringUtils } from '../../../utils/utils';
import { IKImage } from 'imagekitio-react';
import useMerchantReward from '../../../customHooks/useMerchantReward';

export interface AccommodationPopupPopupProps extends PopupProps {
	destinationDetails: Api.Destination.Res.Details | undefined;
	pointPerDollar?: number;
	destinationName?: string | undefined;
	renderAccommodationSize: () => React.ReactNode;
	handleReserveStay: () => void;
	renderAmenities: () => React.ReactNode | React.ReactNodeArray;
	renderLayoutImages: () => React.ReactNodeArray;
	packageCount: number | undefined;
	popUp: React.ReactNode;
	accommodationStay: Api.Accommodation.Res.Availability;
	loyaltyStatus: Model.LoyaltyStatus;
	pointsEarnable: number;
	resortPointsPrice: number;
	closeSelf: () => void;
	isCustomResort?: boolean;
	phone?: number;
}

interface PriceObj {
	priceCents: number;
	pricePoints: number;
	quantityAvailable: number;
	rate: Api.Rate;
	minStay: number;
}

const AccommodationPopup: React.FC<AccommodationPopupPopupProps> = (props) => {
	const reservationFilters = useRecoilValue<Misc.ReservationFilters>(globalState.reservationFilters);

	const [destinationObj, setDestinationObj] = React.useState({
		campaigns: props.accommodationStay.prices[0].rate.campaigns,
		minAccommodationPriceInCents: props.accommodationStay.prices[0].baseRates
	});

	const [pointPerDollar, setPointPerDollar] = React.useState<number>(0);
	const userTierValue = useRecoilValue<number>(globalState.userTierValue);
	const user = useRecoilValue<Api.User.Res.Detail | undefined>(globalState.user);

	const setAccommodationCampaign = () => {
		const price = props.accommodationStay.prices.reduce((minPrice, currentPrice) => {
			return currentPrice.pricePoints < minPrice.pricePoints ? currentPrice : minPrice;
		}, props.accommodationStay.prices[0]);
		setDestinationObj({
			campaigns: price.rate.campaigns,
			minAccommodationPriceInCents: price.baseRates
		});
	};

	React.useEffect(() => {
		if (typeof props.pointPerDollar === 'undefined') {
			setPointPerDollar(1);
		} else {
			setPointPerDollar(props.pointPerDollar);
		}
		setAccommodationCampaign();
	}, []);

	function renderPricePerNight() {
		if (!props.accommodationStay) return;
		if (reservationFilters.redeemPoints && props.loyaltyStatus === 'ACTIVE') {
			return (
				<Box className={'pricePerNightContainer'}>
					<Label variant={'h2'} className={'yellowText'}>
						{StringUtils.addCommasToNumber(
							Math.min(...props.accommodationStay.prices.map((price) => price.pricePoints))
						)}
					</Label>
					<Label variant={'body1'} mb={16}>
						points per night
					</Label>
					<LabelButton
						look={'containedPrimary'}
						variant={'buttonMdLg'}
						label={props.isCustomResort ? 'Call to Book' : 'Book Now'}
						className={'yellow'}
						onClick={() => {
							props.handleReserveStay();
							popupController.closeAll();
						}}
					/>
				</Box>
			);
		} else {
			return (
				<Box className={'pricePerNightContainer'}>
					<Label variant={'h2'}>
						$
						{StringUtils.formatMoney(
							Math.min(...props.accommodationStay.prices.map((price) => price.priceCents))
						)}
					</Label>
					<Label variant={'body1'}>
						$
						{StringUtils.formatMoney(
							Math.min(...props.accommodationStay.prices.map((price) => price.grandTotalCents))
						)}
					</Label>
					{!reservationFilters.redeemPoints && (
						<Label variant={'body1'} mb={16}>
							including taxes and fees
						</Label>
					)}
					<LabelButton
						look={'containedPrimary'}
						variant={'buttonMdLg'}
						label={props.isCustomResort ? 'Call to Book' : 'Book Now'}
						className={'yellow'}
						onClick={() => {
							props.handleReserveStay();
							popupController.closeAll();
						}}
					/>
					{!reservationFilters.redeemPoints && props.loyaltyStatus === 'ACTIVE' && (
						<div style={{ marginTop: '10px', width: '300px', textAlign: 'right' }}>
							<Label className={'secondaryTextColor alignmentCenter'} variant={'captionBI'}>
								{/* {useMerchantReward.pointEarningCalculation(
									destinationObj,
									pointPerDollar,
									userTierValue,
									user,
									!!props.accommodationStay.campaigns ? props.accommodationStay.campaigns.length : 0
								)} */}
								{useMerchantReward.rewardPointsCalculation(
									destinationObj,
									pointPerDollar,
									userTierValue,
									user
								)}
							</Label>
						</div>
					)}
				</Box>
			);
		}
	}
	return (
		<Popup {...props}>
			<Paper className={'rsAccommodationOverviewPopup'}>
				<div className={'popupHeader'}>
					<Label variant={'h3'}>{`${props.destinationName} - ${props.accommodationStay.name}`}</Label>
					<Icon
						iconImg={'icon-close'}
						size={32}
						onClick={() => {
							props.closeSelf();
						}}
					/>
				</div>
				<Box className={'popupBody'} padding={'34px 48px'} maxWidth={1644}>
					{!!props.accommodationStay.media && (
						<DestinationImageGallery
							resortPointsData={props.pointsEarnable}
							onGalleryClick={() => {
								popupController.open<TabbedCarouselPopupProps>(LightBoxCarouselPopup, {
									imageData: props.accommodationStay.media.sort(
										(a: any, b: any) => a.mediaIndex - b.mediaIndex
									)
								});
							}}
							imageData={props.accommodationStay.media}
						/>
					)}
					<Box className={'columnRow'}>
						<Box className="accommodationStayColumn">
							<Box display={'flex'} gap={20}>
								<ImageLabel
									labelName={`${props.accommodationStay.bedroomCount.toString()} Bed`}
									imgSrc={'sleep.png'}
									imgWidth={'40px'}
									imgHeight={'40px'}
									iconPosition={'left'}
									labelVariant={'h5'}
								/>
								<hr />
								<ImageLabel
									labelName={`${props.accommodationStay.bathroomCount.toString()} Bath`}
									imgSrc={'shower.png'}
									imgWidth={'40px'}
									imgHeight={'40px'}
									iconPosition={'left'}
									labelVariant={'h5'}
								/>

								{props.accommodationStay.size && (
									<>
										<hr />
										<ImageLabel
											labelName={props.renderAccommodationSize()}
											imgSrc={'square-foot.png'}
											imgWidth={'40px'}
											imgHeight={'40px'}
											iconPosition={'left'}
											labelVariant={'h5'}
										/>
									</>
								)}
							</Box>

							<Label variant={'body1'} margin={'21px auto 50px'}>
								{props.accommodationStay.longDescription}
							</Label>
						</Box>
						<Box className={'accommodationPricingColumn'}>{renderPricePerNight()}</Box>
					</Box>
					{props.accommodationStay.amenities && !!props.accommodationStay.amenities.length && (
						<div className={'amenitiesList'}>
							<Label variant={'h3'} marginTop={20}>
								Amenities
							</Label>
							<Box display={'flex'} flexWrap={'wrap'} margin={'30px auto'} gap={40}>
								{props.renderAmenities()}
							</Box>
						</div>
					)}

					{!!props.accommodationStay.layout.length && (
						<>
							<Label variant={'h3'} margin={'60px auto'}>
								Floor Plan
							</Label>

							{!!props.accommodationStay?.layout.length ? (
								<Carousel
									className={'imageContainer'}
									showControls
									children={props.renderLayoutImages()}
								/>
							) : (
								<div className={'singleLayoutImg'}>
									<IKImage
										loading="lazy"
										src={props.accommodationStay.layout[0].media.urls.imageKit}
										alt={''}
									/>
								</div>
							)}
						</>
					)}
				</Box>
			</Paper>
		</Popup>
	);
};
export default AccommodationPopup;
