import * as React from 'react';
import { FormEvent, useEffect, useState } from 'react';
import './SignupPopup.scss';
import { Box, Link, Popup, popupController } from '@bit/redsky.framework.rs.996';
import Label from '@bit/redsky.framework.rs.label/dist/Label';
import Paper from '../../components/paper/Paper';
import Icon from '@bit/redsky.framework.rs.icon';
import { RsFormControl, RsFormGroup, RsValidator, RsValidatorEnum } from '@bit/redsky.framework.rs.form';
import { PopupProps } from '@bit/redsky.framework.rs.996/dist/popup/Popup';
import LabelInput from '../../components/labelInput/LabelInput';
import LabelCheckbox from '../../components/labelCheckbox/LabelCheckbox';
import { WebUtils } from '../../utils/utils';
import serviceFactory from '../../services/serviceFactory';
import UserService from '../../services/user/user.service';
import SigninPopup, { SigninPopupProps } from '../signin/SigninPopup';
import { rsToastify } from '@bit/redsky.framework.rs.toastify';
import SpinningLoaderPopup from '../spinningLoaderPopup/SpinningLoaderPopup';
import useIsAtBreakpoint from '../../customHooks/useIsAtBreakpoint';
import LabelButton from '../../components/labelButton/LabelButton';
import router from '../../utils/router';
import PasswordValidator from '../../components/passwordValidator/PasswordValidator';
import OnboardingFlowOne from '../../components/onboardingFlow/OnboardingFlowOne';
import VerifyEmailPopup, { VerifyEmailPopupProps } from '../verifyEmailPopup/VerifyEmailPopup';
import OnboardingFlowOtp, { OnboardingFlowOtpProps } from '../../components/onboardingFlow/OnboardingFlowOtp';
import ProgressBar from '../../components/progressBar/ProgressBar';

interface ISignUpForm extends Api.User.Req.Create {
	confirmPassword?: string;
}

export interface SignupPopupProps extends PopupProps {
	primaryEmail?: string;
	password?: string;
}

const SignupPopup: React.FC<SignupPopupProps> = (props) => {
	const userService = serviceFactory.get<UserService>('UserService');
	const isMobile = useIsAtBreakpoint();
	const [hasAgreedToTerms, setHasAgreedToTerms] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<string>('');
	const [canSubmit, setCanSubmit] = useState<boolean>(false);
	const [passwordValidatorCheck, setPasswordValidatorCheck] = useState<boolean>(false);
	const [signUpForm, setSignUpForm] = useState<RsFormGroup>(
		new RsFormGroup([
			new RsFormControl('firstName', '', [new RsValidator(RsValidatorEnum.REQ, 'First name is required')]),
			new RsFormControl('lastName', '', [new RsValidator(RsValidatorEnum.REQ, 'Last name is required')]),
			new RsFormControl('primaryEmail', props.primaryEmail || '', [
				new RsValidator(RsValidatorEnum.REQ, 'Email Required'),
				new RsValidator(RsValidatorEnum.EMAIL, 'Invalid email')
			]),
			new RsFormControl('password', props.password || '', [
				new RsValidator(RsValidatorEnum.REQ, 'Please provide a password'),
				new RsValidator(RsValidatorEnum.CUSTOM, 'Password must not be password', (control) => {
					return control.value.toString() !== 'password';
				})
			]),
			new RsFormControl('confirmPassword', '', [
				new RsValidator(RsValidatorEnum.REQ, 'Please retype your new password'),
				new RsValidator(RsValidatorEnum.CUSTOM, 'Password does not match', (control): boolean => {
					return control.value.toString() === signUpForm.get('password').value.toString();
				})
			])
		])
	);
	const fullProps = popupController.convertProps(props);

	useEffect(() => {
		window.history.pushState(null, 'SignupPopup Close', window.location.pathname + window.location.search);
		window.addEventListener('popstate', (e: PopStateEvent) => {
			e.preventDefault();
			popupController.close(SignupPopup);
		});
		const id = router.subscribeToBeforeRouterNavigate(async () => {
			router.getCurrentPath();
			return true;
		});
		return () => {
			router.unsubscribeFromBeforeRouterNavigate(id);
			window.removeEventListener('popstate', (e: PopStateEvent) => {
				e.preventDefault();
				popupController.close(SignupPopup);
			});
		};
	}, []);

	const ConTwoDecDigit = (digit: any) => {
		return digit.indexOf('.') > 0
			? digit.split('.').length <= 4
				? digit.split('.')[0] + '.' + digit.split('.')[1].substring(-1, 4)
				: digit
			: digit;
	};

	async function updateForm(control: RsFormControl) {
		if (control.key === 'firstName') {
			if (!isNaN(Number(control.value))) {
				// If the value is a number, clear it
				control.value = '';
			} else {
				// Remove special characters and ensure two decimal digits
				control.value = control.value.toString().replace(/[^A-Za-z]/g, '');
				control.value = ConTwoDecDigit(control.value);
			}
		}

		if (control.key === 'lastName') {
			if (!isNaN(Number(control.value))) {
				// If the value is a number, clear it
				control.value = '';
			} else {
				// Remove special characters and ensure two decimal digits
				control.value = control.value.toString().replace(/[^A-Za-z]/g, '');
				control.value = ConTwoDecDigit(control.value);
			}
		}

		setSignUpForm(signUpForm.clone().update(control));
		if (errorMessage !== '') {
			setErrorMessage('');
		}
		if (control.key === 'password') {
			await signUpForm.isValid();
			setSignUpForm(signUpForm.clone());
		}
	}

	async function signUp(event: FormEvent<HTMLFormElement>) {
		event.preventDefault();
		if (!(await signUpForm.isValid())) {
			setSignUpForm(signUpForm.clone());
			setErrorMessage('Missing information');
			return;
		}
		let createUserModel: ISignUpForm = signUpForm.toModel();
		delete createUserModel.confirmPassword;
		try {
			popupController.open(SpinningLoaderPopup);
			let userResponse = await userService.createNewUser(createUserModel);
			rsToastify.success('Account created successfully', 'Success!');

			let otp = await userService.sendOtp({
				id: userResponse.id,
				primaryEmail: userResponse.primaryEmail,
				firstName: userResponse.firstName,
				lastName: userResponse.lastName
			});

			if (userResponse && userResponse.isVerified) {
				const userDetails = await userService.loginUserByPassword(
					createUserModel.primaryEmail,
					signUpForm.get('password').value.toString()
				);
				// setErrorMessage('');
				// rsToastify.success('Login successful');

				popupController.close(SignupPopup);
				popupController.close(SpinningLoaderPopup);
				if (userDetails) {
					popupController.open(OnboardingFlowOne);
				} else {
					popupController.open(OnboardingFlowOne);
				}
			} else {
				popupController.close(SignupPopup);
				popupController.close(SpinningLoaderPopup);
				popupController.open<OnboardingFlowOtpProps>(OnboardingFlowOtp, {
					userResponse: userResponse
				});
			}
		} catch (e) {
			popupController.close(SpinningLoaderPopup);
			setErrorMessage(WebUtils.getRsErrorMessage(e, 'Unexpected Server error'));
		}
	}

	const progressbar1 = '../../images/onboardingFlow/progressbar1.svg';

	useEffect(() => {
		(async function () {
			const isFormValid: boolean = await signUpForm.isValid();
			setCanSubmit(isFormValid && hasAgreedToTerms && passwordValidatorCheck);
		})();
	}, [signUpForm, hasAgreedToTerms, passwordValidatorCheck]);

	return (
		<Popup {...props} preventCloseByBackgroundClick>
			<Paper className={'rsSignupPopup'} position={'relative'}>
				<Icon
					iconImg={'icon-close'}
					className={'closeBtn'}
					onClick={() => {
						popupController.closeById(fullProps.popupId);
					}}
					size={16}
					cursorPointer
				/>
				<Box className={'popupTitle'}>
					<Label variant={'h5'} className={'primaryTextColor'}>
						<img src={`../../images/rentyl-rewards.png`} className="rr-logo" alt="Rentyl Reward" />
					</Label>

					<div className="onboarding-progressbar-container progressbar-s">
						<ProgressBar totalProgressBar={2} currentStep={1} />
						{/* <img src={progressbar1} className="onboarding-progressbar" alt="progressbar1" /> */}
						{/* <span className="onboarding-progressbar-title">1/3</span> */}
					</div>
				</Box>
				<Box className={'popupBody'}>
					<Box pb={16}>
						<Label variant={'h5'} textAlign="center">
							Sign Up & start earning today.{' '}
							<a href="https://rentylresorts.com/loyalty/" target="_blank" style={{ color: '#2c3c60' }}>
								Learn More
							</a>
							<span style={{ marginLeft: 6, marginTop: 5, verticalAlign: 'middle' }}>
								<svg
									width="19"
									height="19"
									viewBox="0 0 19 19"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M11.5 0.799988V2.79999H15.09L5.26 12.63L6.67 14.04L16.5 4.20999V7.79999H18.5V0.799988M16.5 16.8H2.5V2.79999H9.5V0.799988H2.5C1.39 0.799988 0.5 1.69999 0.5 2.79999V16.8C0.5 17.3304 0.710714 17.8391 1.08579 18.2142C1.46086 18.5893 1.96957 18.8 2.5 18.8H16.5C17.0304 18.8 17.5391 18.5893 17.9142 18.2142C18.2893 17.8391 18.5 17.3304 18.5 16.8V9.79999H16.5V16.8Z"
										fill="#2C3C60"
									/>
								</svg>
							</span>
						</Label>
					</Box>
					<form onSubmit={signUp}>
						<Box display={'flex'} flexDirection={isMobile ? 'column' : 'row'} gap={isMobile ? 0 : 36}>
							<LabelInput
								labelVariant={'h6'}
								title={'First name'}
								inputType={'text'}
								control={signUpForm.get('firstName')}
								updateControl={updateForm}
							/>
							<LabelInput
								labelVariant={'h6'}
								title={'Last name'}
								inputType={'text'}
								control={signUpForm.get('lastName')}
								updateControl={updateForm}
							/>
						</Box>
						<LabelInput
							labelVariant={'h6'}
							title={'Email Address'}
							inputType={'email'}
							control={signUpForm.get('primaryEmail')}
							updateControl={updateForm}
						/>
						<Box display={'flex'} flexDirection={isMobile ? 'column' : 'row'} gap={isMobile ? 0 : 36}>
							<LabelInput
								labelVariant={'h6'}
								title={'Create new password'}
								inputType={'password'}
								control={signUpForm.get('password')}
								updateControl={updateForm}
							/>
							<LabelInput
								labelVariant={'h6'}
								title={'Confirm new password'}
								inputType={'password'}
								control={signUpForm.get('confirmPassword')}
								updateControl={updateForm}
							/>
						</Box>

						<Box>
							<PasswordValidator
								password={signUpForm.get('password').value.toString()}
								onValidationStatusUpdate={(isValid) => setPasswordValidatorCheck(isValid)}
							/>
						</Box>

						<LabelCheckbox
							value={1}
							text={
								<>
									I agree to the{' '}
									<Link
										path={`/legal/terms-and-conditions`}
										external
										target={'blank'}
										className="termsAndCondition"
									>
										<span>Terms and Conditions</span>.
									</Link>
								</>
							}
							onSelect={() => {
								setHasAgreedToTerms(true);
							}}
							onDeselect={() => {
								setHasAgreedToTerms(false);
							}}
							isChecked={hasAgreedToTerms}
						/>
						<Box display={'flex'} justifyContent={'center'}>
							<LabelButton
								look={'containedPrimary'}
								className={'yellow'}
								disabled={!canSubmit}
								label={'Sign up'}
								variant={'buttonMdLg'}
								buttonType={'submit'}
							/>
						</Box>
					</form>
					<Label variant={'body2'} color={'red'} marginBottom={24}>
						{errorMessage}
					</Label>
					<Box className={'memberTextContainer'}>
						<Label variant={'h6'} textAlign="center">
							Already a member?{' '}
							<LabelButton
								className={'loginButton'}
								look={'none'}
								onClick={() => {
									popupController.closeById(fullProps.popupId);
									popupController.open<SigninPopupProps>(SigninPopup, {});
								}}
								label={'log in'}
								variant={'h6'}
							/>{' '}
							here
						</Label>
						<Box p={4} mt={8} display="flex" justifyContent="center" className="loginPopupQuestion">
							Have question?{' '}
							<a
								href="https://rentylresorts.com/contact-us/"
								style={{ marginLeft: 6, textDecoration: 'none' }}
							>
								Contact Us
							</a>
							.
						</Box>
					</Box>
				</Box>
			</Paper>
		</Popup>
	);
};

export default SignupPopup;
