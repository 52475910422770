import React from 'react';
import './AccountLandingPage.scss';
import { Box, Page, popupController } from '@bit/redsky.framework.rs.996';
import { useRecoilValue } from 'recoil';
import globalState from '../../state/globalState';
import UserBasicInfoPaper from '../../components/userBasicInfoPaper/UserBasicInfoPaper';
import LoadingPage from '../loadingPage/LoadingPage';
import { useNavigate } from 'react-router-dom';
import router from '../../utils/router';
import serviceFactory from '../../services/serviceFactory';
import UserService from '../../services/user/user.service';
import AccountNavigationTile from '../../components/accountNavigationTile/AccountNavigationTile';
import { useEffect } from 'react';
import SubNavMenu from '../../components/subNavMenu/SubNavMenu';
import RecoverPasswordPopup, {
	RecoverPasswordPopupProps
} from '../../popups/recoverPasswordPopup/RecoverPasswordPopup';
import SigninPopup, { SigninPopupProps } from '../../popups/signin/SigninPopup';

interface MyAccountPageProps {}

const AccountLandingPage: React.FC<MyAccountPageProps> = () => {
	let navigate = useNavigate();
	const userService = serviceFactory.get<UserService>('UserService');
	const user = useRecoilValue<Api.User.Res.Detail | undefined>(globalState.user);
	const params = router.getPageUrlParams<{ accountAccess: string; passwordResetGuid: string }>([
		{ key: 'aa', default: '', type: 'string', alias: 'accountAccess' },
		{ key: 'guid', default: '', type: 'string', alias: 'passwordResetGuid' }
	]);

	useEffect(() => {
		window.scrollTo(0, 0);
		let appBar = document.querySelector('.rsAppBar');
		if (!appBar) return;
	}, []);

	useEffect(() => {
		if (user) return;
		if (params.accountAccess === 'recovery' || params.accountAccess === 'welcome') {
			popupController.open<RecoverPasswordPopupProps>(RecoverPasswordPopup, {
				accountAccess: params.accountAccess,
				passwordResetGuid: params.passwordResetGuid
			});
		} else {
			popupController.open<SigninPopupProps>(SigninPopup, {
				successCallback: () => {
					navigate('/account');
				}
			});
		}
	}, [user]);

	return !user ? (
		<LoadingPage />
	) : (
		<Page className={'rsAccountLandingPage'}>
			<div className={'rs-page-content-wrapper'}>
				<SubNavMenu title={'Account'} navigateTo={'HOME'} />
				<UserBasicInfoPaper
					userData={user}
					onLogOut={() => {
						userService.logout();
						navigate('/');
					}}
				/>
				<Box className={'accountNavigationTileWrapper'} mt={30}>
					<AccountNavigationTile
						imgSrc={'id'}
						route={'/account/personal-info'}
						title={'Personal Information'}
					/>
					<AccountNavigationTile imgSrc={'house'} route={'/account/address'} title={'My Addresses'} />
					<AccountNavigationTile
						imgSrc={'credit'}
						route={'/account/payment-methods'}
						title={'Payment Methods'}
					/>
					<AccountNavigationTile imgSrc={'medal'} route={'/account/points'} title={'Manage Points'} />
					<AccountNavigationTile
						imgSrc={'travel-preferences'}
						route={'/account/travel-preferences'}
						title={'Travel Preferences:'}
					/>
				</Box>
			</div>
		</Page>
	);
};

export default AccountLandingPage;
