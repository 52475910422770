// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rsPrivacyPolicyPage p{padding:20px}.rsPrivacyPolicyPage th{border:1px solid black}.rsPrivacyPolicyPage .centerAlign:first-child{margin-top:80px}.rsPrivacyPolicyPage .centerAlign{text-align:center}.small .rsPrivacyPolicyPage .h3{text-align:center}
`, "",{"version":3,"sources":["webpack://./src/pages/privacyPolicyPage/PrivacyPolicyPage.scss"],"names":[],"mappings":"AAEA,uBAEE,YAAa,CAFf,wBAKE,sBAAuB,CALzB,8CASE,eAAgB,CATlB,kCAYE,iBAAkB,CAClB,gCAMC,iBAAkB","sourcesContent":["@import '../../themes/themes';\n\n.rsPrivacyPolicyPage {\n\tp {\n\t\tpadding: 20px;\n\t}\n\tth {\n\t\tborder: 1px solid black;\n\t}\n\n\t.centerAlign:first-child {\n\t\tmargin-top: 80px;\n\t}\n\t.centerAlign {\n\t\ttext-align: center;\n\t}\n}\n\n.small {\n\t.rsPrivacyPolicyPage {\n\t\t.h3 {\n\t\t\ttext-align: center;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primaryTextColor": `var(--primaryTextColor)`,
	"secondaryTextColor": `var(--secondaryTextColor)`,
	"successGreen": `var(--successGreen)`,
	"adminPink": `var(--adminPink)`,
	"warningRed": `var(--warningRed)`,
	"buttonLinkColor": `var(--buttonLinkColor)`,
	"secondaryButtonLinkColor": `var(--secondaryButtonLinkColor)`,
	"iconAndHighlightTextColor": `var(--iconAndHighlightTextColor)`,
	"primaryBackgroundColor": `var(--primaryBackgroundColor)`,
	"secondaryBackgroundColor": `var(--secondaryBackgroundColor)`,
	"errorColor": `var(--errorColor)`,
	"primaryBoxShadowColor": `var(--primaryBoxShadowColor)`,
	"greyLineColor": `var(--greyLineColor)`,
	"thinGreyLineColor": `var(--thinGreyLineColor)`,
	"white": `var(--white)`,
	"lightestGray": `var(--lightestGray)`,
	"backgroundGray": `var(--backgroundGray)`,
	"borderGray": `var(--borderGray)`,
	"medGray2": `var(--medGray2)`,
	"medGray2Active": `var(--medGray2Active)`,
	"black": `var(--black)`,
	"primaryCalendarColor": `var(--primaryCalendarColor)`,
	"secondaryCalendarColor": `var(--secondaryCalendarColor)`
};
export default ___CSS_LOADER_EXPORT___;
