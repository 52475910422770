import {
	StringUtils as BaseStringUtils,
	ObjectUtils as BaseObjectUtils,
	RegionUtils as BaseRegionUtils,
	WebUtils as BaseWebUtils,
	DateUtils as BaseDateUtils,
	NumberUtils as BaseNumberUtils
} from '@bit/redsky.framework.rs.utils';
import moment from 'moment';

import globalState, { getRecoilExternalValue } from '../state/globalState';

class StringUtils extends BaseStringUtils {
	static formatCountryCodePhoneNumber(phone: string) {
		let cleaned = ('' + phone).replace(/\D/g, '');
		let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
		if (match) {
			let intlCode = match[1] ? '+1 ' : '';
			return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
		}
	}
	static formatCurrency(currencyCode: string): string {
		if (currencyCode == '' || currencyCode == null) {
			return '$';
		}
		return currencyCode == 'USD' ? '$' : currencyCode + ' ';
	}
	static customBillingEmailPattern = new RegExp(/^[a-zA-Z0-9#$%&'*.\-+/=?^_{}~]+@[a-zA-Z0-9]+\.[A-Za-z]+$/);
	static zipPattern = new RegExp(/^[A-Za-z0-9]*$/);
	static namePattern = new RegExp(/^([a-zA-Z][A-Za-z ,.'`-]*)$/);
	static streetAddressCityPattern = new RegExp(
		`^(?![ -.&,_'":?!])(?!.*[- &_'":]$)(?!.*[-.#@&,:?!]{2})[a-zA-Z0-9- .#@&,_'":.?!]+$`
	);
	static setAddPackagesParams(data: {
		destinationId: number;
		newRoom: Misc.StayParams;
		stays?: Misc.StayParams[];
	}): string {
		return encodeURI(JSON.stringify(data));
	}

	static imageKitBaseUrl() {
		return 'https://ik.imagekit.io/spire';
	}

	static getCorporateName() {
		return 'Rentyl LLC';
	}

	static buildAddressString(
		address: {
			address1?: string;
			address2?: string;
			city?: string;
			state?: string;
			zip?: string;
			country?: string;
		},
		fullCountryName: string
	): string {
		let addressToBuild = '';
		if (address.address1) {
			addressToBuild += `${address.address1}`;
			if (!address.address2 && (address.city || address.state || address.zip)) {
				addressToBuild += `, `;
			}
		}

		if (address.address2) {
			if (address.address1) addressToBuild += ` `;
			addressToBuild += `${address.address2}`;
			if (address.city || address.state || address.zip) {
				addressToBuild += `, `;
			}
		}

		if (address.city) {
			addressToBuild += `${address.city}`;
			if (address.state || address.zip) {
				addressToBuild += `, `;
			}
		}

		if (address.state) {
			addressToBuild += `${address.state} `;
		} else {
			addressToBuild += `${fullCountryName} `;
		}

		if (address.zip && address.country === 'US') {
			addressToBuild += ` ${address.zip}`;
		}

		return addressToBuild;
	}
}

class ObjectUtils extends BaseObjectUtils {
	static areArraysEqual(array1: number[], array2: number[]): boolean {
		if (array1 === array2) return true;
		if (array1 == null || array2 == null) return false;
		if (array1.length !== array2.length) return false;

		for (let i = 0; i < array1.length; ++i) {
			if (array1[i] !== array2[i]) return false;
		}
		return true;
	}
}

class RegionUtils extends BaseRegionUtils {}

class WebUtils extends BaseWebUtils {
	/**
	 * Checks to see if browser is pointed to localhost
	 * @returns true if in localhost otherwise false
	 */
	static isLocalHost(): boolean {
		return window.location.host.includes('localhost');
	}

	static parseURLParamsToFilters(): Misc.ReservationFilters {
		const urlParams = new URLSearchParams(window.location.search);
		let startDate = urlParams.get('arrive')
			? moment(urlParams.get('arrive')!).format('YYYY-MM-DD')
			: moment(new Date()).add(14, 'days').format('YYYY-MM-DD');
		let endDate = urlParams.get('depart')
			? moment(urlParams.get('depart')!).format('YYYY-MM-DD')
			: moment(startDate).add(5, 'days').format('YYYY-MM-DD');

		let sortOrder = urlParams.get('sortOrder') ? urlParams.get('sortOrder')! : 'ASC';

		let reservationFilter: Misc.ReservationFilters = {
			destinationId: urlParams.has('di') ? parseInt(urlParams.get('di')!) : undefined,
			accommodationId: urlParams.has('ai') ? parseInt(urlParams.get('ai')!) : undefined,
			chainId: urlParams.has('chain') ? parseInt(urlParams.get('chain')!) : undefined,
			hotelId: urlParams.has('hotel') ? parseInt(urlParams.get('hotel')!) : undefined,
			startDate,
			endDate,
			adultCount: urlParams.has('adult') ? parseInt(urlParams.get('adult')!) : 1,
			childCount: urlParams.has('child') ? parseInt(urlParams.get('child')!) : 0,
			bedroomCount: urlParams.has('bedrooms') ? parseInt(urlParams.get('bedrooms')!) : 0,
			bathroomCount: urlParams.has('bathrooms') ? parseInt(urlParams.get('bathrooms')!) : 0,
			amenityIds: urlParams.has('amenityIds') ? ObjectUtils.safeParse(urlParams.get('amenityIds')!) : undefined,
			priceRangeMax: urlParams.has('priceRangeMax') ? parseInt(urlParams.get('priceRangeMax')!) : 1000,
			priceRangeMin: urlParams.has('priceRangeMin') ? parseInt(urlParams.get('priceRangeMin')!) : 10,
			propertyTypeIds: urlParams.has('propertyTypeIds')
				? ObjectUtils.safeParse(urlParams.get('propertyTypeIds')!)
				: undefined,
			redeemPoints: !!urlParams.get('redeemPoints'),
			regionIds: urlParams.has('regionIds') ? ObjectUtils.safeParse(urlParams.get('regionIds')!) : undefined,
			experienceIds: urlParams.has('experienceIds')
				? ObjectUtils.safeParse(urlParams.get('experienceIds')!)
				: undefined,
			pagination: { page: 1, perPage: 10 },
			sortOrder: sortOrder === 'ASC' || sortOrder === 'DESC' ? sortOrder : 'ASC'
		};

		let key: keyof Misc.ReservationFilters;
		for (key in reservationFilter) {
			if (reservationFilter[key] === undefined) delete reservationFilter[key];
		}
		return reservationFilter;
	}

	static createUrlParams(reservationFilters: Misc.ReservationFilters): string {
		let stringBuilder: string[] = [];
		if (reservationFilters.destinationId) {
			stringBuilder.push(`di=${reservationFilters.destinationId}`);
		}
		if (reservationFilters.accommodationId) {
			stringBuilder.push(`ai=${reservationFilters.accommodationId}`);
		}

		if (reservationFilters.startDate) {
			stringBuilder.push(`arrive=${reservationFilters.startDate}`);
		}
		if (reservationFilters.endDate) {
			stringBuilder.push(`depart=${reservationFilters.endDate}`);
		}
		if (reservationFilters.adultCount) stringBuilder.push(`adult=${reservationFilters.adultCount}`);
		if (reservationFilters.childCount || reservationFilters.childCount === 0)
			stringBuilder.push(`child=${reservationFilters.childCount}`);
		if (reservationFilters.redeemPoints) stringBuilder.push(`redeemPoints=${reservationFilters.redeemPoints}`);
		if (reservationFilters.bedroomCount || reservationFilters.bedroomCount === 1)
			stringBuilder.push(`bedrooms=${reservationFilters.bedroomCount}`);
		if (reservationFilters.bathroomCount) stringBuilder.push(`bathrooms=${reservationFilters.bathroomCount}`);
		if (reservationFilters.amenityIds)
			stringBuilder.push(`amenityIds=${JSON.stringify(reservationFilters.amenityIds)}`);
		if (reservationFilters.regionIds)
			stringBuilder.push(`regionIds=${JSON.stringify(reservationFilters.regionIds)}`);
		if (reservationFilters.experienceIds)
			stringBuilder.push(`experienceIds=${JSON.stringify(reservationFilters.experienceIds)}`);
		if (reservationFilters.sortOrder) stringBuilder.push(`sortOrder=${reservationFilters.sortOrder}`);
		if (reservationFilters.priceRangeMin) stringBuilder.push(`priceRangeMin=${reservationFilters.priceRangeMin}`);
		if (reservationFilters.priceRangeMax) stringBuilder.push(`priceRangeMax=${reservationFilters.priceRangeMax}`);
		if (reservationFilters.propertyTypeIds)
			stringBuilder.push(`propertyTypeIds=${JSON.stringify(reservationFilters.propertyTypeIds)}`);

		let builtString = stringBuilder.join('&');
		return '?' + builtString;
	}

	static updateUrlParams(reservationFilters: Misc.ReservationFilters): string {
		const builtString = WebUtils.createUrlParams(reservationFilters);
		window.history.replaceState({}, '', builtString);
		return builtString;
	}
	/**
	 * Takes parameters and creates a pageQuery object
	 * @param page - must be a number
	 * @param perPage - must be a number
	 * @param sortOrder - one of a specific list of strings
	 * @param sortField - any string to sort on
	 * @param matchType - a list of specific strings
	 * @param filter - an array of column and value objects
	 */
	static createPageQueryObject(
		page: number = 1,
		perPage: number = 100,
		sortOrder: RedSky.StandardOrderTypes = 'ASC',
		sortField: string = 'name',
		matchType: RedSky.MatchTypes = 'like',
		filter: RedSky.FilterQueryValue[] = [{ column: 'name', value: '' }]
	): RedSky.PageQuery {
		return {
			pagination: { page, perPage },
			sort: { field: sortField, order: sortOrder },
			filter: { matchType, searchTerm: filter }
		};
	}

	/**
	 * Checks an thrown error object from an axios request for the standard RedSky Error Message
	 * @param error - Error object thrown via axios
	 * @param defaultMessage - A message to use incase there wasn't one given
	 * @returns The msg from the RsError object or the defaultMessage passed in
	 */
	static getRsErrorMessage(error: any, defaultMessage: string): string {
		let errorResponse = ObjectUtils.smartParse(WebUtils.getAxiosErrorMessage(error));
		if (typeof errorResponse !== 'object') return errorResponse;
		if ('msg' in errorResponse) return errorResponse.msg;
		else if ('err' in errorResponse) return errorResponse.err;
		return defaultMessage;
	}
}

class DateUtils extends BaseDateUtils {
	static displayUserDate(date: Date | string, formatType?: string): string {
		return this.formatDate(date, formatType);
	}

	static formatDate(date: Date | string, formatType?: string): string {
		switch (formatType) {
			case 'MM/DD/YYYY':
				return moment(date).format('MM/DD/YYYY');
			case 'MM-DD-YY':
				return moment(date).format('MM-DD-YY');
			case 'MM-DD-YYYY':
				return moment(date).format('MM-DD-YYYY');
			default:
				return moment(date).format('ddd MMM DD YYYY');
		}
	}
}

class NumberUtils extends BaseNumberUtils {
	/**
	 * Tests if the candidate passed in is a number
	 * @param candidate
	 */
	static isANumber(candidate: any): boolean {
		return !isNaN(Number(candidate));
	}

	/**
	 * @name dollarsToCents
	 * @param {dollars} - The floating point dollar value
	 * @returns {number} - The integer number of cents
	 */
	static dollarsToCents(dollars: number): number {
		return parseInt((dollars * 100).toFixed(0));
	}

	static centsToDollars(cents: number): number {
		return parseFloat((cents / 100).toFixed(2));
	}

	/**
	 *
	 * @param {number} cents - The price in cents
	 * @param {number} conversionRate - The number of cents per point (default 10)
	 * @param {number} roundToNext - The number of points to round up to (default 1000)
	 * @returns {number} - The price in points
	 */
	static centsToPoints(cents: number, conversionRate: number = 0.7, roundToNext: number = 1000): number {
		return NumberUtils.round(cents / conversionRate, roundToNext);
	}

	static round(num: number, significance: number): number {
		if (num === 0) return 0;
		const sign = Math.sign(num);
		num = Math.abs(num);
		significance = Math.abs(Math.trunc(significance));
		const mod = num % significance;
		if (mod === 0) {
			return num;
		}
		return sign * (num - mod + significance);
	}
}

export { StringUtils, ObjectUtils, RegionUtils, WebUtils, DateUtils, NumberUtils };

export function formatDateTime(dateTime: string | Date) {
	if (dateTime === 'N/A') return dateTime;
	let newDateTime = new Date(`${dateTime}`);
	let DateTimeArray = newDateTime.toString().split(' ');
	return `${DateTimeArray[1]} ${DateTimeArray[2]}, ${DateTimeArray[3]} ${newDateTime.toLocaleTimeString()}`;
}

export function formatReadableDate(date: string) {
	let match = formatDate(date);
	if (match) {
		return `${match[1]}/${match[2]}/${match[3]}`;
	} else {
		return date;
	}
}

export function formatFilterDateForServer(date: moment.Moment | null, startOrEnd: 'start' | 'end'): string {
	if (date) {
		return date.format('YYYY-MM-DD');
	} else {
		if (startOrEnd === 'end') return moment().add(1, 'day').format('YYYY-MM-DD');
		else return moment().format('YYYY-MM-DD');
	}
}

export function formatDateForServer(date: string) {
	let match = formatDate(date);
	if (match) {
		return `${match[3]}-${match[1]}-${match[2]}`;
	} else {
		return date;
	}
}

function formatDate(date: string) {
	if (date === 'N/A') return date;
	let cleaned = ('' + date).replace(/\D/g, '');
	if (cleaned.length === 7) cleaned = '0' + cleaned;
	return cleaned.match(/^(\d{2})(\d{2})(\d{4})$/);
}

export function isRouteUnauthorized(route: string): boolean {
	const company = getRecoilExternalValue<Api.Company.Res.GetCompanyAndClientVariables | undefined>(
		globalState.company
	);
	if (!company) return true;
	let isUnauthorized = company.unauthorizedPages.find((item) => item.route === route);

	return !!isUnauthorized;
}

export const uppercasePattern = new RegExp('^(?=.*[A-Z])');
export const numberPattern = new RegExp('^(?=.*[0-9])');
export const lowerCasePattern = new RegExp('^(?=.*[a-z])');
export const specialAndNumberCharPattern = new RegExp(/[0-9!\"#$%&'()*+,\-.\/:;<=>?@\[\\\]^_`{|}~]/g);
export const specialCharPattern = new RegExp(/\W|_/g);
export const whiteSpacePattern = new RegExp(/\s/g);
export const RESERVATION_NOT_FOUND_TITLE = "We couldn't find the reservation";
export const BACK_TO_ACCOUNT = "Let's get you back to your account";
