// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rsTitleLabel{position:relative}.rsTitleLabel .rsTitleLabelContent{overflow:auto}.rsTitleLabel .rsTitleLabelContent:hover{cursor:pointer}.rsTitleLabel .popupBox{position:absolute;border-radius:20px;background:var(--white);box-shadow:var(--primaryBoxShadowColor);box-sizing:border-box;z-index:1;top:145%;left:-29%}.rsTitleLabel .popupBox.hide{height:0;display:none}.rsTitleLabel .popupBox.show{height:fit-content;display:block}
`, "",{"version":3,"sources":["webpack://./src/components/topSearchBar/titleLabel/TitleLabel.scss","webpack://./src/themes/_mixins.scss"],"names":[],"mappings":"AACA,cACC,iBAAkB,CADnB,mCAGE,aAAc,CAHhB,yCAMG,cAAe,CANlB,wBAUE,iBAAkB,CAClB,kBAAmB,CACnB,uBCYiB,CDXjB,uCCWiB,CDVjB,qBAAsB,CACtB,SAAU,CACV,QAAS,CACT,SAAU,CAjBZ,6BAoBG,QAAS,CACT,YAAa,CArBhB,6BAwBG,kBAAmB,CACnB,aAAc","sourcesContent":["@import '../../../themes/themes';\n.rsTitleLabel {\n\tposition: relative;\n\t.rsTitleLabelContent {\n\t\toverflow: auto;\n\n\t\t&:hover {\n\t\t\tcursor: pointer;\n\t\t}\n\t}\n\t.popupBox {\n\t\tposition: absolute;\n\t\tborder-radius: 20px;\n\t\tbackground: theme-var($white);\n\t\tbox-shadow: theme-var($primaryBoxShadowColor);\n\t\tbox-sizing: border-box;\n\t\tz-index: 1;\n\t\ttop: 145%;\n\t\tleft: -29%;\n\n\t\t&.hide {\n\t\t\theight: 0;\n\t\t\tdisplay: none;\n\t\t}\n\t\t&.show {\n\t\t\theight: fit-content;\n\t\t\tdisplay: block;\n\t\t}\n\t}\n}\n","@import 'variables';\n\n/*\n  include a map in a class\n*/\n@mixin spread-map($map: ()) {\n\t@each $key, $value in $map {\n\t\t#{$key}: $value;\n\t}\n}\n\n/*\n  Returns a CSS variable property specific to themeing.\n  Accepts an optional fallback, and optional map.\n  - SCSS: color: theme-var($--theme-primary, blue)\n  - CSS:  color: var(--theme-primary, blue)\n  Throws error if the variable not part of the map\n*/\n@function theme-var($key, $fallback: null, $map: $defaultTheme) {\n\t@if not map-has-key($map, $key) {\n\t\t@error \"key: '#{$key}', is not a key in map: #{$map}\";\n\t}\n\t@if ($fallback) {\n\t\t@return var($key, $fallback);\n\t} @else {\n\t\t@return var($key);\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primaryTextColor": `var(--primaryTextColor)`,
	"secondaryTextColor": `var(--secondaryTextColor)`,
	"successGreen": `var(--successGreen)`,
	"adminPink": `var(--adminPink)`,
	"warningRed": `var(--warningRed)`,
	"buttonLinkColor": `var(--buttonLinkColor)`,
	"secondaryButtonLinkColor": `var(--secondaryButtonLinkColor)`,
	"iconAndHighlightTextColor": `var(--iconAndHighlightTextColor)`,
	"primaryBackgroundColor": `var(--primaryBackgroundColor)`,
	"secondaryBackgroundColor": `var(--secondaryBackgroundColor)`,
	"errorColor": `var(--errorColor)`,
	"primaryBoxShadowColor": `var(--primaryBoxShadowColor)`,
	"greyLineColor": `var(--greyLineColor)`,
	"thinGreyLineColor": `var(--thinGreyLineColor)`,
	"white": `var(--white)`,
	"lightestGray": `var(--lightestGray)`,
	"backgroundGray": `var(--backgroundGray)`,
	"borderGray": `var(--borderGray)`,
	"medGray2": `var(--medGray2)`,
	"medGray2Active": `var(--medGray2Active)`,
	"black": `var(--black)`,
	"primaryCalendarColor": `var(--primaryCalendarColor)`,
	"secondaryCalendarColor": `var(--secondaryCalendarColor)`
};
export default ___CSS_LOADER_EXPORT___;
