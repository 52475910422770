// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rsUpdatePaymentMethodPopup{position:relative;background:var(--white);max-width:856px;width:90%}.rsUpdatePaymentMethodPopup .rsIcon.icon-close{color:var(--medGray2);position:absolute;top:32px;right:24px}.rsUpdatePaymentMethodPopup .rsLabelButton{margin-top:32px;margin-left:auto}.rsUpdatePaymentMethodPopup .cardBodyContainer{padding:32px 24px;box-sizing:border-box;border-top:1px solid var(--borderGray)}
`, "",{"version":3,"sources":["webpack://./src/popups/updatePaymentMethodPopup/UpdatePaymentMethodPopup.scss","webpack://./src/themes/_mixins.scss"],"names":[],"mappings":"AACA,4BACC,iBAAkB,CAClB,uBCsBkB,CDrBlB,eAAgB,CAChB,SAAU,CAJX,+CAOG,qBCiBgB,CDhBhB,iBAAkB,CAClB,QAAS,CACT,UAAW,CAVd,2CAeE,eAAgB,CAChB,gBAAiB,CAhBnB,+CAoBE,iBAAkB,CAClB,qBAAsB,CACtB,sCCEiB","sourcesContent":["@import '../../themes/themes';\n.rsUpdatePaymentMethodPopup {\n\tposition: relative;\n\tbackground: theme-var($white);\n\tmax-width: 856px;\n\twidth: 90%;\n\t.rsIcon {\n\t\t&.icon-close {\n\t\t\tcolor: theme-var($medGray2);\n\t\t\tposition: absolute;\n\t\t\ttop: 32px;\n\t\t\tright: 24px;\n\t\t}\n\t}\n\n\t.rsLabelButton {\n\t\tmargin-top: 32px;\n\t\tmargin-left: auto;\n\t}\n\n\t.cardBodyContainer {\n\t\tpadding: 32px 24px;\n\t\tbox-sizing: border-box;\n\t\tborder-top: 1px solid theme-var($borderGray);\n\t}\n}\n","@import 'variables';\n\n/*\n  include a map in a class\n*/\n@mixin spread-map($map: ()) {\n\t@each $key, $value in $map {\n\t\t#{$key}: $value;\n\t}\n}\n\n/*\n  Returns a CSS variable property specific to themeing.\n  Accepts an optional fallback, and optional map.\n  - SCSS: color: theme-var($--theme-primary, blue)\n  - CSS:  color: var(--theme-primary, blue)\n  Throws error if the variable not part of the map\n*/\n@function theme-var($key, $fallback: null, $map: $defaultTheme) {\n\t@if not map-has-key($map, $key) {\n\t\t@error \"key: '#{$key}', is not a key in map: #{$map}\";\n\t}\n\t@if ($fallback) {\n\t\t@return var($key, $fallback);\n\t} @else {\n\t\t@return var($key);\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primaryTextColor": `var(--primaryTextColor)`,
	"secondaryTextColor": `var(--secondaryTextColor)`,
	"successGreen": `var(--successGreen)`,
	"adminPink": `var(--adminPink)`,
	"warningRed": `var(--warningRed)`,
	"buttonLinkColor": `var(--buttonLinkColor)`,
	"secondaryButtonLinkColor": `var(--secondaryButtonLinkColor)`,
	"iconAndHighlightTextColor": `var(--iconAndHighlightTextColor)`,
	"primaryBackgroundColor": `var(--primaryBackgroundColor)`,
	"secondaryBackgroundColor": `var(--secondaryBackgroundColor)`,
	"errorColor": `var(--errorColor)`,
	"primaryBoxShadowColor": `var(--primaryBoxShadowColor)`,
	"greyLineColor": `var(--greyLineColor)`,
	"thinGreyLineColor": `var(--thinGreyLineColor)`,
	"white": `var(--white)`,
	"lightestGray": `var(--lightestGray)`,
	"backgroundGray": `var(--backgroundGray)`,
	"borderGray": `var(--borderGray)`,
	"medGray2": `var(--medGray2)`,
	"medGray2Active": `var(--medGray2Active)`,
	"black": `var(--black)`,
	"primaryCalendarColor": `var(--primaryCalendarColor)`,
	"secondaryCalendarColor": `var(--secondaryCalendarColor)`
};
export default ___CSS_LOADER_EXPORT___;
