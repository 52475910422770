import React from 'react';
import './UpdateAvail.scss';

const UpdateAvail = () => {
	return (
		<div className="updateAvail">
			<p>New version is available!</p>
			<button className="update-btn" onClick={() => window.location.reload()}>
				Refresh
			</button>
		</div>
	);
};

export default UpdateAvail;
